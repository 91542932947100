import React, { useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown'
import "../../layout/TutorPayment/EarningHistory.css";
import Input from "../../components/inputs";
import theme from "../../theme";
import { trackPromise } from "react-promise-tracker";
import {IDENTITY_URL, PAYMENT_URL} from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import NoFileHistory from "./NoFileHistory"
import moment from "moment";
import {useTranslation} from "react-i18next";
import Button from "../../components/buttons";


const FileLists: React.FC<any> = ({ handleStatus, uploadedFileName }) => {

    const [fileList, setFileList] = useState([]);
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const { t } = useTranslation();

    const LoadUserFileList = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(IDENTITY_URL + "/api/v1/users/file"));
//        const res = await trackPromise(axios.get<any, ReturnProps>(PAYMENT_URL + "/api/v1/payments/user/revenue"));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            return null
        }
        setFileList(res.data);
        //  dispatch({ type: "TEACHER_DASHBOARD_CHANGED" , payload : res.data });
    }

    const DeleteFile = async (fileId:any) => {
        const res = await trackPromise(axios.delete<any, ReturnProps>(IDENTITY_URL + "/api/v1/users/file/"+fileId));
//        const res = await trackPromise(axios.get<any, ReturnProps>(PAYMENT_URL + "/api/v1/payments/user/revenue"));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            return null
        }
        await LoadUserFileList();
        //  dispatch({ type: "TEACHER_DASHBOARD_CHANGED" , payload : res.data });
    }

    useEffect(() => {
        LoadUserFileList()
    }, []);

    useEffect(() => {
        LoadUserFileList()
    }, [uploadedFileName]);

    return (
        <React.Fragment>
            {(!fileList || fileList.length < 1) && (<NoFileHistory />)}

            {(fileList && fileList.length > 0) && (
                <div className="payout-table-wrapper">
                    <h3>{t("general.file_list")}</h3>
                    <div className="table-responsive">
                        <table className="table payout-table">
                            <thead>
                            <tr>
                                <th>{t("general.created_at")}</th>
                                <th>{t("general.file_name")}</th>
                                <th>{t("general.file_url")}</th>
                                <th>{t("general.delete")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {fileList.map((item: any, index: number) =>
                                (
                                    <tr key={item.createdAt}>
                                        <td>{moment(item.createdAt * 1000).format("DD/MM/YYYY")}</td>
                                        <td>{item.fileName}</td>
                                        <td><a href={item.fileURL} target="_blank" rel="noopener noreferrer">{item.fileURL}</a></td>
                                        <td><Button primary={true} secondary={true}  onClick={()=>DeleteFile(item.guid)} disabled={false} filter={false}  transparent={false}  type={"tutorsCardBtn"}>{t("general.delete")}</Button></td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            )}
        </React.Fragment>
    )
}

export default FileLists;

import React from "react";
import "../../layout/InstructorProfileSettings/ProfileFooter.css";
import StyledButton from "../../components/buttons";
import styled,{ css } from "styled-components";
import theme from "../../theme";

const Button = styled(StyledButton)`
    ${
        ({ primary, transparent }) =>{
            if(primary) return css`
                padding: 10px 61px;
            `;
            if(transparent) return css`
                padding: 10px 61px;
                &:hover {
                    background-color: #fff;
                    color: ${theme.colors.primary};
                }
            `;
        }
    }
`;

const ProfileFooter : React.FC<any> = ({onClick}) => {
    return (
        <div className="instructor-profile-footer">
            <div className="instructor-profile-footer-container">
                <Button type="third" transparent>Cancel</Button>
                <Button type="default" onClick={onClick} primary>Save Changes</Button>
            </div>
        </div>
    )
}

export default ProfileFooter;

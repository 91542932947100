import React, {useState} from "react";
import "../layout/Footer.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
  faLinkedinIn,
  faWhatsapp,
  faWhatsappSquare,
  faInstalod,
  faInstagramSquare
} from "@fortawesome/free-brands-svg-icons";
import "../mobile/Footer.css";
import "../tablet/Footer.css";
import Container from "./Container";
import ChatDialog from "./chat/ChatDialog";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import whatsappIcon from "../assets/Home/icon-whatsapp.png";
import { Image } from "react-bootstrap";
import { FaInstagram } from "react-icons/fa";
import ReactFlagsSelect from "react-flags-select";

// The Footer Component

const Footer = ({ }) => {

  const history = useHistory();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Container>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ChatDialog />
        </div>
      </Container>

      <Container>
        <div >
          <a id="chatButton" href="https://web.whatsapp.com/send?phone=902162180218&amp;text=" className="whatsapp-button-container" rel="noreferrer" target="_blank">
            {/* <i className="fab fa-whatsapp"></i>
          <span className="chatText">WhatsApp Hattı</span> */}
            <Image
              src={whatsappIcon}
              className="whatsapp-icon"
              onClick={() => { window.location.href = "http://api.whatsapp.com/send?phone=902162180218" }}
            />
            {/* <a target="_blank" href="http://api.whatsapp.com/send?phone=902162180218"  className="whatsapp-link">
                  Whatsapp
                </a> */}
            <span className="chatText">WhatsApp</span>
          </a>
        </div>
      </Container>

      <footer>
        <div className="footer-container">
          <Container className="pt-3">
            {/* The Top Part Of The Footer */}
            <div className="footer-top">
              <div className="footer-top-container">
                <a href="/tutor/register" className="footer-top-link">
                  {t("general.become_tutor")}
                </a>
                <a href="/packages" className="footer-top-link">
                  {t("general.buy_package")}
                </a>
                {/* <a href="/support/student" className="footer-top-link">
                  {t("general.support")}
                </a> */}
              </div>
            </div>
            {/* The Bottom Part OF The Footer */}
            <div className="footer-bottom pt-3">
              <p>&copy; 2024 Speakingline</p>
              <div className="footer-bottom-centered-links">

                <a onClick={() => { history.push("/policy/privacy") }} className="footer-top-link">
                  {t("general.privacy_policy")}
                </a>
                {/* <p> {t("general.privacy_policy")}</p> */}

                <a onClick={() => { history.push("/policy/usage") }} className="footer-top-link">
                  {t("general.terms_Use")}
                </a>

                {/* <p>{t("general.terms_Use")}</p> */}
              </div>
              {/* Social Media Icons */}
              <div className="footer-bottom-social-media">
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size={"lg"}
                  className="footer-icon"
                  onClick={() => { window.location.href = "https://www.facebook.com/speakinglineapp" }} />

                <FontAwesomeIcon
                  icon={faYoutube}
                  size={"lg"}
                  className="footer-icon"
                  onClick={() => { window.location.href = "https://www.youtube.com/channel/UC3xaQCQoQxZTb0JhzpohX4g" }} />

                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  size={"lg"}
                  className="footer-icon"
                  onClick={() => { window.location.href = "https://www.linkedin.com/company/speakingline/" }} />
                <FontAwesomeIcon
                  icon={faInstagramSquare}
                  size={"lg"}
                  className="footer-icon"
                  onClick={() => { window.location.href = "https://www.instagram.com/speakinglineapp" }} />
                {/* <FontAwesomeIcon
                  icon={faYoutube}
                  size={"lg"}
                  className="footer-icon"
                  onClick={() => { window.location.href = "https://youtube.com/Speakinglineapp" }}
                />
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  size={"lg"}
                  className="footer-icon"
                  onClick={() => { window.location.href = "https://linkedin.com/Speakinglineapp" }}
                /> */}
                {/* <Image
                  src={whatsappIcon}
                  className="whatsapp-icon"
                  onClick={() => { window.location.href = "http://api.whatsapp.com/send?phone=902162180218" }}
                />
                 <a target="_blank" href="http://api.whatsapp.com/send?phone=902162180218"  className="whatsapp-link">
                  +90 (216) 2180218
                </a> */}
              </div>
            </div>
          </Container>
        </div>
      </footer>

    </React.Fragment>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import Rank from '../../components/tutorDashboard/rank';
import Summary from '../../components/tutorDashboard/summary';
import UpcomingReservations from '../../components/tutorDashboard/upcomingReservations';
import { STUDENT_DASHBOARD_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import AlertInfo from "../../components/alerts/AlertInfo";
import { useTranslation  } from "react-i18next";


const TopBar = () => {
    const { t } = useTranslation();

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [data, setData] = useState<any>(null);

    const dispatch = useDispatch();

    const LoadTeacherDashboardData = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(STUDENT_DASHBOARD_URL + "/api/v1/teacher/dashboard"));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            return null
        }
        //  dispatch({ type: "TEACHER_DASHBOARD_CHANGED" , payload : res.data });
        setData(res.data);
    }

    useEffect(() => {
        LoadTeacherDashboardData()
    }, []);

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />

            <div className="d-flex justify-content-between tutor-dashboard-top-section">
                <Rank response={data} />
                <Summary response={data} />
                <UpcomingReservations response={data} />

            </div>
        </React.Fragment>
    )
}

/* 
* Here We Need To Specify All The Data That Will Be Stored In 
* The Redux State So We Could Control It More Effeciently Later On
* (Instead Of Using any Type In The TopBar Component)
*/

// interface StateProps {

// }

// function mapStateToProps(state:any) {
//     return {
//         auth: state.AuthReducer,
//         dashboard: state.StudentDashboardReducer
//     }
// }

// interface DispatchProps {
//     updateUserLoggedIn: (isLogged: any) => void
//     updateStudentDashboard: (studentDashboard: any) => void
// }

export default TopBar;

//export default connect(mapStateToProps)(TopBar);
import { css } from "styled-components";
import theme from "../../theme";

const StyledButton = css`
    background-color: ${theme.buttons.colors.transparent};
    border-color: ${theme.buttons.colors.transparent};
    color: ${theme.buttons.colors.primary};
    &:hover {
        background-color: ${theme.buttons.colors.primary};
        border-color: ${theme.buttons.colors.primary};
        color: ${theme.buttons.colors.white};
    }
    &:focus, 
    &:active {
        background-color: ${theme.buttons.colors.transparent};
        border-color: ${theme.buttons.colors.transparent};
        color: ${theme.buttons.colors.primary};
    }
`;

export default StyledButton;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import "react-datetime/css/react-datetime.css";
import reportWebVitals from './reportWebVitals';
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";
import AuthReducer from "./reducers/AuthReducer";
import LogoutReducer from "./reducers/LogoutReducer";
import SocketConnectionReducer from "./reducers/SocketConnectionReducer";
import DateRangeFilterReducer from "./reducers/DateRangeFilterReducer";
import SearchTextReducer from "./reducers/SearchTextReducer";
import StudentDashboardReducer from "./reducers/StudentDashboard/StudentDashboardReducer";
import MessagesReducer from "./reducers/StudentVideoChat/MessagesReducer";
import CameraReducer from "./reducers/StudentVideoChat/CameraReducer";
import MicroReducer from "./reducers/StudentVideoChat/MicroReducer";
import ChatRoomReducer from "./reducers/StudentVideoChat/ChatRoomReducer";
import OwnerMessagesReducer from "./reducers/StudentVideoChat/OwnerMessagesReducer";
import CouponReducer from "./reducers/CouponReducer";
import ChatReducer from "./reducers/Chat/ChatReducer";
import ChatDialogReducer from "./reducers/Chat/ChatDialogReducer";

import "./Global/Utilities/axios.config";
import "./Global/Utilities/moment.config";
import "./Global/Utilities/i18n.config";

// import { usePromiseTracker } from "react-promise-tracker";
//import Loader from 'react-loader-spinner';

const store = createStore(combineReducers({
  AuthReducer,
  SocketConnectionReducer,
  LogoutReducer,
  DateRangeFilterReducer,
  SearchTextReducer,
  StudentDashboardReducer,
  messages: MessagesReducer,
  cameraReducer: CameraReducer,
  microReducer: MicroReducer,
  chatRoomReducer: ChatRoomReducer,
  ownerMessages: OwnerMessagesReducer,
  // InstructorsData: InstructorsReducer,
  CouponReducer,
  ChatReducer,
  ChatDialogReducer
}))

ReactDOM.render(

  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React,{ useRef, useState } from "react";
import { InputGroup, FormControl, Image } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import theme from "../../theme";
import "../../layout/inputs/inputs.css";

const InputIcon = styled(InputGroup.Append)`
    margin: 0;
    padding: 0 5px;
    margin-top: 3px;
    cursor: pointer;
`;

const InputContainer = styled(InputGroup)`
    border: 1px solid ${theme.colors.line};
    border-radius: 4px;
    background-color: #FFFCFC;
    &:hover {
        border-color: ${theme.colors.filter};
    }
`;

const InputControl = styled(FormControl)`
    border: 0;
    font-family: ${theme.text.fonts.poppins} !important;
    color: ${theme.text.colors.main} !important;
    font-weight: ${theme.text.weight.light} !important;
    font-size: 16px !important;
    &:focus,
    &:active {
        outline: none;
        box-shadow: none;
    }
    &::-ms-reveal,
    &::-ms-clear {
        display: none;
    }
    background-color: #FFFCFC;
`;

const Label = styled.label`
  text-align: left;
  color: ${theme.text.colors.main};
  font-family: ${theme.text.fonts.poppins};
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  align-items: center;
`;

interface PasswordInputProps {
    name?:string,
    style?: React.CSSProperties,
    labelValue?: string,
    value?: string,
    onChange?: (e:React.ChangeEvent<HTMLInputElement>) => void,
    placeholder?: string;
}

const PasswordInput:React.FC<PasswordInputProps> = ({
    name,
    style,
    labelValue,
    value,
    onChange,
    placeholder=""
}) =>{
    const inputRef:any = useRef();
    const handleFocus = () =>{
        inputRef.current!.style.borderColor = theme.colors.primary;
        setFocused(true)
    }
    const handleUnFocus = () =>{
        inputRef.current!.style.borderColor = theme.colors.line;
        setFocused(false)
    }
    const [icon, setIcon] = useState(theme.icons.password.hide);
    const [showPassword, setShowPassword] = useState(false);
    const [focused, setFocused] = useState(false)
    const handleShowPassword = () =>{
        if(showPassword) {
            setIcon(theme.icons.password.hide)
            setShowPassword(false)
        } else {
            setIcon(theme.icons.password.show)
            setShowPassword(true)
        }
    }
    return (
        <>
            {
                labelValue && <Label>{ labelValue }</Label>
            }
            <InputContainer style={{ ...style, backgroundColor: focused && "#fff" }} size="lg" className="mb-3 input_container" ref={inputRef}>
                <InputControl
                    name = {name}
                    placeholder={placeholder}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    onFocus={handleFocus}
                    onBlur={handleUnFocus}
                    type={!showPassword ? "password" : "text"}
                    className="input-font-family"
                    value={value}
                    onChange={onChange}
                />
                <InputIcon onClick={handleShowPassword}>
                    {
                        <Image src={icon} height={32} width={32} />
                    }
                </InputIcon>
            </InputContainer>
        </>
    )
}

export default PasswordInput;

import React from "react";
import { Form } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import theme from "../../theme";
import "../../layout/inputs/inputs.css";

const InputContainer = styled(Form.Control)`
    border: 1px solid ${theme.colors.line};
    border-radius: 4px;
    background-color: #FFFCFC;
    &:hover {
        border-color: ${theme.colors.filter};
    }
    font-family: ${theme.text.fonts.poppins} !important;
    color: ${theme.text.colors.main} !important;
    font-weight: ${theme.text.weight.light} !important;
    font-size: 16px !important;
    &:focus,
    &:active {
        outline: none;
        box-shadow: none;
        border-color: ${theme.colors.primary};
    }
    &::-ms-reveal,
    &::-ms-clear {
        display: none;
    }
`;

const Input:React.FC<{ style?:React.CSSProperties }> = ({ style }) =>{
    return (
        <InputContainer type="text" size="lg" placeholder="" className="input-font-family" style={style} />
    )
}

export default Input;

const initialState = {
    chatMessage : null
}

interface ActionProps {
    type: String,
    payload: any
}

export default function(state=initialState, action:ActionProps) {
    switch(action.type) {
        case "CHAT_MESSAGE_IS_RECEIVED":
            return state = { ...state, chatMessage: action.payload }
        default:
            return state
    }
}

import React, { useEffect } from "react";
import "../../../layout/Home/Home.css";
import Banner from "./Banner";
import ExpertsInstructors from "./ExpertsInstructors";
import WhySpeakingline from "../WhySpeakingline";
import HowDoesItWork from "./HowDoesItWork";
import SubscriptionsPackages from "./SubscriptionsPackages";
import StudentsReviews from "./StudentsReviews";
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";
import MobileApplication from "./MobileApplication";
import NewFooter from "../../../components/NewFooter";
import "../../../animation/Home/animation.css";
import { controlAnimation } from "../../../animation/Home/animation";
import NavBar from "../../../components/navbars";
import oval from "../../../assets/oval.png";
import { Container, Image } from "react-bootstrap";
import HowItWorks from "./HowItWorks";
import hero from "../../../assets/Home/hero-career.png";
import StartNow from "./StartNow";
import start_now_1 from "../../../assets/Home/corp-start-now-1.png";
import start_now_2 from "../../../assets/Home/corp-start-now-2.png";
import start_now_3 from "../../../assets/Home/corp-start-now-3.png";
import start_now_4 from "../../../assets/Home/corp-start-now-4.png";
import corpCertifiedTrainers from "../../../assets/Home/career-message.png";
import CertifiedTrainers from "./CertifiedTrainers";
import career_how_img1 from "../../../assets/Home/career-how-it-works-1.png";
import corp_how_img2 from "../../../assets/Home/kid-how-it-works-2.png";
import corp_how_img3 from "../../../assets/Home/kid-how-it-works-3.png";

import { questionsForCorprate } from "../../../Global/FrequentlyAskedQuestions";


const HomeV2_Career: React.FC<any> = ({ history }) => {
    useEffect(() => {
        controlAnimation()
    }, []);

    var howDoesItWork = [{ index: 0, title: "career.how_does_it_works.first.title", description: "career.how_does_it_works.first.content", image: career_how_img1 }];

    return (

        <div>
            <div className="home">
                <div style={{ backgroundImage: "url(" + hero + ")" }} className="super_hero">
                    <NavBar type="new_main" history={history} links={[["home.navigation_bar.btn_forkids", "/kids"], ["home.navigation_bar.btn_forbeatutor", "/tutor"], ["home.navigation_bar.btn_forcorp", "/corporate"]]} />
                    <Banner title="career.banner.title" description="career.banner.description" />
                </div>
                {/* <StartNow title="Kurumunuz için en uygun çözümler" items={startNowList} /> */}
                {/* <CertifiedTrainers title="Anadili İngilizce olan uzman sertifikalı eğitmenler" description="Şimdi kaydolun ve Speakingline ayrıcalıklarını keşfedin" image={corpCertifiedTrainers} /> */}
                {/* <ExpertsInstructors /> */}
                <HowDoesItWork items={howDoesItWork} />
                <CertifiedTrainers title="career.certified_trainers.title" description="career.certified_trainers.description" buttonText="career.certified_trainers.button_text" buttonOnClick={() => {window.location.href = "https://www.linkedin.com/company/speakingline/jobs/" }} image={corpCertifiedTrainers} />
                {/* <MessageBanner /> */}
                {/* <SubscriptionsPackages /> */}
                {/* <StudentsReviews /> */}
                {/* <FrequentlyAskedQuestions questions={questionsForCorprate} /> */}
                {/* <MobileApplication /> */}
                <NewFooter />
            </div >
        </div>
    )
}

export default HomeV2_Career;

import React from 'react';
import { Card } from 'react-bootstrap';
import { Chart } from "react-google-charts";
import { useTranslation  } from "react-i18next";


interface SummaryProps
{
    response : any
}

const Summary : React.FC<SummaryProps> = ({
    response,
}) => {
    const { t } = useTranslation();

    const totalMinute = response?.conversationStatistic?.totalMinute ?? 0;
    const totalMeeting = response?.conversationStatistic?.countOfConversation ?? 0;
    const reservedMeetingCount : number = response?.conversationStatistic?.countOfReservedConversation ?? 0;
    const adHocMeetingCount : number = response?.conversationStatistic?.countOfAdHocConversation ?? 0;

    const reservationRate = totalMeeting > 0 ? reservedMeetingCount/totalMeeting * 100  : 0;
    const adhocRate = totalMeeting > 0 ? adHocMeetingCount/totalMeeting * 100  : 0;

    const currentRate = ((response?.revenueStatistic?.currentRate ?? 0)*60).toFixed(2);
    const currentMonthRevenue = (response?.revenueStatistic?.currentMonthRevenue ?? 0).toFixed(2);
    const totalRevenue = (response?.revenueStatistic?.totalRevenue ?? 0).toFixed(2);

    return (
        <Card className="large-card">
            <Card.Body>
                <h5>SUMMARY</h5>
                <div className="d-flex justify-content-between">
                    <div className="summary-small-card">
                        <p>Total Meeting</p>
                        <b>{totalMinute} min.</b>
                        <p>{totalMeeting} meeting</p>
                    </div>

                    <div className="summary-large-card">
                        <div className="chart-wrapper">
                            {totalMeeting > 0 && <Chart
                                width={'160px'}
                                height={'160px'}
                                chartType="PieChart"
                                loader={<div>Loading Chart</div>}
                                data={[
                                    ['Task', 'Hours per Day'],
                                    ['Work', reservedMeetingCount],
                                    ['Eat', adHocMeetingCount],
                                    // ['Work', reservedMeetingCount],
                                    // ['Eat', adHocMeetingCount],

                                    // ['Work', {reservedMeetingCount}],
                                    // ['Eat', {adHocMeetingCount}],

                                ]}
                                options={{
                                    //title: 'My Daily Activities',
                                    // Just add this option
                                    pieHole: 0.6,
                                    backgroundColor: 'none',
                                    legend: "none",
                                    pieSliceText: 'none',
                                    tooltip: { trigger: 'none' },
                                    slices: {
                                        0: { color: '#5389F8' },
                                        1: { color: '#F86F53' },
                                    },
                                }}
                                rootProps={{ 'data-testid': '3' }}
                            />}
                            

                            <div className="chart-content">
                                <h3 className="blue">{t("dashboard.reservation_calls")} %{reservationRate}</h3>
                                <h3 className="orange">{t("dashboard.instant_calls")} %{adhocRate}</h3>
                            </div>
                        </div>

                    </div>
                    <div className="summary-small-card last-card">
                        <div className="card-content">
                            <h4>{t("dashboard.hourly_wage")}</h4>
                            <p>$ {currentRate}</p>
                        </div>

                        <div className="card-content">
                            <h4>{t("dashboard.grand_monthly_earnings")}</h4>
                            <p>$ {currentMonthRevenue}</p>
                        </div>

                        <div className="card-content total">
                            <h4>{t("dashboard.grand_total_earnings")}</h4>
                            <p>$ {totalRevenue}</p>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default Summary;
import React, { useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown'
import "../../layout/TutorPayment/EarningHistory.css";
import Input from "../../components/inputs";
import theme from "../../theme";
import Button from "../../components/StatusButton";
import { trackPromise } from "react-promise-tracker";
import { PAYMENT_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import NoEarningHistory from "./NoEarningHistory"
import moment from "moment";


const EarningHistory: React.FC<any> = ({ handleStatus }) => {

    const [monthlyRevenueList, setMonthlyRevenueList] = useState([]);
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const LoadTeacherRevenueMonthly = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(PAYMENT_URL + "/api/v1/payments/user/revenue"));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            return null
        }
        setMonthlyRevenueList(res.data);
        //  dispatch({ type: "TEACHER_DASHBOARD_CHANGED" , payload : res.data });
    }

    useEffect(() => {
        LoadTeacherRevenueMonthly()
    }, []);

    return (
        <React.Fragment>
            {(!monthlyRevenueList || monthlyRevenueList.length < 1) && (<NoEarningHistory />)}

            {(monthlyRevenueList && monthlyRevenueList.length > 0) && (
                <div className="payout-table-wrapper">
                    <h3>Earning History</h3>
                    <div className="table-responsive">
                        <table className="table payout-table">
                            <thead>
                                <th>Month</th>
                                <th>Meeting Count</th>
                                <th>Total Minute</th>
                                <th>Total ( USD )</th>
                            </thead>
                            <tbody>
                                {monthlyRevenueList.map((item: any, index: number) =>
                                (
                                    <tr>
                                        <td>{moment(item.month * 1000).format("MMMM, YYYY")}</td>
                                        <td>{item.meetingCount}</td>
                                        <td>{item.totalDuration}</td>
                                        <td>{Math.round(item.totalRevenueAmount * 100) / 100}</td>
                                    </tr>
                                ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Total</th>
                                    <th>{monthlyRevenueList.reduce((n, { meetingCount }) => n + meetingCount, 0)}</th>
                                    <th>{monthlyRevenueList.reduce((n, { totalDuration }) => n + totalDuration, 0)}</th>
                                    <th>{Math.round((monthlyRevenueList.reduce((n, { totalRevenueAmount }) => n + totalRevenueAmount, 0)) * 100) / 100}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

            )}
        </React.Fragment>
    )
}

export default EarningHistory;

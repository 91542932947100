import React, { useEffect } from "react";
import "../../../layout/Home/Home.css";
import Banner from "./Banner";
import ExpertsInstructors from "./ExpertsInstructors";
import WhySpeakingline from "../WhySpeakingline";
import HowDoesItWork from "./HowDoesItWork";
import SubscriptionsPackages from "./SubscriptionsPackages";
import StudentsReviews from "./StudentsReviews";
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";
import MobileApplication from "./MobileApplication";
import NewFooter from "../../../components/NewFooter";
import "../../../animation/Home/animation.css";
import { controlAnimation } from "../../../animation/Home/animation";
import NavBar from "../../../components/navbars";
import oval from "../../../assets/oval.png";
import { Container, Image } from "react-bootstrap";
import HowItWorks from "./HowItWorks";
import hero from "../../../assets/Home/hero_tutor.png";
import StartNow from "./StartNow";
import tutor_start_now_1 from "../../../assets/Home/tutor_start_now_1.png";
import tutor_start_now_2 from "../../../assets/Home/tutor_start_now_2.png";
import tutor_start_now_3 from "../../../assets/Home/tutor_start_now_3.png";

import tutorCertifiedTrainers from "../../../assets/Home/tutor-certified-trainers.png";
import CertifiedTrainers from "./CertifiedTrainers";

import home_how_img1 from "../../../assets/Home/tutor-how-it-works-1.png";
import home_how_img2 from "../../../assets/Home/tutor-how-it-works-2.png";
import home_how_img3 from "../../../assets/Home/tutor-how-it-works-3.png";

import MessageBanner from "./MessageBanner";
import { questionsForKids } from "../../../Global/FrequentlyAskedQuestions";
import TuttorMessageBanner from "./TuttorMessageBanner";


const HomeV2_Tutor: React.FC<any> = ({ history }) => {
    useEffect(() => {
        controlAnimation()
    }, []);

    var startNowList = [{ description: "tutor.startnow.first.title", image: tutor_start_now_1 },
    { description: "tutor.startnow.second.title", image: tutor_start_now_2 },
    { description: "tutor.startnow.third.title", image: tutor_start_now_3 }
    ];

    var howDoesItWork = [{ index: 0, title: "tutor.how_does_it_works.first.title", description: "tutor.how_does_it_works.first.content", image: home_how_img1 },
    { index: 1, title:"tutor.how_does_it_works.second.title" , description: "tutor.how_does_it_works.second.content", image: home_how_img2 },
    { index: 2, title: "tutor.how_does_it_works.third.title", description: "tutor.how_does_it_works.third.content", image: home_how_img3 }
    ];

    return (

        <div>
            <div className="home">
                <div style={{ backgroundImage: "url(" + hero + ")" }} className="super_hero">
                    <NavBar type="new_main" history={history} links={[["home.navigation_bar.btn_forkids", "/kids"], ["home.navigation_bar.btn_forbeatutor", "/tutor"], ["home.navigation_bar.btn_forcorp", "/corporate"]]} />
                    <Banner title="tutor.banner.title" description="tutor.banner.description" buttonText="tutor.banner.button_text" butttonAddress="/tutor/register" />
                </div>
                <StartNow title="" items={startNowList} />
                {/* <ExpertsInstructors /> */}
                <HowDoesItWork items={howDoesItWork} />
                <TuttorMessageBanner />
                <CertifiedTrainers title="tutor.certified_trainers.title" description="tutor.certified_trainers.description" buttonText="tutor.certified_trainers.button_text" buttonOnClick={() => { history.push("/tutor/register") }} image={tutorCertifiedTrainers} />
                <NewFooter />
            </div >
        </div>
    )
}

export default HomeV2_Tutor;
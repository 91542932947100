import moment from "moment";
import React from "react";
import "../../layout/StudentVideoChat/OwnerMessage.css";
import Linkify from 'react-linkify'

interface MessageProps {
    content: String,
    time: String
}

interface OwnerMessagesProps {
    message: String,
    time: number,
}

const OwnerMessage: React.FC<OwnerMessagesProps> = ({ message, time }) => {
    var dt = moment(time * 1000).format("HH:mm");
    return (
        <div className="chatroom-message-container">

            <div className="chatroom-message-wrapper">
                {/* <div className="chatroom-message-circle" /> */}
                <div className="chatroom-message-content-wrapper">
                    <div className="chatroom-message-content-info-wrapper">
                        <p className="chatroom-message-time">{dt}</p>
                        <div className="chatroom-message-content-container">
                            <p className="chatroom-message-content-text">
                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                    <a target="blank" href={decoratedHref} key={key}>
                                        {decoratedText}
                                    </a>
                                )}>
                                    {message}
                                </Linkify>

                            </p>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default OwnerMessage


import React, {useState} from "react";
import "../../../layout/Report/report.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Image, Row, Col } from "react-bootstrap";
import EmailVerificationResult from "./ReportEdit";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/navbars";
// @ts-ignore
import ReportEdit from "./ReportEdit";
import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import {ReturnProps} from "../../../@types/functions";
import {CONVERSATION_URL, IDENTITY_URL} from "../../../Global/Constants/constants";

interface ReportEditComponentProps {
    report: any;
    onSubmitRef: (reportData: any) => void;
}

const ReportEditComponent:React.FC<ReportEditComponentProps> = ({report, onSubmitRef}) =>{

    const [packageName, setPackageName] = useState(report.packageName);
    const [packageMinute, setPackageMinute] = useState(report.packageMinute);
    const [successfullMeetingDates, setSuccessfullMeetingDates] = useState(report.successfullMeetingDates);
    const [successRate, setSuccessRate] = useState(report.successRate);
    const [successfullMeetingCount, setSuccessfullMeetingCount] = useState(report.successfullMeetingCount);
    const [tutorName, setTutorName] = useState(report.tutorName);
    // const [totalMeetingCount, setTotalMeetingCount] = useState(report.totalMeetingCount);
    const [languageLevel, setLanguageLevel] = useState<number>(parseInt(report.tutorComment_LanguageLevel));
    const [vocabulary, setVocabulary] = useState<number>(parseInt(report.tutorComment_Vocabulary));
    const [grammar, setGrammar] = useState<number>(parseInt(report.tutorComment_Grammar));
    const [speed, setSpeed] = useState<number>(parseInt(report.tutorComment_Speed));
    const [result, setResult] = useState<number>(parseInt(report.tutorComment_Result));
    const [overall, setOverall] = useState(report.tutorComment_Overall);


    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const reportData = {
            "ReportId": report.guid,
            "SuccessfullMeetingDates": successfullMeetingDates,
            "SuccessRate": successRate,
            "PackageName": packageName,
            // "SuccessfullMeetingCount": parseInt(successfullMeetingCount),
            // "TotalMeetingCount": parseInt(totalMeetingCount),
            "TutorName": tutorName,
            "TutorComment_LanguageLevel": languageLevel.toString(),
            "TutorComment_Vocabulary": vocabulary.toString(),
            "TutorComment_Grammar": grammar.toString(),
            "TutorComment_Speed": speed.toString(),
            "TutorComment_Result": (languageLevel + vocabulary + grammar + speed).toString(),
            "TutorComment_Overall": overall
        };

        onSubmitRef(reportData);
    };

    var totalScore = languageLevel + vocabulary + grammar + speed;

    return (
        <div className="report-edit">
            <form onSubmit={onSubmit}>
                <span>GUID : {report.guid}</span>
                <span>Report Code  : {report.reportCode}</span>
                <label>
                    Student Name:
                    <input type="text" value={report.user.name + ' ' + report.user.surname} readOnly={true}/>
                </label>
                <label>
                    Package Name:
                    <input type="text" value={packageName} onChange={(e) => setPackageName(e.target.value)}/>
                </label>
                <label>
                    Package Minute:
                    <input type="text" readOnly={true} value={packageMinute} onChange={(e) => setPackageMinute(e.target.value)}/>
                </label>
                <label>
                    Successful Meeting Dates:
                    <input type="text" value={successfullMeetingDates}
                           onChange={(e) => setSuccessfullMeetingDates(e.target.value)}/>
                </label>
                <label>
                    Participation Rate (%):
                    <input type="text" value={successRate} onChange={(e) => setSuccessRate(e.target.value)}/>
                </label>
                {/*<label>*/}
                {/*    Successful Meeting Count:*/}
                {/*    <input type="text" value={successfullMeetingCount}*/}
                {/*           onChange={(e) => setSuccessfullMeetingCount(e.target.value)}/>*/}
                {/*</label>*/}
                {/*<label>*/}
                {/*    Total Meeting Count:*/}
                {/*    <input type="text" value={totalMeetingCount}*/}
                {/*           onChange={(e) => setTotalMeetingCount(e.target.value)}/>*/}
                {/*</label>*/}
                <label>
                    Tutor:
                    <input type="text" value={tutorName}
                           onChange={(e) => setTutorName(e.target.value)}/>
                </label>
                <label>
                    Language Perception:
                    <input type="number" value={languageLevel} onChange={(e) => setLanguageLevel(parseInt(e.target.value))}/>
                </label>
                <label>
                    Vocabulary:
                    <input type="number" value={vocabulary} onChange={(e) => setVocabulary(parseInt(e.target.value))}/>
                </label>
                <label>
                    Grammar:
                    <input type="number" value={grammar} onChange={(e) => setGrammar(parseInt(e.target.value))}/>
                </label>
                <label>
                    Speed:
                    <input type="number" value={speed} onChange={(e) => setSpeed(parseInt(e.target.value))}/>
                </label>
                <label>
                    Total Score:
                    <input type="number" readOnly={true} value={totalScore} />
                </label>
                <label>
                    Tutor Comment:
                    <input type="text" value={overall} onChange={(e) => setOverall(e.target.value)}/>
                </label>
                <button type="submit">Submit</button>
            </form>
        </div>
    )
}

export default ReportEditComponent;

import React from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import { dynamicDateFormate } from '../../../utils/helper';
import instructor from '../../../assets/instructor1.png';
import moment from 'moment';
import {useTranslation} from "react-i18next";
interface ReserveDetailsInterface {
    show: Boolean;
    hide: () => void;
    selectedEvent: any
}
const ReserveDetails: React.FC<ReserveDetailsInterface> = ({
    show,
    hide,
    selectedEvent
}) => {
    const { t } = useTranslation();

    return (
        <div className="confirmationModal">
            <button onClick={() => hide()} className="close">x</button>
            <div className="d-flex confirmation-inner">
                <div className="img-wrapper">
                    <Image src={instructor} />
                </div>
                <div className="confirmation-content">
                    <h5>{selectedEvent.title}</h5>
                    <div className="day">{dynamicDateFormate(new Date(selectedEvent.start), "dddd.MM.YYYY")}</div>
                    <div className="date">
                        <h5>
                            {
                                dynamicDateFormate(new Date(selectedEvent.start), "HH:mm") +
                                " - " + dynamicDateFormate(new Date(selectedEvent.end), "HH:mm")
                            }
                        </h5>
                        <span>{moment(selectedEvent.end).diff(moment(selectedEvent.start), 'minutes') + " minutes"}</span>
                    </div>
                </div>

            </div>
            <div className="confirmation-actions">
                <Button variant="light">{t("general.send_message")}</Button>
                <Button variant="light">{t("general.history")}</Button>
            </div>
        </div>

    )
}
export default ReserveDetails;
import React from "react";
import "../../layout/InstructorNotificationsSettings/Settings.css";
import Input from "../../components/inputs";

const Settings = () =>{
    return (
        <div className="instructor-notifications-settings-container">
            <h4 className="instructor-notifications-settings-title">Bildirim ve Gizlilik</h4>
            <div className="instructor-notifications-settings-wrapper">
                <Input 
                    type="checkbox" 
                    label="Seanslarımı kaydet" 
                />
                <Input 
                    type="checkbox" 
                    label="Tarafıma mesaj gönderildiğinde mobil bildirim almak istiyorum" 
                />
                <Input 
                    type="checkbox" 
                    label="Eğitmenin her seans sonrası ders hakkında yaptığı yorumları mobil bildirim almak istiyorum" 
                />
                <Input 
                    type="checkbox" 
                    label="Rezervasyonlarımı saatinden önce hatırlatmak için mobil bildirim almak istiyorum" 
                />
                <Input 
                    type="checkbox" 
                    label="Kampanyalar hakkında mobil bildirim almak istiyorum" 
                />
            </div>
            <hr className="instructor-notifications-settings-sep" />
        </div>
    )
}

export default Settings;

import React from "react";
import DescriptionInput from "../../components/inputs/DescriptionInput";
import "../../layout/InstructorProfileSettings/AboutMe.css";


interface SummaryProps {
    summary : string;
    setSummary : any;
}

const Summary : React.FC<SummaryProps> = ({
    summary,
    setSummary
}) =>{
    return (
        <div className="instructor-profile-about-me-container">
            <h4 className="instructor-profile-about-me-title">Summary</h4>
            <div className="instructor-profile-about-me-desc-container">
            <DescriptionInput value={summary} onChange={(e) => setSummary(e.target.value)}/>
            </div>
            <hr className="instructor-section-sep" />
        </div>
    )
}

export default Summary;

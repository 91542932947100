import React from "react";
import "../../layout/StudentVideoChat/ChatRoom.css";
import { Image } from "react-bootstrap";
import ChatRoomMessanger from "./ChatRoomMessanger";
import { useSpring, animated } from "react-spring";
//import { createSession } from "opentok-react";
import "../../mobile/StudentVideoChat/ChatRoom.css";

import ChatIcon from "../../assets/student-video-chat/chat-icon.png";
import close from "../../assets/student-video-chat/close.png";
import toggleMic from "../../assets/student-video-chat/toggle-mic.png";
import stopCall from "../../assets/student-video-chat/stop-call.png";
import toggleCameraImg from "../../assets/student-video-chat/toggle-camera.png";
import { microphoneOn, microphoneOff, videoOn, videoOff, leaveCall, shareScreen, stopShareScreen } from "../../assets/SVG/SVG";

interface RoomOptionsProp {
    isCameraVisible: boolean;
    isCameraOn: boolean;
    isShareScreenVisible: boolean;
    isShareScreenOn: boolean;
    isMicOn: boolean;
    toggleCamera: () => void;
    toggleMicro: () => void;
    stopCallClicked: () => void;
    toogleShareScreen: () => void;
}

const RoomOptions: React.FC<RoomOptionsProp> = ({
    isCameraVisible,
    isCameraOn,
    isMicOn,
    isShareScreenVisible,
    isShareScreenOn,
    stopCallClicked,
    toggleCamera = () => console.log("Toggle Camera"),
    toggleMicro = () => console.log("Toggle Micro"),
    toogleShareScreen = () => console.log("toogle ShareScreen")
}) => {

    return (
        <div className="chatroom-options">
            <div className="chatroom-options-container">

                <button style={{ background: "none" }} onClick={toggleMicro}>
                    {isMicOn ? microphoneOn : microphoneOff}
                </button>

                <button style={{ background: "none" }} onClick={stopCallClicked}>
                    {leaveCall}
                </button>

                {isCameraVisible && <button style={{ background: "none" }} onClick={toggleCamera}>
                    {isCameraOn ? videoOn : videoOff}
                </button>}

                {isShareScreenVisible && <button style={{ background: "none" }} onClick={toogleShareScreen}>
                    {isShareScreenOn ? stopShareScreen : shareScreen}
                </button>}

                {/* <Image
                    src=
                onClick={toggleMicro}
                /> */}
                {/* <Image
                    src={stopCall}
                    onClick={stopCallClicked}
                /> */}
                {/* <Image
                    src={isMicOn ? microphoneOn : microphoneOff}
                    onClick={toggleCamera}
                /> */}
            </div>
        </div>
    );
};

export default RoomOptions;

import { css } from "styled-components";
import theme from "../../theme";

const ButtonOption = css`
    height: 50px;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
    color: #777777;
    font-weight: 400;
    font-size: 16px;
    font-family: ${theme.text.fonts.nunito};
    border: 1px solid #929598;
`;

export default ButtonOption;

import React, { useState, useEffect } from "react";
import { Navbar, Image, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../layout/navbar/NavBarBurgerMenu.css";
import "../../mobile/navbars/MainNavBar.css"
import { MainNavBarProps } from "../../@types/NavBar";
import Container from "../Container";
import {
    NavBarContainer,
    NavBarWrapper,
    NavBarBrand,
    NavBarLinksContainer,
    NavBarLink,
    NavBarButtons,
    NavBarButton,
    NavBarLink_White
} from "./StyledComponents";

import logo from "../../assets/navbar/logo.png";
import lightLogo from "../../assets/navbar/light-logo.png";
import burgerMenu from "../../assets/navbar/burger-menu.png";
import {useTranslation} from "react-i18next";
import ReactFlagsSelect from "react-flags-select";

const NewMainNavBar: React.FC<MainNavBarProps> = ({ links, history, navTheme }) => {
    const [navLogo, setNavLogo] = useState<any>(null);
    const { t } = useTranslation();
    const handleNavLogo = () => {
        if (navTheme === "light") {
          //  setNavLogo(lightLogo)
        } else {
        //    setNavLogo(logo)
        }
        setNavLogo(lightLogo)
    }
    useEffect(() => {
        handleNavLogo()
    }, [])
    return (
        <NavBarContainer
            collapseOnSelect
            expand="lg"
        >
            <Container>
                <NavBarWrapper>
                    <div className="navbar-mobile">
                        <NavBarBrand>
                            <Image
                                src={navLogo}
                                className="brand-logo"
                                onClick={() => history.push("/")}
                            />
                        </NavBarBrand>
                        <Navbar.Toggle
                            aria-controls="responsive-navbar-nav"
                            className="main-nav-bar-burger-menu"
                        >
                            <Image src={burgerMenu} />
                        </Navbar.Toggle>
                    </div>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <NavBarLinksContainer>

                            {
                                links && links.map((link: any[]) => <NavBarLink_White key={link[0]} onClick={() => history.push(link[1])}>{t(link[0])}</NavBarLink_White>)
                            }

                        </NavBarLinksContainer>
                        <NavBarButtons>
                            <NavBarButton
                                type="third"
                                transparant={true}
                                style={{borderColor: '#ffffff'}}
                                onClick={() => history.push("/login")}
                            >
                                {t("home.navigation_bar.btn_login")}
                            </NavBarButton>
                            <NavBarButton
                                type="third"
                                primary={true}
                                onClick={() => history.push("/register")}
                            >
                                {t("home.navigation_bar.btn_register")}
                            </NavBarButton>
                        </NavBarButtons>
                    </Navbar.Collapse>
                </NavBarWrapper>
            </Container>
        </NavBarContainer>
    )
}

export default NewMainNavBar;

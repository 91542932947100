import React, { useState, useEffect } from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import CorparateForm from './CorparateForm';
import { trackPromise } from 'react-promise-tracker';
import axios from 'axios';
import { ReturnProps } from '../../@types/functions';
import { IDENTITY_URL } from '../../Global/Constants/constants';
import AlertInfo from '../../components/alerts/AlertInfo';

interface CorparateFormModalInterface {
    show: Boolean;
    hide: () => void,
}
const CorparateFormModal: React.FC<CorparateFormModalInterface> = ({
    show,
    hide,
}) => {

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [position, setPosition] = useState("");
    const [company, setCompany] = useState("");
    const [employeenumber, setEmployeenumber] = useState("");
    const [message, setMessage] = useState("");

    const [contactFrom, setContactFrom] = useState("");

    const actions = {
        setEmail: (event: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(event.target.value),
        setName: (event: React.ChangeEvent<HTMLInputElement>) =>
            setName(event.target.value),
        setSurname: (event: React.ChangeEvent<HTMLInputElement>) =>
            setSurname(event.target.value),
        setPhoneNumber: (event: any) =>
            setPhoneNumber(event),
        setPosition: (event: React.ChangeEvent<HTMLInputElement>) => setPosition(event.target.value),
        setCompany: (event: React.ChangeEvent<HTMLInputElement>) => setCompany(event.target.value),
        setEmployeenumber: (event: React.ChangeEvent<HTMLInputElement>) => setEmployeenumber(event.target.value),
        setMessage: (event: React.ChangeEvent<HTMLInputElement>) => setMessage(event.target.value),
        setContactFrom: (event: React.ChangeEvent<HTMLInputElement>) => setContactFrom(event.target.value),
    }

    const controllers = {
        email,
        name,
        surname,
        phoneNumber,
        position,
        company,
        employeenumber,
        message,
        contactFrom,
    }
    const handleSubmit = async () => {
        const corporateFormInfo: any = {
            email: controllers.email,
            name: controllers.name,
            surname: controllers.surname,
            phoneNumber: controllers.phoneNumber,
            position: controllers.position,
            company: controllers.company,
            employeenumber: controllers.employeenumber,
            message: controllers.message,
            contactFrom: controllers.contactFrom
        }

        console.log(corporateFormInfo);

        const res = await trackPromise(axios.post<any, ReturnProps>(IDENTITY_URL + "/api/v1/corporate/form", corporateFormInfo));

        // Handling Error
        if (res.success) {
            setError("Mesajınız ulaştı. En kısa zamanda geri dönüş sağlayacağız.");
            setShowError(true);
            setTimeout(() => hide(), 3000);
        }
        else {
            setError("Bir problem oluştu. Daha sonra tekrar deneyiniz");
            setShowError(true);
        }
    };

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={1} />
            <div>
                <Modal show={show} onHide={hide} size="lg">
                    <Modal.Header closeButton>
                        {/* <Modal.Title> {title} </Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                        <CorparateForm handleSubmit={handleSubmit}
                            controllers={controllers}
                            actions={actions}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    )
}
export default CorparateFormModal;
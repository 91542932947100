import React from "react";
import "../../layout/Payment/Adress.css";
import Input from "../../components/inputs";
import Button from "../../components/buttons";
import styled from "styled-components";
import { Form, FormControl } from "react-bootstrap";
import theme from "../../theme";

const FormBtn = styled(Button)`
    padding: 10px 43px;
    margin-left: 15px;
`;

const Adress:React.FC<any> = ({ closeFunc }) =>{
    return(
        <div className="payment-adress">
            <div className="adress-form-container">
                <h4 className="adress-payment-title">Billing Address</h4>
                <div className="adress-form">
                    <Input type="default" label="Full Name*" placeholder="Ertan Çetin" />
                    <div className="form-adress-input-container">
                        <Input type="default" label="Adress*" placeholder="313 Sokak No:44/A Efeler Mah." />
                    </div>
                    <div className="form-adress-input-container form-adress-info-input">
                        <div className="form-adress-input">
                            <Input type="select" label="Country*" options={["Turkey"]} />
                        </div>
                        <div className="form-adress-input">
                            <Input type="select" label="State" options={["Izmir"]} />
                        </div>
                    </div>
                    <div className="form-adress-info-input">
                        <div className="form-adress-input">
                            <Input type="select" label="City*" options={["Buca"]} />
                        </div>
                        <div className="form-adress-input">
                            <Input type="select" label="Postal Code*" options={["35100"]} />
                        </div>
                    </div>
                    <div className="adress-form-btns">
                        <FormBtn type="third" transparent onClick={closeFunc}>Cancel</FormBtn>
                        <FormBtn type="third" primary>Update</FormBtn>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Adress;

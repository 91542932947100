import React from "react";
import "../../../layout/HomeV2/New_StartNow.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Image, Row } from "react-bootstrap";
import { reviews } from "../../../Global/StudentsReviews";
// import "../../../mobile/Home/StudentsReviews.css";
// import "../../../tablet/Home/StudentsReviews.css";
import Container from "../../../components/Container";
import {useTranslation} from "react-i18next";

interface ContentComponentProps {
    items?: any[];
    title: string,
}

type ContentProps = ContentComponentProps;

const StartNow: React.FC<ContentProps> = ({ title, items }) => {
    const { t } = useTranslation();

    return (
        <div className="new_start-now pb-5">
            <Container className="new_start-now-container-start" id="studentsReviews">
                <h2 className="new_start-now-title">{t(title)}</h2>

                <Row>
                    {
                        Array.isArray(items) && items?.map(item => (
                            <Col md={12 / items.length} className="new-start-item-container">
                                <Container >
                                    <Row style={{display:'flex', justifyContent: 'space-around'}}>
                                        <Image style={{ maxWidth: '100%', maxHeight: '100%' }} src={item.image} />
                                    </Row>
                                    <Row style={{display:'flex', justifyContent: 'space-around'}}>
                                        <span className="new-start-item-description text-center">{t(item.description)}</span>
                                    </Row>
                                </Container>
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        </div>
    )
}

export default StartNow;

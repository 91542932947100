import React from "react";
import "../../layout/Login/Form.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Container, Form, Row, Col } from "react-bootstrap";
import Button from "../../components/buttons";
import Input from "../../components/inputs";
import "../../mobile/Login/Form.css";
import "../../tablet/Login/Form.css";
import { History } from "history";
import { useTranslation  } from "react-i18next";

interface ControllersProps {
    email: String;
    password: String;
    rememberMe: Boolean
}

interface ActionsProps {
    setEmail: (event: React.ChangeEvent<HTMLInputElement>) => void,
    setPassword: (event: React.ChangeEvent<HTMLInputElement>) => void,
    setRememberMe: (event: React.MouseEvent<HTMLInputElement>) => void
}

interface LoginFormProps {
    history: History;
    handleSubmit: () => Promise<any>;
    controllers: ControllersProps;
    actions: ActionsProps
}

const LoginForm: React.FC<LoginFormProps> = ({
    history,
    handleSubmit,
    actions,
    controllers
}) => {

    const { t } = useTranslation();

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        handleSubmit();
    }

    return (
        <div className="mb-5 pb-5 pt-1 login-form">
            <Card className="shadow login-form-container">
                <Card.Body>
                    <Container>
                        <h5 className="login-form-title">
                            {t('login.welcome_back')}
                        </h5>
                        <h6 className="login-form-subtitle text-muted mt-2">
                            {t('login.login_with_account')}
                        </h6>
                        {/* <div className="login-form-btns my-4">
                            <Button 
                                type="facebook" 
                                className="mr-3"
                            >
                                Facebook İle Oturum Aç
                            </Button>
                            <Button type="google">
                                Google İle Oturum Aç
                            </Button>
                        </div> */}
                        {/* <div className="mt-4 pt-3">
                            <div className="login-sep"></div>
                            <h6 className="login-sep-title text-muted">
                                veya
                            </h6>
                        </div> */}
                        <div className="mt-4">
                            <Form
                                className="text-left"
                                onSubmit={onSubmit}
                            >
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label className="login-label">
                                        {t('login.email')}
                                    </Form.Label>
                                    <Input
                                        name="email"
                                        value={controllers.email as string}
                                        type="default"
                                        onChange={actions.setEmail}
                                    />
                                </Form.Group>

                                <Form.Group
                                    controlId="formBasicPassword"
                                    className="mt-3"
                                >
                                    <Form.Label className="login-label">
                                        {t('login.password')}
                                    </Form.Label>
                                    <Input
                                        name="password"
                                        value={controllers.password as string}
                                        type="password"
                                        onChange={actions.setPassword}
                                    />
                                </Form.Group>
                                <Row>
                                    <Col md={6} sm={5}>
                                        {/* <Form.Group controlId="formBasicCheckbox">
                                            <Form.Check
                                                type="checkbox"
                                                className="checkbox-label"
                                                label="Beni hatırla"
                                                onClick={actions.setRememberMe}
                                                checked={controllers.rememberMe as boolean}
                                            />
                                        </Form.Group> */}
                                    </Col>
                                    <Col md={6} sm={7}>
                                        <h6

                                            className="login-forget-password float-right"
                                            onClick={() => history.push("/forget-password")}
                                        >
                                            {t('login.forget_pass')}
                                        </h6>
                                    </Col>
                                </Row>
                                <Button
                                    type="default"
                                    primary
                                    style={{
                                        width: "100%"
                                    }}
                                    className="mt-2"
                                    onClick={onSubmit}
                                >
                                    {t('login.login')}
                                </Button>
                                <div className="mt-3 form-links">
                                    <Form.Text className="not-a-member">
                                        {t('login.not_member')}
                                        <span className="subscribe-now" onClick={() => {
                                            history.push("/register")
                                        }}>{t('login.register_now')}</span> {t('login.start_speak')}
                                    </Form.Text>
                                </div>
                            </Form>
                        </div>
                    </Container>
                </Card.Body>
            </Card>
        </div>
    )
}

export default LoginForm;

import React from "react";
import styled from "styled-components";
import theme from "../../../theme";
import { Image, Badge } from "react-bootstrap";

import img from "../../../assets/existing-reservations/img.png";
import uk from "../../../assets/existing-reservations/uk.png";
import star from "../../../assets/existing-reservations/star.png";

const ReservationCardContainer = styled.div`
    width: 31.5%;
    height: 155px;
    background-color: #fff;
    border-radius: 22px;
    box-shadow: 0px 15px 20px rgba(141, 141, 141, 0.25);
    box-sizing: border-box;
    padding: 13px;
    float: left;
    display: block;
    min-width: 290px;
    margin-left: 1.5%;
    margin-bottom: 15px;
    @media only screen and (max-width: 720px) {
        float: none;
        margin-left: auto;
        margin-right: auto;
    }
`;

const ReservationCardWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

const ReservationCardImageContainer = styled.div`
    height: 82px;
    width: 96px;
    background-color: #00D21D;
    border-radius: 24px;
    box-sizing: border-box;
    padding-bottom: 5px;
`;

const ReservationCardContentWrapper = styled.div`
    width: calc(100% - 110px);
    height: fit-content;
    display: block;
`;

const ReservationCardInfo = styled.div`
    display: flex;
    height: fit-content;
    width: fit-content;
    align-items: center;
`;

const ReservationCardName = styled.h4`
    text-align: left;
    color: #52575C;
    font-size: 19px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    font-family: ${theme.text.fonts.nunito};
    margin-right: 7px;
`;

const ReservationCardNationality = styled(Image)`
    transform: translateY(1px);
`;

const ReservationCardRatingContainer = styled.div`
    display: flex;
    height: fit-content;
    width: fit-content;
    align-items: center;
    margin-top: 5px;
`;

const ReservationCardRatingValue = styled.p`
    text-align: left;
    color: #929598;
    font-size: 14;
    font-weight: 600;
    font-family: ${theme.text.fonts.nunito};
    margin: 0;
    padding: 0;
    margin-left: 6px;
`;

const ReservationCardSchedule = styled.div`
    margin-top: 15px;
    display: block;
`;

const ReservationCardDate = styled.h4`
    text-align: left;
    color: #929598;
    font-size: 14px;
    font-weight: 600;
    font-size: ${theme.text.fonts.nunito};
    margin: 0;
    padding: 0;
    line-height: 18px;
`;

const ReservationCardBadge = styled(Badge)`
    padding: 5px 9px;
    background-color: #FDD9D1;
    border-radius: 3px;
    color: ${theme.colors.primary};
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    margin-top: 5px;
`;

const TutorReservation = () =>{
    return (
        <ReservationCardContainer>
            <ReservationCardWrapper>
                <ReservationCardImageContainer>
                    <Image src={img} />
                </ReservationCardImageContainer>
                <ReservationCardContentWrapper>
                    <ReservationCardInfo>
                        <ReservationCardName>Irina O.</ReservationCardName>
                        <ReservationCardNationality src={uk} />
                    </ReservationCardInfo>
                    <ReservationCardRatingContainer>
                        <Image src={star} />
                        <ReservationCardRatingValue>4,5/5</ReservationCardRatingValue>
                    </ReservationCardRatingContainer>
                    <ReservationCardSchedule>
                        <ReservationCardDate>
                            12.08.2020, Cumartesi <br />
                            20:20 - 20:40
                        </ReservationCardDate>
                        <ReservationCardBadge>20 dakika</ReservationCardBadge>
                    </ReservationCardSchedule>
                </ReservationCardContentWrapper>
            </ReservationCardWrapper>
        </ReservationCardContainer>
    )
}

export default TutorReservation;

import React, { useEffect } from "react";
import "../../../layout/Home/Home.css";
import Banner from "./Banner";
import ExpertsInstructors from "./ExpertsInstructors";
import WhySpeakingline from "../WhySpeakingline";
import HowDoesItWork from "./HowDoesItWork";
import SubscriptionsPackages from "./SubscriptionsPackages";
import StudentsReviews from "./StudentsReviews";
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";
import MobileApplication from "./MobileApplication";
import NewFooter from "../../../components/NewFooter";
import "../../../animation/Home/animation.css";
import { controlAnimation } from "../../../animation/Home/animation";
import NavBar from "../../../components/navbars";
import oval from "../../../assets/oval.png";
import { Container, Image } from "react-bootstrap";
import HowItWorks from "./HowItWorks";
import hero from "../../../assets/Home/hero_kids.png";
import StartNow from "./StartNow";
import kids1 from "../../../assets/Home/speakingline-kids-01.png";
import kids2 from "../../../assets/Home/speakingline-kids-02.png";
import kids3 from "../../../assets/Home/speakingline-kids-03.png";
import kids4 from "../../../assets/Home/speakingline-kids-04.png";
import kidsCertifiedTrainers from "../../../assets/Home/kids-certified-trainers.png";
import CertifiedTrainers from "./CertifiedTrainers";
import home_how_img1 from "../../../assets/Home/kid-how-it-works-1.png";
import home_how_img2 from "../../../assets/Home/kid-how-it-works-2.png";
import home_how_img3 from "../../../assets/Home/kid-how-it-works-3.png";
import home_how_img4 from "../../../assets/Home/kid-how-it-works-4.png";
import home_how_img5 from "../../../assets/Home/kid-how-it-works-5.png";
import home_how_img6 from "../../../assets/Home/kid-how-it-works-6.png";
import MessageBanner from "./MessageBanner";
import { questionsForKids } from "../../../Global/FrequentlyAskedQuestions";


const HomeV2_Kids: React.FC<any> = ({ history }) => {
    useEffect(() => {
        controlAnimation()
    }, []);

    var startNowList = [{ description: "kids.startnow.first.title", image: kids1 },
    { description: "kids.startnow.second.title", image: kids2 },
    { description: "kids.startnow.third.title", image: kids3 },
    { description: "kids.startnow.fourth.title", image: kids4 }
    ];

    var howDoesItWork = [{ index: 0, title: "kids.how_does_it_works.first.title", description: "kids.how_does_it_works.first.content", image: home_how_img1 },
    { index: 1, title: "kids.how_does_it_works.second.title", description: "kids.how_does_it_works.second.content", image: home_how_img2 },
    { index: 2, title: "kids.how_does_it_works.third.title", description: "kids.how_does_it_works.third.content", image: home_how_img3 },
    { index: 3, title: "kids.how_does_it_works.fourth.title", description: "kids.how_does_it_works.fourth.content", image: home_how_img4 }
    ];

    var howDoesItWork2 = [{ index: 0, title: "kids.how_does_it_works_two.first.title", description:"kids.how_does_it_works_two.first.content", image: home_how_img5 },
    { index: 1, title: "kids.how_does_it_works_two.second.title", description: "kids.how_does_it_works_two.second.content", image: home_how_img6 }
    ];

    return (

        <div>
            <div className="home">
                <div style={{ backgroundImage: "url(" + hero + ")" }} className="super_hero">
                    <NavBar type="new_main" history={history} links={[["home.navigation_bar.btn_forkids", "/kids"], ["home.navigation_bar.btn_forbeatutor", "/tutor"], ["home.navigation_bar.btn_forcorp", "/corporate"]]} />
                    <Banner title="kids.banner.title" description="kids.banner.description" buttonText="kids.banner.button_text" butttonAddress="/register" />
                </div>
                <StartNow title="kids.startnow.title" items={startNowList} />
                <CertifiedTrainers title="kids.certified_trainers.title" description="kids.certified_trainers.description" buttonText="kids.certified_trainers.button_text" buttonOnClick={() => { history.push("/register") }} image={kidsCertifiedTrainers} />
                {/* <ExpertsInstructors /> */}
                <HowDoesItWork items={howDoesItWork} />
                <MessageBanner />
                <HowDoesItWork items={howDoesItWork2} />
                {/* <SubscriptionsPackages /> */}
                {/* <StudentsReviews /> */}
                <FrequentlyAskedQuestions questions={questionsForKids} />
                <MobileApplication />
                <NewFooter />
            </div >
        </div>
    )
}

export default HomeV2_Kids;

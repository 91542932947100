import moment from "moment";
import React from "react";
import "../../layout/StudentVideoChat/OpponentMessage.css";
import Linkify from 'react-linkify'


interface MessageProps {
    content: string,
    time: string
}

interface OpponentMessagesProps {
    message: string,
    time: number,
}

const OpponentMessage: React.FC<OpponentMessagesProps> = ({ message, time }) => {
    var dt = moment(time * 1000).format("HH:mm");
    return (
        <div className="chatroom-message-opponent-container">
            {/* <div className="chatroom-message-opponent-circle" /> */}
            <div className="chatroom-messages-opponent-wrapper">
                <div className="chatroom-message-opponent-content-info-wrapper">
                    <div className="chatroom-message-opponent-content">
                        <p className="chatroom-message-opponent-content-text">
                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                            )}>
                                {message}
                            </Linkify>
                        </p>
                    </div>
                    <p className="chatroom-message-opponent-time">{dt}</p>
                </div>
            </div>

        </div>
    )
}

export default OpponentMessage;


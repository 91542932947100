import React, { useEffect, useState } from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import { calender } from '../../../assets/SVG/SVG';
import moment from 'moment';
import { CONVERSATION_URL } from "../../../Global/Constants/constants";
import { ReturnProps } from "../../../@types/functions";
import axios from "axios";
import ReactPlayer from 'react-player'
import "../../../layout/HistoryModal/HistoryModal.css";
import cross from "../../../assets/upgrade-package/cross.png";
import HistoryDetailsSubContent from './HistoryDetailsSubContent';
import { trackPromise } from 'react-promise-tracker';
import AlertInfo from '../../alerts/AlertInfo';
import profile from "../../../assets/blank_profile.png";
import { useTranslation } from 'react-i18next';



interface HistoryDetailsModalInterface {
    userId: string;
    userRole: number;
    meetingId: String;
    show: Boolean;
    hide: () => void;

}
const HistoryDetailsModal: React.FC<HistoryDetailsModalInterface> = ({
    userId,
    meetingId,
    userRole,
    show,
    hide
}) => { 
    const { t } = useTranslation();

    const [meeting, setMeeting] = useState<any>(null);
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const [archiveLink, setArchiveLink] = useState("");

    const LoadMeetingDetails = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(CONVERSATION_URL + "/api/v1/history/" + meetingId));

        if (!res.success || !res.data) {
            setError(res.error as string);
            setShowError(true)
            //alert(res.error)
            return null;
        }
        setMeeting(res.data);
        setArchiveLink(res.data.archiveLink);
    }

    useEffect(() => {
        if (!show)
            return;
        LoadMeetingDetails();
    }, [show]);

    if (!meeting) {
        return (<div />)
    }

    const dateTime = moment((meeting!.startDate as number) * 1000);
    const date = dateTime.format("DD.MM.YYYY");
    const day = dateTime.format("dddd");
    const startHours = dateTime.format("HH:mm");

    const endDateTime = moment((meeting!.finishDate as number) * 1000);
    const endHours = endDateTime.format("HH:mm");
    const userName = meeting!.attendee.name + " " + meeting!.attendee.surname?.charAt(0) + "."
    const countryCode = meeting!.attendee.countryCode
    const minute = meeting!.duration
    const thumb = meeting!.attendee.photoURL || profile

    return (
        <React.Fragment>

            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />

            <div className="greybgHeader">
                <Modal className="historyModal" show={show} onHide={hide}>
                    <Modal.Header className="historyHeader">
                        <Modal.Title>
                            <h4 className="h4">{t("history.history_detail")} </h4>
                        </Modal.Title>
                        <Image
                            onClick={hide}
                            src={cross}
                            height={40}
                            width={40}
                            style={{ cursor: "pointer" }}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <div className="historyHeader">
                            <div className="historyContent">
                                <h3 className="name">{userName}</h3>
                                <p>{date}, {day}</p>
                                <p>{startHours} - {endHours} <span className="badge danger">{minute} {t("general.minute")}</span></p>
                            </div>
                        </div>
    
                    </Modal.Body>
                    <HistoryDetailsSubContent
                        setArchiveLink={setArchiveLink}
                        attendeeName={meeting.attendee.name + ' ' + meeting.attendee.surname.charAt(0)}
                        meetingId={meeting.meetingId}
                        userId={userId}
                        userRole={userRole}
                        videoUrl={meeting.archiveLink}
                        assessment={meeting.assessment}
                    />
                </Modal>
            </div>
        </React.Fragment>
    )
}
export default HistoryDetailsModal;
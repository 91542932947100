
export const reOrder = (oldList:any[], newList:any[]) =>
{
    var onlineOldList = oldList?.filter((n:any) => n.status === 1);
    var onlineNewList = newList?.filter((n:any) => n.status === 1);

    var onlineOrderedList = reOrderForStatus(onlineOldList, onlineNewList);

    var busyOldList = oldList?.filter((n:any) => n.status === 2);
    var busyNewList = newList?.filter((n:any) => n.status === 2);

    var busyOrderedList = reOrderForStatus(busyOldList, busyNewList);

    var offlineOldList = oldList?.filter((n:any) => n.status === 0);
    var offlineNewList = newList?.filter((n:any) => n.status === 0);

    var offlineOrderedList = reOrderForStatus(offlineOldList, offlineNewList);

    return [...onlineOrderedList, ...busyOrderedList, ...offlineOrderedList];
}

const reOrderForStatus = (oldList:any[], newList:any[]) => {
    const removedItems = oldList?.filter((n:any) => !newList?.some((y:any) => y.user.guid === n.user.guid));
    const newAddedItems = newList?.filter((n:any) => !oldList?.some((y:any) => y.user.guid === n.user.guid));

    var orderedNewList = [...oldList];

    var newItemIndex = 0;
    for (let index = 0; index < removedItems.length; index++) {
       var removedItemIndex = orderedNewList.findIndex(x=>x.user.guid === removedItems[index].user.guid);
       if(removedItemIndex !== -1)
       {
           if(newItemIndex < newAddedItems?.length)
           {
           // orderedNewList.splice(removedItemIndex,1,newList[newItemIndex])
            orderedNewList[removedItemIndex] = newAddedItems[newItemIndex];
            newAddedItems.splice(newItemIndex,1);
            newItemIndex++;
           }
           else
           {
               orderedNewList.splice(removedItemIndex,1);
           }
       }
    }

    if(newItemIndex < newAddedItems?.length)
    {
        for (let index = newItemIndex; index < newAddedItems.length; index++) {
           orderedNewList.push(newAddedItems[index]);
        }
    }
    return orderedNewList;
}
import React from "react";
import "../../layout/InstructorReservations/ReservationRecords.css";
import { Image } from "react-bootstrap";
import {NoReservationFoundIcon} from "../../assets/SVG/SVG";

import Button from "../../components/buttons";

import dots from "../../assets/instructor-reservations/dots.png";

import noDataFound from "../../assets/no-data-found.png";
import { useTranslation } from "react-i18next";

const NoHistoryFound = () =>{
    const { t } = useTranslation();

    return (
        <div className="instructor-reservations-records-container">
            <div>{NoReservationFoundIcon}</div>
            <Image src={dots} />
            <p className="instructor-reservations-records-text">
            {t("history.no_history_found")}
            </p>
        </div>
    )
}

export default NoHistoryFound;
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import img from "../../assets/cards/instructor.png";
import uk from "../../../assets/cards/uk.png";
import {
    CardContainer,
    CardTop,
    ImageContainer,
    CardImage,
    BusyIn,
    CardHeader,
    CardInfo,
    CardName,
    Nationality,
    Rating,
    RatingValue,
    RatingOver,
    LanguagesContainer,
    Language,
    ExpertiseContainer,
    Expertise,
    Description,
    ButtonsContainer,
    CardBtn,
    OptionalBtns,
    TutorCardContainer,
} from "../StyledComponents";

import { CONVERSATION_URL, IDENTITY_URL } from "../../../Global/Constants/constants";
import { ReturnProps } from "../../../@types/functions";
import axios from "axios";

import heartFill from "../../../assets/cards/heartFill.png";
import heart from "../../../assets/cards/heart.png";
import TutorDetailsModal from '../../modals/tutorDetailsModal/tutorDetailsModal';
import CallingModal from '../../modals/meeting/CallingModal';
import RatingComp from "./RatingComp";
import { RouteComponentProps } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import AlertInfo from "../../alerts/AlertInfo";
import { useTranslation } from "react-i18next";

interface TutorCardInterface {
    userId: String;
    rating: Number;
    name: String;
    surname: String;
    description: String;
    isFav: boolean;
    availableAfter: Number;
    status: number;
    countryCode?: String;
    languages?: [any];
    expertise?: [any];
    thumb: String;
    onLike: () => void;
    makeReservationClicked?: () => void
}

type TutorCardProps = TutorCardInterface

const TutorCard: React.FC<TutorCardProps> = ({
    userId,
    rating,
    name,
    surname,
    isFav,
    description,
    availableAfter,
    status,
    countryCode,
    languages,
    expertise,
    thumb,
    onLike = () => null,
    makeReservationClicked = () => null
}) => {
    const { t } = useTranslation();

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    // const [isFavourite, setIsFavourite] = useState(isFav);
    const [isShowTutorDetailsModal, setIsShowTutorDetailsModal] = useState(false);
    const [isShowCallingModal, setIsShowCallingModal] = useState(false);
    const [meetingRequestId, setMeetingRequestId] = useState("")

    let userStatus = "inactive";
    if (status === 0)
        userStatus = "inactive";
    else if (status === 1)
        userStatus = "active";
    else if (status === 2)
        userStatus = "busy";

    const onPreferencesChanged = async (key: string, value: boolean) => {
        var params = { teacherId: userId, [key]: value }
        const res = await trackPromise(axios.put<any, ReturnProps>(IDENTITY_URL + "/api/v1/students/student-preferences-for-teacher", params));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            // alert(res.error)
            return null;
        }
        // if(key === "isFavourite")
        //     setIsFavourite(value)
        //console.log(res.data);
    }

    const callClicked = async () => {
        var params = { toUserGuid: userId }
        const res = await trackPromise(axios.post<any, ReturnProps>(CONVERSATION_URL + "/api/v1/meetings/meeting-request", params));
        if (!res.success || !res.headers.location) {
            setError(res.error as string);
            setShowError(true)
            //  alert(res.error)
            return null;
        }
        const header: String = res.headers.location
        const meetReqId = header.split("/")[1];

        // var meetReqId = "ae76021c-33bd-4fc4-b371-8cac9bd9353b";
        setMeetingRequestId(meetReqId);
        setIsShowCallingModal(true)
        // //console.log(res.data);
    }

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />

            <TutorCardContainer className="shadow">
                <CardTop>
                    <div style={{ flex: "20%" }}>
                        <ImageContainer className={userStatus as string || ""}>
                            <CardImage src={thumb as string} />
                        </ImageContainer>
                        {/* <BusyIn>
                            Busy in
                        {" " + availableAfter} minutes
                    </BusyIn> */}
                    </div>
                    <div style={{ flex: "80%" }} className="pl-4">
                        <CardHeader>
                            <div>
                                <CardInfo>
                                    <CardName>{name + " " + surname?.charAt(0) + "."}</CardName>
                                    {countryCode && <Nationality src={countryCode as string} height={14} width={23} />}

                                </CardInfo>
                                <div
                                    style={{
                                        display: "flex",
                                        height: 20,
                                        paddingTop: 3
                                    }}
                                >
                                </div>
                            </div>
                            <div style={{ alignSelf: "flex-start"}}>
                                <RatingComp ratingValue={rating} />
                            </div>
                        </CardHeader>

                        <LanguagesContainer>
                            {languages && languages.length > 0 && languages!.map((item: any) => (
                                <Language>
                                    {item.accent}
                                    <span
                                        style={{
                                            fontWeight: 600
                                        }}
                                    > / {item.language}
                                    </span>
                                </Language>
                            ))
                            }
                        </LanguagesContainer>
                        <ExpertiseContainer>
                            {expertise && expertise?.map((item: any) => (
                                <Expertise>{item.value}</Expertise>
                            ))
                            }
                        </ExpertiseContainer>
                        <Description>
                            {description}
                        </Description>
                    </div>
                </CardTop>
                <ButtonsContainer>
                    <OptionalBtns>
                        <CardBtn

                            type="default"
                            primary={true}
                            first={true}
                            onClick={callClicked}
                            disabled={userStatus !== "active"}
                        >
                            {t("general.call")}
                        </CardBtn>
                        <CardBtn
                            type="default"
                            filter={true}
                            second={true}
                            onClick={makeReservationClicked}
                        >
                            {t("general.create_reservation")}
                        </CardBtn>
                        <CardBtn
                            type="default"
                            filter={true}
                            third={true}
                            onClick={() => { setIsShowTutorDetailsModal(true);
                                //console.log("Profile clicked - ", userId)
                            }}
                        >
                            {t("general.profile")}

                        </CardBtn>
                        <div style={{flex:1, display:"flex", alignSelf:"flex-end", justifyContent : "flex-end"}}>
                            {
                                isFav ? (
                                    <Image
                                        src={heartFill}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        onClick={async () => {
                                            // setLiked(false)
                                            await onPreferencesChanged("isFavourite", false)
                                            onLike()
                                        }}
                                    />
                                ) : (
                                    <Image
                                        src={heart}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        onClick={async () => {
                                            // setLiked(true)
                                            await onPreferencesChanged("isFavourite", true)
                                            onLike()
                                        }}
                                    />
                                )
                            }
                        </div>
                    </OptionalBtns>
                </ButtonsContainer>
            </TutorCardContainer>
            {
                isShowTutorDetailsModal &&
                <TutorDetailsModal
                    show={isShowTutorDetailsModal}
                    hide={() => setIsShowTutorDetailsModal(false)}
                    userId={userId} />
            }

            {
                isShowCallingModal &&
                <CallingModal
                    show={isShowCallingModal}
                    hide={() => setIsShowCallingModal(false)}
                    oppenentId={userId}
                    opponentName={name}
                    meetingRequestId={meetingRequestId}
                    opponentPicture={thumb as String} />
            }
        </React.Fragment>
    )
}
export default TutorCard;



const initialState:any[] = [];

export default function(state=initialState, action:any) {
    switch(action.type) {
        case "ADD_NEW_MSG":
            return state = [ ...state, action.payload ]
        default:
            return state;
    }
}

import React, { useRef } from "react";
import { InputGroup, FormControl, Image } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import PasswordInput from "./PasswordInput";
import DefaultInput from "./DefaultInput";
import IconInput from "./IconInput";
import Input from "./Input";
import CheckBox from "./CheckBox";
import SelectInput from "./SelectionInput";
import TimeRange from "./TimeRange";
import DescriptionInput from "./DescriptionInput";

interface InputProps {
    name?: string
    type: string,
    icon?: any,
    label?: string,
    placeholder?: string,
    value?: string,
    onChange?: (e: any) => void,
    labelIcon?: any,
    style?: React.CSSProperties,
    disabled?: boolean,
    checked?: boolean,
    options?: any[],
    iconHoverLabel?: string,
    labelColor?: string,
    iconHeight?: number,
    iconWidth?: number,
    whiteFocus?: boolean,
    onIconClick?: () => void,
    onEnterPress?: (value: string) => void,
    className?: string,
    focusColor?: string,
    defaultValue?: any[],
    defaultSelection?: any
}

const InputComp: React.FC<InputProps> = ({
    name,
    type,
    icon,
    label,
    placeholder,
    labelIcon,
    value,
    onChange,
    style,
    disabled,
    checked,
    options,
    iconHoverLabel,
    labelColor,
    iconHeight,
    iconWidth,
    whiteFocus,
    onIconClick,
    onEnterPress,
    className = "",
    focusColor,
    defaultSelection,
    defaultValue
}) => {
    return (
        <>
            {
                (type === "password") && <PasswordInput
                    name={name}
                    style={style}
                    labelValue={label}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                />
            }
            {
                (type === "default") && <Input
                    name={name}
                    icon={icon}
                    label={label}
                    placeholder={placeholder}
                    labelIcon={labelIcon}
                    value={value}
                    onChange={onChange}
                    style={style}
                    disabled={disabled}
                    iconHoverLabel={iconHoverLabel}
                />
            }
            {
                (type === "icon") && <IconInput
                    name={name}
                    style={style}
                    icon={icon}
                    placeholder={placeholder}
                    label={label}
                    value={value}
                    onChange={onChange}
                    iconHoverLabel={iconHoverLabel}
                    iconHeight={iconHeight || 32}
                    iconWidth={iconWidth || 32}
                    whiteFocus={whiteFocus}
                    onIconClick={onIconClick}
                    onEnterPress={onEnterPress}
                    className={className}
                    focusColor={!focusColor ? "#FFFCFC" : focusColor}
                />
            }
            {
                (type === "form") && <DefaultInput style={style} />
            }
            {
                (type === "description") && <DescriptionInput value={value}
                    onChange={onChange} style={style} />
            }
            {
                (type === "checkbox") && <CheckBox checked={checked} label={label} labelColor={labelColor} onChange={onChange} />
            }
            {
                (type === "select") && <SelectInput
                    defaultValue={defaultSelection}
                    onChange={onChange}
                    options={options}
                    label={label}
                    icon={icon}
                    iconHoverLabel={iconHoverLabel}
                />
            }
            {
                (type === "time-range") && <TimeRange onChange={onChange} defaultValue={defaultValue} />
            }
        </>
    )
}

export default InputComp;


import React,{ useEffect, useState, useRef } from "react";
import "../../layout/SupportPage.css";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import NavBar from "../navbars";
import "../../mobile/SupportPage.css";
import "../../tablet/SupportPage.css";
import { History } from "history";

import Banner from "../../assets/Support/bg.png"
import InstructorBanner from "../../assets/Support/instructor-support-banner.png";
import StudentBanner from "../../assets/Support/student-support-banner.png";
import MobileDownArrow from "../../assets/Support/downArrow.png";
import MobileUpArrow from "../../assets/Support/mobile-up-arrow.png";
import TabletArrow from "../../assets/Support/tablet-arrow.png";
import TabletCloseArrow from "../../assets/Support/tabletCloseArrow.png";
import TabletNavigation from "../../assets/Support/tablet-navigation.png";

interface SupportPageProps {
    children: React.ReactNode,
    bannerType: String,
    history: History,
}

const Support:React.FC<any> = ({ children, bannerType, history }) =>{
    const [bannerBg, setBannerBg] = useState<any>(null);
    const navigationRef = useRef<HTMLDivElement>(null);
    const [navigationIcon, setNavigationIcon] = useState(TabletArrow);
    const [tabletNavigationOpened, setTabletNavigationOpened] = useState(false);
    const [mobileNavigationIcon, setMobileNavigationIcon] = useState(MobileDownArrow)
    const openTabletNavigation = () =>{
        setNavigationIcon(TabletCloseArrow)
        setTimeout(() =>{
            navigationRef.current?.classList.remove("support-nav-tablet-anim-close")
            navigationRef.current?.classList.add("support-nav-tablet-anim")
        }, 10)
        setTabletNavigationOpened(true)
    }
    const closeTabletNavigation = () =>{
        setNavigationIcon(TabletArrow)
        setTimeout(() =>{
            navigationRef.current?.classList.remove("support-nav-tablet-anim")
            navigationRef.current?.classList.add("support-nav-tablet-anim-close")
        }, 10)
        setTabletNavigationOpened(false)
    }
    const handleTabletOpenNavigation = () =>{
        if(!tabletNavigationOpened) return openTabletNavigation()
        return closeTabletNavigation()
    }
    const set_banner_bg = () =>{
        if(bannerType === "instructor") return InstructorBanner
        return StudentBanner
    }
    useEffect(() =>{
        setBannerBg(set_banner_bg())
    },[])
    const openMobileNavigation = () =>{
        navigationRef.current?.classList.add("support-open-nav-anim")
        setTimeout(() =>{
            navigationRef.current?.classList.add("support-open-nav-anim-content")
        },100)
        setTabletNavigationOpened(true)
        setMobileNavigationIcon(MobileUpArrow)
    }
    const closeMobileNavigation = () =>{
        navigationRef.current?.classList.remove("support-open-nav-anim")
        navigationRef.current?.classList.remove("support-open-nav-anim-content")
        setTabletNavigationOpened(false)
        setMobileNavigationIcon(MobileDownArrow)
    }
    const handleMobileNavigation = () =>{
        if(!tabletNavigationOpened) return openMobileNavigation()
        return closeMobileNavigation()
    }


    return (
        <div>
            <div className="instructor-support-banner" style={{ backgroundImage: `url(${Banner})` }}>
                <div className="instructor-support-banner-container">
                    <NavBar type="main" navTheme="light" history={history} />
                    <Container>
                        <h2 className="banner-title">Destek</h2>
                    </Container>
                </div>
            </div>
            <Container className="support-content">
                <Row>
                    <Col sm={12} md={1} className="support-down-arrow">
                        <Image src={mobileNavigationIcon} className="support-down-arrow-icon" onClick={handleMobileNavigation} />
                        <Image src={navigationIcon} className="support-tablet-arrow" onClick={handleTabletOpenNavigation} />
                        <Image src={TabletNavigation} className="support-tablet-navigation" />
                    </Col>
                    <Col sm={12} lg={3} md={1} className="support-navigation-container">
                        <div className="support-navigation shadow" ref={navigationRef}>
                            <div className="support-links shadow-sm">
                                <div className={window.location.pathname === "/support/student" ? "support-link-container active" : "support-link-container"}>
                                    <Link to="/support/student" className="support-link">Öğrenciler İçin Yardım</Link>
                                </div>
                                <div className={window.location.pathname === "/questions/student" ? "support-link-container active" : "support-link-container"}>
                                    <Link to="/questions/student" className="support-link">Öğrenciler İçin SSS</Link>
                                </div>
                                <div className={window.location.pathname === "/support/tutor" ? "support-link-container active" : "support-link-container"}>
                                    <Link to="/support/tutor" className="support-link">Eğitmenler İçin Yardım</Link>
                                </div>
                                <div className={window.location.pathname === "/questions/tutor" ? "support-link-container active" : "support-link-container"}>
                                    <Link to="/questions/tutor" className="support-link">Eğitmenler İçin SSS</Link>
                                </div>
                                {/* <div className="support-link-container">
                                    <Link to="/" className="support-link">Destek Talebi Oluştur</Link>
                                </div> */}
                            </div>
                            <div className="support-contact">
                                <div className="support-contact-container">
                                    <h5 className="support-contact-title">Destek Hattı</h5>
                                    <h6 className="support-contact-info">0123 456 7 890</h6>
                                </div>
                                <div className="support-contact-container">
                                    <h5 className="support-contact-title">WhatsApp Destek Hattı</h5>
                                    <h6 className="support-contact-info">0123 456 7 890</h6>
                                </div>
                                <div className="support-contact-container">
                                    <h5 className="support-contact-title">E-Posta Gönder</h5>
                                    <h6 className="support-contact-info">info@speakingline.com</h6>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={9} md={11}>
                        <Card style={{ border: 0 }}>
                            <Card.Header className="support-body">
                                {
                                    children
                                }
                            </Card.Header>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <div className="support-footer">
                <Footer />
            </div>
        </div>
    )
}

export default Support;
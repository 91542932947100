import React,{ useState } from "react";
import "../../layout/InstructorProfileSettings/Personality.css";
import OptionButton from "../../components/buttons";
import styled,{ css } from "styled-components";
import theme from "../../theme";
import ParamType from "../../components/others/ParamType";

const Button = styled(OptionButton)`
    ${
        ({ clicked }) =>{
            if (clicked) return css`
                background-color: ${theme.colors.secondary};
                border-color: ${theme.colors.secondary};
                color: #fff;
            `;
            if(!clicked) return css`
                &:hover {
                    color: ${theme.colors.secondary};   
                    border-color: ${theme.colors.secondary};
                }
            `;
        }
    }
    margin-right: 15px;
    margin-bottom: 15px;
`;


interface PersonalityProps {
    selectedPersonality : any[];
    setSelectedPersonality : any;
}

const Personality : React.FC<PersonalityProps> = ({
    selectedPersonality = [],
    setSelectedPersonality
}) =>{
    const [personalitiesSelected, setPersonalitiesSelected] = useState<any[]>([])
    return (
        <div className="instructor-personality-container">
            <h4 className="instructor-personality-title">PERSONALITY</h4>
            <div className="instructor-personality-options-container">
            <ParamType type={4} selectedParams = {selectedPersonality} setSelectedParams={setSelectedPersonality} />
            </div>
            <hr className="instructor-section-sep" />
        </div>
    )
}

export default Personality;

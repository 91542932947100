import React, { useEffect, useState } from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import { calender } from '../../../assets/SVG/SVG';
import moment from 'moment';
import { doCall, endCall } from "../../../assets/SVG/SVG";
import { useHistory } from "react-router-dom";
import { ImageContainer, CardImage, CardInfo, CardName, Nationality, Rating, RatingValue, RatingOver, LanguagesContainer, Language, ExpertiseContainer, Expertise } from '../../../components/cards/StyledComponents';
import { connection, Init } from "../../../Global/Utilities/signalr"
import { MeetingRequestStatusType } from "../../../Global/Constants/constants"

import cross from "../../../assets/upgrade-package/cross.png";
import personImg from "../../../assets/defaultImg.svg";
import phoneIcon from "../../../assets/phoneIcon.svg";
import missedCallIcon from "../../../assets/missedCallIcon.svg";
import "../../../layout/CallingModal/CallingModal.css";
import { CONVERSATION_URL, IDENTITY_URL, MESSAGES_URL } from "../../../Global/Constants/constants";
import { ReturnProps } from "../../../@types/functions";
import axios from "axios";
import { trackPromise } from 'react-promise-tracker';
import AlertInfo from '../../alerts/AlertInfo';
import { useDispatch } from 'react-redux';
import MakeReservationModal from '../MakeReservationModal/MakeReservationModal';
import profile from "../../../assets/blank_profile.png";
import { useTranslation } from 'react-i18next';


interface CallingModalInterface {
    meetingRequestId: String,
    opponentPicture?: String;
    oppenentId?: String;
    opponentName?: String;
    show: Boolean;
    hide: () => void;
    isReservationPopup?: Boolean
}
type CallingProps = CallingModalInterface

const CallingModal: React.FC<CallingProps> = ({
    meetingRequestId,
    opponentPicture,
    oppenentId,
    opponentName,
    show,
    hide,
    isReservationPopup = false
}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    let history = useHistory();

    const [showMakeReservationModal, setShowMakeReservationModal] = useState(false);
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const [meetReqId, setMeetReqId] = useState(meetingRequestId);
    const [status, setStatus] = useState(isReservationPopup ? "reservation-time" : "calling");
    // const [title, setTitle] = useState("calling title");
    // const [description, setDescription] = useState("calling desc");


    useEffect(() => {
        listenMeetingRequestAnswer();
    }, [show]);

    let title = "";
    let description = "";

    const meetingAccepted = (params: any) => {
        setStatus("accepted");
        setTimeout(() => history.push("/conversation/video", { meetingParams: params }), 2000)
    };

    if (status === "calling") {
        if(opponentName)
        {
            title = t("calling.calling") + opponentName + " ...";
        }
        else {
            title = t("calling.calling_anonymous");
        }

        description = t("calling.calling_description");
    }
    else if (status === "accepted") {
        title = t("calling.accepted");
        description = t("calling.accepted_description");
    }
    else if (status === "reservation-time") {
        title =  t("calling.reservation_time");
        description = t("calling.reservation_time_desc");
    }
    else if (status === "rejected") {
        title =  t("calling.rejected");
        description = t("calling.rejected_desc");
    }

    else if (status === "noanswer") {
        title = t("calling.no-answer");
        description = t("calling.no-answer_desc");
    }

    else if (status === "canceled") {
        title = t("calling.canceled");
        description = t("calling.canceled_desc");
    }

    const doReservationClicked = () => {
        hide();
        setShowMakeReservationModal(true);
        //console.log("doReservationClicked - Status " + status);
    }

    const meetingIsCanceledOrTimeout = () => {
        setTimeout(() => hide(), 1500)
    }

    const sendDirectMessage = async () => {
        var param = { talkingWithId: oppenentId }
        const res = await trackPromise(axios.post<any, ReturnProps>(MESSAGES_URL + "/api/v1/rooms/directmessageroom", param))
        if (res.error || !res.headers.location) {
            setError(res.error as string);
            setShowError(true);
            return;
        }
        else {
            hide();
            const header: String = res.headers.location;
            const id = header.split("/")[1];
            dispatch({
                type: "CHAT_DIALOG_IS_SHOWN",
                payload: {
                    isOpen: true,
                    roomId: id,
                }
            });
        }
    }

    const reCallClicked = async () => {
        var params = { toUserGuid: oppenentId }
        const res = await trackPromise(axios.post<any, ReturnProps>(CONVERSATION_URL + "/api/v1/meetings/meeting-request", params));

        if (!res.success) {
            setError(res.error as string);
            setShowError(true);
            return null;
        }
        const header: String = res.headers.location
        const id = header.split("/")[1];

        //var meetReqId = "ae76021c-33bd-4fc4-b371-8cac9bd9353b";
        setMeetReqId(id);
        setStatus("calling");
    }

    const reCallRandomlyClicked = async () => {

        const res = await trackPromise(axios.post<any, ReturnProps>(CONVERSATION_URL + "/api/v1/meetings/meeting-request-randomly"));

        if (!res.success) {
            setError(res.error as string);
            setShowError(true);
            return null;
        }
        const header: String = res.headers.location
        const id = header.split("/")[1];

        //var meetReqId = "ae76021c-33bd-4fc4-b371-8cac9bd9353b";
        setMeetReqId(id);
        setStatus("calling");
    }

    const dummyDataClicked = () => {
        setStatus("Accepted");
        var params = JSON.parse('{"meeting_request_id":"ae76021c-33bd-4fc4-b371-8cac9bd9353b","meeting_id":"25ae47fa-d7cf-42af-89de-85c1e6512042","session_id":"1_MX40NjkxOTc5NH5-MTYxNjA2NjE4MzQwM35MbUJMU3dQTlMzRTZFbWIySzdieWZ5Ky9-fg","meeting_request_answer":1,"meeting_token":"T1==cGFydG5lcl9pZD00NjkxOTc5NCZzaWc9YmRjMTFmZmRiOTYzN2FmNTMyZjAxZjAyYmMxM2MxNTY1ZWM1YmZjNDpzZXNzaW9uX2lkPTFfTVg0ME5qa3hPVGM1Tkg1LU1UWXhOakEyTmpFNE16UXdNMzVNYlVKTVUzZFFUbE16UlRaRmJXSXlTemRpZVdaNUt5OS1mZyZjcmVhdGVfdGltZT0xNjE2MTc0NDIwJm5vbmNlPTAuMDM3NTExMjgwNzg5NDUxMzImcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTYxODc2NjQxOSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==","meeting_duration":45,"attendee_guid":"ba3b6b20-4449-4cfa-adb4-c4b21c413ee0","attendee_name":"MyTeacher","attendee_surname":"Surname Teacher","attendee_photo_url":"http://bucket-url/ba3b6b20-4449-4cfa-adb4-c4b21c413ee0_20210316-154233.png","attendee_email":"newteacher@gmail.com"}');
        meetingAccepted(params);
    }

    const onDropCallClicked = async () => {
        // meetingIsCanceledOrTimeout();
        if (status === "calling") {
            setStatus("canceled");
            var params = { meetingStatus: 3 }
            const res = await axios.put<any, ReturnProps>(CONVERSATION_URL + "/api/v1/meetings/meeting-request/" + meetReqId, params);
            if (!res.success || !res.headers.location) {
                setError(res.error as string);
                setShowError(true)
                return null;
            }
        }

        // var params = JSON.parse('{"meeting_request_id":"ae76021c-33bd-4fc4-b371-8cac9bd9353b","meeting_id":"25ae47fa-d7cf-42af-89de-85c1e6512042","session_id":"1_MX40NjkxOTc5NH5-MTYxNjA2NjE4MzQwM35MbUJMU3dQTlMzRTZFbWIySzdieWZ5Ky9-fg","meeting_request_answer":1,"meeting_token":"T1==cGFydG5lcl9pZD00NjkxOTc5NCZzaWc9YmRjMTFmZmRiOTYzN2FmNTMyZjAxZjAyYmMxM2MxNTY1ZWM1YmZjNDpzZXNzaW9uX2lkPTFfTVg0ME5qa3hPVGM1Tkg1LU1UWXhOakEyTmpFNE16UXdNMzVNYlVKTVUzZFFUbE16UlRaRmJXSXlTemRpZVdaNUt5OS1mZyZjcmVhdGVfdGltZT0xNjE2MTc0NDIwJm5vbmNlPTAuMDM3NTExMjgwNzg5NDUxMzImcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTYxODc2NjQxOSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==","meeting_duration":45,"attendee_guid":"ba3b6b20-4449-4cfa-adb4-c4b21c413ee0","attendee_name":"MyTeacher","attendee_surname":"Surname Teacher","attendee_photo_url":"http://bucket-url/ba3b6b20-4449-4cfa-adb4-c4b21c413ee0_20210316-154233.png","attendee_email":"newteacher@gmail.com"}');
        // meetingAccepted(params);
    }

    const listenMeetingRequestAnswer = () => {
        //console.log("MeetingRequestAnswer inside ");
        connection.off("MeetingRequestAnswer");
        connection.on("MeetingRequestAnswer", (message) => {
            //console.log("MeetingRequestAnswer called " + message)
            //  alert(message);

            if (message.params.meeting_request_answer === MeetingRequestStatusType.ACCEPTED) {
                setStatus("Accepted");
                meetingAccepted(message.params);
            }
            else if (message.params.meeting_request_answer === MeetingRequestStatusType.CANCELED_BY_STUDENT) {
                setStatus("canceled");
            }
            else if (message.params.meeting_request_answer === MeetingRequestStatusType.NOT_ACCEPTED_BY_TEACHER) {
                setStatus("rejected");
            }
            else if (message.params.meeting_request_answer === MeetingRequestStatusType.TIMEOUT) {
                setStatus("noanswer");
            }
        });
    }

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />

            <Modal show={show} onHide={hide} className="callingModal" backdrop="static">
                <Modal.Header>
                    {status !== "calling" &&
                        <Image
                            onClick={hide}
                            src={cross}
                            height={40}
                            width={40}
                            style={{ cursor: "pointer" }}
                        />}

                </Modal.Header>
                <Modal.Body>
                    <div className="callingBody">
                        <div className="caller-img">
                            {status === "noanswer" || status === "rejected" &&
                                <div className="missIcon">
                                    < img src={missedCallIcon} />
                                </div>
                            }
                            {opponentPicture && (<img style={{ width: "104px", height: "104px", borderRadius: "24px" }} src={opponentPicture as string ?? profile} alt="" />)}

                        </div>
                        <div className="caller-content">
                            <h3>{title}</h3>
                            <p>{description}</p>
                        </div>

                        {
                            status === "calling" &&
                            <div className="callingFooter reject">
                                <div className="reject-button">
                                    <button onClick={onDropCallClicked}>
                                        <img src={phoneIcon} alt="" />
                                    </button>
                                </div>

                            </div>
                        }

                        {
                            (status === "noanswer" || status === "rejected" || status === "canceled") &&
                            <div className="callingFooter accept">
                                {oppenentId && (<div className="button-wrapper">
                                    <button onClick={doReservationClicked}>{t("calling.do_reservation")}</button>
                                </div>)}

                                <div className="accept-button">
                                    <button onClick={oppenentId ? reCallClicked : reCallRandomlyClicked}>
                                        <img src={phoneIcon} alt="" />
                                    </button>
                                </div>
                                {oppenentId && (
                                    <div className="button-wrapper">
                                        <button onClick={sendDirectMessage}>{t("calling.send_message")}</button>
                                    </div>
                                )}

                            </div>
                        }

                        {
                            (status === "reservation-time") &&
                            <div className="callingFooter accept">
                                <div className="button-wrapper">
                                    <button onClick={doReservationClicked}>{t("calling.cancel_reservation")}</button>
                                </div>
                                <div className="accept-button">
                                    <button onClick={reCallClicked}>
                                        <img src={phoneIcon} alt="" />
                                    </button>
                                </div>
                                <div className="button-wrapper">
                                    <button onClick={sendDirectMessage}>{t("calling.send_message")}</button>
                                </div>
                            </div>
                        }

                        {
                            status === "accepted" &&
                            <div className="callingFooter content">
                                <p className="danger">{t("calling.routing")}</p>
                            </div>

                        }
                    </div>
                </Modal.Body>
            </Modal>

            {
                showMakeReservationModal &&
                <MakeReservationModal
                    show={showMakeReservationModal}
                    hide={() => setShowMakeReservationModal(false)}
                    userId={oppenentId!}
                    name={opponentName!}
                    surname={""}
                />
            }

        </React.Fragment>
    )
}
export default CallingModal;
import styled,{ css } from "styled-components";
import { Button, ButtonProps } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import theme from "../../theme";
import TutorsCardBtnPrimary from "./TutorsCardBtnPrimary";
import TutorsCardBtnSecondary from "./TutorsCardBtnSecondary";
import TutorsCardBtnFilter from "./TutorsCardBtnFilter";
import ButtonPrimary from "./ButtonPrimary";
import ButtonSecondary from "./ButtonSecondary";
import ButtonInactive from "./ButtonInactive";
import FacebookButton from "./FacebookButton";
import GoogleButton from "./GoogleButton";
import ThirdButtonPrimary from "./ThirdButtonPrimary";
import ThirdButtonSecondary from "./ThirdButtonSecondary";
import ThirdButtonTransparent from "./ThirdButtonTransprent";
import PurchaseButton from "./PurchaseButton";
import ButtonOption from "./ButtonOption";
import MakeSthButton from "./MakeSthButton";
import ButtonReservationOption from "./ButtonReservationOption";

interface StyledButtonProps extends ButtonProps {
    onClick?: (e:any) => any;
    type?: string;
    primary?: boolean;
    secondary?: boolean;
    filter?: boolean;
    transparent?: boolean;
    disabled?: boolean | undefined
}

const StyledButton = styled(Button).attrs((props) =>({
    variant: "",
}))<StyledButtonProps>`
    color: #fff;
    &:hover {
        color: #fff;
    }
    &:focus,
    &:active {
        outline: none;
        box-shadow: none;
    }
    ${
        ({ type, primary=false, secondary = false, filter= false, transparent=false, disabled=false }) =>{
            if(type === "tutorsCardBtn") {
                return css`
                    border-radius: 8px;
                    padding: 7px 20px;
                    font-family: ${theme.text.fonts.nunito};
                    font-weight: ${theme.text.weight.meduim};
                    font-size: ${theme.text.sizes.xxs};
                    ${
                        () =>{
                            if(primary) return TutorsCardBtnPrimary
                            else if(secondary) return TutorsCardBtnSecondary;
                            else if(filter) return TutorsCardBtnFilter;
                        }
                    }
                `;
            } 
            else if(type === "default" || type === "facebook" || type === "google") {
                return css`
                    border-radius: 25px;
                    font-family: ${theme.text.fonts.poppins};
                    font-weight: ${theme.text.weight.meduim};
                    font-size: ${theme.text.sizes.xs};
                    color: ${theme.colors.white};
                    padding: 10px 22px;
                    
                    ${
                        () =>{
                            if(!disabled) {
                                if(primary) return ButtonPrimary;
                                else if(secondary) return ButtonSecondary;
                                else if(filter) return ButtonInactive;
                                
                                if(type === "facebook") return FacebookButton;
                                else if(type === "google") return GoogleButton;
                            }
                        }
                    }
                `
            } else if(type === "third") {
                return css`
                    padding: 8px 25px;
                    font-family: ${theme.text.fonts.poppins};
                    font-size: 15px;
                    font-weight: ${theme.text.weight.meduim};
                    border-radius: 25px;
                    ${
                        () =>{
                            if(primary) return ThirdButtonPrimary;
                            else if(secondary) return ThirdButtonSecondary;
                            else if(transparent) return ThirdButtonTransparent;
                        }
                    }
                `;
            } else if(type === "purchase")  return PurchaseButton;
            else if(type === "makeSth")  return MakeSthButton;
            else if(type === "option") return ButtonOption;
       //     else if(type === "option-reservation-minutes") return ButtonReservationOption;
        }
    }
`;

export default StyledButton;

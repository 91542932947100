
const initialState = {
    dateRange : null
}

interface ActionProps {
    type: String,
    payload: any
}

export default function(state=initialState, action:ActionProps) {
    switch(action.type) {
        case "DATE_FILTER_CHANGED":
            return state = { ...state, dateRange: action.payload}
        default:
            return state
    }
}

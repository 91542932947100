import { css } from "styled-components";
import theme from "../../theme";

const StyledButton = css`
    background-color: ${theme.buttons.colors.inactive};
    border-color: ${theme.buttons.colors.inactive};
    &:hover {
        background-color: ${theme.buttons.colors.inactive};
        border-color: ${theme.buttons.colors.inactive};
    }
    &:active,
    &:focus {
        background-color: ${theme.buttons.colors.inactive};
        border-color: ${theme.buttons.colors.inactive};
    }
`;

export default StyledButton;

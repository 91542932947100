import React, { useEffect, useState } from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import { calender } from '../../../assets/SVG/SVG';
import moment from 'moment';
import { CONVERSATION_URL } from "../../../Global/Constants/constants";
import { ReturnProps } from "../../../@types/functions";
import axios from "axios";
import ReactPlayer from 'react-player'
import "../../../layout/HistoryModal/HistoryModal.css";
import "../../../layout/TeacherAssessmentModal/TeacherAssessmentModal.css";
import cross from "../../../assets/upgrade-package/cross.png";
import MeetingAssessment from '../HistoryDetails/MeetingAssessment';
import { MeetingFinishedIcon } from "../../../assets/SVG/meetingFinished";


interface TeacherAssessmentModalInterface {
    opponentName: String;
    meetingId: String;
    show: Boolean;
    hide: () => void;

}
const TeacherAssessmentModal: React.FC<TeacherAssessmentModalInterface> = ({
    opponentName,
    meetingId,
    show,
    hide
}) => {

    // useEffect(() => {

    // }, [show]);

    return (
        <div className="greybgHeader">
            <Modal className="historyModal" show={show} onHide={hide} backdrop="static">
                <Modal.Header className="historyHeader">
                    <Modal.Title>
                        <h4 className="h4">You accomplished your session. Please evaluate the student.</h4>
                    </Modal.Title>
                    {/* <Image
                      onClick = {hide}
                        src={cross}
                        height={40}
                        width={40}
                        style={{ cursor: "pointer" }}
                    /> */}
                </Modal.Header>
                <Modal.Body>
                    <div className="content">
                        <div className="icon">
                            {MeetingFinishedIcon}
                        </div>

                        <MeetingAssessment assessmentUpdated={hide} meetingId={meetingId} />
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}
export default TeacherAssessmentModal;
import React, { useEffect, useState } from "react";
import "../../../layout/Home/Home.css";
import Banner from "./Banner";
import ExpertsInstructors from "./ExpertsInstructors";
import WhySpeakingline from "../WhySpeakingline";
import HowDoesItWork from "./HowDoesItWork";
import SubscriptionsPackages from "./SubscriptionsPackages";
import StudentsReviews from "./StudentsReviews";
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";
import MobileApplication from "./MobileApplication";
import NewFooter from "../../../components/NewFooter";
import "../../../animation/Home/animation.css";
import { controlAnimation } from "../../../animation/Home/animation";
import NavBar from "../../../components/navbars";
import oval from "../../../assets/oval.png";
import { Container, Image } from "react-bootstrap";
import HowItWorks from "./HowItWorks";
import hero from "../../../assets/Home/hero-corp.png";
import StartNow from "./StartNow";
import start_now_1 from "../../../assets/Home/corp-start-now-1.png";
import start_now_2 from "../../../assets/Home/corp-start-now-2.png";
import start_now_3 from "../../../assets/Home/corp-start-now-3.png";
import start_now_4 from "../../../assets/Home/corp-start-now-4.png";
import corpCertifiedTrainers from "../../../assets/Home/corp-certified-trainers.png";
import CertifiedTrainers from "./CertifiedTrainers";
import corp_how_img1 from "../../../assets/Home/corp-how-it-works-1.png";
import corp_how_img2 from "../../../assets/Home/corp-how-it-works-2.png";
import corp_how_img3 from "../../../assets/Home/corp-how-it-works-3.png";

import MessageBanner from "./MessageBanner";
import { questionsForCorprate } from "../../../Global/FrequentlyAskedQuestions";
import CorparateFormModal from "../../CorparateForm/CorparateFormModal";


const HomeV2_Corporate: React.FC<any> = ({ history }) => {
    useEffect(() => {
        controlAnimation()
    }, []);

    const [showCorparateModal, setShowCorparateModal] = useState(false);

    var startNowList = [{ description: "corp.startnow.first.title", image: start_now_1 },
    { description: "corp.startnow.second.title", image: start_now_2 },
    { description: "corp.startnow.third.title", image: start_now_3 },
    { description: "corp.startnow.fourth.title", image: start_now_4 }
    ];

            var howDoesItWork = [{ index: 0, title: "corp.how_does_it_works.first.title", description: "corp.how_does_it_works.first.content", image: corp_how_img1 },
    { index: 1, title: "corp.how_does_it_works.second.title", description: "corp.how_does_it_works.second.content", image: corp_how_img2 },
    { index: 2, title: "corp.how_does_it_works.third.title", description:"corp.how_does_it_works.third.content" , image: corp_how_img3 },
    ];

    return (
        <React.Fragment>
            <div>
                <div className="home">
                    <div style={{ backgroundImage: "url(" + hero + ")" }} className="super_hero">
                        <NavBar type="new_main" history={history} links={[["home.navigation_bar.btn_forkids", "/kids"], ["home.navigation_bar.btn_forbeatutor", "/tutor"]]} />
                        <Banner title="corp.banner.title" description="corp.banner.description" buttonText="corp.banner.button_text" butttonAddress="/register" />
                    </div>
                    <StartNow title="corp.startnow.title" items={startNowList} />
                    <CertifiedTrainers title="corp.certified_trainers.title" description="corp.certified_trainers.description" buttonText="corp.certified_trainers.button_text" buttonOnClick={() => { setShowCorparateModal(true) }} image={corpCertifiedTrainers} />
                    {/* <ExpertsInstructors /> */}
                    <HowDoesItWork items={howDoesItWork} />
                    {/* <MessageBanner /> */}
                    {/* <SubscriptionsPackages /> */}
                    {/* <StudentsReviews /> */}
                    <FrequentlyAskedQuestions questions={questionsForCorprate} />
                    <MobileApplication />
                    <NewFooter />
                </div >
            </div>

            {showCorparateModal && <CorparateFormModal show={showCorparateModal} hide={() => { setShowCorparateModal(false) }} />}
        </React.Fragment>

    )
}

export default HomeV2_Corporate;

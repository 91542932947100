import React, { useState, useEffect } from "react";
import "../../../layout/HomeV2/New_Application.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Image, Row } from "react-bootstrap";
// import "../../../mobile/Home/Application.css";
// import "../../../tablet/Home/Application.css";
import Container from "../../../components/Container";

import mobile from "../../../assets/Home/mobile-apps-new.png";
import googlePlay from "../../../assets/google-play.png";
import appStore from "../../../assets/appstore.png";

import googlePlayMobile from "../../../assets/Home/google-play-mobile.png";
import appStoreMobile from "../../../assets/Home/app-store-mobile.png";
import { useTranslation } from "react-i18next";
import getMobileOperatingSystem from "../../../Global/Utilities/utility";

const MobileApplication = () => {
    const { t } = useTranslation();

    const [googlePlayIcon, setGooglePlayIcon] = useState(googlePlay);
    const [appStoreIcon, setAppStoreIcon] = useState(appStore);
    const handleResponsiveness = () => {
        const width = window.screen.width;
        if (width < 960) {
            setGooglePlayIcon(googlePlayMobile)
            setAppStoreIcon(appStoreMobile)
        }
    }
    useEffect(() => {
        handleResponsiveness()
    }, [])

    const handleApplicationDownloadClicked = (title: String) => {
        var mobile = getMobileOperatingSystem();
        if (mobile === "Android") {
            window.location.href = "https://play.google.com/store/apps/details?id=com.SpeakingLine.android";
        }
        else if (mobile === "IOS") {
            window.location.href = "itms-apps://apps.apple.com/tr/app/speakingline/id1484624249";
        }
        else {
            if (title === "android") {
                window.location.href = "https://play.google.com/store/apps/details?id=com.SpeakingLine.android";
            }
            else if (title === "ios") {
                window.location.href = "itms-apps://apps.apple.com/tr/app/speakingline/id1484624249";
            }
        }
    }
    return (
        <div className="new_application">
            <Container className="new_application-container">
                <Row>
                    <Col md={6} className="col-12">
                        <div className="new_application-img-container">
                            <Image style={{ maxWidth: '100%', maxHeight: '100%' }} src={mobile} />
                        </div>
                    </Col>

                    <Col md={6} className="col-12 align-self-center">
                        <div className="text-left new_application-container-right">
                            <h4 className="new_application-title">{t("home.application.title")}</h4>
                            <p className="new_application-description">
                                {t("home.application.content")}
                            </p>
                            <div className="new_download-buttons">
                                <div>
                                    <Image src={googlePlayIcon} onClick={() => handleApplicationDownloadClicked('android')} className="new_google-play-icon" />
                                </div>
                                <div>
                                    <Image src={appStoreIcon} onClick={() => handleApplicationDownloadClicked('ios')} className="new_app-store-icon" />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MobileApplication;

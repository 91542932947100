import React from "react";
import "../../../layout/HomeV2/New_CertifiedTrainers.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Image } from "react-bootstrap";
// import "../../../mobile/Home/StudentsReviews.css";
// import "../../../tablet/Home/StudentsReviews.css";
import Container from "../../../components/Container";
import { Col, Row } from "reactstrap";
import { useHistory } from "react-router";
import {useTranslation} from "react-i18next";


interface ContentComponentProps {
    title: string,
    image: any,
    description: string,
    buttonText: string,
    buttonOnClick : () => void
}

type ContentProps = ContentComponentProps;

const CertifiedTrainers: React.FC<ContentProps> = ({ title, image, description, buttonText, buttonOnClick }) => {

    var history = useHistory();
    const { t } = useTranslation();
    return (
        <div className="new_certified-trainers pb-5">
            <Container className="new_certified-trainers-container">
                <Row>
                    <Col className="col-md-5 col-12"><Image className="new_certified-trainers-item-image" src={image} /></Col>
                    <Col className="col-md-7 col-12 align-self-center">
                        <Container>
                            <Row>
                                <span className="new_certified-trainers-item-title">{t(title)}</span>
                            </Row>
                            <Row>
                                <p style={{ whiteSpace: "pre-line" }} className="new_certified-trainers-item-description">{t(description).split("<br/>").join("\n")}</p>
                            </Row>
                            <Row>
                                <Button onClick={buttonOnClick} className="new_certified-trainers-item-button">
                                    {t(buttonText)}
                                </Button>
                            </Row>
                        </Container>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default CertifiedTrainers;

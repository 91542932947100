import paypalIcon from "../assets/paypal.svg";
import dangerIcon from "../assets/warning.svg";
import { Tabs, Tab } from 'react-bootstrap';
import "../layout/Payout/payout.css";
import React from "react";
import AlertInfo from "../components/alerts/AlertInfo";


const Preview5 = () => {
    return (
        <React.Fragment>
            <AlertInfo show = {true} isAutoHide = {false} message = "Bu bir mesaj!" type = {1} setShow = {(d:any)=>{}}/>
            <div className="container">
                <div className="row">
                    <div className="col-lg-9">
                        <div className="payout-card">
                            <div className="payout-body">
                                <div className="payout-header">
                                    <h6 className="payout">Payout Account</h6>
                                </div>
                                <div className="payout-box">
                                    <div className="box-header">
                                        <div className="payimg">
                                            <img src={paypalIcon} alt="" />
                                            <p> <b>Added:</b> 02 Mar 2019</p>
                                        </div>
                                        <div className="payouy-b-action">
                                            <button className="btn btn-primary" disabled>Set Account</button>
                                        </div>
                                    </div>

                                    <div className="payout-tab">
                                        <Tabs id="uncontrolled-tab-example" defaultActiveKey="PayPal">
                                            <Tab eventKey="PayPal" title="PayPal">
                                                <div className="payout-tab-content">
                                                    <p>
                                                        Hac pharetra, faucibus non nunc in lectus risus risus.
                                                        Cras malesuada consequat vehicula risus non at tellus.
                                                        Eget accumsan mi interdum non.
                                                        Morbi at in vel hendrerit consequat. Varius id est arcu nec,
                                                        vestibulum nisl neque nulla. Malesuada laoreet id at cursus.
                                                        Mauris cras amet nulla est dolor quisque.
                                                </p>
                                                    <div className="payout-form-wrapper">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <label>Email address</label>
                                                                    <input type="text" className="form-control" />
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <label>Confirm Email address</label>
                                                                    <input type="text" className="form-control" />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="payout-action">
                                                                    <button className="btn btn-primary">Set Payout Account</button>
                                                                    <button className="btn btn-link">Cancel</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab eventKey="IBAN" title="IBAN">
                                                <div className="payout-tab-content">
                                                    <p>
                                                        Hac pharetra, faucibus non nunc in lectus risus risus.
                                                        Cras malesuada consequat vehicula risus non at tellus.
                                                        Eget accumsan mi interdum non.
                                                        Morbi at in vel hendrerit consequat. Varius id est arcu nec,
                                                        vestibulum nisl neque nulla. Malesuada laoreet id at cursus.
                                                        Mauris cras amet nulla est dolor quisque.
                                                    </p>
                                                    <div className="payout-form-wrapper">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label>Name</label>
                                                                    <input type="text" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label>Surname</label>
                                                                    <input type="text" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label>IBAN</label>
                                                                    <input type="text" className="form-control" />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="payout-action">
                                                                    <button className="btn btn-primary">Set Payout Account</button>
                                                                    <button className="btn btn-link">Cancel</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>

                                <div className="payout-danger-alert">
                                    <img src={dangerIcon} alt="" />
                                    <p>Ödemeleriniz her ayın 2. gününde belirttiğiniz hesaba yatırılmaktadır. Hesap ayarları için tıklayın. </p>
                                </div>


                                <div className="payout-table-wrapper">
                                    <h3>Earning History</h3>
                                    <div className="table-responsive">
                                        <table className="table payout-table">
                                            <thead>
                                                <th>Dönem</th>
                                                <th>Konuşma Süresi (minute)</th>
                                                <th>Çarpan ( USD )</th>
                                                <th>Toplam ( USD )</th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>November 2019</td>
                                                    <td>120</td>
                                                    <td>10</td>
                                                    <td>1.200</td>
                                                </tr>
                                                <tr>
                                                    <td>November 2019</td>
                                                    <td>120</td>
                                                    <td>10</td>
                                                    <td>1.200</td>
                                                </tr>
                                                <tr>
                                                    <td>November 2019</td>
                                                    <td>120</td>
                                                    <td>10</td>
                                                    <td>1.200</td>
                                                </tr>
                                                <tr>
                                                    <td>November 2019</td>
                                                    <td>120</td>
                                                    <td>10</td>
                                                    <td>1.200</td>
                                                </tr>
                                                <tr>
                                                    <td>November 2019</td>
                                                    <td>120</td>
                                                    <td>10</td>
                                                    <td>1.200</td>
                                                </tr>
                                                <tr>
                                                    <td>November 2019</td>
                                                    <td>120</td>
                                                    <td>10</td>
                                                    <td>1.200</td>
                                                </tr>
                                                <tr>
                                                    <td>November 2019</td>
                                                    <td>120</td>
                                                    <td>10</td>
                                                    <td>1.200</td>
                                                </tr>
                                                <tr>
                                                    <td>November 2019</td>
                                                    <td>120</td>
                                                    <td>10</td>
                                                    <td>1.200</td>
                                                </tr>
                                                <tr>
                                                    <td>November 2019</td>
                                                    <td>120</td>
                                                    <td>10</td>
                                                    <td>1.200</td>
                                                </tr>
                                                <tr>
                                                    <td>November 2019</td>
                                                    <td>120</td>
                                                    <td>10</td>
                                                    <td>1.200</td>
                                                </tr>
                                                <tr>
                                                    <td>November 2019</td>
                                                    <td>120</td>
                                                    <td>10</td>
                                                    <td>1.200</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>TOPLAM</th>
                                                    <th colSpan={2}>2.464</th>
                                                    <th>3.250</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Preview5;
import React from "react";
import { Image, Badge } from "react-bootstrap";
import styled from "styled-components";
import theme from "../../../theme";

import turk from "../../../assets/student-instructors-page/turk.png";
import cardThreeImg from "../../../assets/student-instructors-page/card-three-img.png";

import moment from 'moment';
import {useTranslation} from "react-i18next";

const InterviewTimeBadge = styled(Badge)`
    padding: 3 7px;
    background-color: #FDD9D1;
    border-radius: 3px;
    text-align: center;
    color: ${theme.colors.primary};
    font-size: 13px;
    font-weight: 600;
    font-family: ${theme.text.fonts.nunito};
    margin-left: 10px;
`;

interface NextSessionAvailableProps {
    nextSession: any
}

const NextSessionAvailable :React.FC<NextSessionAvailableProps> = ({nextSession}) =>{
    const { t } = useTranslation();
//console.log("Next Session ", nextSession);
const teacherName = nextSession.teacherUser.name + " " + nextSession.teacherUser.surname?.charAt(0) + ".";

const dateTime = moment(nextSession.startDate * 1000);
const date = dateTime.format("DD.MM.YYYY");
const day = dateTime.format("dddd");
const startHours = dateTime.format("HH:mm");

const endDateTime = moment(nextSession.endDate * 1000);
const endHours = endDateTime.format("HH:mm");
const minute = nextSession.totalMinute;

    return (
        <div className="student-instructors-page-card-container">
            <Image 
                src={cardThreeImg}    
            />
            <div className="student-instructors-page-card-content">
                <h4 className="student-instructors-page-card-title">
                    {t("dashboard.upcoming_meeting")}
                </h4>
                <div className="student-instructors-page-card-info">
                    <h4 className="student-instructors-page-card-name">
                        {teacherName}
                    </h4>
                    {/* <Image 
                        src={turk} 
                        height={12}
                        width={20}
                        className="student-instructors-page-nationality"    
                    /> */}
                </div>
                <h4 className="student-instructors-page-card-title">
                    {date}, {day}
                </h4>
                <div className="student-instructors-page-interview-date">
                    <h4 className="student-instructors-page-card-title">
                        {startHours} - {endHours}
                    </h4>
                    <InterviewTimeBadge>
                        {minute}  {t("dashboard.minutes")}
                    </InterviewTimeBadge>
                </div>
            </div>
        </div>
    )
}

export default NextSessionAvailable;

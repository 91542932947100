import React from 'react';
import {Scrollbars} from "react-custom-scrollbars-2";
import Message from "../../StudentVideoChat/Message";


interface ReportType {
    user: any;
    companyName: string;
    guid: string;
    reportCode: string;
    packageName: string;
    packageMinute: number;
    successfullMeetingDates: string;
    successRate: number;
    successfullMeetingCount: number;
    totalMeetingCount: number;
    tutorName:string;
    tutorComment_LanguageLevel: string | null;
    tutorComment_Vocabulary: string | null;
    tutorComment_Grammar: string | null;
    tutorComment_Speed: string | null;
    tutorComment_Result: string | null;
    tutorComment_Overall: string | null;
}

interface ReportTableProps {
    reports: ReportType[];
}

const ReportRow: React.FC<{ report: ReportType }> = ({ report }) => (
    <tr className="report-row">
        {/*<td>{report.guid}</td>*/}
        {/*<td>{report.reportCode}</td>*/}
        <td>{report.user.name} {report.user.surname}</td>
        <td>{report.companyName}</td>
        <td>{report.packageName}</td>
        <td>{report.packageMinute}</td>
        <td>{report.successfullMeetingDates}</td>
        <td>{report.packageMinute}</td>
        <td>%{report.successRate}</td>
        <td>{report.tutorName}</td>
        <td>{report.tutorComment_Overall}</td>
        {/*<td>{report.successfullMeetingCount}</td>*/}
        {/*<td>{report.totalMeetingCount}</td>*/}
        <td>{report.tutorComment_LanguageLevel}</td>
        <td>{report.tutorComment_Vocabulary}</td>
        <td>{report.tutorComment_Grammar}</td>
        <td>{report.tutorComment_Speed}</td>
        <td>{report.tutorComment_Result}</td>

    </tr>
);

const ReportTable: React.FC<ReportTableProps>   = ({ reports}) => {
    return (
        <div style={{width : "100%"}}>
            <table className="report-table">
                <thead>
                <tr>
                    <th className="center-text" colSpan={4}>Genel</th>
                    <th className="center-text" colSpan={3}>Ders Bilgileri</th>
                    <th className="center-text" colSpan={2}>Eğitmen</th>
                    <th className="center-text" colSpan={5}>Değerlendirme</th>
                </tr>
                <tr>
                    {/*<th>GUID</th>*/}
                    {/*<th>Report Code</th>*/}
                    <th>Katılımcı Bilgileri</th>
                    <th>Firma</th>
                    <th>Paket</th>
                    <th>Ders Süresi</th>
                    <th>Görüşme Tarihi</th>
                    <th>Ders Süresi</th>
                    <th>Katılım Oranı</th>
                    <th>Eğitmen</th>
                    <th>Genel</th>
                    {/*<th>Successful Meeting Count</th>*/}
                    {/*<th>Total Meeting Count</th>*/}
                    <th>Dil Algısı</th>
                    <th>Kelime</th>
                    <th>Gramer</th>
                    <th>Hız</th>
                    <th>Sonuç</th>
                </tr>
                </thead>
                <tbody>
                {reports.map((report, index) => (
                    <ReportRow key={report.guid} report={report}/>
                ))}
                </tbody>
            </table>
         </div>
    );
};

export default ReportTable;

import React, { useEffect, useState } from "react";
import IncomingCallModal from '../modals/meeting/IncomingCallModal';
import { connection, Init } from "../../Global/Utilities/signalr"
import { ROLES } from "../../Global/Constants/constants";
import { connect, useDispatch } from "react-redux";
import CallingModal from "../modals/meeting/CallingModal";
import AlertInfo from "../alerts/AlertInfo";
import moment from "moment";
import { useHistory } from "react-router";

interface AfterLoginPageProps {
    children: React.ReactNode,
}

type prop = AfterLoginPageProps & StateProps;

const AfterLoginPage: React.FC<prop> = ({ children, auth, logout }) => {

    const history = useHistory();

    const [isShowIncomingcallModal, setIsShowIncomingcallModal] = useState(false);
    const [isReservationTimeModal, setIsReservationTimeModal] = useState(false);
    const [isShowBeforeMeetingStartInfo, setIsShowBeforeMeetingStartInfo] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");
    const [opponent, setOpponent] = useState<any>({})

    const dispatch = useDispatch();
    useEffect(() => {
        listenChat();
        listenMeetingRequestForTeacher();
        listenReservationTime();
        listenMeetingBeforeStart();
    }, [auth]);

    useEffect(() => {
        //console.log("AfterLoginPage")
        if (logout && logout?.isLoggedOut) {
            history?.push("/login");
            dispatch({ type: "USER_LOGGED_OUT", payload: false });
        }
    }, [logout]);

    const listenMeetingRequestForTeacher = () => {
        if (auth?.user?.role === ROLES.TEACHER) {
            connection.off("MeetingRequest");
            connection.on("MeetingRequest", (message) => {
                //console.log("MeetingRequest called ", message)

                if (message.params) {

                    setOpponent({
                        name: message.params.attendee_name + " " + message.params.attendee_surname?.charAt(0) + ".",
                        id: message.params.attendee_guid,
                        pictureUrl: message.params.attendee_photo_url,
                        meetingRequestId: message.params.meeting_request_id
                    })
                    setIsShowIncomingcallModal(true);
                }
            });
        }
    }

    const listenReservationTime = () => {
        if (auth?.user?.role === ROLES.STUDENT) {
            connection.off("StartScheduledMeeting");
            connection.on("StartScheduledMeeting", (message) => {
                //console.log("StartScheduledMeeting called " + message)

                if (message.params) {
                    setOpponent({
                        name: message.params.attendee_name + " " + message.params.attendee_surname?.charAt(0) + ".",
                        id: message.params.attendee_guid,
                        pictureUrl: message.params.attendee_photo_url,
                        reservation_id: message.params.reservation_id,
                    })
                    setIsReservationTimeModal(true);
                }
            });
        }
    }

    const listenMeetingBeforeStart = () => {
        connection.off("BeforeStartScheduledMeeting");
        connection.on("BeforeStartScheduledMeeting", (message) => {
            //console.log("BeforeStartScheduledMeeting called " + message)

            if (message.params) {
                // setOpponent({
                //     name: message.params.attendee_name + " " + message.params.attendee_surname?.charAt(0) + ".",
                //     id: message.params.attendee_guid,
                //     pictureUrl: message.params.attendee_photo_url,
                //     reservation_id: message.params.reservation_id,
                // })

                var min = moment(message.params.start_time * 1000).diff(moment(), 'minutes');
                const text = "Scheduled Meeting with " + message.params.attendee_name + " will start in " + min + " mins";
                setInfoMessage(text);
                setIsShowBeforeMeetingStartInfo(true);
            }
        });
    }

    const listenChat = () => {
        connection.off("ReceiveChatMessage");
        connection.on("ReceiveChatMessage", (message) => {
            dispatch({
                type: "CHAT_MESSAGE_IS_RECEIVED",
                payload: {
                    messageText: message.params.messageText,
                    roomType: message.params.roomType,
                    roomId: message.params.roomId,
                    senderUserName: message.params.senderUser_Name,
                    senderUserSurname: message.params.senderUser_Surname,
                    senderUserPhotoUrl: message.params.senderUser_PhotoUrl
                }
            })
        })
    };

    return (
        <React.Fragment>
            {
                children
            }

            {
                isShowIncomingcallModal &&
                <IncomingCallModal
                    show={isShowIncomingcallModal}
                    hide={() => setIsShowIncomingcallModal(false)}
                    oppenentId={opponent.id}
                    opponentName={opponent.name}
                    meetingRequestId={opponent.meetingRequestId}
                    opponentPicture={opponent.pictureUrl as String}
                />
            }

            {
                isReservationTimeModal &&
                <CallingModal
                    show={isReservationTimeModal}
                    hide={() => setIsReservationTimeModal(false)}
                    oppenentId={opponent.id}
                    opponentName={opponent.name}
                    meetingRequestId={opponent.reservation_id}
                    opponentPicture={opponent.pictureUrl as String}
                    isReservationPopup={true}
                />
            }
            {
                isShowBeforeMeetingStartInfo &&
                <AlertInfo show={isShowBeforeMeetingStartInfo} setShow={setIsShowBeforeMeetingStartInfo} isAutoHide={false} message={infoMessage} type={2} />
            }
        </React.Fragment>
    );
}

interface StateProps {
    auth: any,
    logout: any
}
function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer,
        logout: state.LogoutReducer
    }
}

export default connect(mapStateToProps)(AfterLoginPage);
import React, { useRef, useState } from "react";
import axios from "axios";
import { SUBSCRIPTION_URL } from "../Global/Constants/constants";
import { ReturnProps } from "../@types/functions";
import { useSetState } from "../Global/Utilities/getsetstate"
import Input from "./inputs";
import styled from "styled-components";
import theme from "../theme";
import Button from "./buttons";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import AlertInfo from "./alerts/AlertInfo";
import {useTranslation} from "react-i18next";

const PromoBtn = styled(Button)`
    padding: 10px 0;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    font-family: ${theme.text.fonts.nunito};
`;

interface PromoCodeProps {  
    promeCode?: any,
    setPromeCode: (code: any) => void
}

const Coupon: React.FC<PromoCodeProps> = ({
    promeCode,
    setPromeCode
}) => {
    const { t } = useTranslation();
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    // const [coupon, setCoupon, getCoupon] = useSetState<any>({});
    const [showPromoLink, setShowPromoLink] = useState(true);
    const [showPromoCode, setShowPromoCode] = useState(false);

    const promoCodeLink = useRef<HTMLParagraphElement>(null);
    const promoCode = useRef<HTMLDivElement>(null);

    const handlePromoCode = () => {
        promoCodeLink.current?.classList.add("promo-code-link-anim");
        setTimeout(() => {
            setShowPromoLink(false)
            setShowPromoCode(true);
            promoCode.current?.classList.add("promo-code-anim")
        }, 400)
    }

    const handlePromoCodeAddClicked = async () => {

        if (promeCode) {
            setPromeCode(null);
            return;
        }

        const res = await trackPromise(axios.get<any, ReturnProps>(SUBSCRIPTION_URL + "/api/v1/coupons/" + inputStates.txtCode));

        // Handling Error
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            return null;
        }

        if (res.data && res.data.guid) {
            setPromeCode(res.data)
        }
        else {
            setError(t("home.payment.invalid_coupon_code"));
            setShowError(true)
        }
        //setPromoCodeTrue(Prev =>!Prev)
    }

    const [inputStates, setInputStates] = useState({
        txtCode: ""
    });

    const handleChange = (event: any) => {
        const name = event.target.name;
        setInputStates({
            ...inputStates,
            [name]: event.target.value,
        });
    };

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />

            {
                showPromoLink &&
                <p
                    className="package-total-bill-promo-code"
                    ref={promoCodeLink}
                    onClick={handlePromoCode}
                >
                    {t("home.payment.have_coupon_code")}
                </p>
            }
            {
                showPromoCode && (
                    <div className="promo-input-container" ref={promoCode}>
                        <div className="promo-input">
                            <Input name="txtCode" type="default" label={t("home.payment.promotion_code")} onChange={handleChange} style={{ backgroundColor: promeCode && "#CACCCF", color: promeCode && "#45494D" } as React.CSSProperties} disabled={promeCode} />
                        </div>
                        <div className="promo-btn">
                            {
                                inputStates.txtCode && (
                                    <PromoBtn type="default" primary style={{ backgroundColor: promeCode && "#EE3F46" }} onClick={handlePromoCodeAddClicked}>
                                        {
                                            promeCode ?  t("home.payment.remove") : t("general.apply")
                                        }
                                    </PromoBtn>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    );
}
export default Coupon
import React from "react";
import "../../layout/ForgetPassword/ForgetPassword.css";
import Form from "./Form";
import Footer from "../../components/Footer";
import NavBar from "../../components/navbars";

const ForgetPassword:React.FC<any> = ({ history }) =>{
    return (
        <div className="forget-password">
            <NavBar type="minimal" history={history} />
            <Form history={history} />
            <Footer />
        </div>
    )
}

export default ForgetPassword;

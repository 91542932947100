import React,{ useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { History } from "history";
import Form from "../../components/others/Form";
import Informations from "./Informations";
import "../../layout/UpdatePaymentMethode/Content.css";
import "../../tablet/UpdatePayment/UpdatePayment.css";

import Arrow from "../../assets/payment/arrow-orange.png";
import whiteArrow from "../../assets/payment/arrow-white.png";

interface ContentProps {
    history: History
}

const Content:React.FC<ContentProps> = ({ history }) =>{
    const [buttonArrow, setButtonArrow] = useState(Arrow);
    const handleHoverIn = () => setButtonArrow(whiteArrow);
    const handleHoverOut = () => setButtonArrow(Arrow)
    return(
        <Container className="payment-container">
            <Row>
                <Col lg={8}>
                    <Form />
                </Col>
                <Col lg={4} className="payment-total-bill-container">
                    <Informations />
                </Col>
            </Row>
            <div 
                className="go-back-payment go-pack-update-payment" 
                onMouseOver={handleHoverIn} 
                onMouseLeave={handleHoverOut} 
                onClick={() =>history.goBack()}
            >
                <Image 
                    src={buttonArrow} 
                    height={24} 
                    width={24} 
                    style={{
                        marginRight: 10 
                    }} 
                />
                <p>Back</p>
            </div>
        </Container>
    )
}

export default Content;

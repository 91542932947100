import React,{ useState, useEffect } from "react";
import "../../layout/Payment/Form.css";
import { Image } from "react-bootstrap";
import Input from "../inputs";
import "../../mobile/Payment/Form.css";
import Address from "../../pages/Payment/Adress";

import visa from "../../assets/payment/Visa.png";
import amex from "../../assets/payment/Amex.png";
import mastercard from "../../assets/payment/Mastercard.png";
import creditCard from "../../assets/payment/creditCard.png";
import info from "../../assets/payment/info.png";


const PaymentForm = () => {
  const [showAdressForm, setShowAdressForm] = useState(false);
  const [expirationDate, setExpirationDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const handleExpirationDate = () =>{
    if(expirationDate.length < 2) {
      
    }else if(expirationDate.length === 2) {
      if(expirationDate[1] === "/") {
        setExpirationDate("0" + expirationDate[0] + "/")
      }else if(+expirationDate > 12) {
        setExpirationDate("12/")
      } else {
        setExpirationDate(Prev =>Prev + "/")
      }
    } else if(expirationDate.length > 2) {
      const date = expirationDate.split("/");
      if(date[1].length > 2) {
        setExpirationDate(date[0] + "/50")
      } else if(+date[1] < 20 && date[1].length === 2) {
        setExpirationDate(date[0] + "/20")
      } else if(+date[1] >= 90 && date[1].length === 2) {
        setExpirationDate(date[0] + "/50")
      }
    }
  }
  const handleExpirationDateInput = (e:any) =>{
    const re = /^\d+(\/\d+)*$/;
    if(e.target.value === "" || re.test(e.target.value)) {
      setExpirationDate(e.target.value)
    }
  }
  const handleCvvInput = (e:any) =>{
    const re = /^\d+(\/\d+)*$/;
    if((e.target.value === "" || re.test(e.target.value)) && e.target.value.length <= 4) {
      setCvv(e.target.value)
    }
  }
  const handleCardNumberInput = (e:any) =>{
    const re = /^\d+(\/\d+)*$/;
    if(e.target.value === "" || re.test(e.target.value)) {
      setCardNumber(e.target.value)
    }
  }
  useEffect(() =>{
    handleExpirationDate()
  },[expirationDate])
  return (
    <div className="payment-form shadow">
      <div className="payment-form-header">
        <h4 className="payment-form-title">Ödeme yöntemini seçiniz</h4>
        <div className="payment-methods">
          <Image src={visa} className="payment-method-icon" />
          <Image src={amex} className="payment-method-icon" />
          <Image src={mastercard} className="payment-method-icon" />
        </div>
      </div>
      <div className="payment-form-card-number">
        <Input type="icon" icon={creditCard} placeholder="4556 3325 8590 3732" label="Card Number" value={cardNumber} onChange={handleCardNumberInput} />
      </div>
      <div className="credit-card-info">
        <div className="credit-card-holder">
          <Input type="default" label="Card Holder Name" />
        </div>
        <div className="credit-card-extra-info">
          <Input type="default" label="Expiration Date" placeholder="GG / AA" value={expirationDate} onChange={handleExpirationDateInput} />
        </div>
        <div className="credit-card-extra-info">
          <Input type="default" label="CVV" labelIcon={info} value={cvv} onChange={handleCvvInput} />
        </div>
      </div>
      <hr className="payment-form-sep" />
      <div className="billing-adress-info">
        <h4>Billing Address</h4>
        <div className="billing-adress-info-container">
          <p className="billing-adress-info-text">
            Ertan Çetin <br />
            313 Sokak No:44/A Efeler Mah. Buca, İzmir
          </p>
          <div className="billing-adress-edit">
            <p onClick={() =>setShowAdressForm(true)}>Edit</p>
          </div>
        </div>
      </div>
      {
        showAdressForm && <Address closeFunc={() =>setShowAdressForm(false)} />
      }
    </div>
  );
};

export default PaymentForm;

import moment from 'moment'

export const dynamicDateFormate = (date: any, formate?: string) => {
   return moment(date).format(formate ? formate : "MMM Do YY");

}

export const makeTimeSlot = (x:any) => {
 
   //Format the time
   let startTime = moment(x.openTime, "HH:mm");

   //Format the end time and the next day to it 
   let endTime = moment(x.closeTime, "HH:mm").add(1, 'days');

   //Times
   let allTimes = [];

   //Loop over the times - only pushes time with 30 minutes interval
   while (startTime < endTime) {
      //Push times
      if(allTimes.length){
         allTimes[allTimes.length-1]+=" - "+startTime.format("HH:mm")
       }
      allTimes.push(startTime.format("HH:mm"));
      //Add interval of 30 minutes
      startTime.add(x.slotInterval, 'minutes');
   }
   allTimes[allTimes.length-1]+=" - "+"00:00"
   return allTimes;
}
export const todayStr = (date: any) => { return (new Date(date).toISOString().replace(/T.*$/, '')) }

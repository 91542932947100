import React, { useEffect, useState } from "react";
import Page from "../../components/pages";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Button from "../../components/buttons";
import "../../layout/SubscriptionDetails/SubscriptionDetails.css";
import "../../mobile/SubscriptionDetails/SubscriptionDetails.css";
import "../../tablet/SubscriptionDetails/SubscriptionDetails.css";
import SubscriptionUpgrade from "./SubscriptionUpgrade";
import PauseSubscription from "./PauseSubscriptionDialog";
import { SUBSCRIPTION_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import NoSubscriptionFound from "./NoSubscriptionFound";
import moment from "moment";
import AlertInfo from "../../components/alerts/AlertInfo";
import {useTranslation} from "react-i18next";


const SubscriptionDetailsContent = () => {
    const { t } = useTranslation();

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [success, setSuccess] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);

    const [showSubsUpgrade, setShowSubsUpgrade] = useState(false);
    const [showPauseSubs, setShowPauseSubs] = useState(false);

    const [subscription, setSubscription] = useState<any>(null);
    const [dataIsReceived, setDataIsReceived] = useState(false);

    const history = useHistory();

    useEffect(() => {
        LoadSubscriptions();
    }, []);

    const LoadSubscriptions = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(SUBSCRIPTION_URL + "/api/v1/subscription/user?productType=1&subscriptionStatusType=5"));
        // Handling Error
        if (!res.success || !res.data) {
            setError(res.error as string);
            setShowError(true)
            return null;
        }
        setDataIsReceived(true);
        // if(!res.data.subscriptions || res.data.subscriptions.length < 1)
        // {
        //     history.push("/packages");
        //     return;
        // }
        if (res.data.subscriptions && res.data.subscriptions.length > 0) {
            setSubscription(res.data.subscriptions[0]);
        }
    }

    const ChangeAutoRenew = async () => {
        const res = await trackPromise(axios.put<any, ReturnProps>(SUBSCRIPTION_URL + "/api/v1/subscription/" + subscription.guid + "/autorenew"));
        // Handling Error
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
        }
        else {
            setSuccess(t("general.successful_message"));
            setShowSuccess(true);
            LoadSubscriptions();
        }
    }

    const resumeSubscription = async () => {
        const res = await trackPromise(axios.put<any, ReturnProps>(SUBSCRIPTION_URL + "/api/v1/subscription/" + subscription.guid + "/resume"));
        // Handling Error
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
        }
        else {
            setSuccess(t("general.successful_message"));
            setShowSuccess(true);
            LoadSubscriptions();
        }
    }

    // if(!dataIsReceived)
    // {
    //     return(<></>)
    // }
    // else
    // {
    //     if()
    // }

    return (
        <React.Fragment>

            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
            <AlertInfo show={showSuccess} setShow={setShowSuccess} isAutoHide={true} message={success} type={1} />

            { !subscription ?
                <NoSubscriptionFound />
                :
                <Col lg={12}>
                    <div className="subscription-detail-plan">
                        <div className="subscription-plan-first-section">
                            <h4 className="subscription-detail-plan-title">
                                {t("home.subscription_packages.active_package")}
                            </h4>
                            <Row className="subscription-details-container">
                                <Col md={6} className="subscription-plan">
                                    <div className="subscription-plan-wrapper">
                                        <h4 className="subscription-plan-title">
                                            {t("general.daily")} {subscription.product.dailyProduct.value}  {t("general.minute")}  {t("general.weekly")} {subscription.product.weeklyProduct.value}  {t("general.day")}
                                        </h4>
                                        <h4 className="subscription-plan-subtitle">
                                            {subscription.product.monthlyProduct.value}  {t("home.subscription_packages.monthly_exist")}
                                        </h4>
                                        <Button
                                            type="default"
                                            primary
                                            className="subscription-plan-btn"
                                            disabled={subscription.status === 3}
                                            style={{
                                                width: "90%",
                                                marginTop: 15,
                                                paddingTop: 12,
                                                paddingBottom: 12
                                            }}
                                            onClick={() => history.push("/packages")}
                                        >
                                            {t("home.subscription_packages.upgrade_subscription")}
                                        </Button>
                                    </div>
                                </Col>
                                <Col md={6} className="subscription-plan">
                                    <div className="subscription-plan-wrapper">
                                        <h4 className="subscription-plan-title">
                                            {t("home.subscription_packages.active_package_end_date")}
                                        </h4>
                                        <h4 className="subscription-plan-subtitle">
                                            {moment(subscription.endDate * 1000).format("DD MMMM yyyy")}
                                        </h4>
                                        <Button
                                            type="default"
                                            secondary
                                            className="subscription-plan-btn"
                                            style={{
                                                width: "90%",
                                                marginTop: 15,
                                                paddingTop: 12,
                                                paddingBottom: 12
                                            }}
                                            onClick={() => {
                                                if (subscription.status === 1) // Active 
                                                {
                                                    if (subscription.pausedDate > 0) {
                                                        setError(t("home.subscription_packages.error_pause"));
                                                        setShowError(true);
                                                        return;
                                                    }
                                                    setShowPauseSubs(true);
                                                }
                                                else {
                                                    resumeSubscription();
                                                }

                                            }}
                                        >
                                            {t("home.subscription_packages.to_subscription")} {subscription.status === 3 ? t("home.subscription_packages.resume_subscription") : t("home.subscription_packages.pause_confirmation_approve")}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="subscription-plan-second-section">
                            <h4 className="subscription-detail-plan-title">
                                {t("home.subscription_packages.payment_configuration")}
                            </h4>
                            <Row className="subscription-details-container">
                              
                                {/* <Col md={6} className="subscription-plan">
                                    <div className="subscription-plan-wrapper">
                                        <h4 className="subscription-plan-title">
                                            Faturalandırma
                                        </h4>
                                        <h4 className="subscription-plan-subtitle">
                                            {subscription.product.monthlyProduct.value} ayda 1 faturlandırılır
                                        </h4>
                                        <Button
                                        type="default"
                                        primary
                                        className="subscription-plan-btn"
                                        style={{
                                            width: "90%",
                                            marginTop: 15,
                                            paddingTop: 12,
                                            paddingBottom: 12
                                        }}
                                    >
                                        Ödeme Bilgilerini Güncelle
                                        </Button>
                                    </div>
                                </Col> */}
                                <Col md={6} className="subscription-plan">
                                    <div className="subscription-plan-wrapper">
                                        <h4 className="subscription-plan-title">
                                            {t("home.subscription_packages.next_invoicing_date")}
                                        </h4>

                                        <h4 className="subscription-plan-subtitle">
                                            {subscription.isRenewActive ? (moment(subscription.nextRenewDate * 1000).format("DD MMMM yyyy")) : t("home.subscription_packages.no_auto_renew")}

                                        </h4>
                                        <Button
                                            type="default"
                                            secondary
                                            className="subscription-plan-btn"
                                            style={{
                                                width: "90%",
                                                marginTop: 15,
                                                paddingTop: 12,
                                                paddingBottom: 12
                                            }}
                                            onClick={ChangeAutoRenew}
                                            disabled={subscription.status === 3}
                                        >
                                            {subscription.isRenewActive ? t("home.subscription_packages.cancel_auto_renew") : t("home.subscription_packages.activate_auto_renew")}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>}
            {/* <Col lg={3}>
                    <div className="subscription-details-my-payments">
                        <div className="subscription-details-my-payments-list">
                            <h4 className="subscription-details-my-payments-title">
                                Ödemelerim
                            </h4>
                        </div>
                        <div className="subscription-details-my-payments-total">
                            <div className="subscription-details-my-payments-total-container">
                                <h4 className="subscription-details-my-payments-total-title">Toplam</h4>
                                <h4 className="subscription-details-my-payments-total-content">₺ 690.75</h4>
                            </div>
                        </div>
                    </div>
                </Col> */}
            {
                showSubsUpgrade && <SubscriptionUpgrade closeFunc={() => setShowSubsUpgrade(false)} />
            }
            {
                showPauseSubs && <PauseSubscription subscription={subscription} closeFunc={(isUpdate: boolean) => {
                    if (isUpdate) {
                        LoadSubscriptions();
                    }
                    setShowPauseSubs(false);
                }} />
            }
        </React.Fragment>
    )
}

export default SubscriptionDetailsContent;
import {
  EDGE_SOCKET_URL
} from "../Constants/constants"
import {
  HubConnectionBuilder,
  HubConnectionState,
  HubConnection,
  LogLevel,
} from '@microsoft/signalr';

var dispatch;
var shouldReConnect = true;


export const connection = new HubConnectionBuilder()
  .withUrl(EDGE_SOCKET_URL, {
    accessTokenFactory: () => localStorage.getItem("speakingline_access_token")
  })
  .withAutomaticReconnect()
  .configureLogging(LogLevel.Debug)
  .build();

connection.onclose(async (a) => {
  dispatch({ type: "SOCKET_STATUS_CHANGED", payload: false });
  if (a?.message?.includes("KeyNotFound")) {
     dispatch({ type: "USER_LOGGED_OUT", payload: true });
    //connection.stop();
    return;
  }

  //console.log("connection.onclose - Should ReConnect ", shouldReConnect)

  if (shouldReConnect) {  
    connection.start().then(() => {
      dispatch({ type: "SOCKET_STATUS_CHANGED", payload: true });
    }).catch(err => {
      dispatch({ type: "SOCKET_STATUS_CHANGED", payload: false });
      dispatch({ type: "USER_LOGGED_OUT", payload: true });
    });
  }
});

connection.onreconnected(async (a) => {
})

connection.onreconnecting(async (a) => {
  ///  dispatch({ type: "SOCKET_STATUS_CHANGED", payload: true });
})

// connection.hub.disconnected(function () {
//   if (connection.hub.lastError) 
//       { alert("Disconnected. Reason: " +  connection.hub.lastError.message); }
// });

const setUpSignalRConnection = () => {

  if (connection.state === HubConnectionState.Disconnected) {
    connection.start().then(() => {
      //console.log("aaa setUpSignalRConnection() SIGNAL R Connected  " + connection);
      dispatch({ type: "SOCKET_STATUS_CHANGED", payload: true });
    }).catch(err => {
      dispatch({ type: "SOCKET_STATUS_CHANGED", payload: false });
      //console.log("aaa setUpSignalRConnection() SignalR - ERROR- ", err);
      dispatch({ type: "USER_LOGGED_OUT", payload: true });
    });
  }

  ////console.log("CONNECTION " + connection);
  // if (connection.state === HubConnectionState.Connected) {

  //   connection.invoke('SubscribeToBoard', boardId).catch((err) => {
  //     return console.error(err.toString());
  //   });
  // }

  // EdgeConnection = edgeConnection;
  // return edgeConnection;
};

export const IsConnected = ()=> {
  return connection && connection.state === HubConnectionState.Connected
} 

export const Init = (_dispatch) => {
  dispatch = _dispatch;
  if (!connection || connection.state !== HubConnectionState.Connected) {
    shouldReConnect = true;
    EdgeConnection = setUpSignalRConnection();
  }
}

export const disconnectConnection = () => {
  if (connection && connection.state === HubConnectionState.Connected) {
    shouldReConnect = false;
    connection.stop({ message: "my" });
    dispatch({ type: "SOCKET_STATUS_CHANGED", payload: false });
  }
}

export let EdgeConnection;
import React,{ useState } from "react";
import "../../layout/InstructorProfileSettings/Interests.css";
import OptionButton from "../../components/buttons";
import styled,{ css } from "styled-components";
import theme from "../../theme";
import ParamType from "../../components/others/ParamType";

const Button = styled(OptionButton)`
    ${
        ({ clicked }) =>{
            if (clicked) return css`
                background-color: ${theme.colors.secondary};
                border-color: ${theme.colors.secondary};
                color: #fff;
            `;
            if(!clicked) return css`
                &:hover {
                    color: ${theme.colors.secondary};   
                    border-color: ${theme.colors.secondary};
                }
            `;
        }
    }
    margin-right: 15px;
    margin-bottom: 15px;
`;

interface LessonStyleProps {
    selectedLessonStyle : any[];
    setSelectedLessonStyle : any;
}

const ExpertiseOn : React.FC<LessonStyleProps> = ({
    selectedLessonStyle = [],
    setSelectedLessonStyle
}) =>{
    const [stylesSelected, setStylesSelected] = useState<any[]>([])
    return (
        <div className="instructor-style-container">
            <h4 className="instructor-style-title">SPECIALIZED ON</h4>
            <div className="instructor-style-options-container">
            <ParamType maxSelectionCount={3} type={5} selectedParams = {selectedLessonStyle} setSelectedParams={setSelectedLessonStyle} />
            </div>
            <hr className="instructor-section-sep" />
        </div>
    )
}

export default ExpertiseOn;

import React,{ useState } from "react";
import Page from "../../components/pages";
import { RouteComponentProps } from "react-router-dom";
import TopBar from "./TopBar";
import Content from "./Content";
//  import { instructors, getInstructorsByStatus } from "../../Global/InstructorsPage/Instructors";

const InstructorsPage:React.FC<RouteComponentProps> = ({ history }) => {
   const [instructorsList, setInstructorsList] = useState([]);
    const handleStatus = (status: "active" | "inactive" | "bussy" | "" | "favourite") => {};
    return (
        <Page type="regular" history={history} headerTitle="Eğitmenler">
             <TopBar handleStatus={handleStatus} />
            <Content instructors={instructorsList} />
        </Page>
    )
}

export default InstructorsPage;

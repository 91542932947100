import React,{ useState } from "react";
import "../../layout/RegularPage.css";
import NavBar from "../navbars";
import { History } from "history";
import { Container } from "react-bootstrap";
import Footer from "../Footer";
import "../../mobile/RegularPage.css";
import { connect, useDispatch } from "react-redux";


interface AdminDashboardPageInterface {
    auth: any
    history: History,
    children: React.ReactNode,
    headerTitle: String,
    activePage: String
}

const AdminDashboardPage: React.FC<AdminDashboardPageInterface> = ({ history, auth, children, headerTitle, activePage }) => {

    return (
        <div style={{ backgroundColor: "#fafafa" }}>
            <div className="regular-page-header">
                <NavBar type="admin-dashboard" history={history}  />
                <Container className="regular-paga-header-container">
                    <h4 className="regular-page-header-title">{headerTitle}</h4>
                    <h4 className="regular-page-header-subtitle">
                    </h4>
                </Container>
            </div>
            <Container className="regular-page-content">
                {
                    children
                }
            </Container>
            <div className="regular-page-footer-container">
                <Footer />
            </div>
        </div>
    )
}

function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer
    }
}

export default connect(mapStateToProps)(AdminDashboardPage);

import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown'
import "../../layout/TutorPayment/EarningHistory.css";
import Input from "../../components/inputs";
import theme from "../../theme";
import Button from "../../components/StatusButton";
import { NoReservationFoundIcon } from "../../assets/SVG/SVG";

const NoEarningHistory: React.FC<any> = () => {
    return (
        <React.Fragment>
            <div className="payout-table-wrapper">
                <h3>Earning History</h3>
                <div className="history-records-container">
                    <div>{NoReservationFoundIcon}</div>
                    <p className="history-records-notfound-text">
                        No record found
                 </p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default NoEarningHistory;

import React from "react";
import "../../layout/StudentVideoChat/ChatRoom.css";
import { Image } from "react-bootstrap";
import ChatRoomMessanger from "./ChatRoomMessanger";
import { useSpring, animated } from "react-spring";
import "../../mobile/StudentVideoChat/ChatRoom.css";

import ChatIcon from "../../assets/student-video-chat/chat-icon.png";
import close from "../../assets/student-video-chat/close.png";
import toggleMic from "../../assets/student-video-chat/toggle-mic.png";
import stopCall from "../../assets/student-video-chat/stop-call.png";
import toggleCameraImg from "../../assets/student-video-chat/toggle-camera.png";

interface ChatRoomProps {
    handleShowChatRoom: () => void;
    showChatRoom: boolean;
    hideChatRoom: () => void;
    session: any;
    attendeeName: string;
    attendeeGuid: string;
    senderId: any;
    meetingId: string;
    isMeetingFinished: boolean;
    // toggleCamera: () => void;
    // toggleMicro: () => void
}

const ChatRoom: React.FC<ChatRoomProps> = ({
    handleShowChatRoom,
    showChatRoom,
    hideChatRoom,
    isMeetingFinished,
    meetingId,
    session,
    attendeeName,
    attendeeGuid,
    senderId,
    // toggleCamera= () => //console.log("Toggle Camera"),
    // toggleMicro= () => //console.log("Toggle Micro")
}) => {
    const getChatRoomWidth = () => {
        const width = window.screen.width;
        if (width < 720) return width * 1.09
        else return 350
    }
    const chatRoomProps = useSpring({
        width: showChatRoom ? getChatRoomWidth() : 50,
    })
    const chatRoomContentProps = useSpring({
        opacity: showChatRoom ? 1 : 0,
        config: {
            delay: 500
        }
    })

    return (
        <React.Fragment>
            {
                !showChatRoom && (
                    <div className="chatroom-icon-container">
                        <Image
                            src={ChatIcon}
                            height={48}
                            width={48}
                            onClick={handleShowChatRoom}
                        />
                    </div>
                )
            }
            <animated.div
                className="chatroom-container"
                style={{
                    width: chatRoomProps.width
                }}
            >
                {
                    showChatRoom && (
                        <div className="chatroom-close-icon">
                            <Image
                                src={close}
                                height={30}
                                width={30}
                                onClick={hideChatRoom}
                            />
                        </div>
                    )
                }
                <ChatRoomMessanger
                    showChatRoom={showChatRoom}
                    session={session}
                    meetingId={meetingId}
                    isMeetingFinished={isMeetingFinished}
                    attendeeName={attendeeName}
                    senderId={senderId}
                    attendeeGuid={attendeeGuid}
                />
            </animated.div>

        </React.Fragment>

    )
}


export default ChatRoom;

import React from "react";
import "../../layout/LightRegularPage.css";
import NavBar from "../navbars";
import { History } from "history";
import Footer from "../Footer";
import { Container } from "react-bootstrap";
import "../../mobile/LightRegularPage.css";

interface LightRegularPageProps {
    history: History,
    children: React.ReactNode
}

const LightRegularPage:React.FC<LightRegularPageProps> = ({ history, children }) =>{
    return(
        <div className="light-regular-page">
            <NavBar type="dashboard" history={history} theme="dark" />
            <div className="light-regular-page-content-wrapper">
                <Container className="light-regular-page-container">
                    {
                        children
                    }
                </Container>
            </div>
            <div className="light-regular-page-footer-container">
                <Footer />
            </div>
            
        </div>
    )
}

export default LightRegularPage;

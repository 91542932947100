import React, { useState } from "react";
import "../../layout/StudentInstrucotrsPage/Header.css";
import Button from "../../components/StatusButton";
import Input from "../../components/inputs";
import "../../tablet/StudentInstructors/Header.css";

import search from "../../assets/student-instructors-page/search.png";
import { useTranslation } from "react-i18next";

interface HeaderProps {
    handleStatus:
    (status: "active" | "busy" | "inactive" | "favourite" | "" | "all") => void,
}

interface HeaderAllOptionsProps {
    handleStatus:
    (status: "active" | "busy" | "inactive" | "favourite" | "" | "all") => void
}

const HeaderAllOptions: React.FC<HeaderAllOptionsProps> = ({ handleStatus }) => {
    return (
        <div className="header-all-options-container">
            <div className="header-all-options-wrapper">
                <Button
                    status="all"
                    selected
                    onClick={() => handleStatus("all")}
                >
                    All Tutors
            </Button>
                <Button
                    status="online"
                    onClick={() => handleStatus("active")}
                >
                    Online
            </Button>
                <Button
                    status="busy"
                    onClick={() => handleStatus("busy")}
                >
                    Busy
            </Button>
                <Button
                    status="offline"
                    onClick={() => handleStatus("inactive")}
                >
                    Offline
            </Button>
            </div>
        </div>
    )
}

const Header: React.FC<HeaderProps> = ({ handleStatus }) => {
    const { t } = useTranslation();

    const [showAllHeaderOptions, setShowAllHeaderOptions] = useState(false);
    const [selectedAction, setSelectedAction] = useState({ title: "Tümü", icon: "all" });
    const [isFavSelected, setIsFavSelected] = useState(false);

    const handleStatusBeforeSent = (status: any) => {
        setShowAllHeaderOptions(false);
        handleStatus(status);
        switch (status) {
            case "all":
                setSelectedAction({ title: "Tümü", icon: "all" });
                break;
            case "active":
                setSelectedAction({ title: "Online", icon: "online" });
                break;
            case "busy":
                setSelectedAction({ title: "Meşgul", icon: "busy" });
                break;
            case "inactive":
                setSelectedAction({ title: "Offline", icon: "offline" });
                break;
            case "favourite":
                setIsFavSelected(!isFavSelected)
                break;
        }
    }

    return (
        <div className="student-instructors-page-header-container">
            <div>
                <h4 className="student-instructors-page-header-title">{t("general.findatutor")}</h4>
                <div className="student-instructors-page-status-btns">
                    <Button
                        status={selectedAction.icon || "all"}
                        selected
                        onClick={() => setShowAllHeaderOptions(!showAllHeaderOptions)}
                    >
                        {selectedAction.title}
                    </Button>
                    <Button
                        status="favourite"
                        selected = {isFavSelected}
                        onClick={() => handleStatusBeforeSent("favourite")}
                    >
                        Favorilerim
                    </Button>
                    {/* <div className="student-instructors-page-sep" />
                    <Button 
                        status="none"
                        onClick={() => handleStatus("")}
                    >
                        Lesson Style
                    </Button>
                    <Button 
                        status="none"
                        onClick={() => handleStatus("favourite")}
                    >
                        Tutor Accent
                    </Button> */}
                </div>
            </div>
            <div className="student-instructors-page-search-input-container">
                {/* <Input 
                    type="icon" 
                    icon={search}
                    focusColor="#fff"
                    placeholder="Find a tutor; Name, experience... "
                /> */}
            </div>
            {/* Header All Options Component */}
            {
                showAllHeaderOptions && <HeaderAllOptions
                    handleStatus={handleStatusBeforeSent}
                />
            }
        </div>
    )
}

export default Header;


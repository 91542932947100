import { css } from "styled-components";
import theme from "../../theme";

const StyledButton = css`
    background-color: ${theme.buttons.colors.facebook};
    border-color: ${theme.buttons.colors.facebook};
    &:hover {
        background-color: ${theme.buttons.colors.facebookHover};
        border-color: ${theme.buttons.colors.facebookHover};
    }
    @media only screen and (max-width: 720px) {
        width: 100%;
        padding: 8px 0 !important;
        font-size: 15px;
    }
    @media only screen and (max-width: 960px) and (min-width: 721px) {
        padding: 10px 20px !important;
        font-size: 15px;
    }
    &:active,
    &:focus {
        background-color: ${theme.buttons.colors.facebook};
        border-color: ${theme.buttons.colors.facebook};
    }
`;

export default StyledButton;

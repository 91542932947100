import styled from "styled-components";
import { Container } from "react-bootstrap";

const StyledContainer = styled(Container)`
    height: fit-content;
    box-sizing: border-box;
    padding: 0 15px;
`;

export default StyledContainer;


export const links = [
    {
        id: 0,
        label: "Öğretmen seç",
        to: "/questions/student"
    },
    {
        id: 1,
        label: "Ayrıntıları açıklama",
        to: "/privacy"
    },
    {
        id: 2,
        label: "İlk ders ödemesi",
        to: "/"
    },
    {
        id: 3,
        label: "Ders paketleri ödemesi",
        to: "/"
    },
    {
        id: 4,
        label: "Ders onaylanması ve değerlendirilmes",
        to: "/"
    },
    {
        id: 5,
        label: "Öğretmene yorum bırakmak",
        to: "/"
    },
    {
        id: 6,
        label: "Ders ile ilgili sorun ihbar etme",
        to: "/"
    },
    {
        id: 7,
        label: "«Öğrenciler» bölümü ile çalışma",
        to: "/"
    },
    {
        id: 8,
        label: "Platform garantileri ve diğer kurallar",
        to: "/"
    },
    {
        id: 9,
        label: "İptal Politikası",
        to: "/"
    },
]

export const paragraphs = [
    // {
    //     id: 0,
    //     content: [
    //         "Preply seçtiğiniz öğretmenlerle konuları, dilleri veya hobileri öğrenmek için bir platformdur. Platformu kullanma konusundaki önerilerimizi aşağıda bulabilirsiniz."
    //     ]
    // },
    // {
    //     id: 1,
    //     content: [
    //         "Öğretmen seç",
    //         "«Öğretmen bul» bölümünde arama sonuçlarını ihtiyaçlarınıza ve bütçe aralığınıza uygun bir şekilde daraltmak için arama filteleri kullanabilirsiniz. İİhtiyaç duyduğunuz uzmanlığı seçebilir, fiyat aralığını, çalışma için tercih edilen zamanı belirleyebilirsiniz. Biz mevcut filtrelerin tamamını kullanmanızı öneriyoruz. Bu, uygun öğretmen aramada size zaman kazandıracak ve hedeflerinize en iyi şekilde ulaşmanıza yardımcı olan birini seçtiğinizi kesinleştirecektir."
    //     ]
    // },
    // {
    //     id: 2,
    //     content: [
    //         "Ayrıntıları açıklama",
    //         "Eğer öğretmen seçmeden önce herhangi bir detayı netleştirmeniz gerekiyorsa, doğrudan arama sonuçlarında veya seçilen öğretmenin profil sayfasında kişisel mesaj gönderebilirsiniz. Site kuralları gereğince ilk dersin ödemesi yapılana kadar iletişim bilgisi paylaşmak yasaktır. Bu kuralın ihlali hesabınızın engellenmesiyle sonuçlanacaktır. Eğer öğretmenle sohbetiniz esnasında sistem tarafından yanlışlıkla engellenirseniz lütfen teknik destekle irtibata geçin."
    //     ]
    // },
    // {
    //     id: 3,
    //     content: [
    //         "İlk ders ödemesi",
    //         "Ders için zamanı seçtikten sonra, ödeme yöntemini seçerek doğrudan ödemeye geçin:"
    //     ]
    // },
    // {
    //     id: 4,
    //     content: [
    //         "Visa/Mastercard",
    //         "Paypal",
    //         "İlk ders sırasında, öğretmenin gereksinimlerinize uygun olup olmadığını belirleyebileceksiniz. Öğretmen size uygun değilse veya ders olmazsa, ödenen tutarın % 100'ünü iade eder veya bir başka öğretmenle ilk dersiniz için ödeme yaparız."
    //     ]
    // },
    // {
    //     id: 5,
    //     content: [
    //         "Ders paketleri ödemesi",
    //         "Birinci dersin ardından, öğrenmeye devam etmek için saat sayısı seçebilir ve ders paketi için ödeme yapabilirsiniz. 5, 10, 15 ve 20 saatlik paketler sizin için hazır. Paket ödemeler, dersin yalnızca, her dersin gerçekleştiği onaylandıktan sonra ödenmesini sağladığından, paranızın güvenliğini garanti eder."
    //     ]
    // },
    // {
    //     id: 6,
    //     content: [
    //         "Ders onaylanması ve değerlendirilmesi",
    //         "Dersten sonra öğretmen süreyi düzeltebilir ve dersin özetini verebilir. Ayrıca, öğretmen ev ödevi de ekleyebilir. Platform, dersi onaylamanız ve değerlendirmeniz için size otomatik olarak bir istek gönderecektir. Bunu yaptıktan sonra, öğretmen kendi hesabına ödemeyi alacaktır. Derslerin otomatik onayını açık olarak ayarladıysanız, dersiniz ders tamamlanma saatinden 72 saat sonra (veya geçmişte eklenen dersler Takvim'e eklendikten sonra 72 saat içinde) onaylanacaktır. Bu özellik öğretmeninizle birlikte yapılan ilk ders haricindeki tüm dersler için geçerlidir ve istediğiniz zaman etkinleştirilebilir veya devre dışı bırakılabilir."
    //     ]
    // },
    // {
    //     id: 7,
    //     content: [
    //         "Ders olmadıysa veya dersten tamamen memnun kalmadıysanız, ders saatinden sonra 72 saat içerisinde 'Derslerim' menüsündeki 'Sorun ihbar et' düğmesini kullanarak bize bunu bildirin, böylece gerekli adımlar atabiliriz."
    //     ]
    // },
    // {
    //     id: 8,
    //     content: [
    //         "Lütfen tüm ders değerlendirmelerinin anonim olduğunu unutmayın. Dolayısıyla, öğretmen hakkında dürüst bir geribildirim vermenizi öneririz. Bu, öğretmenlerimizin tabanını daha etkin bir şekilde yönetmemize yardımcı olacaktır"
    //     ]
    // },
    // {
    //     id: 9,
    //     content: [
    //         "Öğretmene yorum bırakmak",
    //         "İlk dersin ödemesinden iki hafta sonra, size öğretmeniniz için değerlendirme bırakmanızı öneren e-posta göndeririz. Yorum bırakarak, öğretmenin derecelendirme işlemine katılır ve gelecekteki öğrencilerin doğru öğretmen bulmasına yardımcı olursunuz. İsterseniz, İsteklerim bölümünden belirli bir öğretmenle olan sohbete girerek daha hızlı olarak değerlendirme bırakabilirsiniz."
    //     ]
    // },
    // {
    //     id: 10,
    //     content: [
    //         "Ders paketleri ödemesi",
    //         "Bazen, ders yapmanıza izin vermeyen bazı öngörülemeyen sorunlarla karşılaşabilirsiniz. Bu, dersi kaçırma, derse geç kalma, dersle ilgili teknik veya kişisel sorun yaşama ile ilgili olabilir. Bu durumda, gerekli adımları zamanında alabilmemiz için sorunu en kısa zamanda bildirmenizi tavsiye ederiz."
    //     ]
    // },
    // {
    //     id: 11,
    //     content: [
    //         "Dersinizle ilgili sorun bildirmek için bu adımları izleyin:"
    //     ]
    // },
    // {
    //     id: 12,
    //     content: [
    //         "‘Derslerim’ menüsüne gidin",
    //         'Sorun bildirmek istediğiniz öğretmeni ve dersi bulun, "Sorun İhbar et" butonunu tıklayın. Pop-up pencerede sorunla ilgili konuyu seçin, aksi halde "Diğer" i seçin. Tüm detaylı bilgileri \'Yorum\' alanına yazın. Seçiminizi temel alarak, mevcut çözümlerden bazıları önerilir, aksi takdirde sorun destek ekibine gönderilecektir.'
    //     ]
    // },
    // {
    //     id: 13,
    //     content: [
    //         "Ders tamamlanma saatinden sonra alacağınız e-postadaki 'Sorun bildir' bağlantısını da izleyebilirsiniz",
    //         "«Öğrenciler» bölümü ile çalışma",
    //         "«Öğrenciler Bul» bölümünde platformda doğrulanmış öğretmenlerden teklif almak isteyen öğrencilerimizin isteklerini yayınlıyoruz."
    //     ]
    // },
    // {
    //     id: 14,
    //     content: [
    //         "İsteğinizin «Öğrenciler Bul» bölümünde yayınlanmasını istiyorsanız, bu bağlantıdandevam etmeniz gerekiyor."
    //     ]
    // },
    // {
    //     id: 15,
    //     content: [
    //         "Tüm öğretmenlerin yanıtları profilinizin İsteklerim bölümünde görüntülenir."
    //     ]
    // },
    // {
    //     id: 16,
    //     content: [
    //         "İsteği nasıl kapatabilirim?",
    //         "İsteğinizi kapatmak istiyorsanız, web sitesine giriş yapıp, isteğinizin en üstünde olan «Öğrenciler Bul» bölümünü ziyaret etmeniz gerekir. Ardından «İsteği Kapat» butonuna tıklayın."
    //     ]
    // },
    // {
    //     id: 17,
    //     content: [
    //         "Platform garantileri ve diğer kurallar",
    //         "Yaptığınız ödeme için iade seçeneği, bütün planlanmamış derslerin ödemesinin yapıldığı tarihten itibaren 90 (doksan) günlük dönem süresince geçerlidir. Öğretmenin size uymadığı durumlarda veya planlanan bir dersin yapılmamasına rağmen yanlışlıkla onaylanması durumunda, planlanmış ders zamanından sonraki 3 gün içinde bizden paranızın transferini veya iadesini isteyebilirsiniz. Kullanıcı, platforma yatırdığı parayı ve/veya bu para karşılığında aldığı ders saatlerini paranın yatırıldığı tarihten itibaren 180 (yüz seksen) günlük bir dönemden daha uzun süre kullanmazsa, bu Kullanıcının parası ve saatleri geri dönülemez şekilde kaybolur ve talep üzerine iade edilmeyecektir. İlk dersten sonra öğretmen değiştirmeye hakkınızı 2 (ikiden) fazla defa yapamazsanız. Öğretmeninizi 2 (iki) kereden daha fazla kez değiştirmeye karar verirseniz, yeni öğretmenle bir sonraki dersinizin ödemesi yapılmalıdır. Preply, ödeme sistemlerinin kestiği herhangi bir ücretten sorumlu değildir ve para iadesi yaparken bunları karşılamayacaktır. Yasaların izin verdiği ölçüde, herhangi bir zamanda yapılacak herhangi bir para iadesi tarafımızın takdirindedir."
    //     ]
    // },
    // {
    //     id: 18,
    //     content: [
    //         "İptal Politikası",
    //         "Bazen planlarınız değişebilir ve bir dersi iptal etmek/yeniden planlamak zorunda kalabilirsiniz. Böyle bir durumda, öğretmene ders saatinden en az 4 saat önce haber vermenizi rica ederiz. Aksi takdirde, öğretmen sizinle görüşüp sizden ücret kesmemeye karar vermedikçe, planlanmış dersin tam ücretinin ödemesinden sorumlu olursunuz. Her öğretmenin ders saatine 4 saatten az kala iptal eden dersler için tam ücret kesme hakkını saklı tutarız; bu ücretin tarafınıza iade edilme veya başka öğretmenlere transfer edilmesi söz konusu değildir."
    //     ]
    // },
    // {
    //     id: 19,
    //     content: [
    //         "Preply hesabındaki derslerinizi kolayca iptal etmek/yeniden planlamak için, ders saatinden 4 saat öncesine kadar 'Derslerim' menüsündeki seçili ders kaydında bulunan ilgili butona tıklayabilirsiniz. Dersi neden İptal ettiğinize dair bir sebep vermeniz istenecek ve çoğu durumda bu bilgi öğretmeninize iletilecektir."
    //     ]
    // },
]

export const questions1 = [
    {
        id: 0,
        title: "Leo cum amet, sed ultrices lorem interdum mauris. Quis vitae condimentum bibendum amet.",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
    {
        id: 1,
        title: "Adipiscing vel ullamcorper nunc feugiat amet augue scelerisque. Massa ultrices interdum facilisis.",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
    {
        id: 2,
        title: "Tempor porta id ac magna lectus posuere sed amet dui. Risus lacus fames nullam quam congue.",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
    {
        id: 3,
        title: "Ut viverra egestas sit nibh pretium dui. Viverra vel faucibus neque, suscipit porttitor nunc. Ut.",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
    {
        id: 4,
        title: "Nisl in amet sapien nec. In ut commodo in fermentum viverra. Enim venenatis, eget orci, pretium. ",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
    {
        id: 5,
        title: "At nec ornare congue eget mauris pulvinar. Turpis donec purus aliquet nunc commodo. Ac.",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
    {
        id: 6,
        title: "Adipiscing elit aliquam venenatis, commodo vivamus ornare eget luctus. Platea eget ut quis.",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
    {
        id: 7,
        title: "In sit ac leo amet eget pellentesque feugiat turpis. Aliquam gravida tellus, proin morbi ultricies. ",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
]

export const questions2 = [
    {
        id: 0,
        title: "Non fringilla nunc amet, posuere odio. Vel natoque hac id auctor est quam. Tristique dictumst. ",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
    {
        id: 1,
        title: "Pellentesque velit sed aliquam congue dui venenatis pulvinar. Diam et dolor elementum amet justo. ",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
    {
        id: 2,
        title: "Nunc, ipsum maecenas suspendisse amet adipiscing tellus tellus, diam sit. Et a in iaculis sed. ",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
    {
        id: 3,
        title: "Ut ultrices integer cras dictum nunc nunc amet, lacus. Amet auctor tristique eu in et id sed elit vel. ",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
    {
        id: 4,
        title: "Et ut ut id sed eget. Sem tempor viverra arcu orci. Massa at sed nibh enim sed sapien sem. ",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
    {
        id: 5,
        title: "A et sit mattis odio consectetur feugiat sollicitudin massa malesuada. Diam nulla non viverra sit. ",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
]

export const questions3 = [
    {
        id: 0,
        title: "Sollicitudin convallis est nec posuere egestas. Sed faucibus ut facilisi pretium ut id arcu dui. ",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
    {
        id: 1,
        title: "Nisi, ac cursus molestie faucibus dictum tempor commodo eu. Massa nunc eget ac fermentum id. ",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
    {
        id: 2,
        title: "Sit hac etiam pretium, velit proin cursus. In ac accumsan vitae neque tellus non vitae ullamcorper. ",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
    {
        id: 3,
        title: "Ridiculus eget vitae scelerisque purus suscipit sed id nulla. Varius sem dui dui cursus risus.  ",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
]

export const questions4 = [
    {
        id: 0,
        title: "Non fringilla nunc amet, posuere odio. Vel natoque hac id auctor est quam. Tristique dictumst. ",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
    {
        id: 1,
        title: "Pellentesque velit sed aliquam congue dui venenatis pulvinar. Diam et dolor elementum amet justo. ",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
    {
        id: 2,
        title: "Nunc, ipsum maecenas suspendisse amet adipiscing tellus tellus, diam sit. Et a in iaculis sed. ",
        desc: "Velit, consectetur ut sodales tristique nunc mattis luctus. Sagittis, ut leo amet in. Porttitor et, eget a, nec scelerisque in. Diam a euismod mauris curabitur semper varius. Nulla faucibus morbi imperdiet quis at cursus egestas pellentesque. Nibh risus euismod ornare volutpat. A sagittis non molestie imperdiet pharetra egestas suspendisse iaculis."
    },
]


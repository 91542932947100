import React from "react";
import OwnerMessage from "./OwnerMessage";
import OpponentMessage from "./OpponentMessage";

interface MessageContentProps {
    content: string,
    time: string
}

interface MessageProps {
    type: "owner" | "opponent",
    message?: string,
    time : number,
}

const Message:React.FC<MessageProps> = ({ type, message, time }) =>{
    return (
        <>
            {
                (type === "owner") ?
                (
                    <OwnerMessage
                        message={message || ""}  time = {time}
                    />
                ) : (
                    <OpponentMessage
                        message={message || ""} time = {time}
                    />
                )
            }
        </>
    )
}

export default Message;

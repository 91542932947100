import { css } from "styled-components";
import theme from "../../theme";

const StyledButton = css`
    background-color: ${theme.buttons.colors.primary};
    border-color: ${theme.buttons.colors.primary};
    &:hover {
        background-color: ${theme.buttons.colors.primaryHover};
        border-color: ${theme.buttons.colors.primaryHover};
    }
    &:active,
    &:focus {
        background-color: ${theme.buttons.colors.primary};
        border-color: ${theme.buttons.colors.primary};
    }
    background-color: "#000" !important;
`;

export default StyledButton;

import React, { useEffect, useLayoutEffect, useState } from "react";
import { Image } from "react-bootstrap";
import "../../layout/EmailVerification/Form.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Container, Form } from "react-bootstrap";
import "../../mobile/EmailVerfication/Form.css";
import { MeetingFinishedIcon } from "../../assets/SVG/meetingFinished";
import { confirmationImg, NoReservationFoundIcon } from "../../assets/SVG/SVG";
import Button from "../../components/buttons";

import dots from "../../assets/instructor-reservations/dots.png";
import { IDENTITY_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";

const PaymentConfirmationResult: React.FC<any> = ({ history, code, lang }) => {

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const [response, setResponse] = useState<any>(null);
    const [isSuccess, setIsSuccess] = useState(true);

    useEffect(() =>{
        setTimeout(() => history.replace("/student/dashboard"), 4000);
      },[])
      

    return (
        <React.Fragment>
            <div className="email-verification-form">
                {
                    <Card className="shadow email-verification-form-container">
                        <Card.Body>
                            <Container>
                                <div className="email-verification-form-group">
                                    {
                                        isSuccess ? (
                                            <div className="email-verification-form-group">
                                                <h5 className="email-verification-form-title">{lang === "0" ? 'Tebrikler!' : 'Congratulations!'}</h5>
                                                <h6 className="email-verification-form-subtitle text-muted mt-4">{lang === "0" ?'Ödemeniz gerçekleşti. Şimdi konuşmaya başlayabilirsiniz':'Payment is done!'}</h6>
                                                <div> {confirmationImg} </div>
                                            </div>
                                        ) : (
                                            <div className="email-verification-form-group">

                                                <h5 className="email-verification-form-title">{lang === "1" ?'Aktivasyon sırasında hata oluştu' : 'Error occured while activation'}</h5>
                                                <h6 className="email-verification-form-subtitle text-muted mt-4">{lang === "1" ? 'Aktivasyonunuz kodu hatalı':'Activation code is invalid or expired.'}</h6>
                                                <div> {NoReservationFoundIcon} </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </Container>
                        </Card.Body>
                    </Card>
                }
            </div>

        </React.Fragment>

    )
}

export default PaymentConfirmationResult;

import React from "react";
import "../../../layout/HomeV2/New_TuttorMessageBanner.css";
import { Row, Col, Image, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { cards } from "../../../Global/WhySpeakingline";
import "../../../tablet/Home/WhySpeakingLine.css";
import "../../../mobile/Home/WhySpeakingLine.css";
import Container from "../../../components/Container";
import { useTranslation } from "react-i18next";
import img from "../../../assets/Home/tutor-message-banner.png";
import { useHistory } from "react-router";

const TuttorMessageBanner = () => {
    const { t } = useTranslation();
    var history = useHistory();
    return (
        <div className="new-tutor-message-banner">
            <Container className="col-md-7">
                <div className="new-tutor-message-banner-container">
                    <Row className="new-tutor-message-banner-container-row">
                        <Col>   
                            <Image className="col-md-12" src={img} />
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <p className="new-tutor-message-banner-title" >{t("tutor.tutor_message.title")}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p  style={{ whiteSpace: "pre-line" }} className="new-tutor-message-banner-description" >
                                {t("tutor.tutor_message.content").split("<br/>").join("\n")}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button onClick={()=>{history.push("/tutor/register")}} className="new-tutor-message-banner-button" > <span className="new-tutor-message-banner-button-text">START RIGHT NOW</span> </Button>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default TuttorMessageBanner;
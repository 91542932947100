import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import chatIcon from "../../../assets/chatIcon.svg";
import "../../../layout/HistoryModal/HistoryModal.css";
import { CONVERSATION_URL, ROLES } from "../../../Global/Constants/constants";
import { ReturnProps } from "../../../@types/functions";
import axios from "axios";
import "../../../layout/HistoryModal/HistoryModal.css";
import ChatRoom from './ChatRoom';
import { trackPromise } from 'react-promise-tracker';
import AlertInfo from '../../alerts/AlertInfo';
import {useTranslation} from "react-i18next";



interface HistoryRecordInterface {
    videoUrl: string;
    meetingId: string;
    userRole: number;
    attendeeName: string
    setArchiveLink: any;
}

const HistoryRecord: React.FC<HistoryRecordInterface> = ({
    videoUrl,
    meetingId,
    userRole,
    attendeeName,
    setArchiveLink
}) => {
    const { t } = useTranslation();
    const [showChatRoom, setShowChatRoom] = useState(false);
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const [success, setSuccess] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);

    const DeleteMeetingRecord = async () => {
        const res = await trackPromise(axios.delete<any, ReturnProps>(CONVERSATION_URL + "/api/v1/history/" + meetingId));
        // Handling Error
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            return null;
        }
        else {
            setSuccess(t("general.successful_message"));
            setShowSuccess(true);
            setArchiveLink(null);
        }
    }
    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
            <AlertInfo show={showSuccess} setShow={setShowSuccess} isAutoHide={true} message={success} type={1} />

            <div className="c-r-bt" hidden={userRole !== ROLES.STUDENT}>
                <button className="btn btn-danger" onClick={DeleteMeetingRecord}>Görüşme Kaydını Sil</button>
            </div>
            <div className="videoContainer">
                {

                    <div className="chat-Icons">
                        {showChatRoom ? <div className="chat-room-container">
                            <ChatRoom
                                handleShowChatRoom={() => setShowChatRoom(true)}
                                hideChatRoom={() => setShowChatRoom(false)}
                                showChatRoom={showChatRoom}
                                attendeeName={attendeeName}
                                meetingId={meetingId}
                            // toggleCamera={() =>setCameraState(!cameraState)}
                            // toggleMicro={() => setMicroState(!microState)}    
                            />
                        </div> : <button>
                            <img src={chatIcon} alt="" onClick={() => {
                                setShowChatRoom(true);
                            }} />
                        </button>}

                    </div>
                }
                <div >
                    <ReactPlayer width="560" height="315" controls={true} url={videoUrl} />
                </div>
                {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/dWDIoW7f6js"></iframe> */}
            </div>

        </React.Fragment>
    )
}

export default HistoryRecord;
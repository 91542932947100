import React from "react";
import { Nav, Navbar, Image } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import theme from "../../theme";
import { MainNavBarProps } from "../../@types/NavBar";
import Container from "../Container";

import logo from "../../assets/navbar/logo.png";
import burgerMenu from "../../assets/navbar/burger-menu.png";
import "../../layout/navbar/NavBarBurgerMenu.css";
import "../../tablet/navbars/MainNavBar.css";
import "../../mobile/navbars/MinimalNavBar.css";

const NavLink = styled(Nav.Link)`
    color: ${theme.colors.primary} !important;
    font-weight: ${theme.text.weight.meduim};
    font-size: ${theme.text.sizes.xxs};
    text-align: right;
    padding-top: 5px;
    @media only screen and (max-width: 960px) {
        text-align: center;
        margin-top: 25px;
    }
`;

const NavLinkContainer = styled(Nav)`
    margin-left: auto;
`;

const NavBarBrand = styled(Navbar.Brand)`
    transform: translateY(-5px);
    cursor: pointer;
`;

const MinimalNavBar: React.FC<MainNavBarProps> = ({ links, history }) => {
    return (
        <Navbar collapseOnSelect expand="lg" className="pt-4">
            <Container>
                <NavBarBrand onClick={() => history.push("/")}>
                    <div>
                        <Image src={logo} className="brand-logo" height={30} />
                    </div>
                </NavBarBrand>
                {links && links.length > 0 && <React.Fragment><Navbar.Toggle aria-controls="responsive-navbar-nav" className="main-nav-bar-burger-menu">
                    <Image src={burgerMenu} />
                </Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <NavLinkContainer>
                            {
                                links && links.map((link: String) => (
                                    <NavLink>{link}</NavLink>
                                ))
                            }
                        </NavLinkContainer>
                    </Navbar.Collapse></React.Fragment>}

            </Container>
        </Navbar >
    )
}

export default MinimalNavBar;

import React, { useState, useEffect } from "react";
import "../../../layout/StudentInstrucotrsPage/TopBar.css";
import { useDispatch } from "react-redux";
import "../../../tablet/StudentInstructors/TopBar.css";
import { connect } from "react-redux";
import handleStudentDashboardData from "../../../Global/StudentDashboard/StudentDashboard";
import Card from "../../../components/cards";
import { CONVERSATION_URL, STUDENT_DASHBOARD_URL } from "../../../Global/Constants/constants";
import { ReturnProps } from "../../../@types/functions";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import AlertInfo from "../../../components/alerts/AlertInfo";
import CallingModal from "../../../components/modals/meeting/CallingModal";

const TopBar = (prop: any) => {

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const [isShowCallingModal, setIsShowCallingModal] = useState(false);
    const [meetingRequestId, setMeetingRequestId] = useState("")

    const dispatch = useDispatch();
    const LoadStudentDashboardData = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(STUDENT_DASHBOARD_URL + "/api/v1/student/dashboard"));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true);
            return null
        }
        dispatch({ type: "STUDENT_DASHBOARD_CHANGED", payload: res.data });
    }


    const randomCallClicked = async () => {
        const res = await trackPromise(axios.post<any, ReturnProps>(CONVERSATION_URL + "/api/v1/meetings/meeting-request-randomly"));
        if (!res.success || !res.headers.location) {
            setError(res.error as string);
            setShowError(true)
            //  alert(res.error)
            return null;
        }
        const header: String = res.headers.location
        const meetReqId = header.split("/")[1];

        // var meetReqId = "ae76021c-33bd-4fc4-b371-8cac9bd9353b";
        setMeetingRequestId(meetReqId);
        setIsShowCallingModal(true)
        // //console.log(res.data);
    }

    useEffect(() => {
        LoadStudentDashboardData()
    }, []);

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
            <div className="student-instructors-page-top-bar-container">

                <Card
                    type="student-dashboard"
                    cardName="main-card"
                    userName={prop.auth.user.name}
                    onPrimaryButtonClicked = {randomCallClicked}
                />

                <Card
                    type="student-dashboard"
                    cardName="subscription-summary-card"
                    subscriptionDetails={prop.dashboard.dashboard.subscriptions}
                />

                <Card
                    type="student-dashboard"
                    cardName="next-session-card"
                    nextSession={prop.dashboard.dashboard.scheduledMeeting}
                />

            </div>

            {
                isShowCallingModal &&
                <CallingModal
                    show={isShowCallingModal}
                    hide={() => setIsShowCallingModal(false)}
                    oppenentId=""
                    opponentName=""
                    meetingRequestId={meetingRequestId}
                    opponentPicture="" />
            }
        </React.Fragment>
    )
}

interface StateProps {

}

function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer,
        dashboard: state.StudentDashboardReducer
    }
}

export default connect(mapStateToProps)(TopBar);

const initialState = false;

export default function(state=initialState, action:any) {
    switch(action.type) {
        case "SHOW_CHATROOM":
            return state = true
        case "CLOSE_CHATROOM":
            return state = false
        default:
            return state
    }
}

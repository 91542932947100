import React from 'react';
import { Card } from 'react-bootstrap';
import { arrowTopCross, star } from '../../assets/SVG/SVG';
import { useTranslation } from "react-i18next";


interface RankProps {
    response: any
}

const Rank: React.FC<RankProps> = ({
    response,
}) => {

    const { t } = useTranslation();

    let rank = <div></div>
    if (!response?.user?.rate) {
        rank = (<div className="rank-card">
            <div>{star}</div>
            <h3></h3>
            <h6>No points yet</h6>
        </div>);
    }
    else {
        rank = (<div className="rank-card">
            <div>{star}</div>
            <h3>{response?.user?.rate}/5</h3>
            <h6>{t('dashboard.my_points')}</h6>
        </div>);
    }


    return (
        <Card className="small-card">
            <Card.Body>
                <h5>{t('dashboard.rank')}</h5>
                <div className="d-flex justify-content-between rank-main-wrapper">
                    <div className="rank-card-wrapper">
                        <div className="rank-card">
                            <div>{arrowTopCross}</div>
                            <h3>12</h3>
                            <h6>TOP 100 </h6>
                        </div>
                    </div>

                    <div className="rank-card-wrapper">
                        {/* <div className="rank-card">
                            <div>{star}</div>
                            <h3>{response?.user?.rate}4.3/5</h3>
                            <h6>Puanım</h6>
                        </div> */}
                        {rank}
                    </div>

                </div>
            </Card.Body>
        </Card>
    )
}

export default Rank;
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Alert } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import '../../layout/alerts/MessageReceivedInfo.css'

interface MessageReceivedInfo {
    show: boolean,
    setShow: any
    isAutoHide: boolean;
    title: string;
    message: string;
    onClicked?: () => {}
}
const MessageReceivedInfo: React.FC<MessageReceivedInfo> = ({
    show,
    setShow,
    isAutoHide = false,
    title,
    message,
    onClicked,
}) => {

    const onDismiss = () => {
        setShow(false);
    };

    useEffect(() => {
        if (show && isAutoHide) {
            setTimeout(() => setShow(false), 3000);
        }
    }, [show])

    var color = "success";

    // if (type === 1) color = "success";
    // else if (type === 0) color = "danger"
    // else if (type === 2) color = "info"

    return (
        <div className="fixed-top" style={{ zIndex: 9999 }}>
            <Alert style={{ textAlign: "center", marginLeft: 'auto', width: '400px', height: 'fit-content', marginRight: 30, marginTop: 30 }} color={color} isOpen={show} onClick={onClicked} toggle={onDismiss}>
                <div className="toastcontainer">
                    <div className="toasttitle">
                        New Message
                    </div>
                    <div className="toastdescription">
                        <span className="toastname">{title} : </span> <span className="toastmessage">{message} </span>
                    </div>
                </div>
            </Alert>
        </div>
    )
}

export default MessageReceivedInfo;
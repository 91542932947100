import React from "react";
import "../../layout/StudentVideoChat/MobileOptions.css";
import "../../mobile/StudentVideoChat/MobileOptions.css";
import { Image } from "react-bootstrap";
import { connect } from "react-redux";

import mobileMessagesIcon from "../../assets/student-video-chat/mobile-messages-icon.png";
import mobileToggleCameraIcon from "../../assets/student-video-chat/mobile-camera-toggle.png";
import mobileFlipCameraIcon from "../../assets/student-video-chat/mobile-flip-camera-icon.png";
import mobileStopCallIcon from "../../assets/student-video-chat/mobile-stop-call-icon.png";
import mobileToggleMicIcon from "../../assets/student-video-chat/mobile-toggle-mic-icon.png";
import mobileOptionsIcon from "../../assets/student-video-chat/mobile-options-icon.png";

interface MobileOptionsProps {
    optionsFunc: () => void,
    toggleCameraFunc: () => void,
    toggleMicFunc: () => void,
    endCallFunc: () => void,
    handleShowChatRoom?: () => void
}

const MobileOptions:React.FC<MobileOptionsProps> = ({ 
    optionsFunc, 
    toggleCameraFunc, 
    toggleMicFunc, 
    endCallFunc,
    handleShowChatRoom
}) =>{
    return(
        <div className="mobile-options">
            <div className="mobile-option-icon">
                <Image 
                    src={mobileMessagesIcon} 
                    onClick={handleShowChatRoom}
                />
            </div>
            <div className="mobile-option-icon">
                <Image 
                    src={mobileToggleCameraIcon} 
                    onClick={toggleCameraFunc}
                />
            </div>
            <div className="mobile-option-icon">
                <Image 
                    src={mobileFlipCameraIcon} 
                />
            </div>
            <div className="mobile-option-icon phone">
                <Image 
                    src={mobileStopCallIcon} 
                    onClick={endCallFunc}
                />
            </div>
            <div className="mobile-option-icon">
                <Image 
                    src={mobileToggleMicIcon} 
                    onClick={toggleMicFunc}
                />
            </div>
            <div className="mobile-option-icon options">
                <Image 
                    src={mobileOptionsIcon} 
                    onClick={optionsFunc}
                />
            </div>
        </div>
    )
}

function mapStateToProps(state:any) {
    return {}
}

function mapDispatchToProps(dispatch:any) {
    return {
        handleShowChatRoom: () => dispatch({ type: "SHOW_CHATROOM" })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileOptions);

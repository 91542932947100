import React from "react";
import "../../../layout/HomeV2/New_MessageBanner.css";
import { Row, Col, Image } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { cards } from "../../../Global/WhySpeakingline";
import "../../../tablet/Home/WhySpeakingLine.css";
import "../../../mobile/Home/WhySpeakingLine.css";
import Container from "../../../components/Container";
import { useTranslation } from "react-i18next";
import img from "../../../assets/Home/message-banner-item.jpeg";

const MessageBanner = () => {
    const { t } = useTranslation();
    return (
        <div className="pb-4 new-message-banner">
            <Container>
                <div className="new-message-banner-container">
                    <Row className="new-message-banner-container-row">
                        <Col className="new-message-banner-content col-md-3 col-12 align-self-center">
                            <Image style={{ maxWidth: '100%', maxHeight: '100%' }} src={img} />
                        </Col>
                        <Col className="new-message-banner-image-container col-md-9 col-12">
                            <p className="new-message-banner-container-item-container-item-description">{t("kids.message_banner.title")}</p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default MessageBanner;


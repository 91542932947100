import React from "react";
import TutorCard from "./TutorCard/TutorCard";
import TutorReservation from "./TutorCard/TutorReservation";
import TutorHistory from "./TutorCard/TutorHistory";
import ReservedStudent from "./ReservationCard";
import StudentDashboardCard from "./StudentDashboardCards";
import HistoryCard from "./HistoryCard";
import profile from "../../assets/blank_profile.png";


interface RootComponentProps {
    type: "tutor" | "tutor-reservation" | "history" | "reserved-student" | "student-dashboard";
    userId? : String;
    item? : String;
    name?: String;
    surname?: String;
    status?: number;
    isFav?: boolean;
    countryCode?: String;
    languages?: [any];
    expertise?: [any];
    description?: String;
    rating?: Number;
    availableAfter?: Number,
    thumb?: String;
    onLike?: () => any;
    handleLiked?: () => void,
    cardName?: "main-card" | "subscription-summary-card" | "next-session-card" | "",
    subscriptionDetails?: any;
    userName?: String,
    nextSession?: any;
    startTime?: Number,
    endTime? : Number
    minute? : Number
    itemId? : string;
    onPrimaryButtonClicked? : () => void
    onDetails?: () => any;
    onRefresh?: ()=> void;
    makeReservationClicked? : () =>void;
    reservationIsDeletable? : Boolean;
}

const RootComponent: React.FC<RootComponentProps> = ({
    type,
    userId="",
    name = "",
    surname = "",
    status=0,
    isFav = false,
    countryCode = "",
    languages ,
    expertise ,
    description="",
    rating = 0,
    availableAfter = 0,
    thumb,
    handleLiked,
    cardName = "",
    userName,
    subscriptionDetails,
    nextSession,
    startTime = 0,
    endTime = 0,
    minute = 0,
    itemId = "",
    onPrimaryButtonClicked = () => null,
    onLike = () => null,
    onDetails = () => null,
    onRefresh = () => null,
    makeReservationClicked = () => null,
    reservationIsDeletable = false,
}) => {
    switch (type) {
        case "tutor":
            return <TutorCard
                userId = {userId}
                rating={rating}
                languages={languages}
                name={name}
                surname={surname}
                status={status}
                isFav = {isFav}
                expertise={expertise}
                description={description}
                availableAfter={availableAfter}
                countryCode = {countryCode}
                thumb={thumb || profile}
                onLike={onLike}
                makeReservationClicked = {makeReservationClicked}
            />
        case "tutor-reservation":
            return <TutorReservation />
        // case "tutor-history":
        //     return <TutorHistory />
        case "history":
                return <HistoryCard 
                userId = {userId}
                name = {name} 
                surname = {surname} 
                endTime = {endTime} 
                startTime = {startTime} 
                thumb = {thumb || profile}  
                countryCode = {countryCode} 
                meetingId={itemId} 
                minute = {minute} />
        case "reserved-student":
            return <ReservedStudent
            userId = {userId} 
            name={name}
            surname={surname}
            thumb={thumb || profile}
            startTime = {startTime}
            countryCode = {countryCode}
            endTime = {endTime}
            minute = {minute}
            itemId = {itemId}
            onDetails = {onDetails}
            isDeletable = {reservationIsDeletable}
            onRefresh = {onRefresh}
            reservationId = {itemId}
            />
        case "student-dashboard":
            return <StudentDashboardCard
            onPrimaryButtonClicked = {onPrimaryButtonClicked}
                cardName={cardName}
                subscriptionDetails={subscriptionDetails}
                userName={userName}
                nextSession={nextSession}
            />
    }
}

export default RootComponent;


import React from "react";
import Page from "../../components/pages";
import { RouteComponentProps } from "react-router-dom";
import Settings from "./Settings";
import LanguageAndTimeZone from "./LanguageAndTimeZone";
import SettingsFooter from "./SettingsFooter";

const InstructorNotificationsSettings:React.FC<RouteComponentProps> = ({ history }) =>{
    return (
        <Page
            history={history}
            type="dashboard"
            headerTitle="Bildirim Ayarları"
            studentDashboard={false}
        >
            <Settings />
            <LanguageAndTimeZone />
            <SettingsFooter />
        </Page>
    )
}

export default InstructorNotificationsSettings;

import styled, { css } from "styled-components";
import theme from "../../theme";
import { Image } from "react-bootstrap";
import Button from "../buttons";


export const CardContainer = styled.div`
background-color: #fff;
border-radius: 22px;
box-sizing: border-box;
padding: 16px;
display: block;
float: left;
width: 48%;
margin: 0 1% 2% 1%;
    @media only screen and (max-width: 1024px) and (min-width: 991px) {
        height: 300px;
        width: 450px;
    }
    @media only screen and (max-width: 990px) and (min-width: 721px) {
        width: 100%;
        height: 300px;
    }
    @media only screen and (max-width: 720px) {
        height: 432px;
        width: 330px;
        float: unset;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const TutorCardContainer = styled.div`
background-color: #fff;
border-radius: 22px;
box-sizing: border-box;
padding: 16px;
display: flex;
flex-direction : column;
float: left;
flex : 1;
width: 48%;
height:225px;
margin: 0 1% 2% 1%;
    @media only screen and (max-width: 1024px) and (min-width: 991px) {
        height: 300px;
        width: 450px;
    }
    @media only screen and (max-width: 990px) and (min-width: 721px) {
        width: 100%;
        height: 300px;
    }
    @media only screen and (max-width: 720px) {
        height: 432px;
        width: 330px;
        float: unset;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const ImageContainer = styled("div")`
    height: 104px;
    width: 104px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    object-fit:cover;
    padding-bottom: 8px;
    background-color: ${
        ({ className }) =>{
            switch(className) {
                case "active":
                    return "#00D21D"
                case "busy":
                    return "#EE3F46"
                case "inactive":
                    return "#AAAAAA"
                default:
                    return "#AAAAAA"
            }
        }
    }
    };
    border-radius: 24px;
    @media only screen and (max-width: 720px) {
        width: 73px;
        height: 79px;
    }
`;

export const CardTop = styled.div`
    display: flex;
    flex:1;
    align-items: flex-start;
    justify-content: space-around;
    @media only screen and (max-width: 720px) {
        display: flex;
        justify-content: space-between;
        width: 298px;
    }
`;

export const CardImage = styled(Image)`
    width: 100%;
    height: 100%;
    border-radius: 24px;
`;

export const CardHeader = styled.div`
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 1024px) and (min-width: 721px) {
        width: 300px;
        margin-left: 16px;
        justify-content: space-between;
    }
    @media only screen and (max-width: 990px) and (min-width: 901px) {
        width: 55vw;
    }
    @media only screen and (max-width: 830px) and (min-width: 768px) {
        width: 48vw;
    }
    @media only screen and (max-width: 900px) and (min-width: 831px) {
        width: 60vw;
    }
    @media only screen and (max-width: 720px) {
        width: 200px;
        display: block;
        margin-left: 18px;
    }
`;

export const CardInfo = styled.div`
    display: flex;
    @media only screen and (max-width: 720px) {
        transform: translateX(-8px);
    }
`;

export const CardName = styled.div`
    text-align: left;
    color: ${theme.colors.dark};
    font-size: 19px;
    font-weight: 700;
    font-family: ${theme.text.fonts.nunito};
    @media only screen and (max-width: 1024px) and (min-width: 721px) {
        transform: translateY(2px);
    }
`;

export const Nationality = styled(Image)`
    margin-left: 14px;
    transform: translateY(9px);
`;

export const Rating = styled.div`
    margin: 0; 
    text-align: left;
    color: #F2C94C;
    font-size: 14px;
    font-weight: 700;
     ${theme.text.fonts.nunito};
    margin-left: 5px;
    display: flex;
    padding: 5px 10px;
    background-color: #FDF7E4;
    border-radius: 5px;
    align-items: center;
    @media only screen and (max-width: 1024px) and (min-width: 721px) {
        margin-left: 0;
        width: 95px;
    }
    @media only screen and (max-width: 720px) {
        width: 95px;
        margin-left: 0;
        margin-bottom: 10px;
        transform: translateX(-10px);
    }
`;

export const RatingValue = styled.p`
    text-align: center;
    color: #45494D;
    font-size: 15px;
    line-height: 14px;
    font-weight: bold;
    font-family: ${theme.text.fonts.nunito};
    margin: 0;
    padding: 0;
    margin-left: 8px;
`;

export const RatingOver = styled.span`
    text-align: left;
    color: #45494D;
    font-family: ${theme.text.fonts.nunito};
    margin: 0;
    padding: 0;
    font-Size: 10px;
    line-height: 12px;
    font-weight: 400;
    transform: translateY(-3px);
    margin-left: 1px;
    letter-spacing: 1px;
`;

export const LanguagesContainer = styled.div`
    display: flex;
    margin-top: -10px;
    @media only screen and (max-width: 1024px) and (min-width: 721px) {
        width: 260px;
        margin-left: 18px;
        margin-top: 0px;
    }
    @media only screen and (max-width: 720px) {
        display: block;
        height: 75px;
        width: 210px;
        transform: translateX(10px);
    }
`;

export const Language = styled.div`
    width: fit-content;
    padding: 2px 6px;
    background-color: #FFEEEB;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: ${theme.colors.primary};
    margin-right: 8px;
    @media only screen and (max-width: 720px) {
        float: left;
        margin-top: 8px;
    }
`;

export const ExpertiseContainer = styled.div`
    display: flex;
    margin-top: 8px;
    @media only screen and (max-width: 1024px) and (min-width: 721px) {
        width: 280px;
        margin-left: 18px;
        display: block;
        height: 75px;
    }
    @media only screen and (max-width: 720px) {
        display: block;
        height: 75px;
        width: 210px;
        margin-top: 0;
        transform: translateX(10px);
    }
`;

export const Expertise = styled.div`
    width: fit-content;
    padding: 2px 6px;
    background-color: #E5EEFF;
    color: ${theme.colors.secondary};
    font-size: 14px;
    font-weight: 400;
    font-family: ${theme.text.fonts.nunito};
    text-align: center;
    margin-right: 8px;
    border-radius: 3px;
    @media only screen and (max-width: 1024px) and (min-width: 721px) {
        float: left;
        margin-top: 8px;
    }
    @media only screen and (max-width: 720px) {
        float: left;
        margin-top: 8px;
    }
`;

export const Description = styled.p`
    text-align: left;
    color: #929598;
    font-size: 14px;
    line-height: 19px;
    max-height: 35px;
    overflow:hidden;
    font-weight: 400;
    font-family: ${theme.text.fonts.nunito};
    margin: 0;
    padding: 0;
    margin-top: 16px;
    max-width: 370px;
    @media only screen and (max-width: 1024px) and (min-width: 721px) {
        width: 290px;
        height: 60px;
        margin-top: 3px;
        margin-left: 18px;
    }
    @media only screen and (max-width: 720px) {
        height: 95px;
        width: 200px;
        transform: translate(10px, -7px);
    }
`;

export const BusyIn = styled.p`
    text-align: center;
    color: #929598;
    font-family: ${theme.text.fonts.nunito};
    font-size: 13px;
    line-height: 17px;
    font-family: 400;
    padding: 0;
    margin: 8px auto;
    max-width: 90px;
    display: block;
`;

export const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    justify-self: flex-end;
    align-self: flex-end;
`;

export const CardBtn = styled(Button)`
    padding: 5px 20px !important;
    margin-right : 10px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    ${({ filter, first, second, third }) => {
        if (filter) return css`
                background-color: #F2F2F2;
                color: #45494D;
                border-color: #F2F2F2;
                &:hover {
                    color: #45494D;
                }
            `;
        if (first) return css`
                width: 120px;
            `;
        if (second) return css`
                width: 200px;
            `;
        if (third) return css`
                width: 140px;
            `;
    }
    }
    @media only screen and (max-width: 1024px) and (min-width: 721px) {
        ${({ first, second, third }) => {
        if (first) return css`
                    height: 32px;
                    width: 100px;
                `;
        if (second) return css`
                    height: 32px;
                    width: 165px;
                    margin-left: 10px;
                    font-size: 14px;
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                `;
        if (third) return css`
                    height: 32px;
                    width: 95px;
                `;
    }
    }
    }
    @media only screen and (max-width: 990px) and (min-width: 721px) {
        ${({ first, second, third }) => {
        if (first) return css`
                    width: 25%;
                `;
        if (second) return css`
                    width: 50%;
                `;
        if (third) return css`
                    width: 37%;
                `;
    }
    }
    }
    @media only screen and (max-width: 720px) {
        ${({ first, second, third }) => {
        if (first) return css`
                    height: 72px;
                    width: 73px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                `;
        if (second) return css`
                    margin-bottom: 8px;
                    width: 210px;
                `;
        if (third) return css`
                    margin-right: 8px;
                    width: 168px;
                `;
    }
    }
    }
`;

export const OptionalBtns = styled.div`
    display: flex;
    width:100%;
    justify-content: space-between;
    @media only screen and (max-width: 990px) and (min-width: 721px) {
        width: 75%;
    }
    @media only screen and (max-width: 720px) {
        height: 72px;
        display: block;
        margin-left: 16px;
    }
`;

import React from "react";
import DescriptionInput from "../../components/inputs/DescriptionInput";
import "../../layout/InstructorProfileSettings/AboutMe.css";


interface AboutMeProps {
    aboutMe : string;
    setAboutMe : any;
}

const AboutMe : React.FC<AboutMeProps> = ({
    aboutMe,
    setAboutMe
}) =>{
    return (
        <div className="instructor-profile-about-me-container">
            <h4 className="instructor-profile-about-me-title">About me</h4>
            <div className="instructor-profile-about-me-desc-container">
            <DescriptionInput value={aboutMe} onChange={(e) => setAboutMe(e.target.value)}/>
            </div>
            <hr className="instructor-section-sep" />
        </div>
    )
}

export default AboutMe;

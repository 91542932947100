import React from "react";
import Page from "../../../components/pages";
import "../../../layout/Support/Students/Support.css";
import { Link } from "react-router-dom";
import { links, paragraphs } from "../../../Global/Support/Students/Support";

const Support:React.FC<any> = ({ history }) =>{
    return (
        <Page type="support" bannerType="student" history={history}>
            <h6 className="support-instructor mt-2">Öğrenciler İçin Yardım</h6>
            <div className="mt-4">
                {
                    links.map(link =>(
                        <div className="mb-1" key={link.id}>
                            <Link to={link.to} className="instructors-support-link">
                                {link.label}
                            </Link>
                        </div>
                    ))
                }
                
            </div>
            {/* <div className="instructors-support-text mt-4">
            {
                paragraphs.map(para =>(
                    <p className="mb-3" key={para.id}>
                    {
                        para.content.map((p,index) =>(
                        <>
                            {p}
                            { (para.content.length > 0 &&  index < (para.content.length - 1)) && <br />}
                        </>
                        ))
                    }
                    </p>
                ))
            }
            </div> */}
        </Page>
    )
}

export default Support;


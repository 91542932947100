import React from "react";
import "../../layout/InstructorReservations/ReservationRecords.css";
import { Image } from "react-bootstrap";

import dots from "../../assets/instructor-reservations/dots.png";

const ReservationRecords = () =>{
    return (
        <div className="instructor-reservations-records-container">
            <Image src={dots} />
        </div>
    )
}

export default ReservationRecords;

import React, { useState, useEffect } from "react";
import "../../layout/Home/Application.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Image } from "react-bootstrap";
import "../../mobile/Home/Application.css";
import "../../tablet/Home/Application.css";
import Container from "../../components/Container";

import mobile from "../../assets/mobile-apps.png";
import googlePlay from "../../assets/google-play.png";
import appStore from "../../assets/appstore.png";

import googlePlayMobile from "../../assets/Home/google-play-mobile.png";
import appStoreMobile from "../../assets/Home/app-store-mobile.png";
import { useTranslation } from "react-i18next";
import getMobileOperatingSystem from "../../Global/Utilities/utility";

const Application = () => {
    const { t } = useTranslation();

    const [googlePlayIcon, setGooglePlayIcon] = useState(googlePlay);
    const [appStoreIcon, setAppStoreIcon] = useState(appStore);
    const handleResponsiveness = () => {
        const width = window.screen.width;
        if (width < 960) {
            setGooglePlayIcon(googlePlayMobile)
            setAppStoreIcon(appStoreMobile)
        }
    }
    useEffect(() => {
        handleResponsiveness()
    }, [])

    const handleApplicationDownloadClicked = (title: String) => {
        var mobile = getMobileOperatingSystem();
        if (mobile === "Android") {
            window.location.href = "https://play.google.com/store/apps/details?id=com.SpeakingLine.android";
        }
        else if (mobile === "IOS") {
            window.location.href = "itms-apps://apps.apple.com/tr/app/speakingline/id1484624249";
        }
        else {
            if (title === "android") {
                window.location.href = "https://play.google.com/store/apps/details?id=com.SpeakingLine.android";
            }
            else if (title === "ios") {
                window.location.href = "itms-apps://apps.apple.com/tr/app/speakingline/id1484624249";
            }
        }
    }
    return (
        <div className="application" style ={{"marginTop" : 60, backgroundColor:"transparent !important"}}>
            <Container>
                <div >
                    <div className="application-img-container">
                        <Image src={mobile} />
                    </div>
                    <div className="text-left application-container-right">
                        <h4 className="application-title">{t("home.application.title")}</h4>
                        <p className="application-description">
                            {t("home.application.content")}
                        </p>
                        <div className="download-buttons">
                            <div>
                                <Image src={googlePlayIcon} onClick={() => handleApplicationDownloadClicked('android')} className="google-play-icon" />
                            </div>
                            <div>
                                <Image src={appStoreIcon}  onClick = {() => handleApplicationDownloadClicked('ios')} className="app-store-icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Application;

import React from "react";
import { Image } from "react-bootstrap";
import { useHistory } from "react-router";

import cardTwoImg from "../../../assets/student-instructors-page/card-two-img.png";
import {useTranslation} from "react-i18next";

const SubscriptionSummaryUnavailable = () =>{
    var history = useHistory();
    const { t } = useTranslation();
    return (
        <div className="student-instructors-page-card-container">
            <Image 
                src={cardTwoImg}   
            />
            <div className="student-instructors-page-card-content">
                <h4 className="student-instructors-page-not-available-title">
                    {t("dashboard.no_active_package")}
                </h4>
                <h4 className="student-instrucotrs-page-not-available-desc">
                    <span onClick={() => {
                        history.push("/packages")
                    }}> {t("dashboard.buy_package_now")}</span>
                </h4>
            </div>
        </div>
    )
}

export default SubscriptionSummaryUnavailable;

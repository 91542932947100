
function desktopAnimation() {
    const s = window.pageYOffset;
    if(s >= 150){
        const expertsInstructors = document.querySelector("#experts-instructors-img") as HTMLElement;
        expertsInstructors?.classList.add("experts-instructors");
        expertsInstructors?.classList.remove("experts-instructors-start");
    }
    if(s >= 670){
        const whySpeakingline = document.querySelector("#speaking-line") as HTMLElement;
        whySpeakingline?.classList.add("why-speaking-line-container")
        whySpeakingline?.classList.remove("why-speaking-line-container-start")
    }
    if(s >= 1200){
        const title = document.querySelector(".how-does-it-work-title") as HTMLElement;
        title?.classList.add("how-does-it-work-title-animation")
        title?.classList.remove("how-does-it-work-title-start")

        const leftSide = document.querySelector("#howDoesItWorkLeftSide") as HTMLElement;
        leftSide?.classList.add("how-does-it-work-section1-left-side-animation")
        leftSide?.classList.remove("how-does-it-work-section1-left-side-start")

        const rightSide = document.querySelector("#howDoesItWorkRightSide") as HTMLElement;
        rightSide?.classList.add("how-does-it-work-section1-right-side-animation")
        rightSide?.classList.remove("how-does-it-work-section1-right-side-start")
    }
    if(s >= 1640){

        const leftSide = document.querySelector("#howDoesItWorkLeftSide2") as HTMLElement;
        leftSide?.classList.add("how-does-it-work-section2-left-side-animation")
        leftSide?.classList.remove("how-does-it-work-section2-left-side-start")

        const rightSide = document.querySelector("#howDoesItWorkRightSide2") as HTMLElement;
        rightSide?.classList.add("how-does-it-work-section2-right-side-animation")
        rightSide?.classList.remove("how-does-it-work-section2-right-side-start")
    }
    if(s >= 2300){

        const leftSide = document.querySelector("#howDoesItWorkLeftSide3") as HTMLElement;
        leftSide?.classList.add("how-does-it-work-section1-left-side-animation")
        leftSide?.classList.remove("how-does-it-work-section1-left-side-start")

        const rightSide = document.querySelector("#howDoesItWorkRightSide3") as HTMLElement;
        rightSide?.classList.add("how-does-it-work-section1-right-side-animation")
        rightSide?.classList.remove("how-does-it-work-section1-right-side-start")
    }
    if(s >= 2910){

        const image = document.querySelector("#subscriptionsPackagesImages") as HTMLElement;
        image?.classList.add("subscriptions-packages-image")
        image?.classList.remove("subscriptions-packages-image-start")

        const leftSide = document.querySelector("#subscriptionsPackagesLeftSide") as HTMLElement;
        leftSide?.classList.add("subscriptions-packages-left-side-animation")
        leftSide?.classList.remove("subscriptions-packages-left-side-start")
    }
    if(s >= 3420){

        const reviews = document.querySelector("#studentsReviews") as HTMLElement;
        reviews?.classList.add("students-reviews-container")
        reviews?.classList.remove("students-reviews-container-start")
        
    }
    if(s >= 3910){

        const questions = document.querySelector("#frequentlyAskedQuestions") as HTMLElement;
        questions?.classList.add("frequently-asked-questions-container")
        questions?.classList.remove("frequently-asked-questions-container-start")

    }
    if(s >= 4440){

        const leftSide = document.querySelector("#applicationLeftSide") as HTMLElement;
        leftSide?.classList.add("application-container-left-side")
        leftSide?.classList.remove("application-container-left-side-start")

        const rightSide = document.querySelector("#applicationRightSide") as HTMLElement;
        rightSide?.classList.add("application-container-right-side")
        rightSide?.classList.remove("application-container-right-side-start")

    }
}

function tabletAnimation() {
    const s = window.pageYOffset;
    //console.log(s);
    if(s >= 200){
        const expertsInstructors = document.querySelector("#experts-instructors-img") as HTMLElement;
        expertsInstructors?.classList.add("experts-instructors");
        expertsInstructors?.classList.remove("experts-instructors-start");
    }
    if(s >= 360){
        const whySpeakingline = document.querySelector("#speaking-line") as HTMLElement;
        whySpeakingline?.classList.add("why-speaking-line-container")
        whySpeakingline?.classList.remove("why-speaking-line-container-start")
    }
    if(s >= 1200){
        const title = document.querySelector(".how-does-it-work-title") as HTMLElement;
        title?.classList.add("how-does-it-work-title-animation")
        title?.classList.remove("how-does-it-work-title-start")

        const leftSide = document.querySelector("#howDoesItWorkLeftSide") as HTMLElement;
        leftSide?.classList.add("how-does-it-work-section1-left-side-animation")
        leftSide?.classList.remove("how-does-it-work-section1-left-side-start")

        const rightSide = document.querySelector("#howDoesItWorkRightSide") as HTMLElement;
        rightSide?.classList.add("how-does-it-work-section1-right-side-animation")
        rightSide?.classList.remove("how-does-it-work-section1-right-side-start")
    }
    if(s >= 1640){

        const leftSide = document.querySelector("#howDoesItWorkLeftSide2") as HTMLElement;
        leftSide?.classList.add("how-does-it-work-section2-left-side-animation")
        leftSide?.classList.remove("how-does-it-work-section2-left-side-start")

        const rightSide = document.querySelector("#howDoesItWorkRightSide2") as HTMLElement;
        rightSide?.classList.add("how-does-it-work-section2-right-side-animation")
        rightSide?.classList.remove("how-does-it-work-section2-right-side-start")
    }
    if(s >= 2100){

        const leftSide = document.querySelector("#howDoesItWorkLeftSide3") as HTMLElement;
        leftSide?.classList.add("how-does-it-work-section1-left-side-animation")
        leftSide?.classList.remove("how-does-it-work-section1-left-side-start")

        const rightSide = document.querySelector("#howDoesItWorkRightSide3") as HTMLElement;
        rightSide?.classList.add("how-does-it-work-section1-right-side-animation")
        rightSide?.classList.remove("how-does-it-work-section1-right-side-start")
    }
    if(s >= 2500){

        const image = document.querySelector("#subscriptionsPackagesImages") as HTMLElement;
        image?.classList.add("subscriptions-packages-image")
        image?.classList.remove("subscriptions-packages-image-start")

        const leftSide = document.querySelector("#subscriptionsPackagesLeftSide") as HTMLElement;
        leftSide?.classList.add("subscriptions-packages-left-side-animation")
        leftSide?.classList.remove("subscriptions-packages-left-side-start")
    }
    if(s >= 2800){

        const reviews = document.querySelector("#studentsReviews") as HTMLElement;
        reviews?.classList.add("students-reviews-container")
        reviews?.classList.remove("students-reviews-container-start")
        
    }
    if(s >= 3300){

        const questions = document.querySelector("#frequentlyAskedQuestions") as HTMLElement;
        questions?.classList.add("frequently-asked-questions-container")
        questions?.classList.remove("frequently-asked-questions-container-start")

    }
    if(s >= 3900){

        const leftSide = document.querySelector("#applicationLeftSide") as HTMLElement;
        leftSide?.classList.add("application-container-left-side")
        leftSide?.classList.remove("application-container-left-side-start")

        const rightSide = document.querySelector("#applicationRightSide") as HTMLElement;
        rightSide?.classList.add("application-container-right-side")
        rightSide?.classList.remove("application-container-right-side-start")

    }
}

function mobileAnimation() {
    const s = window.pageYOffset;
    if(true){
        const expertsInstructors = document.querySelector("#experts-instructors-img") as HTMLElement;
        expertsInstructors?.classList.remove("experts-instructors-start");
    }
    if(true){
        const whySpeakingline = document.querySelector("#speaking-line") as HTMLElement;
        whySpeakingline?.classList.remove("why-speaking-line-container-start")
    }
    if(true){
        const title = document.querySelector(".how-does-it-work-title") as HTMLElement;
        title?.classList.remove("how-does-it-work-title-start")

        const leftSide = document.querySelector("#howDoesItWorkLeftSide") as HTMLElement;
        leftSide?.classList.remove("how-does-it-work-section1-left-side-start")

        const rightSide = document.querySelector("#howDoesItWorkRightSide") as HTMLElement;
        rightSide?.classList.remove("how-does-it-work-section1-right-side-start")
    }
    if(true){

        const leftSide = document.querySelector("#howDoesItWorkLeftSide2") as HTMLElement;
        leftSide?.classList.remove("how-does-it-work-section2-left-side-start")

        const rightSide = document.querySelector("#howDoesItWorkRightSide2") as HTMLElement;
        rightSide?.classList.remove("how-does-it-work-section2-right-side-start")
    }
    if(true){

        const leftSide = document.querySelector("#howDoesItWorkLeftSide3") as HTMLElement;
        leftSide?.classList.remove("how-does-it-work-section1-left-side-start")

        const rightSide = document.querySelector("#howDoesItWorkRightSide3") as HTMLElement;
        rightSide?.classList.remove("how-does-it-work-section1-right-side-start")
    }
    if(true){

        const image = document.querySelector("#subscriptionsPackagesImages") as HTMLElement;
        image?.classList.remove("subscriptions-packages-image-start")

        const leftSide = document.querySelector("#subscriptionsPackagesLeftSide") as HTMLElement;
        leftSide?.classList.remove("subscriptions-packages-left-side-start")
    }
    if(true){

        const reviews = document.querySelector("#studentsReviews") as HTMLElement;
        reviews?.classList.remove("students-reviews-container-start")
        
    }
    if(true){

        const questions = document.querySelector("#frequentlyAskedQuestions") as HTMLElement;
        questions?.classList.remove("frequently-asked-questions-container-start")

    }
    if(true){

        const leftSide = document.querySelector("#applicationLeftSide") as HTMLElement;
        leftSide?.classList.remove("application-container-left-side-start")

        const rightSide = document.querySelector("#applicationRightSide") as HTMLElement;
        rightSide?.classList.remove("application-container-right-side-start")

    }
}

export function controlAnimation() {
    const animationFunction = () =>{
        const width = window.screen.width;
        if(width < 720) return mobileAnimation()
        else if(width < 960) return mobileAnimation()
        else return desktopAnimation()
    }
    window.addEventListener("scroll",() =>animationFunction())
}

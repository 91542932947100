import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../../layout/Home/Banner.css";
import { Row, Col, Image } from "react-bootstrap";
import Button from "../../../components/buttons";
import styled from "styled-components";
import theme from "../../../theme";
import "../../../mobile/Home/Banner.css";
import "../../../tablet/Home/Banner.css";
import Container from "../../../components/Container";

import bannerImg from "../../assets/banner-img.png";
import oval from "../../../assets/oval.png";
import { useHistory } from "react-router";
import { homeScreenImage } from "../../../assets/SVG/SVG";
import {useTranslation} from "react-i18next";

const BannerBtn = styled(Button)`
    &:hover {
        background-color: ${theme.colors.secondary};
        border-color: ${theme.colors.secondary};
    }
`;

interface ContentComponentProps {
    title: string;
    description: string;
    buttonText?: string;
    butttonAddress?: string
}

type ContentProps = ContentComponentProps;

const Banner: React.FC<ContentProps> = ({ title, description, buttonText, butttonAddress }) => {
    var history = useHistory();
    const { t } = useTranslation();
    return (
        <div className="banner">
            <Container>
                <Row className="banner-container">
                    <Col lg={7} md={11} className="banner-left-side">
                        <h1 style={{ whiteSpace: "pre-line" }} className="new_home-banner-title">
                            {t(title).split("<br/>").join("\n")}
                        </h1>
                        <p className="new_banner-description">
                            {t(description)}
                        </p>
                        {buttonText && <BannerBtn onClick={() => { history.push(butttonAddress!) }} type="default" primary className="new_banner-btn">
                            {t(buttonText!)}
                        </BannerBtn>}

                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Banner;

import React, { useState } from "react";
import { Form, FormControl, Image } from "react-bootstrap";
import theme from "../../theme";
import styled from "styled-components";
import down from "../../assets/preferences/down.png";

const InputContainer = styled(FormControl)`
    width: 100%;
    display: flex;
    justify-content: space-around;
    border: 1px solid ${theme.colors.line};
    font-size: 16px;
    font-family: ${theme.text.fonts.poppins};
    font-weight: 500;
    color: ${theme.colors.filter};
    padding: 7px;
    width: 100%;
    background-color: #FFFCFC;
    padding-right: 12px;
    padding-left: 12px;
    appearance: none;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    background: url(${down}) no-repeat 97% #FFFCFC;
    &:focus,
    &:active {
        outline: none;
        box-shadow: none;
        border-color: ${theme.colors.primary};
    }
`;

const Label = styled.label`
  text-align: left;
  color: ${theme.text.colors.main};
  font-family: ${theme.text.fonts.poppins};
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  align-items: center;
`;

const HoverLabel = styled.div`
  max-width: 250px;
  padding: 7px;
  background-color: ${theme.colors.filter};
  border-radius: 5px;
  position: absolute;
  left: 40%;
  bottom: 90%;
  color: #fff;
  text-align: left;
  font-size: 14px;
  font-family: ${theme.text.fonts.nunito};
`;

interface SelectInputProps {
    name?: String,
    label?: String,
    options?: any[],
    icon?: any;
    iconHoverLabel?: String,
    onChange: any,
    defaultValue: any
}

const SelectInput: React.FC<SelectInputProps> = ({ label, options, icon, iconHoverLabel, onChange, defaultValue }) => {
    const [showHoverLabel, setShowHoverLabel] = useState(false);
    const handleHoverLabelIn = () => setShowHoverLabel(true);
    const handleHoverLabelOut = () => setShowHoverLabel(false);
    return (
        <Form.Group controlId="exampleForm.ControlSelect1">
            {label && <Label>
                {label}

                {
                    icon && <Image
                        src={icon}
                        style={{
                            marginLeft: 5,
                            cursor: "pointer"
                        }}
                        onMouseEnter={handleHoverLabelIn}
                        onMouseLeave={handleHoverLabelOut}
                    />
                }
                {
                    ((iconHoverLabel?.length !== 0) && (iconHoverLabel !== undefined) && showHoverLabel) && <HoverLabel>{iconHoverLabel}</HoverLabel>
                }
            </Label>}

            <InputContainer
                onChange={onChange}
                defaultValue={defaultValue}
                as="select">
                {
                    options?.map((option: any, index: number) => <option key={index} selected={defaultValue === option.value} defaultValue={defaultValue} value={option.value}>{option.title}</option>)
                }
            </InputContainer>
        </Form.Group>
    )
}

export default SelectInput;

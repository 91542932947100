import React from "react";
import { Container, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../layout/PrivacyPage.css";
import NavBar from "../navbars";
import Footer from "../Footer";
import "../../mobile/Privacy/Privacy.css";
import "../../tablet/Privacy/Privacy.css";
import NewFooter from "../NewFooter";

const PrivacyPage:React.FC<any> = ({ children, history, headerTitle }) =>{
    return (
        <div className="privacy-page">
            <div className="privacy-page-header">
                <NavBar type="light" history={history} />
                <div className="privacy-page-header-title-container">
                    <Container>
                        <h4>{headerTitle}</h4>
                    </Container>
                </div>
            </div>
            <Container className="privacy-policy-container">
                <Card style={{ border: 0, backgroundColor: "transparent" }}>
                    <Card.Header className="privacy-policy-body">
                        {
                            children
                        }
                    </Card.Header>
                </Card>
            </Container>
            <div className="privacy-policy-footer">
                <NewFooter />
            </div>
        </div>
    )
}

export default PrivacyPage;


import React from "react";
import "../../layout/EmailVerification/EmailVerification.css";
import EmailVerificationResult from "./EmailVerificationResult";
import Footer from "../../components/Footer";
import NavBar from "../../components/navbars";
// @ts-ignore
import queryString from "query-string"

const EmailVerificationResultPage:React.FC<any> = ({ history, location }) =>{
    const value = queryString.parse(location.search);
    const activationCode = value?.code;
    const lang = value?.lang;
    console.log("lang => ",lang);

    return (
        <div className="email-verification">    
            <NavBar type="minimal" history={history} />
                <EmailVerificationResult lang={lang} code={activationCode} history={history} />
            <Footer />
        </div>
    )
}

export default EmailVerificationResultPage;

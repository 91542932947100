import React from "react";
import "../../../layout/HomeV2/New_StudentsReviews.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Image, Row } from "react-bootstrap";
import { reviews } from "../../../Global/StudentsReviews";
// import "../../../mobile/Home/StudentsReviews.css";
// import "../../../tablet/Home/StudentsReviews.css";
import Container from "../../../components/Container";
import {useTranslation} from "react-i18next";

const StudentsReviews = () => {
    const { t } = useTranslation();
    return (
        <div className="new_students-reviews pb-5">
            <Container className="new_students-reviews-container-start" id="studentsReviews">

                <h2 className="new_students-reviews-title">{t("home.reviews.title")}</h2>
                <div className="new_students-reviews-cards-container">
                    <div className="new_students-reviews-cards">
                        <Row>
                            {
                                reviews.map(review => (
                                    <Col className="col-12 col-md-4">
                                        <div className="new_students-reviews-card-item" key={review.id}>
                                            <div className="new_students-reviews-card-item-top">
                                                <div className="new_students-reviews-card-item-img"></div>
                                            </div>
                                            <p className="new_students-reviews-cards-item-description">
                                                {t(review.description)}
                                            </p>
                                            <div className="new_students-reviews-cards-item-owner">
                                                <div>
                                                    <Image style={{ width: 59, height: 59 }} src={review.image} />
                                                </div>
                                                <div className="new_students-reviews-cards-item-owner-name-container">
                                                    <p className="new_students-reviews-cards-item-owner-name">
                                                        <strong>{review.name}</strong> <br /> {t(review.country)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>
                </div>

            </Container>
        </div >
    )
}

export default StudentsReviews;
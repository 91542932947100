
import React, { useEffect, useState } from "react";
import "../../layout/Chat/ChatRoom.css"
// @ts-ignore
import { ChatList } from 'react-chat-elements'
import 'react-chat-elements/dist/main.css';
import { MESSAGES_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import moment from "moment";
import dots from "../../assets/instructor-reservations/dots.png";
import { Image } from "react-bootstrap";
import { SpeakingLineIcon } from "../../assets/SVG/speakinglineIcon";
import { person_empty } from "../../assets/SVG/person_empty";
import { useDispatch } from "react-redux";

const ChatRoom: React.FC<any> = ({ isOpen, hide }) => {

    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [success, setSuccess] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);

    const [roomDataSource, setRoomDataSource] = useState<any[any]>();

    useEffect(() => {
        if (isOpen) {
            LoadRooms();
        }
    }, [isOpen]);


    const startChatHandle = (c: any) => {
        //console.log("startChatHandle ", c);
        hide();
        dispatch({
            type: "CHAT_DIALOG_IS_SHOWN",
            payload: {
                isOpen: true,
                roomId: c.id,
                roomData:
                {
                    roomId: c.id,
                    roomType: c.roomType,
                    talkingWith: c.talkingWith
                }
            }
        });
    };

    const LoadRooms = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(MESSAGES_URL + "/api/v1/rooms/user"));
        // Handling Error
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            return null;
        }
        if (res.data && res.data.length > 0) {
            const list = res.data.map((item: any) => {
                var itemDate = (item.messages && item.messages.length > 0) ? moment(item.messages[0].createdAt * 1000) : moment(item.createdAt * 1000);
                //console.log("Date - ",itemDate.toDate());
                return {
                    id: item.id,
                    roomType : item.roomType,
                    talkingWith : item.talkingWith,
                    avatar: (item.roomType === 1 ? SpeakingLineIcon : (item.talkingWith.photoUrl ? item.talkingWith.photoUrl : person_empty)),
                    alt: 'Reactjs',
                    title: (item.roomType === 1 ? "Support" : (item.talkingWith.name + " " + item.talkingWith.surname?.charAt(0))),
                    subtitle: (item.messages && item.messages.length > 0) ? item.messages[0].messageText : '',
                    date: itemDate.toDate(),
                    unread: item.unReadMessageCount,
                }
            });

            //console.log("ROOM ",list);
           // list.sort((message: any, messageSecond: any) => (message.date > messageSecond.date) ? 1 : -1);
            setRoomDataSource(list);
        }
    }

    return (
        <React.Fragment>
            <div className="chatroom-popup-container">
                {(!roomDataSource || roomDataSource.length < 1) ? (
                    <div className="chatroom-no-records-container">
                        <Image src={dots} />
                        <p className="chatroom-no-records-text">
                            No chat found
                           </p>
                    </div>
                ) : (
                    <ChatList
                        className='chat-list'
                        dataSource={roomDataSource}
                        onClick={(x: any) => startChatHandle(x)}
                    />
                )}

            </div>
        </React.Fragment>
    )
};

export default ChatRoom;


// [
//     {
//         avatar: 'https://facebook.github.io/react/img/logo.svg',
//         alt: 'Reactjs',
//         title: 'Facebook',
//         subtitle: 'What are you doing?',
//         date: new Date(),
//         unread: 0,
//     },

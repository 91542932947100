import React, { useEffect, useState } from "react";
import "../../layout/InstructorReservations/TopBar.css";
import Input from "../../components/inputs";
import search from "../../assets/student-instructors-page/search.png";
// @ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { useSetState } from "../../Global/Utilities/getsetstate";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const TopBar = () => {
    const { t } = useTranslation();
    const [timeRangeValue, setTimeRangeValue] = useState([moment().add(-7, "day"), moment()]);

    useEffect(() => {
        dispatch({
            type: "DATE_FILTER_CHANGED",
            payload: { timeRangeValue }
        })
    }, timeRangeValue);

    const dispatch = useDispatch();
    return (
        <div className="instructor-reservations-top-bar-container">
            <div className="instructor-reservations-top-bar-time-range-input-container">
                {<Input type="time-range" 
                defaultValue={timeRangeValue} 
                onChange={(e: any) => {
                    setTimeRangeValue(e);
                }}
                />}

            </div>
            <div className="reservations-top-bar-search-input">
                <Input
                    type="icon"
                    icon={search}
                    focusColor="#fff"
                    // value={searchText}
                    onEnterPress={(value: string) => {
                        // setSearchText(value);
                        dispatch({
                            type: "SEARCH_TEXT_CHANGED",
                            payload: { value }
                        })
                    }}
                    placeholder={t("general.search")}
                />
            </div>
        </div>
    )
}


export default TopBar;

import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import chatIcon from "../../../assets/chatIcon.svg";
import "../../../layout/HistoryModal/HistoryModal.css";
import { CONVERSATION_URL, ROLES } from "../../../Global/Constants/constants";
import { ReturnProps } from "../../../@types/functions";
import axios from "axios";
import Input from "../../../components/inputs";
import "../../../layout/HistoryModal/HistoryModal.css";
import AsessmentScoreOptions from './AsessmentScoreOptions';
import StyledButton from '../../buttons';
import DescriptionInput from '../../inputs/DescriptionInput';
import { trackPromise } from 'react-promise-tracker';
import AlertInfo from '../../alerts/AlertInfo';
import {useTranslation} from "react-i18next";

interface MeetingAssessmentInterface {
    meetingId: String;
    userRole?: number;
    assessment?: any;
    assessmentUpdated?: any;
}

const MeetingAssessment: React.FC<MeetingAssessmentInterface> = ({
    meetingId,
    userRole = ROLES.TEACHER,
    assessment = null,
    assessmentUpdated = () => { }
}) => {
    const { t } = useTranslation();
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    // useEffect(() =>{
    //     setTaskAchivement(assessment?.taskAchievementScore);
    //     setVocabulary(assessment?.vocabularyScore);
    //     setFluency(assessment?.fluencyScore)
    // },[assessment])

    const [taskAchivement, setTaskAchivement] = useState<number>(assessment?.taskAchievementScore);
    const [vocabulary, setVocabulary] = useState<number>(assessment?.vocabularyScore);
    const [pronunciation, setPronunciation] = useState<number>(assessment?.pronunciationScore);
    const [fluency, setFluency] = useState<number>(assessment?.fluencyScore);
    const [grammer, setGrammer] = useState<number>(assessment?.grammerScore);
    const [note, setNote] = useState<string>(assessment?.note);

    // const UpdateMeetingAssessment = async() =>{
    //     const res = await axios.delete<any, ReturnProps>(CONVERSATION_URL + "/api/v1/history/"+meetingId);
    //     // Handling Error
    //     if(!res.success || !res.data) {
    //     //   setError(res.error as string);
    //     //   setShowError(true)
    //       alert(res.error)
    //       return null;
    //     }
    // }

    const UpdateAssessment = async () => {
        const param = {
            meetingId: meetingId,
            vocabularyScore: vocabulary,
            pronunciationScore: pronunciation,
            fluencyScore: fluency,
            grammerScore: grammer,
            TaskAchievementScore: taskAchivement,
            note: note
        }
        //console.log(vocabulary);
        //console.log(taskAchivement);
        //console.log(note);

        const res = await trackPromise(axios.post<any, ReturnProps>(CONVERSATION_URL + "/api/v1/meeting-assessment", param));
        // // Handling Error
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            //  alert(res.error)
           // return null;
        }
        assessmentUpdated();
    }

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />

            <div className="history-tab-content">
                {/* <div className="c-r-bt" hidden={userRole === ROLES.STUDENT}>
                <button className="btn btn-danger" onClick={UpdateAssessment}>Guncelle</button>
            </div> */}
                <div className="history-keyValue">
                    <ul>
                        <li>
                            <span className="key">{t("assessment.task")}</span>
                            <span className="value">
                                <AsessmentScoreOptions role={userRole} name="task" value={taskAchivement} handleChange={(e: any) => { setTaskAchivement(e.target.value) }} />
                            </span>
                        </li>

                        <li>
                            <span className="key">{t("assessment.vocabulary")}</span>
                            <span className="value">
                                <AsessmentScoreOptions role={userRole} name="vocabulary" value={vocabulary} handleChange={(e: any) => { setVocabulary(e.target.value) }} />
                            </span>
                        </li>

                        <li>
                            <span className="key">{t("assessment.pronunciation")}</span>
                            <span className="value">
                                <AsessmentScoreOptions role={userRole} name="pronunciation" value={pronunciation} handleChange={(e: any) => { setPronunciation(e.target.value) }} />
                            </span>
                        </li>

                        <li>
                            <span className="key">{t("assessment.fluency")}</span>
                            <span className="value">
                                <AsessmentScoreOptions role={userRole} name="fluency" value={fluency} handleChange={(e: any) => { setFluency(e.target.value) }} />
                            </span>
                        </li>

                        <li>
                            <span className="key">{t("assessment.grammar")}</span>
                            <span className="value">
                                <AsessmentScoreOptions role={userRole} name="grammar" value={grammer} handleChange={(e: any) => { setGrammer(e.target.value) }} />
                            </span>
                        </li>

                        {/* <li>
                                            <span className="key">Vocabulary</span>
                                            <span className="value">  
                                            <select  disabled = {false && userRole === ROLES.STUDENT}
                                            onSelect={(e)=>{
                                                setVocabulary(e);
                                            }} name="" id="">
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                            </select></span>
                                        </li> */}
                        {/* <li>
                                            <span className="key">Vocabulary</span>
                                            <span className="value">  
                                            <select  disabled = {userRole === ROLES.STUDENT}
                                            onSelect={(e)=>{
                                                setVocabulary(e);
                                            }} name="" id="">
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                            </select></span>
                                        </li>
                                        <li>
                                            <span className="key">Vocabulary</span>
                                            <span className="value">  
                                            <select  disabled = {userRole === ROLES.STUDENT}
                                            onSelect={(e)=>{
                                                setVocabulary(e);
                                            }} name="" id="">
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                            </select></span>
                                        </li> */}


                        {/* <li>
                                            <span className="key">Task Achievement</span>
                                            <span className="value">  <select disabled = {userRole === ROLES.STUDENT} name="" id="">
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                            </select></span>
                                        </li> */}

                        {/* 
                                        <li>
                                            <span className="key">Task Achievement</span>
                                            <span className="value">  <select name="" id="">
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                            </select></span>
                                        </li>

                                        <li>
                                            <span className="key">Task Achievement</span>
                                            <span className="value">  <select name="" id="">
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                            </select></span>
                                        </li>
                                        <li>
                                            <span className="key">Task Achievement</span>
                                            <span className="value">  <select name="" id="">
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                                <option value="">0.01(A1)</option>
                                            </select></span>
                                        </li> */}
                    </ul>
                </div>
                <div className="note-wrapper">
                    <h5>{t("assessment.note")}</h5>
                    <div className="note-inner">
                        <DescriptionInput
                            disabled={userRole === ROLES.STUDENT}
                            name="email"
                            value={note ? (note as string) : ""}
                            onChange={(e: any) => setNote(e.target.value)}
                        />
                    </div>
                </div>
                {
                    (userRole === ROLES.TEACHER) && <div className="saveButton">
                        <StyledButton type="tutorsCardBtn" primary onClick={UpdateAssessment}>{t("assessment.update")}</StyledButton>
                    </div>
                }

            </div>
        </React.Fragment>
    )
}

export default MeetingAssessment;
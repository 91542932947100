import React from "react";
import { Container, Navbar, Nav, Row, Col, Image, Button } from "react-bootstrap";

import logo from "../assets/logo.png";
import calender from "../assets/Calender.png";
import message from "../assets/message.png";
import profile from "../assets/Profile.png";

const SecondNavBar = () =>{
    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="nav-bar pt-4">
            <Container>
                <Navbar.Brand href="#home">
                    <Row>
                        <Col sm={1}>
                            <Image src={logo} className="brand-logo" />
                        </Col>
                        <Col sm={2}>
                            <p className="brand-title">Speaking<small>Line</small></p>
                        </Col>
                    </Row>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto mr-4">
                        {/* <Nav.Link href="#features" style={{marginRight: 15}}>
                            <p className="nav-bar-link">Nasıl Çalışır?</p>
                        </Nav.Link> */}
                        <Nav.Link style={{marginRight: 15}}>
                            <p className="nav-bar-link">Paket Satın Al</p>
                        </Nav.Link>
                        {/* <Nav.Link href="#pricing">
                            <p className="nav-bar-link">Sık Sorulan Sorular</p>
                        </Nav.Link> */}
                    </Nav>
                    <Nav>
                        <Nav.Link>
                            <Image src={calender} />
                        </Nav.Link>
                        <Nav.Link eventKey={2} href="#memes">
                            <Image src={message} />
                        </Nav.Link>
                        <Nav.Link eventKey={2} href="#memes">
                            <Image src={profile} />
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default SecondNavBar;

import React from "react";
import "../../layout/InstructorReservations/ReservationRecords.css";
import { Image } from "react-bootstrap";
import { NoReservationFoundIcon } from "../../assets/SVG/SVG";
import Button from "../../components/buttons";

import dots from "../../assets/instructor-reservations/dots.png";
import { useHistory } from "react-router";
import {useTranslation} from "react-i18next";

const NoSubscriptionFound = () => {
    const history = useHistory();
    const { t } = useTranslation();
    return (
        <div className="instructor-reservations-records-container">
            <div>{NoReservationFoundIcon}</div>
            <Image src={dots} />
            <p className="instructor-reservations-records-text">
                {t("dashboard.no_active_package")}
            <br />
                {t("dashboard.buy_now_to_speak_and_learn")}
            <br />
                <Button onClick={() => { history.push("/packages") }}>{t("dashboard.buy_package_now")}</Button>
            </p>
        </div>
    )
}

export default NoSubscriptionFound;

import React, { useEffect, useState } from "react";
import "../../layout/Preferences/Prefrences.css";
import Page from "../../components/pages";
import { RouteComponentProps } from "react-router-dom";
import Input from "../../components/inputs";
import { Row, Col, Image } from "react-bootstrap";
import Button from "../../components/buttons";
import { IDENTITY_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";

import info from "../../assets/preferences/info.png";
import { trackPromise } from "react-promise-tracker";
import AlertInfo from "../../components/alerts/AlertInfo";
import {useTranslation} from "react-i18next";

const StudentPreferences: React.FC<RouteComponentProps> = ({ history }) => {
    const { t } = useTranslation();

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [success, setSuccess] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);

    const [isRecordMeetings, setIsRecordMeetings] = useState(false);
    const [isNoficationForMessageEnabled, setIsNoficationForMessageEnabled] = useState(false);
    const [isNoficationForBeforeReservedMeeting, setIsNoficationForBeforeReservedMeeting] = useState(false);
    const [isNoficationForCampaigns, setIsNoficationForCampaigns] = useState(false);


    const LoadUserPreferences = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(IDENTITY_URL + "/api/v1/students/student-preferences"));
        // Handling Error
        if (!res.success || !res.data) {
            setError(res.error as string);
            setShowError(true)
            //alert(res.error)
            return null;
        }

        setIsRecordMeetings(res.data.isRecordConversations);
        setIsNoficationForMessageEnabled(res.data.isSendPushWhenMessageReceived)
        setIsNoficationForBeforeReservedMeeting(res.data.isSendPushBeforeReservation);
        setIsNoficationForCampaigns(res.data.isSendMessageCampaignMessages);
    }

    const UpdateUserPreferences = async () => {
        var param = {
            isRecordConversations: isRecordMeetings,
            isSendPushWhenMessageReceived: isNoficationForMessageEnabled,
            isSendMessageCampaignMessages: isNoficationForCampaigns,
            IsSendPushBeforeReservation: isNoficationForBeforeReservedMeeting
        };
        const res = await trackPromise(axios.put<any, ReturnProps>(IDENTITY_URL + "/api/v1/students/student-preferences", param));
        // Handling Error
        if (!res.success || !res.data) {
            setError(res.error as string);
            setShowError(true)
            return null;
        }
        else {
            setShowSuccess(true);
            setSuccess(t("general.successful_message"));
        }
    }

    useEffect(() => {
        LoadUserPreferences();
    }, []);

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
            <AlertInfo show={showSuccess} setShow={setShowSuccess} isAutoHide={true} message={success} type={1} />

            <Page type="dashboard" history={history} headerTitle="Tercihler" activePage="StudentPreferences">
                <h4 className="preferances-title">Bilgilendirme Ayarları</h4>
                <div className="preferances-settings-container">
                    <Input
                        type="checkbox"
                        checked={isRecordMeetings}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsRecordMeetings(e.target.checked)}
                        label="Seanslarımı kaydet" />

                    <Input
                        type="checkbox"
                        checked={isNoficationForMessageEnabled}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsNoficationForMessageEnabled(e.target.checked)}
                        label="Tarafıma mesaj gönderildiğinde mobil bildirim almak istiyorum" />

                    <Input
                        type="checkbox"
                        checked={isNoficationForBeforeReservedMeeting}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsNoficationForBeforeReservedMeeting(e.target.checked)}
                        label="Rezerve edilmiş görüşme öncesinde bildirim almak istiyorum" />

                    <Input
                        type="checkbox"
                        checked={isNoficationForCampaigns}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIsNoficationForCampaigns(e.target.checked)}
                        label="Kampanya mesajlarını almak istiyorum" />
                </div>
                {/* <hr className="preferences-sep" /> */}
                {/* <div>
                <h4 className="preferances-title">{"Language & Location"}</h4>
                <Row className="preferances-language-location">
                    <Col md={4}>
                        <Input 
                            type="select" 
                            label="Langauge" 
                            options={["Turkish"]} 
                        />
                    </Col>
                    <Col md={4}>
                        <Input 
                            type="select" 
                            label="Location" 
                            icon={info} 
                            iconHoverLabel="The Location Option Will Help Us Figure out The Time Zone That You Are Currently On ." 
                            options={["Turkey"]} 
                        />
                    </Col>
                    <Col md={4}>
                        <Input 
                            type="default" 
                            label="Time Zone" 
                            value="Istanbul (GMT+3)" 
                            style={{ backgroundColor: "#CACCCF" }} 
                            disabled 
                        />
                    </Col>
                </Row>
            </div> */}

                {/* <hr className="preferences-sep" /> */}
                <div className="preferences-save-button-container">
                    <Button onClick={UpdateUserPreferences} type="default" primary style={{ paddingLeft: 40, paddingRight: 40 }} className="preferences-save-button">Kaydet</Button>
                </div>
            </Page>
        </React.Fragment>
    )
}

export default StudentPreferences;


import calendarBtn from "../assets/calendarBtn.png";

export default {
    colors: {
        primary: "#F86F53",
        primaryHover: "#E55F44",
        secondary: "#5389F8",
        secondaryHover: "#426FCD",
        inactive: "#CACCCF",
        filter: "#F2F2F2",
        filterActive: "#929598",
        filterHover: "#E8E8E8",
        facebook: "#3D5288",
        google: "#F2F2F2",
        facebookHover: "#2B3A61",
        googleHover: "#DFDFDF",
        transparent: "transparent",
        white: "#fff",
    },
    icons: {
        calendar: calendarBtn,
    }
}

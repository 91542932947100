import { ROLES } from "../../../Global/Constants/constants";

interface AsessmentScoreOptionsProps{
    name : any ,
    value : any,
    handleChange : any,
    role : number
}

const AsessmentScoreOptions: React.FC<AsessmentScoreOptionsProps>  = ({ name, value, handleChange, role }) => (
    <select 
    style={{ width: "100px" }} 
    disabled={role === ROLES.STUDENT} 
    name={name} 
    value={value} 
    onChange={handleChange}>
      <option value={0}>0</option>
      <option value={1}>1</option>
      <option value={2}>2</option>
      <option value={3}>3</option>
      <option value={4}>4</option>
      <option value={5}>5</option>
    </select>
)

export default AsessmentScoreOptions;
import React,{ useState, useEffect } from "react";
import { Navbar, Image, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../layout/navbar/NavBarBurgerMenu.css";
import "../../mobile/navbars/MainNavBar.css"
import { MainNavBarProps } from "../../@types/NavBar";
import Container from "../Container";
import {
    NavBarContainer,
    NavBarWrapper,
    NavBarBrand,
    NavBarLinksContainer,
    NavBarLink,
    NavBarButtons,
    NavBarButton
} from "./StyledComponents";

import logo from "../../assets/navbar/logo.png";
import lightLogo from "../../assets/navbar/light-logo.png";
import burgerMenu from "../../assets/navbar/burger-menu.png";

const MainNavBar:React.FC<MainNavBarProps> = ({ links, history, navTheme }) =>{
    const [navLogo, setNavLogo] = useState<any>(null);
    const handleNavLogo = () =>{
        if(navTheme === "light") {
            setNavLogo(lightLogo)
        } else {
            setNavLogo(logo)
        }
    }
    useEffect(() =>{
        handleNavLogo()
    },[])
    return (
        <NavBarContainer
            collapseOnSelect
            expand="lg"
        >
            <Container>
                <NavBarWrapper>
                    <div className="navbar-mobile">
                        <NavBarBrand>
                            <Image
                                src={navLogo}
                                className="brand-logo"
                                onClick={() =>history.push("/")}
                            />
                        </NavBarBrand>
                        <Navbar.Toggle
                            aria-controls="responsive-navbar-nav"
                            className="main-nav-bar-burger-menu"
                        >
                            <Image src={burgerMenu} />
                        </Navbar.Toggle>
                    </div>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <NavBarLinksContainer>
                            {
                                links && links.map((link:any[]) =><NavBarLink  key={link[0]} onClick={(e:any) =>history.push(link[1])}>{link[0]}</NavBarLink>)
                            }
                        </NavBarLinksContainer>
                        <NavBarButtons>
                            <NavBarButton
                                type="third"
                                secondary ={true}
                                onClick={() =>history.push("/login")}
                            >
                                Giriş Yap
                            </NavBarButton>
                            <NavBarButton
                                type="third"
                                primary ={true}
                                onClick={() =>history.push("/register")}
                            >
                                Üye Ol
                            </NavBarButton>
                            <NavBarButton
                                type="third"
                                transparent={true}
                                onClick={() =>history.push("/tutor/register")}
                            >
                                Be a Tutor
                            </NavBarButton>
                        </NavBarButtons>
                    </Navbar.Collapse>
                </NavBarWrapper>
            </Container>
        </NavBarContainer>
    )
}

export default MainNavBar;

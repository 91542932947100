import React, { useState, useEffect } from "react";
import { History } from "history";
import { Navbar, Nav, Image, Button } from "react-bootstrap";
import theme from "../../theme";
import {
    DashboardNavBarContainer,
    DashboardNavBarWrapper,
    DashboardNavBarLink,
    DashboardNavBarIcon,
    NavBarCollpase
} from "./StyledComponents";
import "../../layout/navbar/NavBarBurgerMenu.css";
import styled, { css } from "styled-components";

import logo from "../../assets/navbar/white-logo.png";
import logoDark from "../../assets/navbar/logo.png";
import burgerMenu from "../../assets/navbar/burger-menu.png";
import burgerMenuWhite from "../../assets/navbar/burger-menu-white.png";
import { ROLES } from "../../Global/Constants/constants";
import { connect, useDispatch } from "react-redux";
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import ChatRoom from "../chat/ChatRoom";
import { message_white, message_black, message_white_new, message_black_new, calendar_white_new, calendar_black_new, profile_black_new, profile_white_new } from "../../assets/SVG/SVG"
import { MESSAGES_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import useSound from "use-sound";
import MessageReceivedInfo from "../alerts/MessageReceivedInfo";
import {useTranslation} from "react-i18next";

interface DashboardNavBarProps {
    history: History,
    navTheme?: "normal" | "dark",
    onClickExpand?: () => any,
}

const studentDashboardPageLink = [
    // {
    //     title: "Eğitmenler",
    //     link: ""
    // },

    {
        title: "dashboard.buy_package",
        link: "/packages"
    },
    {
        title: "reservation.do_reservation",
        link: "/reservations/create"
    },
    {
        title: "history.history",
        link: "/history"
    },

];

const InstructorDashboardPageLink = [
    {
        title: "history.history",
        link: "/history"
    },
    {
        title: "reservation.reservation",
        link: "/reservations"
    },
    // {
    //     title: "Öğrenciler",
    //     link: ""
    // },
]

const ExtraMobileLinks = styled.div`
    display: none;
    @media only screen and (max-width: 1024px) {
        display: block;
        width: 80%;
        transform: translateX(6%);
        margin-right: auto;
    }
`;

const ExtraMobileLink = styled.p`
    margin: 0;
    padding: 0;
    text-align: center;
    color: ${theme.colors.white} !important;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    font-family: ${theme.text.fonts.poppins};
    margin-right: 50px;
    transform: translateX(-15px);
    ${({ id }) => {
        if (id === "dark") return css`
                color: ${theme.colors.dark} !important;
            `;
    }
    }
    &:hover {
        color: ${theme.colors.primaryShade} !important;
    }
    @media only screen and (max-width: 960px) {
        width: 90vw;
        text-align: center;
    }
    margin-top: 15px;
`;

const DashboardNavBar: React.FC<any> = ({
    history,
    auth,
    chatProp,
    navTheme,
    onClickExpand
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [messageIcon, setMessageIcon] = useState<any>({ white: message_white, dark: message_black });
    const [popoverOpen, setPopoverOpen] = useState(false);
    const toggle = () => {
        setMessageIcon({ white: message_white, dark: message_black })
        setPopoverOpen(!popoverOpen)
    };

    const [showMessageReceivedDialog, setShowMessageReceivedDialog] = useState(false);
    const [messageReceivedDialogTitle, setMessageReceivedDialogTitle] = useState(chatProp?.chatMessage?.senderUserName);
    const [messageReceivedDialogMessage, setMessageReceivedDialogMessage] = useState(chatProp?.chatMessage?.messageText);

    const [play, { stop }] = useSound("/sounds/message_sound.mp3");

    const studentDashboard = (auth?.user?.role === ROLES.STUDENT);

    const [logoImg, setLogoImg] = useState(logo)
    const handleLogoImg = () => {
        if (navTheme === "dark") return setLogoImg(logoDark)
        return logoImg
    }
    useEffect(() => {
        if (chatProp?.chatMessage?.roomId && !popoverOpen) {
            console.log("DashboardNavBar -- popOver not open ", chatProp?.chatMessage);
            if (chatProp?.chatMessage?.senderUserName !== messageReceivedDialogTitle || chatProp?.chatMessage?.messageText !== messageReceivedDialogMessage) {
                setShowMessageReceivedDialog(true);
                setMessageReceivedDialogTitle(chatProp?.chatMessage?.senderUserName);
                setMessageReceivedDialogMessage(chatProp?.chatMessage?.messageText);
                play();
            }
            setMessageIcon({ white: message_white_new, dark: message_black_new })
        }
    }, [chatProp])

    useEffect(() => {
        handleLogoImg();
        LoadRooms();
    }, [])

    const LoadRooms = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(MESSAGES_URL + "/api/v1/rooms/user"));
        // Handling Error
        if (!res.success) {
            return null;
        }
        if (res.data && res.data.length > 0) {  
            const unReadMessage = res.data.filter((x: any) => x.unReadMessageCount > 0);
            if (unReadMessage && unReadMessage.length > 0) {
                setMessageIcon({ white: message_white_new, dark: message_black_new })
            }
            else {
                setMessageIcon({ white: message_white, dark: message_black })
            }

        }
    }

    return (
        <React.Fragment>
            <MessageReceivedInfo show={showMessageReceivedDialog} setShow={setShowMessageReceivedDialog} title={messageReceivedDialogTitle} message={messageReceivedDialogMessage} isAutoHide={true} />

            <DashboardNavBarContainer collapseOnSelect expand="lg" dark={(navTheme === "dark")}>
                <DashboardNavBarWrapper>
                    <Navbar.Brand style={{ cursor: "pointer" }} onClick={() => studentDashboard ? history.push("/student/dashboard") : history.push("/tutor/dashboard")}>
                        <Image src={logoImg} />
                    </Navbar.Brand>
                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                        className="main-nav-bar-burger-menu"
                        style={{ marginTop: -10 }}
                    >
                        {
                            (navTheme === "dark") ? (
                                <Image
                                    src={burgerMenu}
                                    onClick={onClickExpand}
                                />
                            ) : (
                                <Image
                                    src={burgerMenuWhite}
                                    onClick={onClickExpand}
                                />
                            )
                        }
                    </Navbar.Toggle>
                    <NavBarCollpase navTheme={navTheme || "normal"} id="responsive-navbar-nav">
                        <Nav className="ml-auto">
                            {
                                (studentDashboard) ? studentDashboardPageLink.map((link, index) => (
                                    <DashboardNavBarLink
                                        dark={(navTheme === "dark")}
                                        key={index}
                                        onClick={() => history.push(link.link)}
                                    >
                                        {t(link.title)}
                                    </DashboardNavBarLink>
                                )) : InstructorDashboardPageLink.map((link, index) => (
                                    <DashboardNavBarLink
                                        dark={(navTheme === "dark")}
                                        key={index}
                                        onClick={() => history.push(link.link)}
                                    >
                                        {t(link.title)}
                                    </DashboardNavBarLink>
                                ))
                            }
                            <ExtraMobileLinks>
                                {
                                    (studentDashboard) ? studentDashboardPageLink.map((link, index) => (
                                        <ExtraMobileLink
                                            id={`${(navTheme === "dark") && "dark"}`}
                                            key={index}
                                            onClick={() => history.push(link.link)}
                                        >
                                            {t(link.title)}
                                        </ExtraMobileLink>
                                    )) : InstructorDashboardPageLink.map((link, index) => (
                                        <ExtraMobileLink
                                            id={`${(navTheme === "dark") && "dark"}`}
                                            key={index}
                                            onClick={() => history.push(link.link)}
                                        >
                                            {t(link.title)}
                                        </ExtraMobileLink>
                                    ))
                                }

                            </ExtraMobileLinks>
                            {studentDashboard && <DashboardNavBarIcon>
                                <button style={{ background: "none" }} onClick={() => history.push("/reservations")}>
                                    {
                                        (navTheme === "dark") ?
                                            calendar_black_new :
                                            calendar_white_new
                                    }
                                </button>
                                {/* <Image
                                    src={
                                        (navTheme === "dark") ?
                                            calendar_black_new :
                                            calendar_white_new
                                    }
                                    onClick={() => history.push("/reservations")}
                                /> */}
                            </DashboardNavBarIcon>}

                            <DashboardNavBarIcon>
                                <div id="message" >
                                    {
                                        (navTheme === "dark") ?
                                            messageIcon.dark :
                                            messageIcon.white
                                    }
                                </div>

                            </DashboardNavBarIcon>
                            {/* 
                        <DashboardNavBarIcon>
                            <Image 
                                src={
                                    (navTheme === "dark") ? 
                                    theme.icons.notification.dark : 
                                    theme.icons.notification.white
                                } 
                                onClick={() =>history.push("/preview")}
                            />
                        </DashboardNavBarIcon> */}
                            <DashboardNavBarIcon>
                                <button style={{ background: "none" }} onClick={() => studentDashboard ? history.push("/student/profile/settings") : history.push("/tutor/profile/settings")}>
                                    {
                                        (navTheme === "dark") ?
                                            profile_black_new :
                                            profile_white_new
                                    }
                                </button>
                                {/* <Image
                                    src={
                                        (navTheme === "dark") ?
                                            theme.icons.profile.dark :
                                            theme.icons.profile.white
                                    }
                                    onClick={() => studentDashboard ? history.push("/student/profile/settings") : history.push("/tutor/profile/settings")}
                                /> */}
                            </DashboardNavBarIcon>
                        </Nav>
                    </NavBarCollpase>
                </DashboardNavBarWrapper>
            </DashboardNavBarContainer>

            <Popover placement="bottom" isOpen={popoverOpen} target="message" toggle={toggle}>
                <ChatRoom isOpen={popoverOpen} hide={toggle} />
                {/* <PopoverHeader>Popover Title</PopoverHeader>
                <PopoverBody>Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</PopoverBody> */}
            </Popover>

        </React.Fragment>
    )
}

function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer,
        chatProp: state.ChatReducer,
    }
}

export default connect(mapStateToProps)(DashboardNavBar);
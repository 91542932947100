import React, {useState, useRef, useEffect} from "react";
import {connect} from "react-redux";
import Page from "../../components/pages";
import {RouteComponentProps} from "react-router-dom";
import "../../layout/StudentVideoChat/StudentVideoChat.css";
import "../../layout/StudentVideoChat/Publisher.css";
import {CONVERSATION_URL, MESSAGES_URL, ROLES} from "../../Global/Constants/constants";
import {ReturnProps} from "../../@types/functions";
import axios from "axios";

// import Publisher from "./Publisher";
// @ts-ignore
import {OTPublisher, OTPublisherRef, OTSession, OTSubscriber, OTStreams} from "@opentok/client";
import {API_KEY, API_KEY as apiKey} from "../../Global/OpenTok/config";
import ChatRoom from "./ChatRoom";
import "../../mobile/StudentVideoChat/StudentVideoChat.css";
import "../../layout/StudentVideoChat/Subscriber.css";
import MobileBottom from "./MobileBottom";
import Header from "./Header";
import RoomOptions from "./RoomOptions";
//import { PublisherEventHandlers, Session, SessionEventHandlers, Publisher, SubscriberEventHandlers } from "opentok-react/types/opentok";
import {useSetState} from "../../Global/Utilities/getsetstate";
import TeacherAssessmentModal from "../../components/modals/VideoChatModal/TeacherAssessmentModal";
import StudentRateModal from "../../components/modals/VideoChatModal/StudentRateModal";
import useSound from "use-sound";
import AlertInfo from "../../components/alerts/AlertInfo";
import googlePlay from "../../assets/banner-img.png";
import speakingline_bg from '../../assets/speakingline_bg.png';

interface StudentVideoChatProp {
    location: any;
    auth: any
}

type VideoParams = StudentVideoChatProp & RouteComponentProps

const StudentVideoChat: React.FC<VideoParams> = ({history, location, auth}) => {

    const [play, {stop}] = useSound("/sounds/message_sound.mp3");

    const [isCameraVisible, setIsCameraVisible] = useState(true);
    const [showAssessmentModal, setShowAssessmentModal] = useState(false);

    const [audioSource, setAudioSource] = useState<any>(true);
    const [shareScreenState, setShareScreenState] = useState(false);
    const [microState, setMicroState] = useState(true);
    const [cameraState, setCameraState] = useState(true);
    const [showChatRoom, setShowChatRoom] = useState(true);
    const [meetingStarted, setMeetingStarted, getMeetingStarted] = useSetState(false);
    const [waitingOpponent, setWaitingOpponent] = useState(true);
    const [publisherObj, setPublisherObj] = useState<OT.Publisher>();
    const [subscriberObj, setSubscriberObj] = useState<any>();
    const subscriberObjRef = useRef(subscriberObj);
    const [session, setSession] = useState<any>();
    const [isMeetingFinished, setIsMeetingFinished] = useState(false);

    const [publisherSource, setPublisherSource] = useState<any>("camera");
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    let videoFilter:any  = undefined
    if (auth.user.role === ROLES.TEACHER) {
        videoFilter = {
            type: 'backgroundReplacement',
            backgroundImgUrl :speakingline_bg//'https://assets-app-sl.s3.eu-central-1.amazonaws.com/gnr.png'//'https://is1-ssl.mzstatic.com/image/thumb/Purple123/v4/9e/1b/0a/9e1b0a76-84f7-4297-a97c-4648957d50e5/pr_source.png/643x0w.jpg',
        }
    }


    useEffect(() => {
        return () => {
            console.log("unmounting");
            meetingFinishFunction();
        }
    }, []);

    useEffect(() => {

        subscriberObjRef.current = subscriberObj;

        //console.log(publisherRef.current);
        if(publisherObj && session) {

            publisherObj.destroy();

            var source
            if (publisherSource === 'screen') {
                source = 'screen'
            } else if (publisherSource === 'camera') {
                source = undefined
            }

            var publisher = OT.initPublisher('publisher',
                {
                    fitMode: "cover",
                    videoSource: source,
                    insertMode: "append",
                    width: "100%",
                    height: "100%",
                    publishVideo: cameraState,
                    publishAudio: microState,
                    audioSource : audioSource,
                    publishCaptions:false,
                    videoContentHint:"",
                    showControls:false,
                    videoFilter : videoFilter
                },
                function (error) {
                    if (error) {
                       onError(error);
                    } else {
                        session.publish(publisher, function (error: any) {
                            if (error) {
                                // Look error.message to see what went wrong.
                                console.error("Error : ", error);
                                onError(error);
                            }
                        });
                    }
                }
            );
            setPublisherObj(publisher);
        }
    }, [publisherSource]);


    useEffect(() => {
        meetingParams = location?.state?.meetingParams;
        if (!meetingParams) {
            // meetingParams = JSON.parse(
            //     '{"meeting_request_id":"ae76021c-33bd-4fc4-b371-8cac9bd9353b","meeting_id":"25ae47fa-d7cf-42af-89de-85c1e6512042","session_id":"1_MX40NjM5MzIxMn5-MTcyMzI5NTU1ODcyMn4zdThVMWk2SVM3K3QyZm16L0lHQzVkYzd-fn4","meeting_request_answer":1,"meeting_token":"T1==cGFydG5lcl9pZD00NjM5MzIxMiZzaWc9Zjg1NDQwNjMxMDU3M2RjNTgxNDg0NzYwNTRkZjczYTZkOTQwNmI0NzpzZXNzaW9uX2lkPTFfTVg0ME5qTTVNekl4TW41LU1UY3lNekk1TlRVMU9EY3lNbjR6ZFRoVk1XazJTVk0zSzNReVptMTZMMGxIUXpWa1l6ZC1mbjQmY3JlYXRlX3RpbWU9MTcyMzI5NTU5MCZub25jZT0wLjAyNDk1MzcwMzMyMzM4OTMxJnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE3MjU4ODc1ODkmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0=","meeting_duration":45,"attendee_guid":"ba3b6b20-4449-4cfa-adb4-c4b21c413ee0","attendee_name":"MyTeacher","attendee_surname":"Surname Teacher","attendee_photo_url":"http://bucket-url/ba3b6b20-4449-4cfa-adb4-c4b21c413ee0_20210316-154233.png","attendee_email":"newteacher@gmail.com"}'
            // );
            return;
        }

        // @ts-ignore
        var token = meetingParams.meeting_token;
        // @ts-ignore
        // Initialize an OpenTok Session object
        var session = OT.initSession(API_KEY, meetingParams.session_id);
        setSession(session);

        // create a publisher
        var publisher = OT.initPublisher(
            "publisher",
            {
                videoSource: undefined,
                insertMode: "append",
                width: "100%",
                height: "100%",
                publishVideo: cameraState,
                publishAudio: microState,
                publishCaptions:false,
                videoContentHint:"",
                showControls:false,
                videoFilter : videoFilter
            },
            onError
        );

        setPublisherObj(publisher);

        // connect to the session
        session.connect(token, function (error: any) {
            // If the connection is successful, publish to the session
            if (error) {
                onError(error);
            } else {
                session.publish(publisher, onError);
            }
        });

        // subscribe to newly created stream
        session.on("streamCreated", function (event: any) {
            var subscriber = session.subscribe(
                event.stream,
                "subscriber",
                {
                    fitMode: "cover",
                    subscribeToAudio: true,
                    subscribeToVideo:true,
                    insertMode: "append",
                    width: "100%",
                    height: "100%",
                    showControls : false
                },
                onError
            );

            console.log('Session  stream created! - karsi tarafta baglandi', event);
            setWaitingOpponent(false);
            setSubscriberObj(subscriber);
            subscriberObjRef.current = subscriber;
            startMeetingApi();
        });

        session.on("streamDestroyed", (event: any) => {
            console.log('session stream destroyed! - karsi tarafin stream gitti', event);
        });

        session.on("sessionConnected", (e: any) => {
            console.log('session connected -  sen baglandin ve karsi tarafi bekliyorsun.', e);
            setWaitingOpponent(true);
        });

        session.on("sessionDisconnected", (w: any) => {
            console.log('session disconnected - sen kapadin', w);
            finishMeeting();
        });
        session.on("connectionDestroyed", (e: any) => {
            console.log("connection destroyed", e);
            finishMeeting();
        })

    }, [location?.state?.meetingParams]);

    useEffect(() => {
        //console.log("isMeetingFinished hoook");
        if (isMeetingFinished) {
            meetingFinishFunction();
        }
    }, [isMeetingFinished]);

    const meetingFinishFunction = () => {
        console.log("isMeetingFinished=true --- sessionobj: ", session);
        if (session != null) {
            //  sendExitMessageToSubscriber();
            publisherObj?.destroy();
            finishMeetingApi();
            setTimeout(async () => {
                session.disconnect();
            }, 1000);
        }
    }

    let meetingParams = location?.state?.meetingParams;
    //
    // if (!meetingParams) {
    //     meetingParams = JSON.parse('{"meeting_request_id":"ae76021c-33bd-4fc4-b371-8cac9bd9353b","meeting_id":"25ae47fa-d7cf-42af-89de-85c1e6512042","session_id":"1_MX40NjkxOTc5NH5-MTYyMTc1NDY5MDg4M34zNk1DYVBYKytQanBteHM0UHNCNWE0SXl-fg","meeting_request_answer":1,"meeting_token":"T1==cGFydG5lcl9pZD00NjkxOTc5NCZzaWc9NzYzOWE3OWZjNzFhMjkxN2IzNTFkMDc1MmNlNzJjMTVhZGJmZDIzYzpzZXNzaW9uX2lkPTFfTVg0ME5qa3hPVGM1Tkg1LU1UWXlNVGMxTkRZNU1EZzRNMzR6TmsxRFlWQllLeXRRYW5CdGVITTBVSE5DTldFMFNYbC1mZyZjcmVhdGVfdGltZT0xNjIxNzU0NzYwJm5vbmNlPTAuMzEwNTkxNjYwNTcyNDU0OCZyb2xlPXB1Ymxpc2hlciZleHBpcmVfdGltZT0xNjI0MzQ2NzU5JmNvbm5lY3Rpb25fZGF0YT1maXJzdCZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==","meeting_duration":45,"attendee_guid":"ba3b6b20-4449-4cfa-adb4-c4b21c413ee0","attendee_name":"MyTeacher","attendee_surname":"Surname Teacher","attendee_photo_url":"http://bucket-url/ba3b6b20-4449-4cfa-adb4-c4b21c413ee0_20210316-154233.png","attendee_email":"newteacher@gmail.com"}');
    // }

    const hideAssessmentModal = () => {
        setShowAssessmentModal(false);
        if (auth.user.role === ROLES.STUDENT) {
            history.replace("/student/dashboard")
        } else if (auth.user.role === ROLES.TEACHER) {
            history.replace("/tutor/dashboard")
        }
    }

    const finishMeeting = () => {
        setIsMeetingFinished(true);
        //aaa
    }

    const onError = (err: any) => {
        console.log("onError()",err);
        if(!err)
            return;
        if (err.name === 'OT_TIMEOUT') {
            setIsCameraVisible(true);
            setShareScreenState(false);
            setPublisherSource('camera');
            setError("Screen sharing timed out. Please try again.");
            setShowError(true);
        }
        if (err.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
            setIsCameraVisible(true);
            setShareScreenState(false);
            setPublisherSource('camera');
            setError("Screen sharing access denied. Please try again.");
            setShowError(true);
        }
        if (err.name === 'OT_HARDWARE_UNAVAILABLE') {
            setIsCameraVisible(true);
            setShareScreenState(false);
            setPublisherSource('camera');
            setError("Screen sharing hardware unavailable. Please try again.");
            setShowError(true);
        }
    }

    const startMeetingApi = async () => {
        if (auth.user.role === ROLES.TEACHER) {
            const res = await axios.put<any, ReturnProps>(CONVERSATION_URL + "/api/v1/meetings/meeting-start/" + meetingParams.meeting_id);
            if (!res.success) {
                // alert(res.error)
                return null;
            }
            setMeetingStarted(true);
        } else if (auth.user.role === ROLES.STUDENT) {
            setTimeout(async () => {
                const res = await axios.put<any, ReturnProps>(CONVERSATION_URL + "/api/v1/meetings/meeting-start/" + meetingParams.meeting_id);
                if (!res.success) {
                    // alert(res.error)
                    return null;
                }
                setMeetingStarted(true);
            }, 1500)
        }

    };

    //console.log("Meeting started " + meetingStarted);

    const finishMeetingApi = async () => {
        //console.log("finish API = Meeting started " + meetingStarted);

        if (await getMeetingStarted) {
            const time = auth.user.role === ROLES.TEACHER ? 500 : 1500;
            setTimeout(async () => {
                const res = await axios.put<any, ReturnProps>(CONVERSATION_URL + "/api/v1/meetings/meeting-finish/" + meetingParams.meeting_id);
                if (!res.success) {
                    //    alert(res.error)
                    return null;
                }
            }, time)

            setShowChatRoom(true);
            setShowAssessmentModal(true);
            setIsMeetingFinished(true);
        }
        // setStartMeeting(false);
    };

    const startShareScreen = () => {

        // publisherObj?.applyVideoFilter({
        //     type: 'backgroundBlur',
        //     blurStrength: 'high',
        // });

        // publisherObj?.applyVideoFilter({
        //     type: 'backgroundReplacement',
        //     backgroundImgUrl : googlePlay,
        // });
        //
        // return;

        OT.checkScreenSharingCapability(async function (response) {

            const res = await OT.getUserMedia();
            // @ts-ignore
            setAudioSource(res.getAudioTracks()[0])

            if (!response.supported || response.extensionRegistered === false) {
                // This browser does not support screen sharing.
                setError("This browser does not support screen sharing.");
                setShowError(true);
            } else if (response.extensionInstalled === false) {
                setError("This browser does not support screen sharing.");
                setShowError(true);
            } else {
                if (shareScreenState) {
                    setPublisherSource('camera');
                    setIsCameraVisible(true);
                } else {
                    setIsCameraVisible(false);
                    setPublisherSource("screen");
                    publisherObj?.publishVideo(false);
                }
                setShareScreenState(!shareScreenState)
            }
        });
    }

    const name = meetingParams.attendee_name + " " + meetingParams.attendee_surname?.charAt(0) + ".";

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0}/>
            <Page type="video-meeting" history={history}>
                <div className="student-video-chat-container">
                    <div className="student-video-chat-wrapper">

                        <Header
                            isMeetingStarted={meetingStarted}
                            duration={meetingParams.meeting_duration}
                            name={name}
                            isStopCalled={isMeetingFinished}
                            timeComplete={finishMeeting}
                        />
                        <div className="student-video-chat-subscriber">
                            {
                                waitingOpponent &&
                                <div className="student-video-chat-subscriber-waiting-opponent">
                                    <h2 style={{color:'white'}}>Waiting Attendee</h2>
                                </div>
                            }
                            <div id="subscriber" className="subscribers" >
                            </div>
                        </div>
                        <div className="student-video-chat-publisher">
                            <div id="publisher" className="publisher">

                            </div>
                        </div>

                        <ChatRoom
                            handleShowChatRoom={() => setShowChatRoom(true)}
                            hideChatRoom={() => setShowChatRoom(false)}
                            showChatRoom={showChatRoom}
                            session={session}
                            attendeeName={name}
                            attendeeGuid={meetingParams.attendee_guid}
                            senderId={publisherObj?.id}
                            isMeetingFinished={isMeetingFinished}
                            meetingId={meetingParams.meeting_id}
                            // toggleCamera={() =>setCameraState(!cameraState)}
                            // toggleMicro={() => setMicroState(!microState)}
                        />

                        <RoomOptions
                            isCameraVisible={isCameraVisible}
                            isShareScreenVisible={true}
                            isCameraOn={cameraState}
                            isMicOn={microState}
                            isShareScreenOn={shareScreenState}
                            toggleCamera={() => {
                                setCameraState(!cameraState);
                                publisherObj?.publishVideo(!cameraState);
                            }}
                            toggleMicro={() => {
                                setMicroState(!microState)
                                publisherObj?.publishAudio(!microState);
                            }}
                            toogleShareScreen={startShareScreen}
                            stopCallClicked={finishMeeting}
                        />

                        <MobileBottom/>
                    </div>
                </div>
                {
                    (isMeetingFinished && auth?.user?.role === ROLES.TEACHER && showAssessmentModal) &&
                    (<TeacherAssessmentModal opponentName={name}
                                             meetingId={meetingParams.meeting_id}
                                             show={showAssessmentModal}
                                             hide={hideAssessmentModal}/>)
                }
                {
                    (isMeetingFinished && auth?.user?.role === ROLES.STUDENT && showAssessmentModal) &&
                    (<StudentRateModal
                        opponentId={meetingParams.attendee_guid}
                        opponentName={name}
                        meetingId={meetingParams.meeting_id}
                        show={showAssessmentModal}
                        hide={hideAssessmentModal}/>)
                }

            </Page>
        </React.Fragment>

    )
}

function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer,
    }
}

export default connect(mapStateToProps)(StudentVideoChat);

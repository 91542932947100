import React,{ useState } from "react";
import { Modal, Col, Row, Image } from "react-bootstrap";
import Button from "../../components/buttons";
import styled,{ css } from "styled-components";
import theme from "../../theme";
import "../../layout/SubscriptionDetails/PauseSubscription.css";
import { pauseOptions } from "../../Global/SubscriptionDetails/SubscriptionDetails";
import "../../mobile/SubscriptionDetails/SubscriptionUpgrade.css";
import "../../mobile/SubscriptionDetails/PauseSubscription.css";
import { SUBSCRIPTION_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";

import cross from "../../assets/upgrade-package/cross.png";
import moment from "moment";
import {useTranslation} from "react-i18next";

const SubsOption = styled(Button)`
    margin-right: 15px;
    float: left;
    margin-bottom: 15px;
    ${
        ({ clicked }) =>{
            if(clicked) return css`
                color: ${theme.colors.white};
                background-color: ${theme.colors.secondary};
                border-color: ${theme.colors.secondary};
            `;
            if(!clicked) return css`
                &:hover {
                    border-color: ${theme.colors.secondary};
                    color: ${theme.colors.secondary};
                }
            `;
        }
    }
`;

interface PauseSubscriptionDialogProps {
    subscription : any,
    closeFunc: (isUpdate : boolean) => void,
}

const PauseSubscriptionDialog:React.FC<PauseSubscriptionDialogProps> = ({ closeFunc, subscription }) =>{
    const { t } = useTranslation();

    const [pauseOptionChosen, setPauseOptionChosen] = useState(0);

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [success, setSuccess] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);

    const ChangePauseStatus = async () => {
        const param = {
            days : pauseOptions[pauseOptionChosen].value
        }
        const res = await trackPromise(axios.put<any, ReturnProps>(SUBSCRIPTION_URL + "/api/v1/subscription/" + subscription.guid + "/pause",param));
        // Handling Error
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
        }
        else {
            setSuccess(t("home.subscription_packages.successfully_paused"));
            setShowSuccess(true);
            closeFunc(true);
        }
    }

    return(
        <div className="pause-subscription">
            <Modal.Dialog className="pause-subscription-modal">
                <Modal.Header className="upgrade-subscription-modal-header">
                    <Modal.Title>
                        <h4 className="pause-subscription-modal-title">{t("home.subscription_packages.pause_subscription_title")}</h4>
                    </Modal.Title>
                    <Image 
                        src={cross} 
                        height={40} 
                        width={40} 
                        onClick={()=>closeFunc(false)}
                        style={{ cursor: "pointer" }}
                    />
                </Modal.Header>
                <Modal.Body style={{ paddingLeft: 24, paddingRight: 24 }}>
                    <Row>
                        <Col md={6}>
                            <div className="pause-subscription-modal-package">
                                <h4 className="pause-subscription-modal-package-title">{t("home.subscription_packages.title")}</h4>
                                <div className="pause-subscription-modal-content">
                                    <h6 className="pause-subscription-modal-content-title">{t("general.daily")} {subscription.product.dailyProduct.value} {t("general.minute")}</h6>
                                    <h6 className="pause-subscription-modal-content-title">{t("general.weekly")} {subscription.product.weeklyProduct.value} {t("general.day")}</h6>
                                    <h6 className="pause-subscription-modal-content-title">{subscription.product.monthlyProduct.value} {t("home.subscription_packages.monthly_exist")}</h6>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="pause-subscription-modal-package pause-subscription-modal-package-two">
                                <h4 className="pause-subscription-modal-package-title">{t("home.subscription_packages.previous_invoicing_date")}</h4>
                                <div className="pause-subscription-modal-content">
                                    <h6 className="pause-subscription-modal-content-title">{moment(subscription.startDate * 1000).format("DD MMMM yyyy")}</h6>
                                </div>
                                <h4 className="pause-subscription-modal-package-title" style={{ marginTop: 15 }}>{t("home.subscription_packages.next_invoicing_date")}</h4>
                                <div className="pause-subscription-modal-content">
                                    <h6 className="pause-subscription-modal-content-title"> {subscription.isRenewActive ? (moment(subscription.nextRenewDate * 1000).format("DD MMMM yyyy")) : t("home.subscription_packages.no_auto_renew")}</h6>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="pause-subs-options">
                        <h4 className="pause-subs-options-title">{t("home.subscription_packages.how_long_package_will_paused")}</h4>
                        <div className="pause-subs-options-container">
                            {
                                pauseOptions.map((po, index) =><SubsOption 
                                                                    type="option" 
                                                                    clicked={(index === pauseOptionChosen) ? true : false} 
                                                                    onClick={() =>setPauseOptionChosen(index)} 
                                                                    key={index}
                                                                >
                                                                    {po.title}
                                                                </SubsOption>
                                )
                            }
                        </div>
                    </div>
                    <div className="automatic-renewel-cancelation">
                        <h4 className="automatic-renewel-title">{t("home.subscription_packages.pause_confirmation_title")}</h4>
                        <p className="automatic-renewel-parag">
                            {t("home.subscription_packages.pause_confirmation_title")}
                        </p>
                        <Button 
                            type="default" 
                            primary 
                            style={{ 
                                width: "100%", 
                                marginTop: 15 
                            }}
                            className="pause-automatic-renewel-btn"
                            onClick={ChangePauseStatus}
                        >
                            {t("home.subscription_packages.pause_confirmation_approve")}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal.Dialog>
        </div>
    )
}

export default PauseSubscriptionDialog;


const initialState = true;

export default function(state=initialState, action:any) {
    switch(action.type) {
        case "TURN_ON_MICRO":
            return state = true
        case "TURN_OFF_CAMERA":
            return state = false
        default:
            return state
    }
}

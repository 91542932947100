import React from "react";
import "../../../layout/HomeV2/New_SubscriptionsPackages.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Image } from "react-bootstrap";
import Button from "../../../components/buttons";
import styled from "styled-components";
import theme from "../../../theme";
// import "../../../mobile/Home/SubscriptionPackages.css";
// import "../../../tablet/Home/SubscriptionPackages.css";
import Container from "../../../components/Container";

import img from "../../../assets/Home/speakingline-abonelik-paketlerimiz.png";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";


const StyledButton = styled(Button)`
    background-color: #D64502;
    border-radius: 8px;
    width: 60%;
    min-width: 250px;
    margin: 0;
    margin-top: 23px;
    padding: 10px 16px;
    gap: 8px;
    &:hover {
        border-color: ${theme.colors.white};
    }
    &:active,
    &:focus {
        background-color: ${theme.colors.darkPrimary};
    }
    @media only screen and (max-width: 960px) {
        width: 85%;
        margin-left: auto;
        margin-right: auto;
    }
    @media only screen and (max-width: 960px) and (min-width: 721px) {
        margin-left: 0;
    }
`;


const SubscriptionsPackages = () => {
    var history = useHistory();
    const { t } = useTranslation();

    return (
        <div className="new-subscriptions-packages">
            <Container>
                <Row className="new-subscriptions-packages-container">
                    <Col md={5} lg={5} className="order-2 order-md-1 new-subscriptions-packages-left-side new-subscriptions-packages-left-side-start" id="new-subscriptionsPackagesLeftSide">
                        <h2 className="new-subscription-packages-title">{t("home.subscription_packages.title")}</h2>
                        <p className="new-subscriptions-packages-descripton">
                            {t("home.subscription_packages.content")}
                        </p>
                        <StyledButton type="default" onClick={() => { history.push("/packages") }} primary className="float-left mb-4">{t("home.subscription_packages.title")}</StyledButton>
                    </Col>
                    <Col md={6} lg={6} className="order-1 order-md-2 new-subscriptions-packages-image-start" id="new-subscriptionsPackagesImages">
                        <Image src={img} className="new-subscription-package-img" />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SubscriptionsPackages;


import React, { useEffect, useState } from "react";
import { History } from "history";
import DashboardPage from "./DashboardPage";
import LightRegularPage from "./LightRegularPage";
import PrivacyPage from "./PrivacyPage";
import RegularPage from "./RegularPage";
import ReservationPage from "./ReservationPage";
import SupportPage from "./SupportPage";
import { connect } from "react-redux";
import IncomingCallModal from '../modals/meeting/IncomingCallModal';
import { connection, Init } from "../../Global/Utilities/signalr"
import { ROLES } from "../../Global/Constants/constants";
import AfterLoginPage from "./AfterLoginPage";
import VideoMeetingPage from "./VideoMeetingPage";
import AdminDashboardPage from "./AdminDashboardPage";
import NoLoginPage from "./NoLoginPage";

interface RootComponentsProps {
    type: "dashboard" |
    "admin-dashboard" |
    "light-regular-page" |
    "privacy" |
    "regular" |
    "video-meeting" |
    "reservation" |
        "no-login" |
    "support";
    history: History;
    children: React.ReactNode,
    headerTitle?: String,
    activePage?: String,
    bannerType?: String,
    subTitle?: String,
    studentDashboard?: Boolean
}
type props = RootComponentsProps & StateProps
const RootComponents: React.FC<props> = ({
    type,
    history,
    children,
    headerTitle,
    activePage,
    bannerType,
    subTitle,
    studentDashboard
}) => {
    switch (type) {
        case "dashboard":
            return (
                <React.Fragment>
                    <AfterLoginPage>
                        <DashboardPage
                            history={history}
                            headerTitle={headerTitle || ""}
                            activePage={activePage || ""}
                        >
                            {
                                children
                            }
                        </DashboardPage>
                    </AfterLoginPage>
                </React.Fragment>
            )
        case "admin-dashboard":
            return (
                <React.Fragment>
                    <AfterLoginPage>
                        <AdminDashboardPage
                            history={history}
                            headerTitle={headerTitle || ""}
                            activePage={activePage || ""}
                        >
                            {
                                children
                            }
                        </AdminDashboardPage>
                    </AfterLoginPage>
                </React.Fragment>
            )
        case "light-regular-page":
            return (
                <React.Fragment>
                    <AfterLoginPage>
                        <LightRegularPage
                            history={history}
                        >
                            {
                                children
                            }
                        </LightRegularPage>
                    </AfterLoginPage>
                </React.Fragment>
            )
        case "privacy":
            return (
                <PrivacyPage headerTitle={headerTitle || ""} history={history}>
                    {
                        children
                    }
                </PrivacyPage>
            )
        case "no-login":
            return (
                <NoLoginPage headerTitle={headerTitle || ""} history={history}>
                    {
                        children
                    }
                </NoLoginPage>
            )
        case "regular":
            return (
                <React.Fragment>
                    <AfterLoginPage>
                        <RegularPage
                            history={history}
                            pageTitle={headerTitle || ""}
                            subTitle={subTitle || ""}
                        >
                            {
                                children
                            }
                        </RegularPage>
                    </AfterLoginPage>
                </React.Fragment>
            )
        case "reservation":
            return (
                <ReservationPage>
                    {
                        children
                    }
                </ReservationPage>
            )
        case "support":
            return (
                <SupportPage
                    history={history}
                    bannerType={bannerType || ""}
                >
                    {
                        children
                    }
                </SupportPage>
            )
        case "video-meeting":
            return (
                <React.Fragment>
                    <VideoMeetingPage
                        history={history}
                    >
                        {
                            children
                        }
                    </VideoMeetingPage>
                </React.Fragment>
            )
        default:
            return <div></div>
    }
}

interface StateProps {
    auth: any
}
function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer,
    }
}

export default connect(mapStateToProps)(RootComponents);

//import axios from "axios";

// export const LOGIN_BASE_URL = axios.create({
//     baseURL: "https://localhost:20001",
// })

// export const STUDENT_DASHBOARD_URL = axios.create({
//     baseURL: "https://localhost:20008"
// })

let _IDENTITY_URL = ""
let _STUDENT_DASHBOARD_URL = "";
let _SUBSCRIPTION_URL = "";
let _PAYMENT_URL = "";
let _CONVERSATION_URL = "";
let _MESSAGES_URL = "";
let _EDGE_SOCKET_URL = "";
let _ADMIN_PANEL_URL = "";
let _STRIPE_PUBLISHABLE_KEY = "";

//console.log("Environment",process.env);

const ENV =  ""+"PROD"//process.env.REACT_APP_ENV ?  process.env.REACT_APP_ENV : "TEST" ;
const SERVICE_URL = "https://www.speakingline.com";

if (!ENV || ENV === 'LOCAL') {
    _IDENTITY_URL = "https://localhost:20001";
    _STUDENT_DASHBOARD_URL = "https://localhost:20008";
    _SUBSCRIPTION_URL = "https://localhost:20003";
    _PAYMENT_URL = "https://localhost:20012";
    _CONVERSATION_URL = "https://localhost:20005";
    _MESSAGES_URL = "https://localhost:20006";
    _EDGE_SOCKET_URL = "https://www.speakingline.com/edge/hubs/edge";//"https://localhost:32895/hubs/edge";
    _ADMIN_PANEL_URL= "http://localhost:4000/applications/6101a2eb93ad10335d4e3f56/pages/6101ae3f9b82521150f033b8";

}else if (ENV === 'DEV') {
    _IDENTITY_URL = "http://localhost:8080/identity";
    _STUDENT_DASHBOARD_URL = "http://localhost:8080/userdashboard";
    _SUBSCRIPTION_URL = "http://localhost:8080/subscription";
    _PAYMENT_URL = "http://localhost:8080/payment";
    _CONVERSATION_URL = "http://localhost:8080/conversation";
    _MESSAGES_URL = "http://localhost:8080/message";
    _EDGE_SOCKET_URL = "http://localhost:8080/edge/hubs/edge";
    _ADMIN_PANEL_URL= "http://localhost:4000/applications/6101a2eb93ad10335d4e3f56/pages/6101ae3f9b82521150f033b8";

}else if ( ENV === 'TEST' || ENV === 'PROD') {
    _IDENTITY_URL = SERVICE_URL+"/identity";
    _STUDENT_DASHBOARD_URL = SERVICE_URL+"/userdashboard";
    _SUBSCRIPTION_URL = SERVICE_URL+"/subscription";
    _PAYMENT_URL = SERVICE_URL+"/payment";
    _CONVERSATION_URL = SERVICE_URL+"/conversation";
    _MESSAGES_URL = SERVICE_URL+"/message";
    _EDGE_SOCKET_URL = SERVICE_URL+"/edge/hubs/edge";
    _ADMIN_PANEL_URL= SERVICE_URL+"/applications/6101a2eb93ad10335d4e3f56/pages/6101ae3f9b82521150f033b8";
}

if(ENV === 'PROD')
{
    _STRIPE_PUBLISHABLE_KEY = "pk_live_YpU525bhc75kCvXKEPaSa3hh00OqvUyQCc";
}
else
{
    _STRIPE_PUBLISHABLE_KEY = "pk_test_AdQv8OMOoHIzvju3FKPPQSCf00Sgntm9RM";
}

export const IDENTITY_URL = _IDENTITY_URL;
export const STUDENT_DASHBOARD_URL = _STUDENT_DASHBOARD_URL;
export const SUBSCRIPTION_URL = _SUBSCRIPTION_URL;
export const PAYMENT_URL = _PAYMENT_URL;
export const CONVERSATION_URL = _CONVERSATION_URL;
export const MESSAGES_URL = _MESSAGES_URL;
export const EDGE_SOCKET_URL = _EDGE_SOCKET_URL;
export const ADMIN_PANEL_URL = _ADMIN_PANEL_URL;
export const STRIPE_PUBLISHABLE_KEY = _STRIPE_PUBLISHABLE_KEY;

export var REFRESH_TOKEN = "";

export const ROLES = {
    STUDENT: 1,
    TEACHER: 2,
    ADMIN: 3
};

export const MeetingRequestStatusType =
{
    WAITING_ANSWER: 0,
    ACCEPTED: 1,
    NOT_ACCEPTED_BY_TEACHER: 2,
    CANCELED_BY_STUDENT: 3,
    TIMEOUT: 4
};


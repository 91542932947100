import React from "react";
import { Image, InputGroup, FormControl } from "react-bootstrap";
import styled from "styled-components";
import theme from "../../theme";
import "../../layout/inputs/inputs.css";

const Label = styled.label`
  text-align: left;
  color: ${theme.text.colors.main};
  font-family: ${theme.text.fonts.poppins};
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const InputContainer = styled(FormControl)`
  height: 44px;
  border: 1px solid ${theme.colors.line};
  font-family: ${theme.text.fonts.poppins} !important;
  font-weight: ${theme.text.weight.light} !important;
  font-size: 16px !important;
  color: ${theme.text.colors.main} !important;
  padding: 0 12px;
  background-color: #FFFCFC;
  &:focus,
  &:active {
    border-color: #F86F53;
    outline: none;
    box-shadow: none;
  }
`;

const HoverLabel = styled.div`
  max-width: 250px;
  padding: 15px;
  background-color: #fff;
  border-radius: 15px;
`;

interface InputProps {
  name? :string;
  label?: string;
  placeholder?: string;
  labelIcon?: any,
  icon?: any;
  value?: string;
  onChange?: (e:any) =>void,
  style?: React.CSSProperties,
  disabled?: boolean,
  iconHoverLabel?: string
}

const Input:React.FC<InputProps> = ({
  name,
  label,
  placeholder,
  labelIcon,
  value,
  onChange,
  style,
  disabled,
  iconHoverLabel
}) =>{
    return (
      <>
      <Label>
        <span style={{marginRight: 3}}>{label}</span>
        {
          labelIcon && <Image src={labelIcon} />
        }
        {
          ((iconHoverLabel?.length !== 0) && (iconHoverLabel !== undefined)) && <HoverLabel>{iconHoverLabel}</HoverLabel>
        }
      </Label>
      <InputGroup>
        <InputContainer
          name = {name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          style={style}
          disabled={disabled}
          className="input-font-family"
        />
      </InputGroup>
      </>
    )
}

export default Input;

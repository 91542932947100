import React, { useState, useEffect } from "react";
import { Image, Row, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../../layout/Home/ExpertsInstructors.css";
import "../../../mobile/Home/ExpertsInstructors.css";
import "../../../tablet/Home/ExpertsInstrucotrs.css";
import Container from "../../../components/Container";
import { useTranslation } from "react-i18next";

//import expertsInstructors from "../../assets/Home/experts.png";
import expertsInstructors_new from "../../../assets/experts_new.png";
//import expertsInstructorsMobile from "../../assets/Home/experts-instructors-mobile.png";

const ExpertsInstructors = () => {
    const { t } = useTranslation();

    // const [expertsInstructorsImg, setExpertsInstructorsImg] = useState(expertsInstructors);
    // const handleResponsiveness = () => {
    //     const width = window.screen.width;
    //     if (width < 920) setExpertsInstructorsImg(expertsInstructorsMobile);
    // }
    useEffect(() => {
        // handleResponsiveness()
    }, [])
    return (
        <div className="experts-instructors">
            <Container className="experts-instructors-container col-md-8 col-12">
                <Row>
                    <Col >
                        <h2 className="new_experts-instructors-title text-center"> {t("home.experts.title")}</h2>
                        <p style={{ whiteSpace: "pre-line" }}  className="new_experts-instructors-description text-center">
                            {t("home.experts.description").split("<br/>").join("\n")}
                        </p>
                    </Col>
                </Row>
                <Row >  
                    <Col className="experts-instructors-img-container">
                        <Image src={expertsInstructors_new} className="experts-instructors-img" />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ExpertsInstructors;


import React from "react";
import Card from "../../components/cards";
import "../../layout/InstructorsPage/Content.css";
import "../../tablet/InstructorsPage/Content.css";
import "../../mobile/InstructorsPage/Content.css";
import { InstructorProps  } from "../../Global/InstructorsPage/Instructors";

const Content:React.FC<{instructors: InstructorProps[]}> = ({ instructors }) =>{
    //const handleLiked = (index: Number) => instructorsArray[index as number].liked = true
    return(
        <div className="instructors-cards-container">
            {/* {
                instructors.map((instructor, index) =>(
                    <Card 
                        key={index}
                        type="tutor"
                        name={instructor.name}
                       // languages={instructor.languages}
                        rating={instructor.rating}
                        availableAfter={instructor.availableAfter}
                       // expertise={instructor.expertise}
                        status={instructor.status}
                        description={instructor.description}
                        thumb={instructor.thumb}
                        handleLiked={() => {}}
                    />
                ))
            } */}
        </div>
    )
}

export default Content;


import React, { useEffect, useRef, useState } from "react";
import "../../layout/StudentVideoChat/Header.css";
import { Image } from "react-bootstrap";

import uk from "../../assets/student-video-chat/uk.png";
import clock from "../../assets/student-video-chat/clock.png";
import Countdown, { zeroPad } from "react-countdown";

interface HeaderProps {
    name: String;
    duration: number;
    isStopCalled: boolean;
    timeComplete: any;
    isMeetingStarted: boolean,
}

const Header: React.FC<HeaderProps> = ({ name, duration, isStopCalled = false, timeComplete, isMeetingStarted }) => {
    const clockRef = useRef<any>();

    const [endTime, setEndTime] = useState(Date.now() + duration * 60 * 1000);

    useEffect(() => {
        if (isStopCalled) {
            clockRef?.current?.pause();
        }
    }, [isStopCalled]);

    return (
        <div className="student-video-chat-header">
            <h4 className="student-video-chat-header-name">{name}</h4>
            {/* {!countryCode &&
                <Image
                    src={countryCode}
                    height={14}
                    width={23}
                />} */}

            {
                isMeetingStarted &&
                <div className="student-video-chat-time-wrapper">
                    <Image src={clock} />
                    <Countdown
                        ref={clockRef}
                        date={endTime}
                        renderer={(props) => (
                            <div>
                                <h4 className="student-video-chat-time">
                                    {zeroPad(props.hours)}:{zeroPad(props.minutes)}:{zeroPad(props.seconds)}
                                </h4>
                            </div>
                        )}
                        onComplete={timeComplete}
                    />
                </div>
            }

        </div>
    )
}

export default Header;


import React, { useEffect, useState }  from 'react';
import HistoryRecord from './HistoryRecord';
import { Modal, Image, Tabs, Tab } from 'react-bootstrap';
import MeetingAssessment from './MeetingAssessment';
import "../../../layout/HistoryModal/HistoryModal.css";
import NoHistoryRecord from './NoHistoryRecord';
import { useTranslation } from 'react-i18next';

interface HistoryDetailsModalInterface {
    userId : string;
    userRole : number;
    meetingId : string;
    videoUrl : string;
    assessment:any;
    attendeeName : string;
    setArchiveLink:any;
}
const HistoryDetailsSubContent: React.FC<HistoryDetailsModalInterface> = ({ 
    userId,
    meetingId,
    userRole,
    videoUrl,
    assessment,
    attendeeName,
    setArchiveLink
}
) => {
    const { t } = useTranslation();

    return (
        <div className="historyTab">
                    <Tabs id="uncontrolled-tab-example" defaultActiveKey={videoUrl ? "GörüşmeKaydı" : "Puanlarım"}>
                        {videoUrl && 
                        <Tab eventKey="GörüşmeKaydı" title ={ t('history.converstation_detail') }>
                           <HistoryRecord setArchiveLink={setArchiveLink} attendeeName = {attendeeName}  videoUrl = {videoUrl} meetingId = {meetingId} userRole = {userRole}/> 
                        </Tab>
                        }
                        
                        <Tab eventKey="Puanlarım" title={ t('history.converstation_assessment') }>
                            <MeetingAssessment assessment = {assessment} meetingId = {meetingId} userRole = {userRole} />
                        </Tab>
                    </Tabs>
                </div>
    )
}

export default HistoryDetailsSubContent;

import React from "react";
import "../../layout/UpdatePaymentMethode/UpdatePaymentMethode.css";
import Page from "../../components/pages";
import { RouteComponentProps } from "react-router-dom";
import Content from "./Content";

const UpdatePaymentMethode:React.FC<RouteComponentProps> = ({ history }) =>{
    return(
        <Page 
            type="regular" 
            history={history} 
            headerTitle="Update Payment Method"
        >
            <Content history={history} />
        </Page>
    )
}

export default UpdatePaymentMethode;


import React, { useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import './App.css';
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import EmailVerification from "./pages/EmailVerification/EmailVerification";
import EmailVerificationResultPage from "./pages/EmailVerification/EmailVerificationResultPage";
import ChoosePackages from "./pages/ChoosePackage/ChoosePackage";
import Payment from "./pages/Payment/Payment";
import InstructorsSupport from "./pages/Support/Instructors/Support";
import StudentsSupport from "./pages/Support/Student/Support";
import InstructorsQuestions from "./pages/Support/Instructors/Questions";
// import CreateReservation from "./pages/Reservation/CreateReservation";
import Privacy from "./pages/Privacy/Privacy";
import { useHistory } from "react-router";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import StudentProfileSettings from "./pages/StudentProfileSettings/StudentProfileSettings";
import StudentPreferences from "./pages/StudentPreferences/StudentPreferances";
import TutorPreferences from "./pages/TutorPreferences/TutorPreferences";
import SubscriptionDetails from "./pages/SubscriptionDetails/SubscriptionDetailsPage";
import Trainings from "./pages/Trainings/Trainings";
import UpgradeSubscription from "./pages/UpgradeSubscription/UpgradeSubscription";
import UpdatePaymentMethode from "./pages/UpdatePaymentMethod/UpdatePaymentMethod";
//import StudentVideoChat from "./pages/StudentVideoChat/StudentVideoChat";
import Preview from "./pages/Preview";
import Preview1 from "./pages/Preview1";
import Preview2 from "./pages/Preview2";
import Preview3 from "./pages/Preview3";
import Preview4 from "./pages/Preview4";
import Preview5 from "./pages/Preview5";
import PreviewChat from "./pages/PreviewChat";
import InstructorsPage from "./pages/InstructorsPage/InstructorsPage";
import MakeReservation from "./pages/CreateReservation/MakeReservation";
import StudentDashboardPage from "./pages/StudentDashboardPage/StudentDashboardPage";
import ExistingReservations from "./pages/ExistingReservations/ExistingReservations";
import HistoryPage from "./pages/HistoryList/HistoryPage";
import InstructorProfileSettings from "./pages/TutorProfileSettings/InstructorProfileSettings";
import InstructorChangePassword from "./pages/InstructorChangePassword/InstructorChangePassword";
import InstructorNotificationsSettings from "./pages/InstructorNotificationsSettings/InstructorNotificationsSettings";
import InstructorPaymentSettings from "./pages/InstructorPaymentSettings/IntsructorPaymentSettings";
import TutorPayment from "./pages/TutorPayment/TutorPaymentPage";
import InstructorReservations from "./pages/ReservationList/InstructorReservations";
import PrivateRoute from "./components/router/PrivateRoute"
import { connection, Init } from "./Global/Utilities/signalr";
import { ROLES } from "./Global/Constants/constants";
import TutorDashboardPage from './pages/TutorDashboard/TutorDashboardPage';
import { usePromiseTracker } from "react-promise-tracker";
import Spinner from './components/others/Spinner';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import AdminMainPage from './pages/Admin/Main/AdminMainPage';
import LoginRoute from './components/router/LoginRoute';
import ApplicationPage from './pages/ApplicationPage/ApplicationPage';
import TagManager from 'react-gtm-module'
import PaymentConfirmationPage from './pages/Payment/PaymentConfirmationPage';
import HomeV2_Kids from './pages/Home/V2/HomeV2_Kids';
import HomeV2 from './pages/Home/V2/HomeV2';
import HomeV2_Tutor from './pages/Home/V2/HomeV2_Tutor';
import HomeV2_Corporate from './pages/Home/V2/HomeV2_Corporate';
import HomeV2_Career from './pages/Home/V2/HomeV2_Career';
import ScrollToTop from './components/ScrollToTop';
import ReportEditPage from "./pages/Report/Edit/ReportEditPage";
import ReportViewPage from "./pages/Report/View/ReportViewPage";
import StudentVideoChat from "./pages/StudentVideoChat/StudentVideoChat";
import FileUploadPage from "./pages/FileUpload/FileUploadPage";


export var Lang: any;

const tagManagerArgs = {
  gtmId: 'GTM-K6P39TN',
  dataLayerName: 'PageDataLayer'
}

TagManager.initialize(tagManagerArgs)

function App(prop: any) {

  // const { t, i18n } = useTranslation();

  const { t, i18n } = useTranslation();
  Lang = t;

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {

    if (prop.auth && prop.auth?.isLogged) {
      Init(dispatch);
    }

    //console.log("useEffect -- App - Girdi")
    // if (prop.auth.user?.role === ROLES.TEACHER) {
    //   i18n.changeLanguage('en-US');
    //   moment.locale("en-US");
    // }
    // else {
    //   i18n.changeLanguage('tr');
    //   moment.locale("tr");
    // }
  }, [prop.auth]);

  const { promiseInProgress } = usePromiseTracker();

  const locale = navigator.language;
  useEffect(() => {
      // i18n.changeLanguage(locale);
      // moment.locale(locale);
      // console.log("App Here ", locale)
  }, []);

  return (
    <div className="app">

      {promiseInProgress && <Spinner />}

      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={HomeV2} />
          <PrivateRoute path="/change-password" component={ChangePassword} />
          <LoginRoute exact path="/login" component={Login} />
          <Route exact path="/forget-password" component={ForgetPassword} />
          <Route exact path="/applications" component={ApplicationPage} />
          <Route exact path="/register" component={Signup} />
          <Route exact path="/tutor/register" component={Signup} />
          <Route exact path="/email" component={EmailVerification} />
          <Route exact path="/email-verification" component={EmailVerificationResultPage} />
          <Route exact path="/packages" component={ChoosePackages} />
          <PrivateRoute exact path="/payment" component={Payment} />
          <Route exact path="/support/tutor" component={InstructorsSupport} />
          <Route exact path="/support/student" component={StudentsSupport} />
          <Route exact path="/questions/tutor" component={InstructorsQuestions} />
          <Route exact path="/questions/student" component={InstructorsQuestions} />
          <Route exact path="/policy/privacy" component={Privacy} />
          <Route exact path="/policy/usage" component={Privacy} />
          <Route exact path="/policy/cookies" component={Privacy} />
          <Route exact path="/report/edit" component={ReportEditPage} />
          <Route exact path="/report/view" component={ReportViewPage} />
          <PrivateRoute role={ROLES.STUDENT} exact path="/reservations/create" component={MakeReservation} />
          {/* <Route exact path="/password/change" component={ChangePassword} /> */}
          <PrivateRoute role={ROLES.STUDENT} exact path="/student/profile/settings" component={StudentProfileSettings} />
          <PrivateRoute role={ROLES.STUDENT} exact path="/student/profile/preferences" component={StudentPreferences} />
          <PrivateRoute role={ROLES.TEACHER} exact path="/tutor/profile/preferences" component={TutorPreferences} />
          <PrivateRoute role={ROLES.STUDENT} exact path="/subscription/details" component={SubscriptionDetails} />
          <PrivateRoute exact path="/trainings" component={Trainings} />
          <PrivateRoute exact path="/subscription/upgrade" component={UpgradeSubscription} />
          <PrivateRoute exact path="/payment/update" component={UpdatePaymentMethode} />
          <PrivateRoute exact path="/payment/confirmation" component={PaymentConfirmationPage} />
          <PrivateRoute exact path="/conversation/video" component={StudentVideoChat} />
          {/* <Route exact path="/test/video" component={StudentVideoChat} /> */}
          <PrivateRoute role={ROLES.TEACHER} exact path="/files" component={FileUploadPage} />

           <Route exact path="/preview" component={StudentVideoChat} />
          <Route exact path="/preview1" component={Preview1} />
          {/*<Route exact path="/preview2" component={Preview2} />*/}
          {/* <Route exact path="/homev2" component={HomeV2} /> */}
          <Route exact path="/v2" component={HomeV2} />
          <Route exact path="/kids" component={HomeV2_Kids} />
          <Route exact path="/tutor" component={HomeV2_Tutor} />
          <Route exact path="/corporate" component={HomeV2_Corporate} />
          <Route exact path="/career" component={HomeV2_Career} />
          <Route exact path="/preview4" component={Preview4} />
          <Route exact path="/preview5" component={Preview5} />
          <Route exact path="/chat" component={PreviewChat} />
          <PrivateRoute exact path="/student/search/instructors" component={InstructorsPage} />
          {/* <Route exact path="/student/reservation/new" component={MakeReservation} /> */}
          <PrivateRoute exact path="/student/dashboard" role={ROLES.STUDENT} component={StudentDashboardPage} />
          {/* <Route exact path="/student/reservations" component={ExistingReservations} /> */}
          <PrivateRoute exact path="/history" component={HistoryPage} />

          <PrivateRoute role={ROLES.TEACHER} exact path="/tutor/profile/settings" component={InstructorProfileSettings} />
          <PrivateRoute role={ROLES.ADMIN} exact path="/admin/dashboard" component={AdminMainPage} />

          {/* <PrivateRoute exact path="/tutor/password/change" component={InstructorChangePassword} /> */}
          <PrivateRoute role={ROLES.TEACHER} exact path="/tutor/notifications/settings" component={InstructorNotificationsSettings} />
          <PrivateRoute role={ROLES.TEACHER} exact path="/tutor/payment" component={TutorPayment} />
          <PrivateRoute exact path="/reservations" component={InstructorReservations} />
          <PrivateRoute role={ROLES.TEACHER} exact path="/tutor/dashboard" component={TutorDashboardPage} />
        </Switch>
      </Router>
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    auth: state.AuthReducer,
  }
}
export default connect(mapStateToProps)(App);

import moment from 'moment';
import getUserLocale from 'get-user-locale';

// moment.locale(getUserLocale());


moment.updateLocale('tr', {
    months : 'Ocak_Şubat_Mart_Nisan_Mayıs_Haziran_Temmuz_Ağustos_Eylül_Ekim_Kasım_Aralık'.split('_'),
    weekdays : 'Pazar_Pazartesi_Salı_Çarşamba_Perşembe_Cuma_Cumartesi'.split('_'),
    weekdaysShort : 'Pzr._Pzt._Sa._Çarş._Perş._Cm._Cmt.'.split('_'),
    weekdaysMin : 'Pzr_Pzt_Sa_Çrş_Prş_Cm_Cmt'.split('_'),
    week : {
        dow : 1, // Monday is the first day of the week.
    }
})
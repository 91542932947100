import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import "../../../layout/EmailVerification/Form.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Container, Form } from "react-bootstrap";
import "../../../mobile/EmailVerfication/Form.css";
import { MeetingFinishedIcon } from "../../../assets/SVG/meetingFinished";
import { NoReservationFoundIcon } from "../../../assets/SVG/SVG";
import Button from "../../../components/buttons";

import dots from "../../../assets/instructor-reservations/dots.png";
import {CONVERSATION_URL, IDENTITY_URL} from "../../../Global/Constants/constants";
import { ReturnProps } from "../../../@types/functions";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import {useTranslation} from "react-i18next";
import ReportEditComponent from "./ReportEditComponent";

const ReportEdit: React.FC<any> = ({ history, reportId}) => {
    const { t } = useTranslation();
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const [reports, setReports] = useState<any>(null);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        getReportDetails();
    }, [reportId]);


    const onSubmit = async (param: any) => {

        const res = await trackPromise(axios.post<any, ReturnProps>(CONVERSATION_URL + "/api/v1/report/update", param));

        if (!res.success) {
            setError(res.error as string);
            setShowError(true);
            return;
        }
        setIsSuccess(true);
        await getReportDetails();
        console.log("onSubmit");
    };

    const getReportDetails = async () => {
        if (!reportId) { return null; }

        const res = await trackPromise(axios.get<any, ReturnProps>(CONVERSATION_URL + "/api/v1/report/" + reportId));

        // Handling Error
        if (!res.success) {
            setIsSuccess(false);
            return null;
        }
        setReports(res.data);
        setIsSuccess(true);
    }
    return (
        <React.Fragment>
            <div className="report-edit-container">
                    {
                        reports && reports.map((res: any, index: any) => (
                        <ReportEditComponent
                            key={res.guid}
                            report={res}
                            onSubmitRef={onSubmit}
                        /> ))
                    }

            </div>
        </React.Fragment>
    )
}

export default ReportEdit;

import React from "react";
import "../../layout/Trainings/Trainings.css";
import { RouteComponentProps } from "react-router-dom";
import { Image } from "react-bootstrap";
import { trainingCards } from "../../Global/Trainings/Trainings";
import "../../tablet/Trainings/Trainings.css";
import "../../mobile/Trainings/Trainings.css";
import Page from "../../components/pages";

const Trainings:React.FC<RouteComponentProps> = ({ history }) =>{
    return (
        <Page type="regular" history={history} headerTitle="Eğitimler">
            <div className="training-cat-wrapper">
                <div className="training-cat-container">
                    {
                        trainingCards.map((card, index) =>(
                            <div className="training-category-card">
                                <div className="training-category-card-container">
                                    <h4 
                                        className={`
                                            training-category-card-title 
                                            ${(index === 2) && "training-category-card-title-third"}
                                        `}>
                                            {card.title}
                                        </h4>
                                    <Image src={card.img} />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </Page>
    )
}

export default Trainings;


import React from "react";
import "../../../layout/EmailVerification/EmailVerification.css";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/navbars";
// @ts-ignore
import queryString from "query-string"
import Page from "../../../components/pages";
import TopBar from "../../HistoryList/TopBar";
import Content from "../../HistoryList/Content";
import ReportView from "./ReportView";

const ReportViewPage:React.FC<any> = ({ history, location }) =>{
    const value = queryString.parse(location.search);
    const reportId = value?.report;
    console.log("reportId => ",reportId);

    return (

        <Page
            type="no-login"
            history={history}
            // headerTitle= {t("history.history")}
        >
            <ReportView reportId={reportId} history={history} />
        </Page>
    )
}

export default ReportViewPage;

import React, { useState, useEffect } from "react";
import { History } from "history";
import { Navbar, Image } from "react-bootstrap";
import theme from "../../theme";
import {
    DashboardNavBarContainer,
    DashboardNavBarWrapper} from "./StyledComponents";
import "../../layout/navbar/NavBarBurgerMenu.css";
import styled, { css } from "styled-components";

import logo from "../../assets/navbar/white-logo.png";
import logoDark from "../../assets/navbar/logo.png";
import burgerMenu from "../../assets/navbar/burger-menu.png";
import burgerMenuWhite from "../../assets/navbar/burger-menu-white.png";
import { connect } from "react-redux";

interface VideoMeetingNavBarProps {
    history: History,
    navTheme?: "normal" | "dark",
    onClickExpand?: () => any,
}


const ExtraMobileLinks = styled.div`
    display: none;
    @media only screen and (max-width: 1024px) {
        display: block;
        width: 80%;
        transform: translateX(6%);
        margin-right: auto;
    }
`;

const ExtraMobileLink = styled.p`
    margin: 0;
    padding: 0;
    text-align: center;
    color: ${theme.colors.white} !important;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    font-family: ${theme.text.fonts.poppins};
    margin-right: 50px;
    transform: translateX(-15px);
    ${({ id }) => {
        if (id === "dark") return css`
                color: ${theme.colors.dark} !important;
            `;
    }
    }
    &:hover {
        color: ${theme.colors.primaryShade} !important;
    }
    @media only screen and (max-width: 960px) {
        width: 90vw;
        text-align: center;
    }
    margin-top: 15px;
`;

const VideoMeetingNavBar: React.FC<any> = ({
    history,
    auth,
    chatProp,
    navTheme,
    onClickExpand
}) => {

    const [logoImg, setLogoImg] = useState(logo)
    const handleLogoImg = () => {
        if (navTheme === "dark") return setLogoImg(logoDark)
        return logoImg
    }


    useEffect(() => {
        handleLogoImg();
    }, [])

    return (
        <React.Fragment>
            <DashboardNavBarContainer collapseOnSelect expand="lg" dark={(navTheme === "dark")}>
                <DashboardNavBarWrapper>
                    <Navbar.Brand>
                        <Image src={logoImg} />
                    </Navbar.Brand>
                    {/* <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                        className="main-nav-bar-burger-menu"
                        style={{ marginTop: -10 }}
                    >
                        {
                            (navTheme === "dark") ? (
                                <Image
                                    src={burgerMenu}
                                    onClick={onClickExpand}
                                />
                            ) : (
                                <Image
                                    src={burgerMenuWhite}
                                    onClick={onClickExpand}
                                />
                            )
                        }
                    </Navbar.Toggle> */}
                    
                </DashboardNavBarWrapper>
            </DashboardNavBarContainer>

        </React.Fragment>
    )
}

function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer,
    }
}

export default connect(mapStateToProps)(VideoMeetingNavBar);
import React, { useEffect } from "react";
import "../../layout/Home/Home.css";
import Application from "./Application";
import Footer from "../../components/Footer";
// import "../../animation/Home/animation.css";
// import { controlAnimation } from "../../animation/Home/animation";
import NavBar from "../../components/navbars";


const ApplicationPage: React.FC<any> = ({ history }) => {
    useEffect(() => {
      //  controlAnimation()
    }, [])
    return (
        <div className="home">
            <NavBar type="main" history={history} links={[["Paket Satın Al", "/packages"]]} />
            <Application />
            <Footer />
        </div>
    )
}

export default ApplicationPage;

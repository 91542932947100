import styled,{ css } from "styled-components"
import theme from "../../theme";
import { Form } from "react-bootstrap";
import check from "../../assets/preferences/check.png";

const CheckBox = styled(Form.Check)`
    text-align: left;
    color: #45494D;
    ${
        ({ labelColor }) =>{
            if(labelColor) return css`
                color: ${labelColor};
            `;
        }
    }
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 30px;
    display: flex;
    ${
        ({ singleLine }) =>{
            if(!singleLine) return css`
                align-items: flex-start;
            `;
            else return css`
                align-items: center;
            `;
        }
    }
    & label {
        margin-left: 20px;
        ${
            ({ singleLine }) =>{
                if(!singleLine) return css`
                    margin-top: 3px;
                `;
            }
        }
        font-size: 16px;
    }
    & input[type="checkbox"] {
        appearance: none;
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        background-color: #FFFCFC;
        border: 1px solid #CACCCF;
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    & input[type="checkbox"]::after {
        content: url(${check});
        height: 24px;
        width: 24px;
        font-weight: 900;
        display: none;
        justify-content: center;
        align-items: center;
        color: #fff;
        position: relative;
        top: 65%;
        left: 60%;
        transform: translate(-50%,-50%);
    }
    & input[type="checkbox"]:active,
    & input[type="checkbox"]:focus {
        outline: none;
        box-shadow: none;
    }
    & input[type="checkbox"]:checked {
        outline: none;
        box-shadow: none;
        background-color: ${theme.colors.primary};
        border-color: ${theme.colors.primary};
    }
    & input[type="checkbox"]:checked::after {
        display: flex;
    }
`;

export default CheckBox;

import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import Datetime from "react-datetime";
import moment from 'moment';
import { makeTimeSlot, todayStr } from '../../../utils/helper';
import { deleteIcon } from '../../../assets/SVG/SVG';
import { CONVERSATION_URL } from "../../../Global/Constants/constants";
import { ReturnProps } from "../../../@types/functions";
import axios from "axios";
import { trackPromise } from 'react-promise-tracker';
import AlertInfo from '../../alerts/AlertInfo';
import { useTranslation } from "react-i18next";


interface QuickAvailabilityModalInterface {
    existingTimeSlots: any[]
    show: Boolean;
    hide: () => void;
    update: () => void;
}
const QuickAvailabilityModal: React.FC<QuickAvailabilityModalInterface> = ({
    existingTimeSlots,
    show,
    hide,
    update
}) => {
    const { t } = useTranslation();

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [deletedItemGuids, setDeletedItemGuids] = useState<any>([]);

    //console.log("Deleted  +" + deletedItemGuids);

    const [date, setDate] = useState(moment());
    const [allTimeSlot, setAllTimeSlot] = useState<any>([]);
    const [fromSlot, setFromSlot] = useState('00:00');
    const [toSlot, setToSlot] = useState('01:00');
    const handleAddTimeSlot = () => {
        console.log("handleAddTimeSlot");
        const startDate = moment(todayStr(moment(date)) + `T${fromSlot}:00`);
        const endDate = moment(todayStr(moment(date)) + `T${toSlot}:00`);

        if(moment().isAfter(startDate)  )
        {
            setError("Start Time must be after Now");
            setShowError(true);
            return;
        }

        if (startDate.isBefore(endDate)) {
            setAllTimeSlot([...allTimeSlot, {
                // from: fromSlot,
                // to: toSlot,
                id: null,
                startDate: startDate,
                endDate: endDate
            }]);
        }
        else {
            setError("End Time must be after than Start Time");
            setShowError(true);
        }
    }
    const SendAvailableTimesToServer = async () => {

        var insertedItems = allTimeSlot?.filter((x: any) => !x.id);
        let flag = false;
        let response: any = {};
        if (deletedItemGuids && deletedItemGuids.length > 0) {
            flag = true;
            response.DeletedItems = [];
            deletedItemGuids.map((itm: any) => {
                response.DeletedItems.push(itm);
            })
        }
        if (insertedItems && insertedItems.length > 0) {
            flag = true;
            response.InsertedItems = [];
            insertedItems.map((itm: any) => {
                response.InsertedItems.push({ StartDate: moment(itm.startDate).unix(), EndDate: moment(itm.endDate).unix() });
            })
        }

        if (!flag) {
            hide();
            return;
        }
        //console.log("Load Res  ");
        const res = await trackPromise(axios.post<any, ReturnProps>(CONVERSATION_URL + "/api/v1/available-dates/bulk", response));
        if (!res.success) {
            //   setError(res.error as string)
            return null
        }

        hide();
        update();
    }

    useEffect(() => {
        var newArray: any[] = [];
        existingTimeSlots.map((itm: any) => {
            newArray.push({
                id: itm.id,
                startDate: itm.start,
                endDate: itm.end
            });
        })
        setAllTimeSlot(newArray);
    }, [show]);

    var yesterday = moment().subtract(1, 'day');
    var valid = function (current: any) {
        return current.isAfter(yesterday);
    };

    return (
        <React.Fragment>

            <Modal show={show} onHide={hide} className="quickReservation">
                <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
                <Modal.Header closeButton>
                    <Modal.Title>{t("dashboard.set_availability")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Datetime
                        className="date-picker"
                        timeFormat={false}
                        dateFormat="DD.MM.YYYY, dddd"
                        locale="en"
                        inputProps={{ placeholder: "Select date" }}
                        isValidDate={valid}
                        onChange={(d) => setDate(moment(d))}
                        value={date}
                        closeOnSelect={true}
                    />
                    <div className="d-flex justify-content-between res-action-wrapper">
                        <div className="button-wrapper">
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Control as="select" onChange={e => setFromSlot(e.target.value)}>
                                    {
                                        makeTimeSlot({
                                            slotInterval: 15,
                                            openTime: 0,
                                            closeTime: '00:00'
                                        }).map((itm, key) => (
                                            <option key={"dropdownFromTimeSlot" + key}>{itm.split('-')[0]}</option>
                                        ))
                                    }

                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div className="button-wrapper">
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Control as="select" onChange={e => setToSlot(e.target.value)}>
                                    {
                                        makeTimeSlot({
                                            slotInterval: 15,
                                            openTime: 0,
                                            closeTime: '00:00'
                                        }).map((itm, key) => (
                                            <option key={"dropdownToTimeSlot" + key}>{itm.split('-')[1]}</option>
                                        ))
                                    }
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div className="button-wrapper">
                            <Button onClick={handleAddTimeSlot} variant="danger">{t("general.add")}</Button>
                        </div>

                    </div>

                    <div className="booked-time">
                        <h5>Dates</h5>
                        {
                            allTimeSlot.filter((x: any) => moment(x.startDate).utc() > moment().utc()).map((itm: any, key: number) => (
                                <div key={key} className="time-slot-for-available-times">

                                    <span className="time">{moment(itm.startDate).locale('en').format("DD.MM.YYYY, dddd / HH:mm") + " - " + moment(itm.endDate).format("HH:mm")}</span>
                                    <span className="quickbook" onClick={() => {
                                        const array = [...allTimeSlot];
                                        const removedItems = array.splice(key, 1);
                                        if (removedItems[0].id) {
                                            const a = [...deletedItemGuids];
                                            a.push(removedItems[0].id);
                                            setDeletedItemGuids(a);
                                        }
                                        setAllTimeSlot(array);

                                    }}>{deleteIcon}</span>

                                </div>
                            ))
                        }
                    </div>
                    <div className="res-action-button mt-4">
                        <Button variant="primary" block onClick={() => SendAvailableTimesToServer()}>
                            Approve
                        </Button>
                        <Button onClick={hide} variant="light" block>Cancel</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
export default QuickAvailabilityModal;
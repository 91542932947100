
export const getCroppedImg =  (image:any, crop:any, fileName:any) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
  
    ctx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
    return canvas;
}
export const getImageFromBase64=(image:any) =>
{
    return new Promise (function (resolved, rejected) {
        var i = new Image()
        i.onload = function(){
          resolved(i)
        };
        i.src = image
      })
}
  
    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');
  
    // As a blob
    // return new Promise((resolve, reject) => {
    //     canvas.toBlob(blob => {
    //       resolve(blob);
    //     }, 'image/jpeg', 1);
    //   });

  
import axios from "axios";
import { disconnectConnection } from "../Global/Utilities/signalr";

const initialState = {
    isLoggedOut: false,
}

interface ActionProps {
    type: String,
    payload: any
}

export default function (state = initialState, action: ActionProps) {
    switch (action.type) {
        case "USER_LOGGED_OUT":
            //console.log("Logged Out Reducer")
            //  disconnectConnection();
            if (action.payload) {
                localStorage.removeItem("speakingline_user");
                localStorage.removeItem("speakingline_access_token");
                axios.defaults.headers.common['Authorization'] = "";
            }
            return state = { ...state, isLoggedOut: action.payload }
        default:
            return state
    }
}

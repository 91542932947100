import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown'
import "../../layout/InstructorsPage/TopBar.css";
import Input from "../../components/inputs";
import theme from "../../theme";
import Button from "../../components/StatusButton";
import "../../mobile/InstructorsPage/TopBar.css";
import "../../tablet/InstructorsPage/TopBar.css";

const TopBar: React.FC<{ handleStatus: (status: "active" | "inactive" | "bussy" | "" | "favourite") => void }> = ({ handleStatus }) => {
    const [selectedStatus, setSelectedStatus] = useState({ label: "All Tutors", status: "all" });
    const [selectedLessonStyle, setSelectedLessonStyle] = useState({
        conversationPractice: false,
        grammarLesson: false,
        kidsLesson: false,
        businessEnglish: false
    });
    const handleChangeLessonStyle = (e: any) => {
        setSelectedLessonStyle({ ...selectedLessonStyle, [e.target.value]: e.target.checked })
    }
    const onClearAllChecked = () => [
        setSelectedLessonStyle({ ...selectedLessonStyle, conversationPractice: false, grammarLesson: false, kidsLesson: false, businessEnglish: false })
    ]
    return (
        <>
            <div className="top-bar-input">
                <div className="top-bar-status-btn-container">
                    <div className="top-bar-status-btns">
                        {/* <Button
                            status="selected"
                            onClick={() => handleStatus("")}
                        >
                            Tümü
                        </Button>

 */}

                        <Dropdown className="staus-wrapper">
                            <Dropdown.Toggle className="sc-fmlJLJ kPQdBY p-0">
                                <span className={"circle " + selectedStatus.status}></span>
                                {selectedStatus.label}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="custom-dropdown">
                                <div className="status-wrapper" onClick={() => setSelectedStatus({ label: "All Tutors", status: "all" })}>
                                    <span className="circle all"></span>
                                    All Tutors
                                </div>
                                <div className="status-wrapper" onClick={() => setSelectedStatus({ label: "Online", status: "online" })}>
                                    <span className="circle  online"></span>
                                    Online
                                </div>
                                <div className="status-wrapper" onClick={() => setSelectedStatus({ label: "Busy", status: "busy" })}>
                                    <span className="circle  busy"></span>
                                    Busy
                                </div>
                                <div className="status-wrapper" onClick={() => setSelectedStatus({ label: "Offline", status: "offline" })}>
                                    <span className="circle  offline"></span>
                                    Offline
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>



                        <Button
                            status="favourite"
                            onClick={() => handleStatus("favourite")}
                        >
                            Favorilerim
                        </Button>
                        <div className="student-instructors-page-sep" />

                        <Dropdown>

                            <Dropdown.Toggle className="sc-fmlJLJ kPQdBY">
                                Lesson Style
                        </Dropdown.Toggle>

                            <Dropdown.Menu className="custom-dropdown">
                                <div className="checkbox-wrapper">
                                    <input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="conversationPractice" checked={selectedLessonStyle.conversationPractice} onClick={handleChangeLessonStyle} />
                                    <label htmlFor="styled-checkbox-1">Conversation Practice</label>
                                </div>
                                <div className="checkbox-wrapper">
                                    <input className="styled-checkbox" id="styled-checkbox-2" type="checkbox" value="grammarLesson" checked={selectedLessonStyle.grammarLesson} onClick={handleChangeLessonStyle} />
                                    <label htmlFor="styled-checkbox-2">Grammar Lesson</label>
                                </div>
                                <div className="checkbox-wrapper">
                                    <input className="styled-checkbox" id="styled-checkbox-3" type="checkbox" value="kidsLesson" checked={selectedLessonStyle.kidsLesson} onClick={handleChangeLessonStyle} />
                                    <label htmlFor="styled-checkbox-3">Kids Lesson</label>
                                </div>
                                <div className="checkbox-wrapper">
                                    <input className="styled-checkbox" id="styled-checkbox-4" type="checkbox" value="businessEnglish" checked={selectedLessonStyle.businessEnglish} onClick={handleChangeLessonStyle} />
                                    <label htmlFor="styled-checkbox-4">Business English</label>
                                </div>

                                <div className="clearFilter" onClick={onClearAllChecked}>
                                    <div className="cross">
                                        x
                                    </div>
                                     SEÇİMİ TEMİZLE
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button
                            status="none"
                            onClick={() => handleStatus("favourite")}
                        >
                            Tutor Accent
                        </Button>
                    </div>
                </div>
                <Input
                    type="icon"
                    icon={theme.icons.search}
                    placeholder="Eğitmen adı ile ara..."
                    className="instructors-search-bar"
                    focusColor="#fff"
                />
            </div>
        </>
    )
}

export default TopBar;

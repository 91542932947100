import React, { useState } from "react";
import "../../../layout/HomeV2/New_HowItWorks.css";
import { Row, Col, Image } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { cards } from "../../../Global/WhySpeakingline";
import "../../../tablet/Home/WhySpeakingLine.css";
import "../../../mobile/Home/WhySpeakingLine.css";
import Container from "../../../components/Container";
import { useTranslation } from "react-i18next";
import img0 from "../../../assets/Home/how-it-works-frame.png";
import img1 from "../../../assets/Home/how-it-works-frame-1.png";
import img2 from "../../../assets/Home/how-it-works-frame-2.png";
import img3 from "../../../assets/Home/how-it-works-frame-3.png";

const HowItWorks = () => {
    const { t } = useTranslation();
    const [selectedIndex, setSelectedIndex] = useState(0);
    var imgList = [img0, img1, img2, img3];

    return (
        <div className="pb-4 new-how-it-works">
            <Container className="new-how-it-works-container-start" id="speaking-line">
                <h2 className="new-how-it-works-title mb-4">{t("home.how_it_works.title")}</h2>
                <div className="new-how-it-works-container">
                    <Row className="new-how-it-works-container-row">
                        <Col className="new-how-it-works-content col-md-5 col-12">
                            <Container className="new-how-it-works-container-start" >
                                <Row onClick={() => { setSelectedIndex(0) }} className="new-hot-it-works-content-item-container">
                                    <Container className={selectedIndex === 0 ? "new-how-it-works-container-item-container-selected" : "new-how-it-works-container-item-container"} >
                                        <p className={selectedIndex === 0 ? "new-how-it-works-container-item-container-item-title-selected" : "new-how-it-works-container-item-container-item-title"}>{t("home.how_it_works.first.title")}</p>
                                        <p className={selectedIndex === 0 ? "new-how-it-works-container-item-container-item-description-selected" : "new-how-it-works-container-item-container-item-description"}>{t("home.how_it_works.first.content")}</p>
                                    </Container>
                                </Row>
                                <Row onClick={() => { setSelectedIndex(1) }}>
                                    <Container className={selectedIndex === 1 ? "new-how-it-works-container-item-container-selected" : "new-how-it-works-container-item-container"} >
                                        <p className={selectedIndex === 1 ? "new-how-it-works-container-item-container-item-title-selected" : "new-how-it-works-container-item-container-item-title"}>{t("home.how_it_works.second.title")}</p>
                                        <p className={selectedIndex === 1 ? "new-how-it-works-container-item-container-item-description-selected" : "new-how-it-works-container-item-container-item-description"}>{t("home.how_it_works.second.content")}</p>
                                    </Container>
                                </Row>
                                <Row onClick={() => { setSelectedIndex(2) }}>
                                    <Container className={selectedIndex === 2 ? "new-how-it-works-container-item-container-selected" : "new-how-it-works-container-item-container"} >
                                        <p className={selectedIndex === 2 ? "new-how-it-works-container-item-container-item-title-selected" : "new-how-it-works-container-item-container-item-title"}>{t("home.how_it_works.third.title")}</p>
                                        <p className={selectedIndex === 2 ? "new-how-it-works-container-item-container-item-description-selected" : "new-how-it-works-container-item-container-item-description"}>{t("home.how_it_works.third.content")}</p>
                                    </Container>
                                </Row>
                                <Row onClick={() => { setSelectedIndex(3) }}>
                                    <Container className={selectedIndex === 3 ? "new-how-it-works-container-item-container-selected" : "new-how-it-works-container-item-container"} >
                                        <p className={selectedIndex === 3 ? "new-how-it-works-container-item-container-item-title-selected" : "new-how-it-works-container-item-container-item-title"}>{t("home.how_it_works.fourth.title")}</p>
                                        <p className={selectedIndex === 3 ? "new-how-it-works-container-item-container-item-description-selected" : "new-how-it-works-container-item-container-item-description"}>{t("home.how_it_works.fourth.content")}</p>
                                    </Container>
                                </Row>
                            </Container>
                        </Col>
                        <Col className="new-how-it-works-image-container col-md-7 col-12">
                            <Image src={imgList[selectedIndex]} className="new-how-does-it-work-img3  new-how-does-it-work-img" />
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default HowItWorks;


import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown'
import "../../layout/TutorPayment/EarningHistory.css";
import Input from "../../components/inputs";
import theme from "../../theme";
import Button from "../../components/StatusButton";
import { NoReservationFoundIcon } from "../../assets/SVG/SVG";
import {useTranslation} from "react-i18next";

const NoFileHistory: React.FC<any> = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div className="payout-table-wrapper">
                <h3>{t("general.file_list")}</h3>
                <div className="history-records-container">
                    <p className="history-records-notfound-text">
                        No record found
                 </p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default NoFileHistory;

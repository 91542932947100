import React from "react";
import Page from "../../components/pages";
import { RouteComponentProps } from "react-router-dom";
import TopBar from "./TopBar";
import Content from "./Content";
import { useTranslation } from "react-i18next";

const HistoryPage:React.FC<RouteComponentProps> = ({ history }) =>{
    const { t } = useTranslation();

    return (
        <Page
            type="regular"
            history={history}
            headerTitle= {t("history.history")}
        >
            <TopBar />
            <Content />
        </Page>
    )
}

export default HistoryPage;

import React, { useState } from "react";
import Page from "../../components/pages";
import { RouteComponentProps } from "react-router-dom";
import { Form, Card } from 'react-bootstrap';
import "../../layout/Tutor/TutorDashboard.css"
import IncomingCallModal from '../../components/modals/meeting/IncomingCallModal';
import { connection, Init, disconnectConnection } from "../../Global/Utilities/signalr"
import TopBar from "./TopBar";
import ScheduleCalendarContent from "./ScheduleCalendarContent";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    HubConnectionBuilder,
    HubConnectionState,
} from '@microsoft/signalr';

const TutorDashboardPage: React.FC<any> = ({ history, auth, socket }) => {
    const { t } = useTranslation();
    const [isActive, setIsActive] = useState(true);
    const dispatch = useDispatch();

    const changeConnection = () => {
        //console.log("changeConnection");
        if (!socket.isConnected)
            Init(dispatch);
        else
            disconnectConnection();
    }

    return (
        <Page
            type="light-regular-page"
            history={history}
            headerTitle=""
            studentDashboard={false}>
            <div className={socket.isConnected ? "read-speak" : "read-speak-offline"}>
                <div className="name-wrapper">{t('dashboard.welcome_back')}, <b>{auth.user.name + " " + auth.user.surname}</b></div>
                <div className={socket.isConnected ? "online-wrapper" : "offline-wrapper"}>
                    <div>{socket.isConnected ? "You are now online! Ready to speak!" : "You are not online. Meetings can not start"}</div>
                    <Form>
                        <Form.Check
                            checked={socket.isConnected}
                            onChange={changeConnection}
                            type="switch"
                            id="custom-switch"
                        />
                    </Form>
                </div>
            </div>
            <TopBar />
            <ScheduleCalendarContent />
        </Page>

    )
}


function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer,
        socket: state.SocketConnectionReducer
    }
}

export default connect(mapStateToProps)(TutorDashboardPage);
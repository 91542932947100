import React from "react";
import "../../layout/InstructorNotificationsSettings/LanguageAndTimeZone.css";
import Input from "../../components/inputs";

const LanguageAndTimeZone = () =>{
    return (
        <div className="instructor-language-and-time-zone-container">
            <h4 className="instructor-language-and-time-zone-title">Dil ve Saat Dilimi</h4>
            <div className="instructor-language-and-time-zone-choices-container">
                <div className="instructor-language-and-time-zone-input-group">
                    <h4 className="instructor-language-and-time-zone-input-label">Dil</h4>
                    <Input 
                        type="select" 
                        options={["Türkiye"]}
                    />
                </div>

                <div className="instructor-language-and-time-zone-input-group">
                    <h4 className="instructor-language-and-time-zone-input-label">Ülke</h4>
                    <Input 
                        type="select" 
                        options={["Türkiye"]}
                    />
                </div>

                <div className="instructor-language-and-time-zone-input-group">
                    <h4 className="instructor-language-and-time-zone-input-label">Saat Dilimi</h4>
                    <Input 
                        type="select" 
                        options={["Türkiye"]}
                    />
                </div>
            </div>
            <hr className="instructor-notifications-settings-sep" />
        </div>
    ) 
}

export default LanguageAndTimeZone;

import React from "react";
import { Image, InputGroup, FormControl } from "react-bootstrap";
import styled from "styled-components";
import theme from "../../theme";
import "../../layout/inputs/inputs.css";

const InputContainer = styled(FormControl)`
  height: 100%;
  border: none;
  font-family: ${theme.text.fonts.poppins} !important;
  font-weight: ${theme.text.weight.light} !important;
  font-size: 16px !important;
  color: #929598 !important;
  padding: 0 0;
  background-color: #FFFCFC;
  word-break: break-word;
  &:focus,
  &:active {
    border-color: #F86F53;
    outline: none;
    box-shadow: none;
  }
`;


interface InputProps {
  name?: string;
  placeholder?: string;
  labelIcon?: any,
  icon?: any;
  value?: string;
  onChange?: (e: any) => void,
  style?: React.CSSProperties,
  disabled?: boolean,
}

const DescriptionInput: React.FC<InputProps> = ({
  name,
  placeholder,
  labelIcon,
  value = "",
  onChange,
  style,
  disabled,
}) => {
  return (
    <>

      <div className="form-group">
        <textarea
          className="form-control"
          id="exampleFormControlTextarea1"
          value={value as string}
          onChange={onChange}
          rows={5}
        >
          <InputContainer
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            style={style}
            disabled={disabled}
            className="input-font-family"
          />
        </textarea>
      </div>
      {/* <InputGroup>

      </InputGroup> */}
    </>
  )
}

export default DescriptionInput;

import React, { useEffect } from "react";
import "../../../layout/Home/Home.css";
import Banner from "./Banner";
import ExpertsInstructors from "./ExpertsInstructors";
import WhySpeakingline from "./../WhySpeakingline";
import HowDoesItWork from "./HowDoesItWork";
import SubscriptionsPackages from "./SubscriptionsPackages";
import StudentsReviews from "./StudentsReviews";
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";
import MobileApplication from "./MobileApplication";
import NewFooter from "../../../components/NewFooter";
import "../../../animation/Home/animation.css";
import { controlAnimation } from "../../../animation/Home/animation";
import NavBar from "../../../components/navbars";
import oval from "../../../assets/oval.png";
import { Container, Image } from "react-bootstrap";
import HowItWorks from "./HowItWorks";
import home_how_img1 from "../../../assets/Home/how-it-works-1.png";
import home_how_img2 from "../../../assets/Home/how-it-works-2.png";
import home_how_img3 from "../../../assets/Home/how-it-works-3.png";
import home_how_img4 from "../../../assets/Home/how-it-works-4.png";
import { questions } from "../../../Global/FrequentlyAskedQuestions";

const HomeV2: React.FC<any> = ({ history }) => {
    useEffect(() => {
        controlAnimation()
    }, []);

    var howDoesItWork = [{ index: 0, title: "home.how_does_it_works.first.title", description: "home.how_does_it_works.first.content", image: home_how_img1 },
    { index: 1, title: "home.how_does_it_works.second.title", description: "home.how_does_it_works.second.content", image: home_how_img3 },
    { index: 2, title: "home.how_does_it_works.third.title", description: "home.how_does_it_works.third.content", image: home_how_img2 },
    { index: 3, title: "home.how_does_it_works.fourth.title", description: "home.how_does_it_works.fourth.content", image: home_how_img4 }
    ];

    return (
        // <Container>
        //     
        <div>
            <div className="home">
                <div className="super_hero">
                    <NavBar type="new_main" history={history} links={[["home.navigation_bar.btn_forkids", "/kids"], ["home.navigation_bar.btn_forbeatutor", "/tutor"], ["home.navigation_bar.btn_forcorp", "/corporate"]]} />
                    <Banner title="home.banner.title" description="home.banner.description" buttonText="home.banner.button_text" butttonAddress="/register" />
                </div>
                <ExpertsInstructors />
                <HowDoesItWork items={howDoesItWork} />
                <HowItWorks />
                <SubscriptionsPackages />
                <StudentsReviews />
                <FrequentlyAskedQuestions questions={questions} />
                <MobileApplication />
                <NewFooter />
            </div >
        </div>
    )
}

export default HomeV2;

import React,{ useEffect, useState } from "react";
import "../../layout/InstructorProfileSettings/Interests.css";
import OptionButton from "../buttons";
import styled,{ css } from "styled-components";
import theme from "../../theme";
import { IDENTITY_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";

const Button = styled(OptionButton)`
    ${
        ({ clicked }) =>{
            if (clicked) return css`
                background-color: ${theme.colors.secondary};
                border-color: ${theme.colors.secondary};
                color: #fff;
            `;
            if(!clicked) return css`
                &:hover {
                    color: ${theme.colors.secondary};   
                    border-color: ${theme.colors.secondary};
                }
            `;
        }
    }
    margin-right: 15px;
    margin-bottom: 15px;
`;


interface ParamTypeProps
{
    type : number;
    selectedParams : any[];
    setSelectedParams : any;
    singleSelection? : boolean;
    maxSelectionCount? : number;
}

const ParamType : React.FC<ParamTypeProps> = ({ type, selectedParams, setSelectedParams, singleSelection = false, maxSelectionCount=3 })  =>{

  //  const [selectedParams, setSelectedParams] = useState<any[]>([])
    const [params, setParams] = useState<any[any]>([])

    const LoadParams = async () => {
        const res = await axios.get<any, ReturnProps>(IDENTITY_URL + "/api/v1/parameters/type/" + type);
        // Handling Error
        if (!res.success || !res.data) {
            //   setError(res.error as string);
            //   setShowError(true)
            //alert(res.error)
            return null;
        }
        setParams(res.data);
    }

    useEffect(() => {
        LoadParams();
    },[]);

    return (

            <div className="instructor-style-options-container">
                {
                    params.map((item : any, index : number) => (
                        <Button 
                            key = {item.guid}
                            type="option"
                            clicked={selectedParams?.find(x=>x.parameter.guid === item.guid)}
                            onClick={() =>{
                                if(selectedParams?.find(x=>x.parameter.guid === item.guid))
                                {
                                    const array = [...selectedParams];
                                    var index = selectedParams.findIndex((x:any) =>x.parameter.guid === item.guid);
                                    var result = array.splice(index,1);
                                    setSelectedParams([ ...array])
                                }
                                else
                                {
                                    const v = {parameter:item}
                                    if(singleSelection)
                                    {
                                        setSelectedParams([v])
                                    }
                                    else if(selectedParams.length < maxSelectionCount)
                                    {
                                        setSelectedParams([ ...selectedParams, v])
                                    }
                                }
                            }}
                        >
                            {item.name}
                        </Button>
                    ))
                }
            </div>
    )
}

export default ParamType;

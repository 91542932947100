import React, {useState,useEffect} from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import ReservationCalendarForTeacher from '../tutorDetailsModal/ReservationCalendarForTeacher'
import "../../../layout/InstructerDetailModal/InstructurDetail.css"
import { privacyRules, privacyRulesForTutor } from '../../../Global/Support/PrivacyRules';
import "../../../layout/Privacy/Privacy.css";
import {useTranslation} from "react-i18next";

interface PolicyModalInterface {
    show: Boolean;
    hide: () => void,
    roleId : number
}
const PolicyModal: React.FC<PolicyModalInterface> = ({
    show,
    hide,
    roleId = 1
}) => {

    const { t } = useTranslation();

    var data: any[] = [];
    var title = "";

    data = (roleId === 1 ? [...privacyRules]:[...privacyRulesForTutor]);
    title = t("general.privacy_policy");
    return (
        <div>
            <Modal show={show} onHide={hide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title> {title} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {
                    data.map(item => (
                        <p className="privacy-policy-paragraph">
                            <p className = "privacy-policy-title"> {item.title} </p>
                            <p className = "privacy-policy-content"> {item.content} </p>
                        </p>
                    ))
                }
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default PolicyModal;
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import "../../../layout/EmailVerification/Form.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Container, Form } from "react-bootstrap";
import "../../../mobile/EmailVerfication/Form.css";
import { MeetingFinishedIcon } from "../../../assets/SVG/meetingFinished";
import { NoReservationFoundIcon } from "../../../assets/SVG/SVG";
import Button from "../../../components/buttons";

import dots from "../../../assets/instructor-reservations/dots.png";
import {CONVERSATION_URL, IDENTITY_URL} from "../../../Global/Constants/constants";
import { ReturnProps } from "../../../@types/functions";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import {useTranslation} from "react-i18next";
import ReportTable from "./ReportTable";
import moment from "moment/moment";

const ReportView: React.FC<any> = ({ history, reportId}) => {
    const { t } = useTranslation();
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const [reports, setReports] = useState<any>(null);
    const [isSuccess, setIsSuccess] = useState(false);

    // state for start and end date
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);


    useEffect(() => {
        getReportDetails();
    }, [reportId]);

    const onSubmit = async (param: any) => {

        const res = await trackPromise(axios.post<any, ReturnProps>(CONVERSATION_URL + "/api/v1/report/update", param));

        if (!res.success) {
            setError(res.error as string);
            setShowError(true);
            return;
        }
        setIsSuccess(true);
        await getReportDetails();
        console.log("onSubmit");
    };

    const getReportDetails = async () => {
        if (!reportId) { return null; }

        const res = await trackPromise(axios.get<any, ReturnProps>(CONVERSATION_URL + "/api/v1/report/" + reportId));

        // Handling Error
        if (!res.success) {
            setIsSuccess(false);
            return null;
        }
        setReports(res.data);
        setIsSuccess(true);

        if(res.data && res.data.length > 0)
        {
            const dateTime = moment(res.data[0].startDate * 1000);
            const date = dateTime.format("DD.MM.YYYY");

            const endTime = moment(res.data[0].endDate * 1000);
            const endDate = endTime.format("DD.MM.YYYY");
            setStartDate(date);
            setEndDate(endDate);
        }

    }
    return (
        <React.Fragment>
            <div className="report-view-container">
                {
                    isSuccess  && reports && reports.length > 0 ? (
                        <div className="report-view-group">
                            <h5 className="report-view-title ">Rapor</h5>
                            <h6 className="report-view-subtitle text-muted mt-4"> {startDate} - {endDate} tarihleri arasındaki raporun detayları aşağıdaki gibidir.</h6>
                            <ReportTable reports={reports}/>
                        </div>
                    ) : (
                        <div className="report-view-group">

                            {/*<h5 className="email-verification-form-title">Error</h5>*/}
                            <h6 className="report-view-subtitle text-muted mt-4">Report Not Found</h6>
                            <div> {NoReservationFoundIcon} </div>
                        </div>
                    )
                }
            </div>
        </React.Fragment>
    )
}

export default ReportView;

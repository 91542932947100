import React, { useEffect, useRef, useState } from "react";
import "../../layout/ProfileSettings/ProfileSettings.css";
// import DashboardPage from "../../components/pages/DashboardPage";
import Page from "../../components/pages";
import { RouteComponentProps } from "react-router-dom";
import { Image, Row, Col, Form, InputGroup } from "react-bootstrap";
import Input from "../../components/inputs";
import Button from "../../components/buttons";
import styled, { css } from "styled-components";
import theme from "../../theme";
import { levels, intrests, goals } from "../../Global/ProfileSettings";
import "../../tablet/ProfileSettings/ProfileSettings.css";
import "..//../mobile/ProfileSettings/ProfileSettings.css";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { IDENTITY_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";

import profilePic from "../../assets/blank_profile.png";
// import profilePic from "../../assets/profile-settings/profilePic.png";
import changePicIcon from "../../assets/profile-settings/changePicIcon.png";
import imgSrc from "../../assets/profile-settings/img.jpg";
import { connect } from "react-redux";
import ParamType from "../../components/others/ParamType";
import { getCroppedImg, getImageFromBase64 } from "../../utils/ImageCrop"
import ImageCropAndUpload from "../../components/others/ImageCropAndUpload";
import { trackPromise } from "react-promise-tracker";
import AlertInfo from "../../components/alerts/AlertInfo";
import { useTranslation } from "react-i18next";
import LanguageChooser from "../../components/LanguageChooser";

const OptionButton = styled(Button)`
    margin-right: 15px;
    float: left;
    margin-bottom: 15px;
    ${({ clicked }) => {
        if (clicked) return css`
                color: ${theme.colors.white};
                background-color: ${theme.colors.secondary};
                border-color: ${theme.colors.secondary};
            `;
        if (!clicked) return css`
                &:hover {
                    border-color: ${theme.colors.secondary};
                    color: ${theme.colors.secondary};
                }
            `;
    }
    }
`;

const StudentProfileSettings: React.FC<any> = ({ history, auth }) => {
    const { t } = useTranslation();

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const [success, setSuccess] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);

    const [userProfile, setUserProfile] = useState<any>();
    const [name, setName] = useState<string>()
    const [surname, setSurname] = useState<string>()
    const [email, setEmail] = useState<string>()

    const [selectedHobbies, setSelectedHobbies] = useState<any[]>([])
    const [selectedPurpose, setSelectedPurpose] = useState<any[]>([])

    const [chosenLevel, setChosenLevel] = useState(0)
    // const [chosenIntrests, setChosenInrests] = useState<Number[]>([]);
    // const [chosenGoals, setChosenGoals] = useState<Number[]>([]);
    const [img, setImg] = useState(profilePic);
    const [prevImg, setPrevImg] = useState(profilePic);
    const [showCropper, setShowCropper] = useState(false);
    // const [crop, setCrop] = useState<any>({
    //     aspect: 1/1,
    //     height: 400,
    //     width: 400
    // })
    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        //console.log("handleImageUploadn");
        if (e.target?.files && e.target?.files?.length > 0) {
            const url = URL.createObjectURL(e.target.files && e.target.files[0])
            setPrevImg(img);
            setImg(url);
            setShowCropper(true);
        }
    }

    // const uploadCroppedImage = async () =>{
    //    // var newImage = await getImageFromBase64(img);
    //     const canvas =  getCroppedImg(imgRef, crop,"Test");
    //     const blob  = await new Promise((resolve, reject) => {
    //              canvas.toBlob(blob => {
    //               resolve(blob);
    //              }, 'image/jpeg', 1);
    //            });

    //     const base64 = canvas.toDataURL('image/jpeg');


    //     var data = new FormData();
    //     data.append('file', (blob as Blob));
    //     const config = {
    //         headers: { 'content-type': 'multipart/form-data' }
    //     }
    //     const res = await axios.post<any, ReturnProps>(IDENTITY_URL + "/api/v1/users/picture", data, config);
    //     if (!res.success) {
    //         //   setError(res.error as string);
    //         //   setShowError(true)
    //         alert(res.error)
    //         return null;
    //     }
    //     setImg(base64);
    //     setPrevImg(base64);
    //     //console.log("Successfull");
    // }

    const onSaveChangesClick = async () => {
        const param: any = {};

        if (JSON.stringify(selectedHobbies) !== JSON.stringify(userProfile?.hobbies)) {
            param.hobbiesGuid = selectedHobbies.map((item: any) => { return item.parameter.guid });
        }

        if (JSON.stringify(selectedPurpose) !== JSON.stringify(userProfile?.usagePurposes)) {
            param.usagePurposesGuid = selectedPurpose.map((item: any) => { return item.parameter.guid });
        }

        if (chosenLevel != userProfile.level) {
            param.LanguageLevel = chosenLevel;
        }
        if (param && Object.keys(param).length > 0) {

            const res = await trackPromise(axios.put<any, ReturnProps>(IDENTITY_URL + "/api/v1/students/profile", param));
            if (!res.success) {
                setError(res.error as string);
                setShowError(true)
                //alert(res.error)
                return null;
            }
            else {
                setSuccess(t('general.record_saved'));
                setShowSuccess(true);
                LoadUserProfile();
            }
        }
    }

    // const onImageLoaded = (image:any) => {
    //     setImgRef(image);
    // };

    const LoadUserProfile = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(IDENTITY_URL + "/api/v1/students/" + auth.user.guid + "/profile"));
        // Handling Error
        if (!res.success || !res.data) {
            setError(res.error as string);
            setShowError(true)
            // alert(res.error)
            return null;
        }
        setUserProfile(res.data);
        setName(res.data.user?.name);
        setSurname(res.data.user?.surname);
        setEmail(res.data.user?.email);
        setSelectedHobbies(res.data?.hobbies);
        setSelectedPurpose(res.data?.usagePurposes);
        setChosenLevel(res.data?.level);
        setImg(res.data?.user?.photoUrl || img);
    }

    useEffect(() => {
        LoadUserProfile();
    }, []);

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />

            <Page type="dashboard" history={history} headerTitle={t("profile.profile_settings")} activePage="StudentProfileSettings">
                <h4 className="profile-settings-title">{t("profile.profile_settings")}</h4>
                <div className="profile-settings-info">
                    <div className="profile-settings-pic">
                        <Image src={img} width={150} height={150} style={{ borderRadius: 20 }} />
                        <div className="profile-settings-pic-change-icon-container">
                            <Form.Group as={Col}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text className="profile-settings-upload-image" style={{ height: 150, width: 150 }}>
                                            <Image src={changePicIcon} />
                                            <Form.File id="exampleFormControlFile1" label="" onChange={handleImageUpload} className="profile-settings-upload-image-file" />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                </InputGroup>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="profile-settings-inputs">
                        <Row>
                            <Col md={6}>
                                <Input disabled={true} type="default" label={t("signup.name")} value={name} onChange={(event: any) => setSurname(event.target.value)} />
                            </Col>
                            <Col md={6}>
                                <div className="profile-settings-surname-input">
                                    <Input disabled={true} type="default" name="surname" value={surname} onChange={(event: any) => setSurname(event.target.value)} label={t("signup.surname")} />
                                </div>
                            </Col>
                        </Row>
                        <div style={{ marginTop: 30 }}>
                            <Input type="default" label="Email" style={{ backgroundColor: "#CACCCF" }} disabled={true} onChange={(event: any) => setSurname(event.target.value)} value={email} />
                        </div>
                        <div style={{ marginTop: 30 }}>
                            <LanguageChooser/>
                        </div>
                    </div>
                </div>

                <hr className="profile-settings-sep" />
                <div className="profile-settings-level-container">
                    <h4 className="profile-settings-title">{t("profile.level")}</h4>
                    <div className="profile-settings-level-wrapper">
                        {
                            levels.map((level, index) => (
                                <div className="profile-level-button">
                                    <OptionButton type="option" clicked={(index === chosenLevel) ? true : false} onClick={() => setChosenLevel(index)}>{level}</OptionButton>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <hr className="profile-settings-sep" />
                <div className="profile-settings-level-container">
                    <h4 className="profile-settings-title">{t("profile.aim")}</h4>
                    <div className="profile-settings-intrests-wrapper">
                        {/* {
                        intrests.map((intrest, index) =>(
                            <div className="profile-intrests-button">
                                <OptionButton type="option" clicked={(chosenIntrests.includes(index)) ? true : false} onClick={() =>setChosenInrests([...chosenIntrests, index])}>{intrest}</OptionButton>
                            </div>
                        ))
                    } */}

                        <ParamType type={3} selectedParams={selectedPurpose} setSelectedParams={setSelectedPurpose} />

                    </div>
                </div>

                <hr className="profile-settings-sep" />
                <div className="profile-settings-level-container">
                    <h4 className="profile-settings-title">{t("tutor.hobbies")}</h4>
                    <div className="profile-settings-goals-wrapper">
                        {/* {
                        goals.map((goal, index) =><OptionButton type="option" clicked={(chosenGoals.includes(index)) ? true : false} onClick={() =>setChosenGoals([...chosenGoals, index])}>{goal}</OptionButton>)
                    } */}
                        <ParamType type={2} selectedParams={selectedHobbies} setSelectedParams={setSelectedHobbies} />
                    </div>
                </div>

                <hr className="profile-settings-sep" />
                <div className="save-button-container">
                    <Button secondary={false}  onClick={onSaveChangesClick} type="default" primary style={{ paddingLeft: 40, paddingRight: 40, marginLeft: "auto" }} className="profile-settings-save-changes" disabled={false}>{t("general.save")}</Button>
                </div>
                {
                    showCropper && (
                        <ImageCropAndUpload img={img} setImg={setImg} setPrevImg={setPrevImg} prevImage={prevImg} onFinish={() => {
                            //console.log("Finish");
                            setShowCropper(false)
                        }} />
                        // <div className="profile-settings-image-cropper">
                        //     <div className="profile-settings-image-cropper-container">
                        //         <ReactCrop
                        //             onImageLoaded={onImageLoaded}
                        //             src={img}
                        //             crop={crop}
                        //             onChange={newCrop => setCrop(newCrop)}
                        //         />
                        //     </div>
                        //     <div className="profile-settings-image-cropper-btns-container">
                        //         <Button
                        //             type="default"
                        //             primary
                        //             className="profile-settings-image-cropper-btn"
                        //             onClick={() => {
                        //                 uploadCroppedImage() ;
                        //                 setShowCropper(false)
                        //             }}
                        //         >
                        //             DONE
                        //         </Button>
                        //         <Button
                        //             type="default"
                        //             primary
                        //             className="profile-settings-image-cropper-btn"
                        //             onClick={() =>{
                        //              //   setImg(prevImg)
                        //                 setShowCropper(false)
                        //             }}
                        //         >
                        //             CANCEL
                        //         </Button>
                        //     </div>
                        // </div>
                    )
                }
            </Page>
        </React.Fragment>
    )
}


function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer
    }
}
export default connect(mapStateToProps)(StudentProfileSettings);

import React from "react";
import Page from "../../components/pages";
import { RouteComponentProps } from "react-router-dom";
import TopBar from "./TopBar";
import Content from "./Content";
import ReservationSummary from "./ReservationSummary";
import { useTranslation  } from "react-i18next";

const InstructorReservations:React.FC<RouteComponentProps> = ({ history }) =>{
    const { t } = useTranslation();

    return (
        <Page
            history={history}
            type="regular"
            headerTitle={t("general.reservations")}
        >
            <TopBar />
            <ReservationSummary/>
            <Content />
        </Page>
    )
}

export default InstructorReservations;


import React from "react";
import { Image } from "react-bootstrap";
import Button from "../../buttons";
import styled from "styled-components";

import cardOneImg from "../../../assets/student-instructors-page/card-one-img.png"
import { useTranslation } from "react-i18next";

const CardBtn = styled(Button)`
    border-radius: 8px;
    padding: 5px 29px !important; 
    font-weight: 600;
`;

interface StudentDashboardMainCardProps {
    userName: String;
    onPrimaryButtonClicked? : () => void

}

const StudentDashboardMainCard:React.FC<StudentDashboardMainCardProps> = ({ userName, onPrimaryButtonClicked}) =>{
    const { t } = useTranslation();
    return (
        <div
            className={`
                student-instructors-page-card-container 
                first-card
            `}
        >
            <Image 
                src={cardOneImg} 
                style={{
                    marginLeft: 10
                }}    
            />
            <div className="student-instructors-page-card-content">
                <h4 className="student-instructors-page-card-title">
                    {t("dashboard.welcome_back")},
                </h4>
                <h4 className="student-instructors-page-card-name">
                    {userName}
                </h4>
                <div className="student-instructors-page-card-btn">
                    <CardBtn 
                        onClick={onPrimaryButtonClicked}
                        type="default" 
                        primary
                    >
                        {t("dashboard.speak_now")}
                    </CardBtn>
                </div>
            </div>
        </div>
    )
}

export default StudentDashboardMainCard;

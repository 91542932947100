import React, { useEffect, useRef, useState } from "react";
import "../layout/TotalBill.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from "./buttons";
import { Form } from "react-bootstrap";
import "../mobile/TotalBill.css";
import "../tablet/TotalBill.css";
import Input from "./inputs";
import styled from "styled-components";
import theme from "../theme";
import { useSetState } from "../Global/Utilities/getsetstate"
import axios from "axios";
import { SUBSCRIPTION_URL } from "../Global/Constants/constants";
import { ReturnProps } from "../@types/functions";
import Coupon from "./Coupon";
import { connect } from "react-redux";
import AlertInfo from "./alerts/AlertInfo";
import {useTranslation} from "react-i18next";

interface TotalBillProps {
    comp: String,
    totalSum: number,
    currentMinutes?: Number,
    currentWeek?: number,
    currentMonth?: number,
    discountForExistingPackages?: number;
    discountRate?: number,
    title?: string,
    productId: any,
    onClickPayment: (couponCode?: string) => void
}

//const discount = Math.floor(Math.random()*300);

const TotalBill: React.FC<TotalBillProps> = ({
    comp = "packages",
    totalSum = 0,
    currentMinutes,
    currentMonth = 1,
    currentWeek,
    discountForExistingPackages = 0,
    discountRate,
    title,
    productId,
    onClickPayment
}, prop) => {
    const { t } = useTranslation();
    // const [error, setError] = useState("");
    // const [showError, setShowError] = useState(false);
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [couponData, setCouponData] = useState<any>(null);
    const [termsAccepted, setTermsAccepted] = useState<Boolean>(false)
    const [finalPrice, setFinalPrice, getFinalPrice] = useSetState(0);

    // if(!(comp === "packages" || comp === "upgrade") && (!currentMinutes || currentMinutes === 0 || !currentMonth || currentMonth === 0 || !currentWeek || currentWeek === 0))
    // {
    //     return(<div/>)
    // }

    // if(comp !== "payment")
    // {}

    let afterExistingPackage = totalSum - (discountForExistingPackages || 0);
    let discount = Math.round((afterExistingPackage * (discountRate || 0) / 100) * 100) / 100;
    let afterDiscount = Math.round((afterExistingPackage - discount) * 100) / 100;
    const couponDiscountRate = (couponData?.couponProducts?.find((x: any) => x.productId === productId) && couponData?.discountRate) || 0;
    const couponDiscount = Math.round((afterDiscount * (couponDiscountRate || 0) / 100) * 100) / 100;
    const afterCouponDiscount = Math.round((afterDiscount - couponDiscount) * 100) / 100;

    const finalAmount = afterCouponDiscount;
    // setFinalPrice(finalAmount)

    const monthlyPrice = Math.round((finalAmount / currentMonth ?? 1) * 100) / 100;
    const monthlyCouponDiscount = Math.round((couponDiscount / currentMonth ?? 1) * 100) / 100;
    const monthlyDiscount = Math.round((discount / currentMonth ?? 1) * 100) / 100;
    const monthlyDiscountForExistingPackage = Math.round((discountForExistingPackages / currentMonth ?? 1) * 100) / 100;
    const monthlyTotalSum = Math.round((totalSum / currentMonth ?? 1) * 100) / 100;

    useEffect(() => {
      if(couponData && productId && !couponData?.couponProducts?.find((x: any) => x.productId === productId))
      {
          setError(t("home.subscription_packages.coupon_code_not_applicable"))
          setShowError(true);
          setCouponData(null);
      }
    }, [couponData, productId]);


    if (afterCouponDiscount < 1) {
        return (
            <>
            </>
        )
    }

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
            <>
                <div
                    className={`
                packages-total-bill 
                ${(comp === "packages" || comp === "upgrade") && "shadow"}
            `}
                    style={{
                        backgroundColor: (comp === "packages" || comp === "upgrade") ? "#FFFFFF" : "#F2F2F2"
                    }}
                >
                    <h4 className="packages-total-bill-title">{t("home.payment.summary_payment_title")}</h4>
                    <div className="package-total-bill-feaures">
                        {
                            (comp === "packages" || comp === "upgrade") && (
                                <>
                                    <p>{t("general.daily")} {currentMinutes} {t("general.minute")} {t("general.weekly")} {currentWeek} {t("general.day")}</p>
                                    {/* <p>{currentMonth} Ay süreli görüşme paketi <span>₺ {totalSum}</span></p> */}
                                    <p>{currentMonth} {t("home.subscription_packages.monthly_exist_package")} <span>₺ {monthlyTotalSum}</span></p>
                                </>
                            )
                        }
                        {
                            (comp === "payment") && (
                                <>
                                    <p>{title}</p>
                                    {/* <p>{currentMonth} Ay süreli görüşme paketi <span>₺ {totalSum}</span></p> */}
                                    <p>{t("general.total")} <span>₺ {totalSum}</span></p>
                                </>
                            )
                        }
                        {
                            (discountForExistingPackages !== 0) && (
                                <>
                                    {/* <p>Mevcut paketinizden gelen indirim <span>₺ {discountForExistingPackages}</span></p> */}
                                    <p>{t("home.payment.discount_existing_package")} <span>₺ {monthlyDiscountForExistingPackage}</span></p>
                                </>
                            )
                        }
                        {
                            (comp === "packages") && (discountRate && discountRate > 0) ? (
                                <>
                                    {/* <p>Promosyon Indirimi: %{discountRate} <span>₺ {discount}</span></p> */}
                                    <p>{t("home.payment.promotion_discount")}: %{discountRate} <span>₺ {monthlyDiscount}</span></p>
                                </>
                            ) : (<></>)
                        }

                        {
                            (comp !== "upgrade" && couponDiscount !== 0) && (
                                <>
                                    {/* <p>Kupon Indirimi: %{couponDiscountRate} <span>₺ {couponDiscount}</span></p> */}
                                    <p>{t("home.payment.coupon_discount")}: %{couponDiscountRate} <span>₺ {monthlyCouponDiscount}</span></p>
                                </>
                            )
                        }
                    </div>
                    {
                        (comp === "packages" || comp === "upgrade") && (
                            <>
                                <hr className="packages-total-bill-sep" />
                                <div className="package-total-bill-info">
                                    {/* <p>Toplam <span>₺ {afterCouponDiscount}</span></p> */}
                                    <p>{t("general.total")} <span>₺ {monthlyPrice}</span></p>
                                </div>
                                <hr className="packages-total-bill-sep" />
                                {/* <p className="package-total-bill-monthly-payment"><span>TOPLAM - <span>Aylık Ödemeniz</span></span> <span>₺ {monthlyPrice}</span></p> */}

                                <Coupon promeCode={couponData} setPromeCode={setCouponData} />
                            </>
                        )
                    }
                    {/* {
                (comp === "payment") && (
                    <div style={{ marginTop: 30 }}>
                        <Input 
                            type="checkbox"
                            onChange={(e:React.ChangeEvent<HTMLInputElement>) =>setTermsAccepted(e.target.checked)}
                            label={`
                                Kredi kartı bilgileri saklama 
                                ve satış sözleşmeleri ile ilgili kurallar buraya yazılabilir. 
                                Kurallar bir ya da iki cümle olabilir.
                            `}
                            labelColor="#929598"
                        />
                    </div>
                )
            } */}
                </div>


                <div className="package-total-bill-purchase-btn-container">
                    {/* {
                (comp !== "packages" && comp !== "upgrade") && (
                    <Button 
                        type="purchase"
                        onClick={()=> onClickPayment} 
                        style={{ 
                            backgroundColor: termsAccepted ? theme.colors.secondary : theme.colors.filter,
                            borderColor: termsAccepted ? theme.colors.secondary : theme.colors.filter
                        }}
                        disabled={!termsAccepted}
                    >
                        Satın Al
                    </Button>
                )
            } */}
                    {
                        (comp === "packages" || comp === "upgrade") && (
                            <Button
                                type="purchase"
                                onClick={() => onClickPayment(couponData?.code)}
                            >
                                {t("home.payment.purchase")}
                            </Button>
                        )
                    }
                </div>
                <p className="package-total-bill-link">{t("home.payment.payment_is_secure")}</p>
            </>
        </React.Fragment>

    )
}

// export default TotalBill;

// interface StateProps {
//     coupon: any
// }

// function mapStateToProps(state: any) {
//     return {
//         coupon: state.CouponReducer,
//     }
// }

export default TotalBill;



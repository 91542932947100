import styled,{ css } from "styled-components";
import { Navbar, Container, Nav } from "react-bootstrap";
import Button from "../buttons";
import theme from "../../theme";

/******************* Main Nav Bar *********************************/

export const NavBarContainer = styled(Navbar)`
    background-color: ${theme.colors.transparent} !important;
    padding-top: 32px;
    z-index:999;
    @media only screen and (max-width: 720px) {
        width: 100vw;
        padding-top: 14px;
    }
`;

export const NavBarWrapper = styled.div`
    width: 100%;
    display: flex;

    justify-content: flex-end !important;
    @media only screen and (max-width: 960px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const NavBarLinksContainer = styled.div`
    display: flex;
    flex:1;
    min-width: 400px;
    justify-content: end;
    transform: translateY(7px);
    margin-right:31px;
    @media only screen and (max-width: 960px) {
        display: block;
        padding-top: 15px;
        width: 90vw;
        min-width: 90vw;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const NavBarLink = styled.p`
    color: ${theme.text.colors.main};
    font-size: 15px;
    font-weight: ${theme.text.weight.meduim};
    margin-left: 25px;
    cursor: pointer;
    &:hover {
        color: ${theme.colors.primary}
    }
    @media only screen and (max-width: 960px) {
        text-align: center;
        margin-top: 5px;
        margin-left: 0;
    }
`;

export const NavBarLink_White = styled.p`
    color: white;
    font-size: 15px;
    font-weight: ${theme.text.weight.meduim};
    margin-left: 25px;
    cursor: pointer;
    &:hover {
        color: ${theme.colors.primary}
    }
    @media only screen and (max-width: 960px) {
        text-align: center;
        margin-top: 5px;
        margin-left: 0;
    }
`;

export const NavBarBrand = styled(Navbar.Brand)`
    transform: translateY(5px);
    cursor: pointer;
`;

export const NavBarButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 9px 22px;
    @media only screen and (max-width: 960px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 90vw;
        min-width: 90vw;
    }
`; 

export const NavBarButton = styled(Button)`
    cursor: pointer;
    margin-right: 8px;
    @media only screen and (max-width: 960px) {
        width: 90vw;
        border-color: ${theme.colors.primary};
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 3px;
        background-color: ${theme.colors.primary};
        color: #fff;
        transform: translateX(-20px);
    }
`;

export const NavBarLightButton = styled(Button)`
    cursor: pointer;
    margin-right: 8px;
    ${
        ({ secondary, primary, transparent }) =>{
            if (secondary) return css`
                border-color: ${theme.colors.white};
                color: ${theme.colors.white};
                &:hover {
                    border-color: ${theme.colors.white};
                    color: ${theme.colors.white};
                }
            `;
            else if(primary) return css`
                border-color: ${theme.colors.white};
                background-color: ${theme.colors.white};
                color: ${theme.colors.primary};
            `;
            else if(transparent) return css`
                color: ${theme.colors.white};
            `;
        }
    }
    @media only screen and (max-width: 960px) {
        width: 90vw;
        border-color: ${theme.colors.primary};
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 3px;
        transform: translateX(-20px);
        background-color: ${theme.colors.primary};
        color: #fff;
    }
`;

/************************* Dashboard NavBar Components ************************************/

export const DashboardNavBarContainer = styled(Navbar)`
    background-color: ${theme.colors.primary};
    padding-top: 35px;
    ${
        ({ dark }) =>{
            if(dark) return css`
                background-color: transparent;
            `;
        }
    }
    @media only screen and (max-width: 720px) {
        padding-top: 20px;
    }
`;

export const DashboardNavBarWrapper = styled(Container)`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
`;

export const DashboardNavBarLink = styled(Nav.Link)`
    text-align: center;
    color: ${theme.colors.white} !important;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    font-family: ${theme.text.fonts.poppins};
    margin-right: 30px;
    ${
        ({ dark }) =>{
            if(dark) return css`
                color: ${theme.colors.dark} !important;
            `;
        }
    }
    &:hover {
        color: ${theme.colors.primaryShade} !important;
    }
    @media only screen and (max-width: 960px) {
        width: 90vw;
        text-align: center;
    }
`;

export const DashboardNavBarIcon = styled(Nav.Link)`
    margin: 0;
    padding: 0;
    margin-right: 5px;
    @media only screen and (max-width: 960px) {
        display: none
    }
`;

export const NavBarCollpase = styled(Navbar.Collapse)<{ navTheme: "normal" | "dark" }>`
    @media only screen and (max-width: 960px) {
        ${
            ({ navTheme }) =>{
                if(navTheme === "normal") return css`
                    position: relative;
                    z-index: 999;
                    transform: translateX(-1px);
                    background-color: ${theme.colors.primary};
                `;
            }
        }
    }
`;

import React from "react";
import "../../layout/Payment/Payment.css";
import NavBar from "../../components/navbars";
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "../../components/Footer";
import "../../mobile/Payment/Payment.css";
import "../../tablet/Payment/Payment.css";
import { RouteComponentProps } from "react-router-dom";
import Content from "./Content";
// import RegularPage from "../../components/pages/RegularPage";
import Page from "../../components/pages";
import { connect } from "react-redux";
import { AuthReducerProps } from "../../@types/Auth";


type PaymentProps = RouteComponentProps & StateProps & PaymentProp;

interface PaymentProp
{
   // subscriptionTransactionId : any,
  location : any
}

const Payment:React.FC<PaymentProps> = ({ history, auth, location}) =>{

    let subscriptionTransactionId : string = location?.state?.subscriptionTransactionId;

    if(!subscriptionTransactionId)
    {
        subscriptionTransactionId= "2d808a45-b2e6-43dd-9b12-59502d59a73b";
       // history.replace("/package")
    }


    return (
        <div className="payment">
            {
                // auth.isLogged ? (
                    <>
                        <Page 
                            type="regular" 
                            history={history} 
                            headerTitle="Ödeme"
                        >
                            <div style={{ marginTop: 30 }}>
                                <Content history={history} transactionId = {subscriptionTransactionId} />
                            </div>
                        </Page>
                    </>
                // ) : (
                //     <>
                //         <NavBar type="main" history={history} />
                //         <Content history={history} transactionId = {subscriptionTransactionId} />
                //         <div className="payment-footer-container">
                //             <Footer />
                //         </div>       
                //     </>
                // )
            }
        </div>
    )
}

interface StateProps {
    auth: AuthReducerProps
}

function mapStateToProps (state:any) {
    return {
        auth: state.AuthReducer
    }
}

export default connect<StateProps>(mapStateToProps)(Payment);

import React from "react";
import DescriptionInput from "../../components/inputs/DescriptionInput";
import "../../layout/InstructorProfileSettings/Experience.css";

interface ExperienceProps {
    experience : string;
    setExperience : any;
}

const Experience : React.FC<ExperienceProps> = ({
    experience,
    setExperience
}) =>{

    return (
        <div className="instructor-profile-experience-container">
            <h4 className="instructor-profile-experience-title">Experience</h4>
            <div className="instructor-profile-experience-desc-container">
                    {/* <input className="instructor-profile-experience-desc" value = {experience} onChange={(e)=> {
                        setExperience(e.target.value);
                    }}>
                    </input> */}

                    <DescriptionInput value={experience} onChange={(e) => setExperience(e.target.value)}/>
                
            </div>
            <hr className="instructor-section-sep" />
        </div>
    )
}

export default Experience;

import React, { useState } from "react";
import "../../layout/Signup/Form.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Container, Form } from "react-bootstrap";
import Button from "../../components/buttons";
import Input from "../../components/inputs";
import "../../mobile/Signup/Form.css";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


interface ControllersProps {
    email: String;
    password: String;
    name: String;
    surname: String;
    phoneNumber: String;
}

interface ActionsProps {
    setEmail: (event: React.ChangeEvent<HTMLInputElement>) => void,
    setPassword: (event: React.ChangeEvent<HTMLInputElement>) => void,
    setName: (event: React.ChangeEvent<HTMLInputElement>) => void,
    setSurname: (event: React.ChangeEvent<HTMLInputElement>) => void,
    setPhoneNumber: (event: any) => void,
}

interface RegisterFormProps {
    handleSubmit: () => Promise<any>;
    controllers: ControllersProps;
    actions: ActionsProps,
    role: number,
    setShowPolicyModal: any
}

const SignupForm: React.FC<RegisterFormProps> = ({
    handleSubmit,
    actions,
    controllers,
    role,
    setShowPolicyModal
}) => {

    var history = useHistory();
    const [termsAccepted, setTermsAccepted] = useState<Boolean>(false)
    const { t } = useTranslation();

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        handleSubmit();
    }

    return (
        <div className="my-4 signup-form pb-5">
            <Card className="shadow signup-form-container">
                <Card.Body>
                    <Container>
                        <h5 className="signup-form-title">{t("signup.title")}</h5>
                        <h6 className="signup-form-subtitle text-muted mt-2">{t("signup.register_now")}</h6>
                        {/* <div className="signup-form-btns my-4">
                            <Button type="facebook" className="mr-3">Facebook İle Oturum Aç</Button>
                            <Button type="google">Google İle Oturum Aç</Button>
                        </div>
                        <div className="mt-4 pt-3">
                            <div className="signup-sep"></div>
                            <h6 className="signup-sep-title text-muted">veya</h6>
                        </div> */}
                        <div className="mt-4">
                            <Form onSubmit={onSubmit} className="text-left">
                                <div className="signup-form-btns">
                                    <Form.Group controlId="formBasicName"   >
                                        <Form.Label className="signup-label"> {t("signup.name")} </Form.Label>
                                        <Input type="default" value={controllers.name as string} onChange={actions.setName} />
                                    </Form.Group>

                                    <Form.Group controlId="formBasicSurname">
                                        <Form.Label className="signup-label">{t("signup.surname")}</Form.Label>
                                        <Input type="default" value={controllers.surname as string} onChange={actions.setSurname} />
                                    </Form.Group>
                                </div>

                                <Form.Group controlId="formBasicPassword" >
                                    <Form.Label className="signup-label">{t("signup.phone")}</Form.Label>
                                    {/* <Input type="default" placeholder={"+905321234567"} value={controllers.phoneNumber as string} onChange={actions.setPhoneNumber} /> */}
                                    <PhoneInput
                                        className="form-control"
                                        style={{height:44, color:'#52575C', fontSize:16, backgroundColor: '#FFFCFC'}}
                                        placeholder={t("signup.phone")}
                                        defaultCountry="TR"
                                        value={controllers.phoneNumber as string}
                                        onChange={actions.setPhoneNumber}
                                        international
                                        countryCallingCodeEditable={false}
                                         />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword" >
                                    <Form.Label className="signup-label">{t("login.email")}</Form.Label>
                                    <Input type="default" value={controllers.email as string} onChange={actions.setEmail} />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword" >
                                    <Form.Label className="signup-label">{t("login.password")}</Form.Label>
                                    <Input type="password" value={controllers.password as string} onChange={actions.setPassword} />
                                </Form.Group>


                                <Form.Group controlId="formBasicCheckbox">
                                    <Input
                                        type="checkbox"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            if (e.target.checked) {
                                                setShowPolicyModal(true);
                                            }
                                            setTermsAccepted(e.target.checked)
                                        }}
                                        className="checkbox-label"
                                        label={t("signup.read_privacy")} />
                                </Form.Group>

                                <Button
                                    primary style={{ width: "100%", backgroundColor: termsAccepted ? undefined : "#CACCCF", opacity: 1 }}
                                    disabled={!termsAccepted}
                                    type="default"
                                >
                                    {t("signup.register")}
                                </Button>
                                <div className="mt-3 form-links">
                                    <Form.Text className="not-a-member">
                                        {t("signup.already_registered")}
                                        <span style={{ color: "#F86F53", cursor: "pointer", marginLeft: 10 }} onClick={() => { history?.push("/login") }}>
                                              {t("signup.login")}
                                        </span>
                                    </Form.Text>
                                </div>
                            </Form>
                        </div>
                    </Container>
                </Card.Body>
            </Card>
        </div>
    )
}

export default SignupForm;

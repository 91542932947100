import React, { } from "react";
import Page from "../../../components/pages";
import { connect } from "react-redux";
import { ADMIN_PANEL_URL } from "../../../Global/Constants/constants";


const AdminMainPage: React.FC<any> = ({ history }) => {

    const accessToken = localStorage.getItem("speakingline_access_token");

    return (
        <React.Fragment>
            <Page type="admin-dashboard" history={history} headerTitle="Admin Dashboard">
                <div>
                    <iframe src={ADMIN_PANEL_URL + "?embed=true&token=" + accessToken} height="1024" width="100%"></iframe>
                </div>
            </Page>
        </React.Fragment>
    )
}

export default AdminMainPage;
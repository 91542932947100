import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { Image } from "react-bootstrap";
import SubscriptionSummaryMoreDetails from "./SubscriptionSummaryMoreDetails";

import downArrow from "../../../assets/student-instructors-page/down-arrow.png";
import cardTwoImg from "../../../assets/student-instructors-page/card-two-img.png";
import { useTranslation } from "react-i18next";

interface SubscriptionAvailableProps {
    subscriptionDetails: any
}

const SubscriptionSummaryAvailable: React.FC<SubscriptionAvailableProps> = ({ subscriptionDetails }) => {
    // var subscription = {usedDayCounts : 3,leftDayCounts:2, todayAvailableMinutes:2};
    const { t } = useTranslation();

    let subscription: any = subscriptionDetails.filter((t: any) => t?.subscriptionType === 1)[0]
    let subscriptionOneTime: any = subscriptionDetails.filter((t: any) => t?.subscriptionType === 0)[0]
    console.log("OneTIME: ", subscriptionOneTime);


    var dayLevelElements: any[] = [];

    if (subscription.usedDayCounts > 0) {
        for (let index = 0; index < subscription.usedDayCounts; index++) {
            dayLevelElements.push(true);
        }
    }

    if (subscription.leftDayCounts > 0) {
        for (let index = 0; index < subscription.leftDayCounts; index++) {
            dayLevelElements.push(false);
        }
    }

    const [showMoreDetails, setShowMoreDetails] = useState(false);
    const [showMoreDetailsContent, setShowMoreDetailsContent] = useState(false)
    const moreInfoState = useSpring({
        height: showMoreDetails ? 300 : 130,
        opacity: showMoreDetails ? 1 : 0,
        transform: showMoreDetails ? "rotate(180deg)" : "rotate(0deg)"
    })

    let usedDay = subscription.usedDayCounts + 1;
    if (subscription.usedDayCounts === subscription.usedDayCounts + subscription.leftDayCounts) {
        usedDay = subscription.usedDayCounts;
    }

    return (
        <animated.div
            className="student-instructors-page-card-container subscription-summary"
            style={{
                top: 0,
                height: moreInfoState.height,
            }}
        >
            <div className="student-instructors-page-card-wrapper subscription-summary">
                <Image
                    src={cardTwoImg}
                    height={88}
                    style={{
                        marginTop: 15
                    }}
                />
                <div className="student-instructors-page-card-content">
                    {/* <h4 className="student-instructors-page-card-title">
                        Ac volutpat nulla nisi
                    </h4> */}
                    <h4 className="student-instructors-page-card-date">
                        {usedDay}. {t("dashboard.day")} - {subscription.todayTotalMinutes} {t("dashboard.minutes")}
                    </h4>
                    <div className="student-instrcutors-page-level-container">
                        {
                            dayLevelElements.map((item, idx) =>
                                item === true ?
                                    <div
                                        key={idx}
                                        className="student-instructors-page-level filled"
                                    /> :
                                    <div
                                        key={idx}
                                        className="student-instructors-page-level"
                                    />
                            )
                        }
                    </div>
                    <div className="student-instructors-page-other-content-container">
                        <h4 className="student-instructors-page-card-title">
                            Bugünün süresi
                        </h4>
                        <div style={{ display: "flex" }}>
                            <h4 className="student-instructors-page-card-call-duration">
                                {subscription.todayAvailableMinutes}
                            </h4>
                            {subscriptionOneTime && subscription ? (<div style={{ display: "flex" }}>
                                <h4 style={{ marginLeft: "4px" }} className="student-instructors-page-card-call-duration">
                                    +
                                </h4>
                                <div className="student-instructors-page-card-one-time"> {subscriptionOneTime.todayAvailableMinutes} </div>
                            </div>) : null}
                            <h4 className="student-instructors-page-card-call-duration">{t("dashboard.minutes_left")}</h4>
                        </div>
                    </div>
                </div>

                <animated.div
                    className="student-instructors-page-down-arrow-container"
                    style={{
                        transform: moreInfoState.transform
                    }}
                >
                    <Image
                        src={downArrow}
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            setShowMoreDetails(!showMoreDetails)
                            setTimeout(() => {
                                setShowMoreDetailsContent(!showMoreDetailsContent)
                            }, 300)
                        }}
                    />
                </animated.div>
            </div>

            { /* Card More Details */}
            {
                showMoreDetails && <SubscriptionSummaryMoreDetails
                    info={subscription}
                    showMoreInfo={showMoreDetailsContent}
                />
            }
        </animated.div>
    )
}

export default SubscriptionSummaryAvailable;

import { css } from "styled-components";
import theme from "../../theme";

const MakeSthButton = css`
    text-align: center;
    font-family: ${theme.text.fonts.poppins};
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    padding: 16px 15px;
    width: 100%;
    background-color: ${theme.colors.secondary};
    border: 1px solid ${theme.colors.secondary};
    border-radius: 8px;
    &:hover {
        background-color: ${theme.colors.secondaryShade};
        border: 1px solid ${theme.colors.secondaryShade};
    }
    &:focus, 
    &:active {
        background-color: ${theme.colors.secondary};
        border: 1px solid ${theme.colors.secondary};
    }
`;

export default MakeSthButton;

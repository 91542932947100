import colors from "./colors";
import icons from "./icons";
import backgrounds from "./backgrounds";
import text from "./text";
import buttons from "./buttons";
import status from "./status";

export default {
    colors: {...colors},
    icons: {...icons},
    backgrounds: {...backgrounds},
    text: {...text},
    buttons: {...buttons},
    status: {...status}
}

import React, { useEffect, useState } from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import { calender } from '../../../assets/SVG/SVG';
import moment from 'moment';
import { doCall, endCall } from "../../../assets/SVG/SVG";
import { useHistory } from "react-router-dom";
import { connection, Init } from "../../../Global/Utilities/signalr"
import { MeetingRequestStatusType } from "../../../Global/Constants/constants"

import cross from "../../../assets/upgrade-package/cross.png";
import personImg from "../../../assets/defaultImg.svg";
import phoneIcon from "../../../assets/phoneIcon.svg";
import missedCallIcon from "../../../assets/missedCallIcon.svg";
import "../../../layout/CallingModal/CallingModal.css";
import { CONVERSATION_URL, IDENTITY_URL } from "../../../Global/Constants/constants";
import { ReturnProps } from "../../../@types/functions";
import axios from "axios";
import AlertInfo from '../../alerts/AlertInfo';
import profile from "../../../assets/blank_profile.png";
import useSound from 'use-sound';
//import ring from '../../../assets/sounds/ring.mp3';

interface CallingModalInterface {
    meetingRequestId: String,
    opponentPicture: String;
    oppenentId: String;
    opponentName: String;
    show: Boolean;
    hide: () => void;

}
type CallingProps = CallingModalInterface

const IncomingCallModal: React.FC<CallingProps> = ({
    meetingRequestId,
    opponentPicture,
    oppenentId,
    opponentName,
    show,
    hide
}) => {

    let history = useHistory();
    let title = "";
    let description = "";

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [meetReqId, setMeetReqId] = useState(meetingRequestId);
    const [status, setStatus] = useState("incoming");

    useEffect(() => {
        listenMeetingRequestAnswer();
    }, [show]);

    // const soundUrl = '../../../assets/sounds/ring.mp3';

    const [play, { stop }] = useSound("/sounds/ring.mp3");

    useEffect(() => {
        if (status === "incoming") {
            play();
        }
        else {
            stop();
        }
    }, [status]);

    const meetingAccepted = (params: any) => {
        //title = "accepted";
        setTimeout(() => history.push("/conversation/video", { meetingParams: params }), 1000)
    };

    const meetingIsCanceledOrTimeout = () => {
        setTimeout(() => hide(), 1500)
    }

    if (status === "incoming") {
        play();
        title = "Incoming call " + opponentName + " ...";
        description = "Please accept or reject";
    }
    else if (status === "accepted") {
        title = "Accepted";
        description = "Your meeting is about to start please be ready";
    }

    else if (status === "canceled") {
        title = "CANCELED BY STUDENT";
        description = "Canceled by student";
        meetingIsCanceledOrTimeout();
    }
    else if (status === "rejected") {
        title = "Didnt accept the call";
        description = "You didnt accept the call.";
        meetingIsCanceledOrTimeout();
    }

    else if (status === "noanswer") {
        title = "You missed the call";
        description = "Missed the call :(. Try to answer it.";
        meetingIsCanceledOrTimeout();
    }

    const dummyDataClicked = () => {
        setStatus("Accepted");
        var params = JSON.parse('{"meeting_request_id":"ae76021c-33bd-4fc4-b371-8cac9bd9353b","meeting_id":"25ae47fa-d7cf-42af-89de-85c1e6512042","session_id":"1_MX40NjkxOTc5NH5-MTYxNjA2NjE4MzQwM35MbUJMU3dQTlMzRTZFbWIySzdieWZ5Ky9-fg","meeting_request_answer":1,"meeting_token":"T1==cGFydG5lcl9pZD00NjkxOTc5NCZzaWc9YmRjMTFmZmRiOTYzN2FmNTMyZjAxZjAyYmMxM2MxNTY1ZWM1YmZjNDpzZXNzaW9uX2lkPTFfTVg0ME5qa3hPVGM1Tkg1LU1UWXhOakEyTmpFNE16UXdNMzVNYlVKTVUzZFFUbE16UlRaRmJXSXlTemRpZVdaNUt5OS1mZyZjcmVhdGVfdGltZT0xNjE2MTc0NDIwJm5vbmNlPTAuMDM3NTExMjgwNzg5NDUxMzImcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTYxODc2NjQxOSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==","meeting_duration":45,"attendee_guid":"ba3b6b20-4449-4cfa-adb4-c4b21c413ee0","attendee_name":"MyTeacher","attendee_surname":"Surname Teacher","attendee_photo_url":"http://bucket-url/ba3b6b20-4449-4cfa-adb4-c4b21c413ee0_20210316-154233.png","attendee_email":"newteacher@gmail.com"}');
        meetingAccepted(params);
    }

    const updateMeetingRequest = async (isAccepted: boolean) => {

        if (isAccepted)
            setStatus("accepted");
        else
            setStatus("rejected");

        // if (!isAccepted) {
        //     meetingIsCanceledOrTimeout();
        // }
        var params = { meetingStatus: isAccepted ? 1 : 2 }
        const res = await axios.put<any, ReturnProps>(CONVERSATION_URL + "/api/v1/meetings/meeting-request/" + meetReqId, params);
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            //alert(res.error)
            return null;
        }
        // if (!isAccepted) {
        //     meetingIsCanceledOrTimeout();
        // }
        // var params = JSON.parse('{"meeting_request_id":"ae76021c-33bd-4fc4-b371-8cac9bd9353b","meeting_id":"25ae47fa-d7cf-42af-89de-85c1e6512042","session_id":"1_MX40NjkxOTc5NH5-MTYxNjA2NjE4MzQwM35MbUJMU3dQTlMzRTZFbWIySzdieWZ5Ky9-fg","meeting_request_answer":1,"meeting_token":"T1==cGFydG5lcl9pZD00NjkxOTc5NCZzaWc9YmRjMTFmZmRiOTYzN2FmNTMyZjAxZjAyYmMxM2MxNTY1ZWM1YmZjNDpzZXNzaW9uX2lkPTFfTVg0ME5qa3hPVGM1Tkg1LU1UWXhOakEyTmpFNE16UXdNMzVNYlVKTVUzZFFUbE16UlRaRmJXSXlTemRpZVdaNUt5OS1mZyZjcmVhdGVfdGltZT0xNjE2MTc0NDIwJm5vbmNlPTAuMDM3NTExMjgwNzg5NDUxMzImcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTYxODc2NjQxOSZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==","meeting_duration":45,"attendee_guid":"ba3b6b20-4449-4cfa-adb4-c4b21c413ee0","attendee_name":"MyTeacher","attendee_surname":"Surname Teacher","attendee_photo_url":"http://bucket-url/ba3b6b20-4449-4cfa-adb4-c4b21c413ee0_20210316-154233.png","attendee_email":"newteacher@gmail.com"}');
        // meetingAccepted(params);
    }

    const listenMeetingRequestAnswer = () => {
        connection.off("MeetingRequestAnswer");
        connection.on("MeetingRequestAnswer", (message) => {
            //console.log("MeetingRequestAnswer called " + message)
            if (message.params.meeting_request_answer === MeetingRequestStatusType.ACCEPTED) {
                setStatus("Accepted");
                meetingAccepted(message.params);
            }
            else if (message.params.meeting_request_answer === MeetingRequestStatusType.CANCELED_BY_STUDENT) {
                setStatus("canceled");
            }
            else if (message.params.meeting_request_answer === MeetingRequestStatusType.TIMEOUT) {
                setStatus("noanswer");
            }
        });
    }

    return (

        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />

            <Modal show={show} onHide={hide} className="callingModal" backdrop="static">
                <Modal.Header>
                    {status !== "incoming" &&
                        <Image
                            onClick={hide}
                            src={cross}
                            height={40}
                            width={40}
                            style={{ cursor: "pointer" }}
                        />}
                </Modal.Header>
                <Modal.Body>
                    <div className="callingBody">

                        <div className="caller-img">
                            {(status === "canceled" || status === "noanswer") &&
                                <div className="missIcon">
                                    < img src={missedCallIcon} />
                                </div>
                            }

                            <img style={{ width: "104px", height: "104px", borderRadius: "24px" }} src={opponentPicture as string ?? profile} alt="" />
                        </div>
                        <div className="caller-content">
                            <h3>{title}</h3>
                            <p>{description}</p>
                        </div>

                        {
                            status === "incoming" &&
                            <div className="callingFooter accept" >

                                <div className="accept-button">
                                    <button onClick={() => { updateMeetingRequest(true) }}>
                                        <img src={phoneIcon} alt="" />
                                    </button>
                                    <h6>Accept</h6>
                                </div>
                                <div className="rejectcall-button">
                                    <button onClick={() => { updateMeetingRequest(false) }}>
                                        <img src={phoneIcon} alt="" />
                                    </button>
                                    <h6>Reject</h6>
                                </div>

                            </div>
                        }

                        {
                            status === "accepted" &&
                            <div className="callingFooter content">
                                <p className="danger">Meeing is about to start. Forwarding...</p>
                            </div>
                        }

                        {
                            status === "canceled" &&
                            <div className="callingFooter content">
                                <p className="danger">Meeting is cancelled by student. Forwarding ....</p>
                            </div>
                        }

                        {
                            status === "rejected" &&
                            <div className="callingFooter content">
                                <p className="danger">You didnt accept the meeting. Forwarding ....</p>
                            </div>
                        }

                        {
                            status === "noanswer" &&
                            <div className="callingFooter content">
                                <p className="danger">Meeting is timeout. Forwarding ....</p>
                            </div>
                        }

                    </div>
                </Modal.Body>

            </Modal>
        </React.Fragment>
    )
}
export default IncomingCallModal;
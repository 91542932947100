import React,{ useState } from "react";
import "../../layout/StudentVideoChat/MobileBottom.css";
import "../../mobile/StudentVideoChat/MobileBottom.css";
import styled from "styled-components";
import theme from "../../theme";
import { Image } from "react-bootstrap";
import Message from "./Message";
import MobileOptions from "./MobileOptions";
import { connect } from "react-redux";
//import { createSession } from "opentok-react";
import { API_KEY as apiKey } from "../../Global/OpenTok/config";

import sendBtn from "../../assets/student-video-chat/sendBtn.png";
import optionMobile from "../../assets/student-video-chat/optionMobile.png";

const Input = styled.input`
    height: 35px;
    width: 79%;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    font-family: ${theme.text.fonts.nunito};
    border: 0;
    background-color: transparent;
    &::-webkit-input-placeholder {
        color: #fff;
    }
    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
    }
`;

const MobileBottom:React.FC<any> = ({ cameraState, turnOnCamera, turnOffCamera, microState, turunOnMic, turnOffMic, endCallClicked }) =>{
    const [showOptions,setShowOptios] = useState(false)
    const handleToggleCamera = () =>{
        if(cameraState) turnOffCamera()
        else turnOnCamera()
    }
    const handleToggleMic = () =>{
        if(microState) turnOffMic()
        else turunOnMic()
    }
    // const endCall = () =>{
    //     const session = createSession({
    //         token,
    //         sessionId,
    //         apiKey
    //     })
    //     session.session.disconnect()
    // }
    return(
        <div className="mobile-bottom">
            <div className="mobile-bottom-messages">
                <Message time= {1}  message="Odio vel ut." type="owner" />
                <p className="mobile-bottom-message-text">
                    How are you? I want to meet with you. Are you available?
                </p>
            </div>
            <div className="mobile-bottom-input-container">
                <div className="mobile-bottom-input-wrapper">
                    <Input placeholder="And m..." />
                    <Image src={sendBtn} />
                </div>
                <Image
                    src={optionMobile}
                    onClick={() =>setShowOptios(true)}
                />
            </div>
            {
                showOptions && <MobileOptions
                                    optionsFunc={() =>setShowOptios(false)}
                                    toggleCameraFunc={handleToggleCamera}
                                    toggleMicFunc={handleToggleMic}
                                    endCallFunc={endCallClicked}
                                />
            }
        </div>
    )
}

function mapStateToProps(state:any) {
    return {
        cameraState: state.cameraReducer,
        microState: state.microReducer
    }
}

function mapDispatchToProps(dispatch:any) {
    return {
        turnOnCamera: () => dispatch({ type: "TURN_ON_CAMERA" }),
        turnOffCamera: () => dispatch({ type: "TURN_OFF_CAMERA" }),
        turunOnMic: () => dispatch({ type: "TURN_ON_MICRO" }),
        turnOffMic: () => dispatch({ type: "TURN_OFF_MICRO" })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileBottom);


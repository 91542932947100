import React, { useEffect, useState } from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import { calender } from '../../../assets/SVG/SVG';
import moment from 'moment';
import ReactPlayer from 'react-player'
import "../../../layout/HistoryModal/HistoryModal.css";
import cross from "../../../assets/upgrade-package/cross.png";
import MeetingAssessment from '../HistoryDetails/MeetingAssessment';
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import theme from "../../../theme";
import StyledButton from '../../buttons';
import { CONVERSATION_URL, IDENTITY_URL, ROLES } from "../../../Global/Constants/constants";
import { ReturnProps } from "../../../@types/functions";
import axios from "axios";
import { trackPromise } from 'react-promise-tracker';
import { MeetingFinishedIcon } from "../../../assets/SVG/meetingFinished";
import "../../../layout/StudentRatingModal/StudentRatingModal.css";
import AlertInfo from '../../alerts/AlertInfo';

interface StudentRateModalInterface {
    opponentName: String;
    opponentId: String;
    meetingId: String;
    show: Boolean;
    hide: () => void;
}
const StudentRateModal: React.FC<StudentRateModalInterface> = ({
    opponentName,
    opponentId,
    meetingId,
    show,
    hide
}) => {

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    
    const [teacherRate, setTeacherRate] = useState<any>();
    const [systemRate, setSystemRate] = useState<any>();

    const UpdateAssessment = async () => {
        const param = {
            converstationId: meetingId,
            rate: teacherRate,
            systemRate: systemRate
        }
        const res = await trackPromise(axios.put<any, ReturnProps>(IDENTITY_URL + "/api/v1/students/" + opponentId + "/rates", param));        // // Handling Error
        hide()
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            //  alert(res.error)
            return null;
        }
    }

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
            <div className="greybgHeader">
                <Modal className="historyModal" show={show} onHide={hide} backdrop="static">
                    <Modal.Header className="historyHeader">
                        <Modal.Title>
                            <h4 className="h4">Görüşme Bitti. {opponentName} nasıldı?</h4>
                        </Modal.Title>
                        <Image
                            onClick={hide}
                            src={cross}
                            height={40}
                            width={40}
                            style={{ cursor: "pointer" }}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <div className="content">
                            <div className="icon">
                                {MeetingFinishedIcon}
                            </div>
                            <div className="content-rate-group">
                                <div>
                                    <h3>{opponentName} nasıldı?</h3>
                                    <ReactStars
                                        value={5}
                                        count={5}
                                        onChange={(e: any) => { setTeacherRate(e) }}
                                        size={40}
                                        isHalf={true}
                                        emptyIcon={<i className="far fa-star"></i>}
                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                        fullIcon={<i className="fa fa-star"></i>}
                                        activeColor={theme.buttons.colors.primary}
                                    />
                                </div>
                                <hr />
                                <div>
                                    <h3>Platform nasıldı?</h3>
                                    <ReactStars
                                        count={5}
                                        onChange={(e: any) => { setSystemRate(e) }}
                                        size={40}
                                        isHalf={true}
                                        emptyIcon={<i className="far fa-star"></i>}
                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                        fullIcon={<i className="fa fa-star"></i>}
                                        activeColor={theme.buttons.colors.primary}
                                    />
                                </div>
                                <div className="saveButton">
                                    <StyledButton type="tutorsCardBtn" primary onClick={UpdateAssessment}>KAYDET</StyledButton>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    )
}
export default StudentRateModal;
import React, { useState } from "react";
import "../../layout/Signup/Form.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Container, Form } from "react-bootstrap";
import Button from "../../components/buttons";
import Input from "../../components/inputs";
import "../../mobile/Signup/Form.css";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


interface ControllersProps {
    email: String;
    name: String;
    surname: String;
    phoneNumber: String;
    position: String;
    company: String;
    employeenumber: String;
    message: String;
    contactFrom: String;
}

interface ActionsProps {
    setEmail: (event: React.ChangeEvent<HTMLInputElement>) => void,
    setName: (event: React.ChangeEvent<HTMLInputElement>) => void,
    setSurname: (event: React.ChangeEvent<HTMLInputElement>) => void,
    setPosition: (event: React.ChangeEvent<HTMLInputElement>) => void,
    setPhoneNumber: (event: any) => void,
    setCompany: (event: React.ChangeEvent<HTMLInputElement>) => void,
    setEmployeenumber: (event: React.ChangeEvent<HTMLInputElement>) => void,
    setMessage: (event: React.ChangeEvent<HTMLInputElement>) => void
    setContactFrom: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface RegisterFormProps {
    handleSubmit: () => Promise<any>;
    controllers: ControllersProps;
    actions: ActionsProps
}

const CorparateForm: React.FC<RegisterFormProps> = ({
    handleSubmit,
    actions,
    controllers
}) => {

    var history = useHistory();
    const [termsAccepted, setTermsAccepted] = useState<Boolean>(false)

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        handleSubmit();
    }

    return (
        <div className="my-4 signup-form pb-5">
            <Card className="signup-form-container">
                <Card.Body>
                    <Container>
                        <h5 className="signup-form-title">Kurumsal Başvuru Formu</h5>
                        <div className="mt-4">
                            <Form onSubmit={onSubmit} className="text-left">
                                <div className="signup-form-btns">
                                    <Form.Group controlId="formBasicName"   >
                                        <Form.Label className="signup-label">Ad</Form.Label>
                                        <Input type="default" value={controllers.name as string} onChange={actions.setName} />
                                    </Form.Group>

                                    <Form.Group controlId="formBasicSurname">
                                        <Form.Label className="signup-label">Soyad</Form.Label>
                                        <Input type="default" value={controllers.surname as string} onChange={actions.setSurname} />
                                    </Form.Group>
                                </div>

                                <Form.Group controlId="formBasicName"   >
                                    <Form.Label className="signup-label">Email</Form.Label>
                                    <Input type="default" value={controllers.email as string} onChange={actions.setEmail} />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword" >
                                    <Form.Label className="signup-label">Telefon No</Form.Label>
                                    {/* <Input type="default" placeholder={"+905321234567"} value={controllers.phoneNumber as string} onChange={actions.setPhoneNumber} /> */}
                                    <PhoneInput
                                        className="form-control"
                                        style={{ height: 44, color: '#52575C', fontSize: 16, backgroundColor: '#FFFCFC' }}
                                        placeholder="Telefon No"
                                        defaultCountry="TR"
                                        value={controllers.phoneNumber as string}
                                        onChange={actions.setPhoneNumber}
                                        international
                                        countryCallingCodeEditable={false}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword" >
                                    <Form.Label className="signup-label">Firma</Form.Label>
                                    <Input type="default" value={controllers.company as string} onChange={actions.setCompany} />
                                </Form.Group>

                                <Form.Group controlId="formBasicName"   >
                                    <Form.Label className="signup-label">Pozisyon</Form.Label>
                                    <Input type="default" value={controllers.position as string} onChange={actions.setPosition} />
                                </Form.Group>

                                {/* <Form.Group controlId="formBasicName"   >
                                    <Form.Label className="signup-label">Çalışan Sayısı</Form.Label>
                                    <Input type="default" value={controllers.employeenumber as string} onChange={actions.setEmployeenumber} />
                                </Form.Group> */}

                                <Form.Group controlId="formBasicName"   >
                                    <Form.Label className="signup-label">Mesajınız</Form.Label>
                                    <Input type="description" value={controllers.message as string} onChange={actions.setMessage} />
                                </Form.Group>

                                <Form.Group controlId="formBasicName"   >
                                    <Form.Label className="signup-label">SpeakingLine’a nereden ulaştınız ?</Form.Label>
                                    <Input type="default" value={controllers.contactFrom as string} onChange={actions.setContactFrom} />
                                </Form.Group>

                                <Button
                                    style={{ marginTop: 40, width: "100%" }}
                                    primary
                                    type="default">
                                    Gönder
                                </Button>
                            </Form>
                        </div>
                    </Container>
                </Card.Body>
            </Card>
        </div>
    )
}

export default CorparateForm;

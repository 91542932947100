import React, { useEffect, useState } from "react";
import "../../layout/ChoosePackage/ChoosePackage.css";
import { Container, Row, Col } from "react-bootstrap";
import Content from "./Content";
import Footer from "../../components/Footer";
import TotalBill from "../../components/TotalBill";
import NavBar from "../../components/navbars";
import { RouteComponentProps } from "react-router-dom";
import { useSetState } from "../../Global/Utilities/getsetstate"
import axios from "axios";
import { SUBSCRIPTION_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import { trackPromise } from "react-promise-tracker";
import AlertInfo from "../../components/alerts/AlertInfo";
import { connect } from "react-redux";
import Page from "../../components/pages";
import {useTranslation} from "react-i18next";

const ChoosePackage: React.FC<any> = ({ history, auth }) => {
    const { t } = useTranslation();

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [discountForExistingPackages, setDiscountForExistingPackages] = useState(0);
    const [existingSubscriptionProduct, setExistingSubscriptionProduct] = useState(null);

    const [currentMinutes, setCurrentMinutes, getCurrentMinutes] = useSetState(0);
    const [currentWeek, setCurrentWeek, getCurrentWeek] = useSetState(0);
    const [currentMonth, setMonth, getMonth] = useSetState(1);
    const [totalSum, setTotalSum, getTotalSum] = useSetState(0);
    const [discountRate, setDiscountRate, getDiscountRate] = useSetState(0);
    const [selectedPackage, setSelectedPackage, getSelectedPackage] = useSetState<any>({});
    // const handleMinutesChosen = (value:Number) =>{
    //     const result = Math.floor(Math.random()*50);
    //     if(value > currentMinutes) setTotalSum(totalSum + result)
    //     else setTotalSum(totalSum - result)
    //     setCurrentMinutes(value as number)
    // }
    // const handleWeekChosen = (value:Number) =>{
    //     const result = Math.floor(Math.random()*50);
    //     if(value > currentWeek) setTotalSum(totalSum + result)
    //     else setTotalSum(totalSum - result)
    //     setCurrentWeek(value as number)
    // }
    const handlePackageValueChosen = (_selectedPackage: any) => {
        setSelectedPackage(_selectedPackage);
        const price = Math.floor(_selectedPackage.price);
        const discountRate = Math.floor(_selectedPackage.discountRate);
        // const finalPrice = price - discountPrice;

        setTotalSum(price);
        setDiscountRate(discountRate);
        setCurrentMinutes(_selectedPackage.dailyProduct.value);
        setCurrentWeek(_selectedPackage.weeklyProduct.value);
        setMonth(_selectedPackage.monthlyProduct.value);
        // setPackageValue(value as number)
    }

    const handleClickPayment = async (couponCode?: string) => {
        if (!auth.isLogged) {
            history.push("/login");
            return;
        }
        var params = {
            productGuid: selectedPackage.guid,
            couponCode: couponCode
        }

        const res = await trackPromise(axios.post<any, ReturnProps>(SUBSCRIPTION_URL + "/api/v1/subscription", params));
        // Handling Error
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)

            return null;
        }
        const header: String = res.headers.location
        var subscriptionTransactionId = header.split("/")[1];

        history.push("/payment", { subscriptionTransactionId: subscriptionTransactionId });
    }

    const getCurrentBalance = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(SUBSCRIPTION_URL + "/api/v1/product/user/remaining-balance"));
        // Handling Error
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            return null;
        }
        if (res.data && res.data.points) {
            setDiscountForExistingPackages(res.data.points);
            setExistingSubscriptionProduct(res.data.product);
        }
    }

    useEffect(() => {
        if (auth.isLogged)
            getCurrentBalance();
    }, []);

    const dataRender = (<Container className="choose-package-wrapper">
        <Row>
            <Col lg={8} className="choose-package-container">
                <Content
                    existingProduct={existingSubscriptionProduct}
                    handlePackageValueChosen={handlePackageValueChosen}
                    discountForExistingPackages={discountForExistingPackages}
                />
            </Col>
            <Col lg={4}>
                <TotalBill
                    comp="packages"
                    productId = {selectedPackage.guid}
                    onClickPayment={handleClickPayment}
                    currentWeek={currentWeek}
                    currentMonth={currentMonth}
                    currentMinutes={currentMinutes}
                    totalSum={totalSum}
                    discountRate={discountRate}
                    discountForExistingPackages={discountForExistingPackages}
                />
            </Col>
        </Row>
    </Container>);

    return (
        <React.Fragment>

            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />

            <div className="choose-package">

                {!auth.isLogged ? (
                    <React.Fragment>
                        <NavBar type="minimal" history={history} />
                        <div className="mainContent">
                            {dataRender}
                        </div>
                        <Footer />
                    </React.Fragment>
                ) : (
                    <Page
                        type="regular"
                        history={history}
                        headerTitle={t("home.subscription_packages.title")}
                    >
                        <div className="mainContent">
                            {dataRender}
                        </div>
                    </Page>
                )}

            </div>
        </React.Fragment>
    )
}

function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer
    }
}
export default connect(mapStateToProps)(ChoosePackage);

import React  from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import { modalDeleteReservation } from '../../../assets/SVG/SVG';
import moment from 'moment';
import { Ellipsis } from 'react-bootstrap/esm/PageItem';
import {useTranslation} from "react-i18next";

interface DeleteReservationModalInterface {
    reservationId : string,
    teacherName : string,
    show: Boolean;
    hide: () => void;
    approveClicked: () => void;
    selectedMeetingMinutes: any;
    date: any;
    timeSlot: any;
    isDeletable:  Boolean;
}
const DeleteReservationModal: React.FC<DeleteReservationModalInterface> = ({
    reservationId,
    teacherName,
    show,
    approveClicked,
    hide,
    selectedMeetingMinutes,
    date,
    timeSlot,
    isDeletable,
}) => {
    const { t } = useTranslation();

    var message = <></>;

    if(isDeletable)
        message = <p>{t("reservation.delete_message_before_four_hours")}</p>
    else
        message = <p>{t("reservation.delete_message_after_four_hours")}</p>

    return (
        <div className="confirmationModal">
            <Modal show={show} onHide={hide}>
                <Modal.Body>
                    <div className="confirmationModalBody">
                        <div className="cal-icon">{modalDeleteReservation}</div>
                        <h5>{t("reservation.delete_reservation_title")}</h5>
                        <div className="content-inner">
                            <p className="large-para"><b>{ moment(date).format('DD MMMM YYYY')}</b> tarihinde, saat <b>{moment(timeSlot).format("HH:mm")}</b>’de eğitmenlerimizden <b>{teacherName} </b> ile <span>{selectedMeetingMinutes} dakikalık </span> 
                   seans rezervasyonunu silmeyi onaylıyor musunuz?
                 </p>

                            {message}
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer className="customModalFooter">
                    <Button onClick={hide} variant="secondary">{t("reservation.reject_delete")}</Button>
                    <Button onClick={approveClicked} variant="primary">{t("reservation.approve_delete")}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default DeleteReservationModal;
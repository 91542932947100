import React from "react";
import Page from "../../../components/pages";
import "../../../layout/Support/Instructors/Questions.css";
import "../../../mobile/Support/Instructor/Questions.css";
import "../../../tablet/Support/Instructor/Questions.css";
import { Accordion, Card, Button } from "react-bootstrap";
import { questions1, questions2, questions3, questions4 } from "../../../Global/Support/Instrcutors/Support";

const Questions:React.FC<any> = ({ history }) => {
  return (
    <Page type="support" bannerType="instructor" history={history}>
      <h3 className="support-questions-title">
        Öğrenciler İçin Sıkça Sorulan Sorular
      </h3>
      <h6 className="category-title-1">KATEGORİ BAŞLIĞI - 01</h6>
      <div className="intsructor-questions-accordion-container">
        {
            questions1.map(question =>(
                <Accordion className="instructor-questions-accordion" key={question.id}>
                    <Card className="support-question-container" style={{border: 0}}>
                        <Card.Header className="support-accordion-header">
                            <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="0"
                                className="support-question-title"
                            >
                                {question.title}
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className="support-question-parag">
                                {question.desc}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            ))   
        }
      </div>

      <h6 className="category-title-1">KATEGORİ BAŞLIĞI - 03</h6>
      <div className="intsructor-questions-accordion-container">
        {
            questions3.map(question =>(
                <Accordion className="instructor-questions-accordion" key={question.id}>
                    <Card className="support-question-container" style={{border: 0}}>
                        <Card.Header className="support-accordion-header">
                            <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="support-question-title"
                            >
                            {question.title}
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className="support-question-parag">
                                {question.desc}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            ))   
        }
      </div>

      <h6 className="category-title-1">KATEGORİ BAŞLIĞI - 02</h6>
      <div className="intsructor-questions-accordion-container">
        {
            questions2.map(question =>(
                <Accordion className="instructor-questions-accordion" key={question.id}>
                    <Card className="support-question-container" style={{border: 0}}>
                        <Card.Header className="support-accordion-header">
                            <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="support-question-title"
                            >
                            {question.title}
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className="support-question-parag">
                                {question.desc}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            ))   
        }
      </div>

      <h6 className="category-title-1">KATEGORİ BAŞLIĞI - 04</h6>
      <div className="intsructor-questions-accordion-container">
        {
            questions4.map(question =>(
                <Accordion className="instructor-questions-accordion" key={question.id}>
                    <Card className="support-question-container" style={{border: 0}}>
                        <Card.Header className="support-accordion-header">
                            <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            className="support-question-title"
                            >
                            {question.title}
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className="support-question-parag">
                                {question.desc}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            ))   
        }
      </div>
    </Page>
  );
};

export default Questions;

import React, { useState, useEffect } from "react";
import "../../layout/Login/Login.css";
import Form from "./Form";
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "../../components/Footer";
import NavBar from "../../components/navbars";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import handleLogin from "../../Global/Login/Login";
//import * as x from "../../Global/Configs/conf" 
import { LoginInfoProps } from "../../@types/Login/LoginInfoProps";
import { IDENTITY_URL, ROLES } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import { connection, Init } from "../../Global/Utilities/signalr"
import { trackPromise } from 'react-promise-tracker';
import AlertInfo from "../../components/alerts/AlertInfo";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";

const Login: React.FC<RouteComponentProps> = ({ history }) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();


  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

 // const [email, setEmail] = useState("");
 // const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const actions = {
    setEmail: (event: React.ChangeEvent<HTMLInputElement>) =>
      setEmail(event.target.value),
    setPassword: (event: React.ChangeEvent<HTMLInputElement>) =>
      setPassword(event.target.value),
    setRememberMe: (event: React.MouseEvent<HTMLInputElement>) =>
      setRememberMe(event.currentTarget.checked)
  }

  const controllers = {
    email,
    password,
    rememberMe
  }

  const handleSubmit = async () => {
    if (controllers.email === "" || controllers.password === "") {
      setError(t("general.not_empty_error"));
      setShowError(true);
      return;
    }
    const loginInfo: LoginInfoProps = {
      email: controllers.email.toLocaleLowerCase(),
      password: controllers.password,
      loginMethod: 0,
      role: 0,
      rememberMe: controllers.rememberMe
    }

    const res = await trackPromise(axios.post<any, ReturnProps>(IDENTITY_URL + "/api/v1/Sessions/login", loginInfo));

    if (!res.success) {
      setError(res.error as string);
      setShowError(true);
      return;
    }

    axios.defaults.headers.common['Authorization'] =
      "Bearer " + res.data.accessToken;

    localStorage.setItem("speakingline_refresh_token", res.data.refreshToken);
    localStorage.setItem("speakingline_access_token", res.data.accessToken);
    localStorage.setItem("speakingline_user", JSON.stringify(res.data.user));

    // if(res.data.user.role === ROLES.STUDENT)
    // {
    //    Init();
    // }

    //   connection.on("ClientConnected", (message) => {
    //     alert("ClientConnectedReceived " + message);
    // });

    // handling Success Response
    dispatch({
      type: "USER_LOGGED_IN",
      payload: res.data.user
    })
    if (res.data.user.role === ROLES.STUDENT) {
      //history.push("/reservations/create");
      history.push("/student/dashboard");
    }
    else if (res.data.user.role === ROLES.TEACHER) {
      // history.push("/tutor/profile/settings");
      history.push("/tutor/dashboard");
    }
    else if (res.data.user.role === ROLES.ADMIN) {
      // history.push("/tutor/profile/settings");
      history.push("/admin/dashboard");
    }
  }

  return (
    <React.Fragment>

      <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />

      <div className="login">
      {/* <NavBar type="new_main" history={history} links={[["Çocuklar İçin", "/kids"], ["Be a Tutor", "/tutor"], ["Kurumsal Eğitim", "/corporate"]]} /> */}
        <NavBar
          type="minimal"
          history={history}
        />
        <div className="mainContent">
          <Form
            history={history}
            handleSubmit={handleSubmit}
            controllers={controllers}
            actions={actions}
          />
        </div>
      </div>
      <Footer />

    </React.Fragment>
  )
}


export default Login;

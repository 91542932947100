
const initialState = {
    coupon: {}
}

interface ActionProps {
    type: String,
    payload: any
}

export default function(state=initialState, action:ActionProps) {
    switch(action.type) {
        case "COUPON_UPDATED":
            return state = { ...state, coupon: action.payload }
        default:
            return state
    }
}

import React, { useEffect, useState } from "react";
import Page from "../../components/pages";
import { RouteComponentProps } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Button from "../../components/buttons";
import "../../layout/SubscriptionDetails/SubscriptionDetails.css";
import "../../mobile/SubscriptionDetails/SubscriptionDetails.css";
import "../../tablet/SubscriptionDetails/SubscriptionDetails.css";
import SubscriptionUpgrade from "./SubscriptionUpgrade";
import PauseSubscription from "./PauseSubscriptionDialog";
import { SUBSCRIPTION_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import SubscriptionDetailsContent from "./SubscriptionDetailsContent";
import {useTranslation} from "react-i18next";

const SubscriptionDetailsPage: React.FC<RouteComponentProps> = ({ history }) => {
    const { t } = useTranslation();

    return (
        <Page
            type="dashboard"
            history={history}
            headerTitle={t("home.subscription_packages.subscription_management_title")}
            activePage="SubscriptionDetails"
        >
            <Row>
                   <SubscriptionDetailsContent />
                {/* <Col lg={3}>
                    <div className="subscription-details-my-payments">
                        <div className="subscription-details-my-payments-list">
                            <h4 className="subscription-details-my-payments-title">
                                Ödemelerim
                            </h4>
                        </div>
                        <div className="subscription-details-my-payments-total">
                            <div className="subscription-details-my-payments-total-container">
                                <h4 className="subscription-details-my-payments-total-title">Toplam</h4>
                                <h4 className="subscription-details-my-payments-total-content">₺ 690.75</h4>
                            </div>
                        </div>
                    </div>
                </Col> */}
            </Row>
        </Page>
    )
}

export default SubscriptionDetailsPage;


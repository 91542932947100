
const initialState:any[] = [];

export default function(state=initialState, action:any) {
    switch(action.type) {
        case "STORE_NEW_OWNER_MSG":
            return state = [ ...state, action.payload ]
        default:
            return state
    }
}

import React,{ useState } from "react";
import "../../layout/ChoosePackage/ChoosePackage.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import Content from "./Content";
import TotalBill from "../../components/TotalBill";
import Page from "../../components/pages";
import "../../mobile/UpgradeSubscription/UpgradeSubscription.css";
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import Arrow from "../../assets/payment/arrow-orange.png";
import whiteArrow from "../../assets/payment/arrow-white.png";

type UpgradeSubscriptionProps = RouteComponentProps & DispatchProps;

const UpgradeSubscription:React.FC<UpgradeSubscriptionProps> = ({ history, updateUserLoggedIn }) =>{
    const [totalSum, setTotalSum] = useState(Math.floor(Math.random()*300))
    const [currentMinutes, setCurrentMinutes] = useState(10)
    const [currentWeek, setCurrentWeek] = useState(2);
    const [currentPackageValue, setCurrentPackageValue] = useState(1)
    const handleMinutesChange = (value: Number) =>{
        const sum = Math.floor(Math.random()*50)
        if(value > currentMinutes) setTotalSum(totalSum + sum)
        else setTotalSum(totalSum - sum)
        setCurrentMinutes(value as number)
    }
    const handleWeekChange = (value: Number) =>{
        const sum = Math.floor(Math.random()*50)
        if(value > currentWeek) setTotalSum(totalSum + sum)
        else setTotalSum(totalSum - sum)
        setCurrentWeek(value as number)
    }
    const handlePackageChange = (value:Number) =>{
        const sum = Math.floor(Math.random()*60)
        if(value > currentPackageValue) setTotalSum(totalSum + sum)
        else setTotalSum(totalSum - sum)
        setCurrentPackageValue(value as number)
    }
    const dispatch = useDispatch()
    const handlePaymentClick = () =>{
        dispatch({ type: "USER_LOGGED_IN" })
        history.push("/payment")
    }

    const [buttonArrow, setButtonArrow] = useState(Arrow);
    const handleHoverIn = () => setButtonArrow(whiteArrow);
    const handleHoverOut = () => setButtonArrow(Arrow)
    return (
        <div className="choose-package">
            <Page 
                type="regular" 
                history={history} 
                headerTitle="Upgrade Subscription"
            >
                <Container className="choose-package-wrapper">
                    <Row>
                        <Col lg={8} className="choose-package-container">
                            <Content 
                                handleMinuteChange={handleMinutesChange} 
                                handleWeekChange={handleWeekChange}
                                handlePackageChange={handlePackageChange}
                                currentMinutes={currentMinutes}
                                currentPackageValue={currentPackageValue}
                                currentWeek={currentWeek}
                            />
                        </Col>
                        <Col lg={4}>
                            <TotalBill 
                                productId={1}
                                comp="upgrade" 
                                totalSum={totalSum}
                                currentMinutes={currentMinutes}
                                currentWeek={currentWeek}
                                currentMonth={currentPackageValue}
                                onClickPayment={handlePaymentClick}
                            />
                        </Col>
                    </Row>
                    <div 
                        className="go-back-payment go-pack-update-payment" 
                        onMouseOver={handleHoverIn} 
                        onMouseLeave={handleHoverOut} 
                        onClick={() =>history.goBack()}
                    >
                        <Image 
                            src={buttonArrow} 
                            height={24} 
                            width={24} 
                            style={{
                                marginRight: 10 
                            }} 
                        />
                        <p>Back</p>
                    </div>
                </Container>
            </Page>
        </div>
    )
}

function mapStateToProps(state:any) {
    return {
        auth: state.AuthReducer
    }
}

interface DispatchProps {
    updateUserLoggedIn: (isLogged: any) => void
}

export default connect(mapStateToProps)(UpgradeSubscription);

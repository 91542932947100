import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import "../../layout/EmailVerification/Form.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Container, Form } from "react-bootstrap";
import "../../mobile/EmailVerfication/Form.css";
import { MeetingFinishedIcon } from "../../assets/SVG/meetingFinished";
import { NoReservationFoundIcon } from "../../assets/SVG/SVG";
import Button from "../../components/buttons";

import dots from "../../assets/instructor-reservations/dots.png";
import { IDENTITY_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import {useTranslation} from "react-i18next";

const EmailVerificationResult: React.FC<any> = ({ history, code, lang=1 }) => {
    const { t } = useTranslation();
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const [response, setResponse] = useState<any>(null);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        checkCodeIsValid();
    }, [code]);

    const checkCodeIsValid = async () => {
        if (!code) { return null; }

        const res = await trackPromise(axios.post<any, ReturnProps>(IDENTITY_URL + "/api/v1/users/email_validation/" + code));
        setResponse(res);
        // Handling Error
        if (!res.success) {
            setIsSuccess(false);
            return null;
        }
        setIsSuccess(true);
    }
    return (
        <React.Fragment>
            <div className="email-verification-form">
                {
                    response &&
                    <Card className="shadow email-verification-form-container">
                        <Card.Body>
                            <Container>
                                <div className="email-verification-form-group">
                                    {
                                        isSuccess ? (
                                            <div className="email-verification-form-group">
                                                <h5 className="email-verification-form-title">{t("general.congrats")}</h5>
                                                <h6 className="email-verification-form-subtitle text-muted mt-4">{t("signup.activation_completed")}</h6>
                                                <div> {MeetingFinishedIcon} </div>
                                            </div>
                                        ) : (
                                            <div className="email-verification-form-group">

                                                <h5 className="email-verification-form-title">{t("signup.activation_completion_error")}</h5>
                                                <h6 className="email-verification-form-subtitle text-muted mt-4">{t("signup.invalid_activation_code")}</h6>
                                                <div> {NoReservationFoundIcon} </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </Container>
                        </Card.Body>
                    </Card>
                }
            </div>

        </React.Fragment>

    )
}

export default EmailVerificationResult;

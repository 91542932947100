import React,{ useState } from "react";
import { Modal, Col, Row, Image } from "react-bootstrap";
import Button from "../../components/buttons";
import "../../mobile/SubscriptionDetails/SubscriptionUpgrade.css";
import "../../layout/SubscriptionDetails/SubscriptionUpgrade.css";

import cross from "../../assets/upgrade-package/cross.png";

interface SubscriptionUpgradeProps {
    closeFunc: () => void
}

const SubscriptionUpgrade:React.FC<SubscriptionUpgradeProps> = ({ closeFunc }) =>{
    const [showCancellation, setShowCancellation] = useState(false)
    return(
        <div className="upgrade-subscription">
            <Modal.Dialog className="upgrade-subscription-modal" style={{ border: 0 }}>
                <Modal.Header className="upgrade-subscription-modal-header">
                    <Modal.Title>
                        <h4 className="upgrade-subscription-modal-title">Otomatik Yenileme </h4>
                    </Modal.Title>
                    <Image 
                        src={cross} 
                        height={40} 
                        width={40} 
                        onClick={closeFunc}
                        style={{ cursor: "pointer" }}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} >
                            <div className="subscription-modal-package">
                                <h4 className="subscription-modal-package-title">Abonelik Paketiniz</h4>
                                <div className="subscription-modal-content">
                                    <h6 className="subscription-modal-content-title">Günde 10 dakika</h6>
                                    <h6 className="subscription-modal-content-title">Haftada 2 gün</h6>
                                    <h6 className="subscription-modal-content-title">3 ay süreli</h6>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="subscription-modal-package">
                                <h4 className="subscription-modal-package-title date">Önceki Faturalandırma Tarihi</h4>
                                <div className="subscription-modal-content date">
                                    <h6 className="subscription-modal-content-title date">03 Mayıs 2021</h6>
                                </div>

                                <h4 className="subscription-modal-package-title date" style={{ marginTop: 15 }}>Gelecek Faturalandırma Tarihi</h4>
                                <div className="subscription-modal-content date">
                                    <h6 className="subscription-modal-content-title date">03 Ağustos 2021</h6>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {
                        showCancellation && (
                            <div className="automatic-renewel-cancelled">
                                <h4>Otomatik yenilemeniz bulunmuyor.</h4>
                            </div>
                        )
                    }
                    <div className="automatic-renewel-cancelation">
                        {
                            showCancellation ? (
                                <>
                                    <h4 className="automatic-renewel-title">Otomatik Yenileme İptal Onayı</h4>
                                    <p className="automatic-renewel-parag">
                                        Sit accumsan dictumst felis eleifend sit nunc viverra pharetra. 
                                        Varius dictumst a et maecenas vulputate neque. 
                                        Aliquam urna praesent nulla gravida semper quis cursus. 
                                        Egestas volutpat tellus pretium cursus tristique nibh donec. 
                                        Vel sit duis mi pellentesque. 
                                    </p>
                                    <Button 
                                        type="default" 
                                        primary 
                                        style={{ 
                                            width: "100%", 
                                            marginTop: 15 
                                        }}
                                        className="automatic-renewel-btn"
                                        onClick={() =>setShowCancellation(false)}
                                    >
                                        Otomatil Yenilemeyi İptal Et
                                    </Button>       
                                </>
                            ) : (
                                <>
                                    <h4 className="automatic-renewel-title">Otomatik Yenilemeyi Aktif Et</h4>
                                    <p className="automatic-renewel-parag">
                                        Sit accumsan dictumst felis eleifend sit nunc viverra pharetra. 
                                        Varius dictumst a et maecenas vulputate neque. 
                                        Aliquam urna praesent nulla gravida semper quis cursus. 
                                        Egestas volutpat tellus pretium cursus tristique nibh donec. 
                                        Vel sit duis mi pellentesque. 
                                    </p>
                                    <Button 
                                        type="default" 
                                        primary 
                                        style={{ 
                                            width: "100%", 
                                            marginTop: 15 
                                        }}
                                        className="automatic-renewel-btn"
                                        onClick={() =>setShowCancellation(true)}
                                    >
                                        Otomatil Yenilemeyi Aktif Et
                                    </Button>
                                </>
                            )
                        }
                    </div>
                </Modal.Body>
            </Modal.Dialog>
        </div>
    )
}

export default SubscriptionUpgrade;

import React, { useEffect, useRef, useState } from "react";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { getCroppedImg, getImageFromBase64 } from "../../utils/ImageCrop"
import profilePic from "../../assets/profile-settings/profilePic.png";
import { IDENTITY_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import Button from "../../components/buttons";
import { trackPromise } from "react-promise-tracker";
import AlertInfo from "../alerts/AlertInfo";



const ImageCropAndUpload: React.FC<any> = ({ onFinish, prevImage, img, setImg, setPrevImg }) => {
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [imgRef, setImgRef] = useState<any>(null);
    //  const [img, setImg] = useState(profilePic);
    // const [prevImg, setPrevImg] = useState(profilePic);
    // const [showCropper, setShowCropper] = useState(false);
    const [crop, setCrop] = useState<any>({
        aspect: 1 / 1,
        height: 400,
        width: 400
    })

    const onImageLoaded = (image: any) => {
        setImgRef(image);
    };

    const uploadCroppedImage = async () => {
        // var newImage = await getImageFromBase64(img);
        const canvas = getCroppedImg(imgRef, crop, "Test");
        const blob = await new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                resolve(blob);
            }, 'image/jpeg', 1);
        });

        //console.log("blob",blob);
        const base64 = canvas.toDataURL('image/jpeg');


        var data = new FormData();
        data.append('file', (blob as Blob));
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        const res = await trackPromise(axios.post<any, ReturnProps>(IDENTITY_URL + "/api/v1/users/picture", data, config));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            //            alert(res.error)
            return null;
        }
        setImg(base64);
        setPrevImg(base64);
        //console.log("Successfull");
    }


    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
            <div className="profile-settings-image-cropper">
                <div className="profile-settings-image-cropper-btns-container">
                    <Button
                        type="default"
                        primary
                        className="profile-settings-image-cropper-btn"
                        onClick={() => {
                            uploadCroppedImage();
                            onFinish()
                        }}
                    >
                        DONE
                    </Button>
                    <Button
                        type="default"
                        primary
                        className="profile-settings-image-cropper-btn"
                        onClick={() => {
                            //   setImg(prevImg)
                            onFinish()
                        }}
                    >
                        CANCEL
                    </Button>
                </div>
                <div className="profile-settings-image-cropper-container">
                    <ReactCrop
                        onImageLoaded={onImageLoaded}
                        src={img}
                        crop={crop}
                        onChange={newCrop => setCrop(newCrop)}
                    />
                </div>
            </div>
        </React.Fragment>

    )

}

export default ImageCropAndUpload;
import cross from "../assets/upgrade-package/cross.png";
import personImg from "../assets/defaultImg.svg";
import phoneIcon from "../assets/phoneIcon.svg";
import missedCallIcon from "../assets/missedCallIcon.svg";
import "../layout/CallingModal/CallingModal.css";
import { Modal, Image, Container } from 'react-bootstrap';
import TeacherAssessmentModal from "../components/modals/VideoChatModal/TeacherAssessmentModal";
import StudentRateModal from "../components/modals/VideoChatModal/StudentRateModal";
import Payment from "./Payment/Payment";
import { useHistory } from "react-router";
import VideoModal from "../components/modals/VideoModal/VideoModal";
import ChatDialog from "../components/chat/ChatDialog";
import React, {useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// @ts-ignore
import VideoRecorder from 'react-video-recorder'
import AlertInfo from "../components/alerts/AlertInfo";
//import StudentVideoChat from "./StudentVideoChat/StudentVideoChat";
import ReactFlagsSelect from "react-flags-select";


const Preview4 = () => {
    const hist = useHistory();
    const [selected, setSelected] = useState("");
    return (
        <React.Fragment>



            {/* <div style={{ display: "flex", width: 1000, height: 1000 }}>
                <h1>asdad</h1>
            </div>

            <div className="fixed-bottom"><ChatDialog /></div> */}
            {/* <div style={{width:"500px", height:"500px"}}>

                <VideoRecorder
                    chunkSize={250}
                    constraints={{
                        audio: true,
                        video: true
                    }}
                    timeLimit={10000}
                    countdownTime={3000}
                    dataAvailableTimeout={500}
                    isFlipped
                    isOnInitially
                    replayVideoAutoplayAndLoopOff
                    showReplayControls
                    onRecordingComplete={(videoBlob: any) => {
                        // Do something with the video...
                        //console.log('videoBlob', videoBlob);

                    }}
                />
            </div> */}
            {/* <AlertInfo show = {true} isAutoHide = {false} message = "Bu bir mesaj!" type = {1} setShow = {(d:any)=>{}}/>

            <VideoModal show={true}
                hide={() => { }}
            /> */}


        </React.Fragment>
    )
}

export default Preview4;

import React from "react";
import SubscriptionSummaryUnavailable from "./SubscriptionSummaryUnavailable";
import SubscriptionSummaryAvailable from "./SubscriptionSummaryAvailable";
import SubscriptionSummaryAvailableOnlyOneTime from "./SubscriptionSummaryAvailableOnlyOneTime";


interface SubscriptionSummaryCardProps {
    subscriptionDetails: any;
}

const SubscriptionSummaryCard: React.FC<SubscriptionSummaryCardProps> = ({
    subscriptionDetails
}) => {

    //     /* 
    //     * Checking For The Subscription Type (Periodic / Anytime)
    //     * productType === 1 --> Periodic
    //     * productType === 0 --> Anytime
    //     */

    //     if(
    //         !subscriptionDetails || 
    //         !Array.isArray(subscriptionDetails) ||
    //         !subscriptionDetails?.filter((t : any) => t.product.productType === 1)
    //     )
    //     {
    //         return  (
    //             <div className="student-instructors-page-card-container">
    //                 <Image 
    //                     src={cardTwoImg}   
    //                 />
    //             </div>
    //         )
    //     }

    //     const subscription : any = subscriptionDetails.filter((t : any) => t.product.productType === 1)[0]

    //     var dayLevelElements : any[] = [];   

    //     if(subscription.usedDayCounts > 0)
    //     {
    //         for (let index = 0; index < subscription.usedDayCounts; index++) {
    //             dayLevelElements.push(true);
    //         }
    //     }

    //     if(subscription.leftDayCounts > 0)
    //     {
    //         for (let index = 0; index < subscription.leftDayCounts; index++) {
    //             dayLevelElements.push(false);
    //         }
    //     }

    //    // const subscription = {usedDayCounts : 0, leftDayCounts : 2, todayAvailableMinutes : 24};
    //     return (
    //         <div className="student-instructors-page-card-container">
    //         <Image 
    //             src={cardTwoImg}   
    //         />
    //         <div className="student-instructors-page-card-content">
    //             <h4 className="student-instructors-page-card-title">
    //                 Ac volutpat nulla nisi
    //             </h4>
    //             <h4 className="student-instructors-page-card-date">
    //                 Day {subscription.usedDayCounts} of {subscription.usedDayCounts + subscription.leftDayCounts } 
    //                 - {subscription.todayTotalMinutes} minutes
    //             </h4>
    //             <div className="student-instrcutors-page-level-container">
    //                 {dayLevelElements.map((item,idx) => item === true ? <div key={idx} className="student-instructors-page-level filled" /> : <div key={idx} className="student-instructors-page-level"/>)}
    //             </div>
    //             <div className="student-instructors-page-other-content-container">
    //                 <h4 className="student-instructors-page-card-title">
    //                     Today’s Minutes
    //                 </h4>
    //                 <h4 className="student-instructors-page-card-call-duration">
    //                     {subscription.todayAvailableMinutes} min. left
    //                 </h4>
    //             </div>
    //         </div>

    //         <div className="student-instructors-page-down-arrow"></div>
    //         {/* <div className="student-instructors-page-down-arrow-container">
    //             <Image src={downArrow} />
    //         </div> */}
    //     </div>

    /* 
    * !subscriptionDetails.subscriptions || 
    * !subscriptionDetails.subscriptions?.filter((t : any) => t.productType === 1)
    */
    //subscriptionDetails.filter((t: any) => t?.subscriptionType === 1)

    let availableSubs = 0;
    if (
        subscriptionDetails &&
        Array.isArray(subscriptionDetails) &&
        subscriptionDetails?.filter((t: any) => t?.subscriptionType === 1).length > 0
    )
         availableSubs = 1;
    else if (
        subscriptionDetails &&
        Array.isArray(subscriptionDetails) &&
        subscriptionDetails?.filter((t: any) => t?.subscriptionType === 0).length > 0 &&
        subscriptionDetails?.filter((t: any) => t?.subscriptionType === 0)[0]?.todayAvailableMinutes > 0
    )
        availableSubs = 2

//    availableSubs = 2;    
    return (
        <>
            {
                availableSubs == 0 ? <SubscriptionSummaryUnavailable /> : (
                    availableSubs == 1 ? <SubscriptionSummaryAvailable
                        subscriptionDetails={subscriptionDetails}
                    /> : <SubscriptionSummaryAvailableOnlyOneTime
                        subscriptionDetails={subscriptionDetails}
                    />
                )
            }
        </>
    )
};

export default SubscriptionSummaryCard;
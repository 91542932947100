import React, { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "../../layout/Chat/ChatDialog.css"
import { MESSAGES_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import moment from "moment";
import { Image } from "react-bootstrap";
import { closeIcon, sendChatButtonIcon, personImg } from "../../assets/SVG/SVG";
import { SpeakingLineIcon } from "../../assets/SVG/speakinglineIcon";
import { connection, IsConnected } from "../../Global/Utilities/signalr"
import AlertInfo from "../alerts/AlertInfo";
import { v4 as uuidv4 } from 'uuid';

// import "bootstrap/dist/css/bootstrap.min.css";

const ChatDialog: React.FC<any> = ({ chatProp, chatDialogProp, auth }) => {
    const messagesEndRef = useRef<any>();
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [success, setSuccess] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);

    const [messageText, setMessageText] = useState("");

    const [messagesDataSource, setMessagesDataSource] = useState<any[any]>([]);
    const [talkingWith, setTalkingWith] = useState({
        userName: "",
        picture: "",
    })
    const [roomType, setRoomType] = useState("");

    useEffect(() => {
        if ((chatDialogProp?.chatDialog && chatDialogProp?.chatDialog?.isOpen)) {
            if (chatDialogProp?.chatDialog?.roomData) {
                setTalkingWith({
                    userName: (chatDialogProp?.chatDialog?.roomData?.roomType === 1 ? "Support" : (chatDialogProp?.chatDialog?.roomData?.talkingWith.name + " " + chatDialogProp?.chatDialog?.roomData?.talkingWith.surname?.charAt(0))),
                    picture: (chatDialogProp?.chatDialog?.roomData?.roomType === 1 ? SpeakingLineIcon : chatDialogProp?.chatDialog?.roomData?.talkingWith.photoUrl),
                })
                setRoomType(chatDialogProp?.chatDialog?.roomData?.roomType);
            }
            LoadPreviousMessages();
        }
    }, [chatDialogProp]);

    useEffect(() => {
        if ((chatProp && chatDialogProp?.chatDialog?.isOpen && chatProp.chatMessage?.roomId === chatDialogProp?.chatDialog?.roomId)) {
            receiveNewMessage();
        }
    }, [chatProp]);

    useEffect(() => {
        scrollToBottom();
    }, [messagesDataSource]);

    const receiveNewMessage = async () => {
        var list = [...messagesDataSource];
        list.push({
            guid : uuidv4(),
            sender: "other",
            messageText: chatProp.chatMessage?.messageText,
            messageDate: moment(),
        });
        setMessagesDataSource(list);
    }

    const sendNewMessage = async () => {
        if (!messageText) {
            return;
        }
        var param = {
            message_text: messageText,
            room_id: chatDialogProp.chatDialog.roomId,
            room_type: roomType
        };
        try {
            if (IsConnected())
            {
                 console.log("Connected");
                 await connection.invoke("SendMessageFromClient", "chat", param);
            }
            else
            {
                 console.log("Not Connected");
                 await axios.post<any, ReturnProps>(MESSAGES_URL + "/api/v1/rooms/message",param);
            }

            //console.log("Done");
        } catch (error:any) {
            setError(error);
            setShowError(true);
            return;
        }
        var list = [...messagesDataSource];
        list.push({
            guid : uuidv4(),
            sender: "me",
            messageText: messageText,
            messageDate: moment(),
        });
        setMessageText("");
        setMessagesDataSource(list);
    }

    const scrollToBottom = () => {
        messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const LoadPreviousMessages = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(MESSAGES_URL + "/api/v1/rooms/" + chatDialogProp.chatDialog.roomId + "/messages?pageSize=30"));
        // Handling Error
        if (!res.success && !res.data) {
            setError(res.error as string);
            setShowError(true)
            return null;
        }
        if (!chatDialogProp?.chatDialog?.roomData) {
            setTalkingWith({
                userName: (res.data.roomType === 1 ? "Support" : (res.data.talkingWith?.name + " " + res.data.talkingWith?.surname?.charAt(0))),
                picture: (res.data.roomType === 1 ? SpeakingLineIcon : res.data.talkingWith?.photoUrl),
            })
            setRoomType(res.data.roomType);
        }
        if (res.data.messages) {
            var list = [...res.data.messages];
            list.sort((message: any, messageSecond: any) => (message.createdAt > messageSecond.createdAt) ? 1 : -1);
            setMessagesDataSource(list.map((item: any) => {
                return {
                    guid : item.guid,
                    sender: (item.senderUser?.guid === auth.user?.guid ? "me" : "other"),
                    messageText: item.messageText,
                    messageDate: moment(item.createdAt * 1000),
                }
            }));
        }
    }



    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />

            {(!chatDialogProp?.chatDialog || !chatDialogProp?.chatDialog?.isOpen) ? (
                <></>
            ) : (
                <div className="chatdialog-container">
                    <div className="col-md-8 col-xl-12 chat">
                        <div className="chat-card card">
                            <div className="chat-card-header">
                                <div className="img_cont">
                                    <img src={talkingWith.picture} className="rounded-circle user_img" />
                                    {/* <span className="online_icon"></span> */}
                                </div>
                                <div className="user_info">
                                    <span>{talkingWith.userName}</span>
                                    {/* <p>1767 Messages</p> */}
                                </div>
                                <div className="chat-card-header-close">
                                    <button style={{ background: "none" }} onClick={() => {
                                        dispatch({
                                            type: "CHAT_DIALOG_IS_SHOWN",
                                            payload: {
                                                isOpen: false,
                                                roomId: null
                                            }
                                        });
                                    }}>
                                        {closeIcon}
                                    </button>
                                    {/* <Image
                                        src={close}
                                        height={48}
                                        width={48}
                                    // onClick={hideChatRoom}
                                    /> */}
                                </div>
                            </div>
                            <div className="chat-card-body card-body msg_card_body">
                                {
                                    messagesDataSource?.map((item: any) => (
                                        item.sender === "me" ? (
                                            <div key={item.guid} className="d-flex justify-content-end mb-4">
                                                <div className="msg_cotainer_send">
                                                    {item.messageText}
                                                    <span className="msg_time">{item.messageDate.format("HH:mm")}</span>
                                                </div>
                                                {/* <div className="img_cont_msg rounded-circle user_img_msg">
                                                    {auth.user.pictureUrl ? (
                                                        <img src={auth.user.pictureUrl} className="rounded-circle user_img_msg" />
                                                    ) : (
                                                        personImg
                                                    )}
                                                </div> */}

                                            </div>
                                        ) : (
                                            <div key={item.guid} className="d-flex justify-content-start mb-4">
                                                {/* <div className="img_cont_msg">
                                                    {talkingWith.picture ? (
                                                        <img src={talkingWith.picture} className="rounded-circle user_img_msg" />
                                                    ) : (
                                                        personImg
                                                    )}
                                                </div> */}
                                                <div className="msg_cotainer">
                                                    {item.messageText}
                                                    <span className="msg_time">{item.messageDate.format("HH:mm")}</span>
                                                </div>

                                            </div>
                                        )
                                    ))
                                }
                                <div ref={messagesEndRef} />
                            </div>
                            <div className="chat-card-footer">
                                <div className="chat-card-footer-text">
                                    <textarea name="" onKeyPress={(e: any) => {
                                        //console.log(e);
                                        if (e.key === "Enter") sendNewMessage()
                                    }} className="type_msg" value={messageText} onChange={(e) => { setMessageText(e.target.value) }} placeholder="Type your message..."></textarea>
                                </div>
                                <div className="chat-card-footer-send-button">
                                    <button style={{
                                        background: "none", width: "36px",
                                        height: "36px"
                                    }} onClick={sendNewMessage}>
                                        {sendChatButtonIcon}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            )}

        </React.Fragment>
    )
};

interface StateProps {
    auth: any,
    chatProp: any
    chatDialogProp: any
}
function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer,
        chatProp: state.ChatReducer,
        chatDialogProp: state.ChatDialogReducer
    }
}

export default connect(mapStateToProps)(ChatDialog);

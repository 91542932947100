import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../theme";
import { Image } from "react-bootstrap";
import "../../layout/inputs/inputs.css";
import moment from "moment"

import calendar from "../../assets/student-history/calendar.png";
// @ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { useTranslation } from "react-i18next";

// @ts-ignore
//import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle';

const TimeRangeInputContainer = styled.div`
    height: 40px;
    display: flex;
    width: 100%;
`;

const TimeRangeType = styled.div`
    width: 25%;
    min-width: 160px;
    background-color: #E0E0E0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    align-items: center;
    padding-left: 12px;
`;

const TimeRangeTypeText = styled.h4`
    text-align: left;
    color: #41566D;
    font-weight: 500;
    font-size: 16px;
    font-family: ${theme.text.fonts.poppins};
    margin: 0;
    padding: 0;
`;

const TimeRangeInputWrapper = styled.div`
    width: 75%;
    height: 40px;
    display: flex;
    border: 1px solid #CACCCF;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #FFFCFC;
    box-sizing: border-box;
    padding: 12px 14px;
    align-items: center;
`;

const TimeRangeInput = styled.div`
    height: 100%;
    width: 95%;
    border: 0;
    background-color: transparent;
    border: 1px solid #FF00CF;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #929598;
    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
    }
`;

interface TimeRangeInfos {
    onChange?: (e: any) => void;
    defaultValue?: any[]
}


const TimeRange: React.FC<TimeRangeInfos> = ({
    defaultValue,
    onChange = (e) => { }
}) => {
    const { t } = useTranslation();
    const [value, setValue] = useState();
    //  const defaultValue = [moment().add(-7,"day"), moment()];
    return (
        <TimeRangeInputContainer>
            <TimeRangeType>
                <TimeRangeTypeText>{t("general.date_range")}</TimeRangeTypeText>
            </TimeRangeType>

            <DateRangePicker className="timeRangeInputWrapper"
                onChange={(e: any) => {
                    // setValue(e);
                    onChange(e);
                }}
                clearIcon={null}
                value={defaultValue}
                format="dd.MM.yyyy"
            />

            {/* <TimeRangeInputWrapper>
                <TimeRangeInput placeholder="Tüm Zamanlar"> 
                <DateRangePicker className = "TimeRangeInput"
        onChange={onChange}
        value={value}
        
      />}
               </TimeRangeInput> 
                <Image src={calendar} /> 
            */ }

        </TimeRangeInputContainer>
    )
}

export default TimeRange;

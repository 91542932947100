import React, { useState, useEffect, useRef } from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import { ImageContainer, CardImage, CardInfo, CardName, Nationality, Rating, RatingValue, RatingOver, LanguagesContainer, Language, ExpertiseContainer, Expertise } from '../../../components/cards/StyledComponents';
import uk from '../../../assets/cards/uk.png';
import star from "../../../assets/cards/star.png";
import heart from "../../../assets/cards/heart.png";
import heartFill from "../../../assets/cards/heartFill.png";
import Iframe from '../../../components/Iframe';
import ReservationCalendarForTeacher from './ReservationCalendarForTeacher'
import "../../../layout/InstructerDetailModal/InstructurDetail.css"
import { IDENTITY_URL, STUDENT_DASHBOARD_URL, CONVERSATION_URL, MESSAGES_URL } from "../../../Global/Constants/constants";
import { ReturnProps } from "../../../@types/functions";
import axios from "axios";
import { useSetState } from '../../../Global/Utilities/getsetstate';
import Languages from '../../../pages/TutorProfileSettings/Languages';
import RatingComp from '../../cards/TutorCard/RatingComp';
import CallingModal from '../meeting/CallingModal';
import { trackPromise } from 'react-promise-tracker';
import AlertInfo from '../../alerts/AlertInfo';
import { CreateDirectMessage } from "../../../Global/Chat/Chat"
import { useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';
import profile from "../../../assets/blank_profile.png";
import { useTranslation } from "react-i18next";

interface TutorDetailsInterface {
    show: Boolean;
    hide: () => void,
    userId: String;
}
const TutorDetailsModal: React.FC<TutorDetailsInterface> = ({
    show,
    hide,
    userId
}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const reservationDivRef = useRef<any>();

    const [isShowCallingModal, setIsShowCallingModal] = useState(false)
    const [meetingRequestId, setMeetingRequestId] = useState("")

    const [name, setName] = useState("");
    const [thumb, setThumb] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [rating, setRating] = useState<Number>();
    const [experience, setExperience] = useState("");
    const [aboutMe, setAboutMe] = useState("");
    const [languages, setLanguages] = useState([]);
    const [expertises, setExpertises] = useState([]);
    const [hobbies, setHobbies] = useState([]);
    const [personalities, setPersonalities] = useState([]);

    const [userStatus, setUserStatus] = useState("inactive");

    const [isFavourite, setIsFavourite] = useState(false);
    const [hideTeacherForMe, setHideTeacherForMe] = useState(false);
    const [informMeWhenTeacherIsOnline, setInformMeWhenTeacherIsOnline] = useState(false);
    const [userProfile, setUserProfile] = useState<any>();

    // "isFavourite": true,
    // "hideTeacherForMe": false,
    // "informMeWhenTeacherIsOnline": true

    const onPreferencesChanged = async (key: string, value: boolean) => {
        var params = { teacherId: userId, [key]: value }
        const res = await trackPromise(axios.put<any, ReturnProps>(IDENTITY_URL + "/api/v1/students/student-preferences-for-teacher", params));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            // alert(res.error)
            return null;
        }
        if (key === "isFavourite")
            setIsFavourite(value)
        //console.log(res.data);
    }


    const callClicked = async () => {
        //console.log("callClicked");
        var params = { toUserGuid: userId }
        const res = await trackPromise(axios.post<any, ReturnProps>(CONVERSATION_URL + "/api/v1/meetings/meeting-request", params));
        if (!res.success || !res.headers.location) {
            setError(res.error as string);
            setShowError(true)
            //alert(res.error)
            return null;
        }
        const header: String = res.headers.location
        const meetReqId = header.split("/")[1];

        // var meetReqId = "ae76021c-33bd-4fc4-b371-8cac9bd9353b";
        setMeetingRequestId(meetReqId);
        setIsShowCallingModal(true)
        // //console.log(res.data);
    }

    const LoadTeacherDetails = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(IDENTITY_URL + "/api/v1/teachers/" + userId + "/profile"));
        // Handling Error
        if (!res.success || !res.data?.user) {
            setError(res.error as string);
            setShowError(true)
            //  alert(res.error)
            return null;
        }
        setUserProfile(res.data);

        //console.log(res.data);
        setLanguages(res.data.knownLanguage);
        setExpertises(res.data.expertiseOns);
        setName(res.data.user.name + " " + res.data.user.surname?.charAt(0) + ".");
        setVideoUrl(res.data.videoLink);
        setAboutMe(res.data.aboutMe);
        setRating(res.data.rate);
        setCountryCode(res.data.user.countryCode);
        setExperience(res.data.experience);
        setThumb(res.data.user.photoUrl);
        setHobbies(res.data.hobbies);
        setPersonalities(res.data.personalities);
        // set
    }

    const LoadStudentPreferencesForTeacher = async () => {
        const res = await axios.get<any, ReturnProps>(IDENTITY_URL + "/api/v1/students/student-preferences-for-teacher?teacherId=" + userId);
        // Handling Error
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            //  alert(res.error)
            return null;
        }
        //console.log(res.data);

        setIsFavourite(res.data?.isFavourite);
        setHideTeacherForMe(res.data?.hideTeacherForMe);
        setInformMeWhenTeacherIsOnline(res.data?.informMeWhenTeacherIsOnline);
    }

    const sendDirectMessage = async () => {
        var param = { talkingWithId: userId }
        const res = await trackPromise(axios.post<any, ReturnProps>(MESSAGES_URL + "/api/v1/rooms/directmessageroom", param))
        if (res.error || !res.headers.location) {
            setError(res.error as string);
            setShowError(true);
            return;
        }
        else {
            hide();
            const header: String = res.headers.location
            const id = header.split("/")[1];
            dispatch({
                type: "CHAT_DIALOG_IS_SHOWN",
                payload: {
                    isOpen: true,
                    roomId: id,
                }
            });
        }
    }

    const LoadTeacherStatus = async () => {
        const res = await axios.get<any, ReturnProps>(STUDENT_DASHBOARD_URL + "/api/v1/teacher/" + userId + "/status");
        // Handling Error
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            // alert(res.error)
            return null;
        }

        if (!res.data || res.data === 0)
            setUserStatus("inactive");
        else if (res.data === 1)
            setUserStatus("online");
        else if (res.data === 2)
            setUserStatus("busy");
    }

    useEffect(() => {
        //     if(!show)
        //         return;

        LoadTeacherDetails();
        LoadStudentPreferencesForTeacher();
        LoadTeacherStatus();
    }, [show]);


    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
            {
                userProfile && (
                    <div>
                        <Modal show={show} onHide={hide} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>{name}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div className="tutor-infor">
                                    <div className="tutor-img">
                                        <ImageContainer className={userStatus as string || ""}>
                                            <CardImage src={thumb as string ?? profile} />
                                        </ImageContainer>
                                    </div>

                                    <div className="tutor-detail">
                                        <div className="d-flex name-details">
                                            <CardInfo>
                                                <CardName>{name}</CardName>
                                                {countryCode && <Nationality src={countryCode as string} height={14} width={23} />}
                                            </CardInfo>

                                            <div className="time-rate">
                                                <RatingComp ratingValue={rating} />
                                            </div>

                                        </div>

                                        {/* <div className="d-flex tutor-avail">
                                            <Button variant="outline-dark">Eğitmeni gizle</Button>
                                            <Button variant="outline-dark" className="ml-3">Çevrimiçi olduğunda haber ver</Button>
                                        </div> */}
                                        <div className="d-flex res-actions">
                                            <Button variant="danger" disabled={userStatus !== "online"} onClick={callClicked}>{t("general.call")}</Button>
                                            <Button variant="danger" onClick={() => { reservationDivRef?.current?.scrollIntoView({ behavior: "smooth" }); }}>{t("general.create_reservation")}</Button>
                                            <Button variant="danger" onClick={sendDirectMessage}>{t("general.send_message")}</Button>

                                            {
                                                isFavourite ? (
                                                    <Image
                                                        src={heartFill}
                                                        style={{
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => {
                                                            onPreferencesChanged("isFavourite", false)
                                                        }}
                                                    />
                                                ) : (
                                                    <Image
                                                        src={heart}
                                                        style={{
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => {
                                                            onPreferencesChanged("isFavourite", true)
                                                        }}
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    videoUrl && (
                                        <div className="video-wrapper">
                                            {/* <Iframe
                                    style={{
                                        position: 'absolute', top: '0', left: '0', bottom: '0', right: '0', width: '100%', height: '100%',
                                    }}
                                    srcLink="https://www.youtube.com/embed/tgbNymZ7vqY"
                                /> */}
                                            <ReactPlayer width="560" height="315" controls={true} url={videoUrl} />
                                        </div>
                                    )
                                }


                                <div className="tutor-detail-wrapper">
                                    {/* <div className="content-inner">
                            <h3>Konuştuğu Diller</h3>

                            <span className="tag danger">British/English</span>
                            <span className="tag danger">N.American/English</span>
                        </div>
                        <div className="content-inner">
                            <h3>Uzmanlık Alanları</h3>
                            <span className="tag info">Conversation Practice</span>
                            <span className="tag info">TOEFL</span>
                            <span className="tag info">Kids Lesson</span>
                        </div> */}

                                    <div className="content-inner">
                                        <h3>{t("tutor.spoken_lang")}</h3>
                                        {/* <LanguagesContainer> */}
                                        <div style={{ display: "flex" }}>
                                            {
                                                languages.map((item: any) => (
                                                    <Language key={item.accent?.guid ?? item.language.guid}>
                                                        {item.accent?.name ?? item.language.name}
                                                        <span
                                                            style={{
                                                                fontWeight: 600
                                                            }}
                                                        > / {item.language.name}
                                                        </span>
                                                    </Language>
                                                ))
                                            }
                                        </div>
                                        {/* </LanguagesContainer> */}
                                    </div>

                                    {expertises?.length > 0 && (
                                        <div className="content-inner">
                                            <h3>{t("tutor.expertise")}</h3>
                                            <ExpertiseContainer>
                                                {
                                                    expertises.map((item: any) => (
                                                        <Expertise key={item.parameter.guid}>{item.parameter.name}</Expertise>
                                                    ))
                                                }
                                            </ExpertiseContainer>
                                        </div>
                                    )}

                                    {/* <div className="content-inner">
                            <h3>Ders Verme Tarzı</h3>
                            <p>Funny, Patient, Sociable, Talkative, Cheerful, Outgoing, Easygoing</p>
                        </div> */}

                                    {personalities?.length > 0 && (<div className="content-inner">
                                        <h3>{t("tutor.style")}</h3>
                                        <p>
                                            {
                                                personalities.map((item: any) => (
                                                    <span key={item.parameter.guid}>{item.parameter.name}, </span>
                                                ))
                                            }
                                        </p>
                                        {/* <p>Sports, Socializing, Sience and Technology, Voluenteering, Training, Learning, Driving</p> */}
                                    </div>
                                    )
                                    }

                                    {hobbies?.length > 0 && (<div className="content-inner">
                                        <h3>{t("tutor.hobbies")}</h3>
                                        <p>
                                            {
                                                hobbies.map((item: any) => (
                                                    <span key={item.parameter.guid}>{item.parameter.name}, </span>
                                                ))
                                            }
                                        </p>
                                        {/* <p>Sports, Socializing, Sience and Technology, Voluenteering, Training, Learning, Driving</p> */}
                                    </div>
                                    )
                                    }
                                    <div className="content-inner" hidden={!aboutMe}>
                                        <h3>{t("tutor.about")}</h3>
                                        <p>{aboutMe}</p>
                                    </div>
                                    {/* <div className="content-inner">
                            <h3>Eğitim</h3>
                            <p>I have a bachelors degree in Linguistics from the University of Dusseldorf, Germany and a 120 hour TESOL teaching certificate.</p>
                        </div> */}
                                    <div className="content-inner" hidden={!experience}>
                                        <h3>{t("tutor.about")}</h3>
                                        <p>{experience}</p>
                                    </div>
                                </div>

                                <div className="calendear-container" ref={reservationDivRef}>
                                    <h6>{t("tutor.availability")}</h6>
                                    <ReservationCalendarForTeacher userName={name} userId={userId} />
                                </div>

                            </Modal.Body>

                        </Modal>
                    </div>
                )
            }
            {
                isShowCallingModal &&
                <CallingModal
                    show={isShowCallingModal}
                    hide={() => setIsShowCallingModal(false)}
                    oppenentId={userId}
                    opponentName={name}
                    meetingRequestId={meetingRequestId}
                    opponentPicture={thumb as String} />
            }
        </React.Fragment>
    )
}
export default TutorDetailsModal;
import React from "react";
import "../../layout/EmailVerification/EmailVerification.css";
import Form from "./Form";
import Footer from "../../components/Footer";
import NavBar from "../../components/navbars";

const EmailVerification:React.FC<any> = ({ history, location }) =>{
    const userId = location.state?.userId;
    const userEmail = location.state?.email;
    const type = location.state?.type;
    const role = location.state?.role ?? 1;

    return (
        <div className="email-verification">    
            <NavBar type="minimal" history={history} />
            <Form type={type} email = {userEmail} userId = {userId} history={history} role = {role} />
            <Footer />
        </div>
    )
}

export default EmailVerification;

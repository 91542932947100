import React  from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import { calender } from '../../../assets/SVG/SVG';
import moment from 'moment';
import {useTranslation} from "react-i18next";

interface TutorConfirmationInterface {
    userId : string,
    userName: string,
    show: Boolean;
    hide: () => void;
    approveClicked: () => void;
    selectedMeetingMinutes: any;
    date: any,
    timeSlot: any
}
const TutorConfirmationModal: React.FC<TutorConfirmationInterface> = ({
    userId,
    userName,
    show,
    approveClicked,
    hide,
    selectedMeetingMinutes,
    date,
    timeSlot
}) => {
    const { t } = useTranslation();
    //console.log("Date " +date+" --- "+" $$ "+moment(date) +"   %%" + moment(date).format('DD MMMM YYYY, dddd'));
    return (
        <div className="confirmationModal">
            <Modal show={show} onHide={hide}>
                <Modal.Body>
                    <div className="confirmationModalBody">
                        <div className="cal-icon">{calender}</div>
                        <h5>{t("reservation.confirmation_title")}</h5>
                        <div className="content-inner">
                            <p className="large-para"><b>{ moment(date).format('DD MMMM YYYY, dddd')}</b> tarihinde, saat <b>{moment(timeSlot).format("HH:mm")}</b>’de eğitmenlerimizden <b>{userName} </b> ile <span>{selectedMeetingMinutes} dakikalık </span> 
                   seans rezervasyonunu onaylıyor musunuz?
                 </p>

                            <p> {t("reservation.confirmation")} </p>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer className="customModalFooter">
                    <Button onClick={hide} variant="secondary">{t("reservation.confirmation_reject")}</Button>
                    <Button onClick={approveClicked} variant="primary"> {t("reservation.confirmation_ok")}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default TutorConfirmationModal;
import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { INITIAL_EVENTS } from '../../utils/const';
import { addAvaliableEventsFromServer, addHistoryFromServer, addMeetingsFromServer } from '../../utils/TutorDashboard/EventHelper';
import { Button } from 'react-bootstrap';
import SetQuickAvailabilityModal from '../../components/modals/tutorDashbaord/setQuickAvailabilityModal';
import ReservedDetailsModal from '../../components/modals/tutorDashbaord/reserveDetailsModal';
import { todayStr } from '../../utils/helper';
import moment from 'moment';

import { CONVERSATION_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import Overlay from 'react-bootstrap/Overlay'
import { trackPromise } from 'react-promise-tracker';
import AlertInfo from '../alerts/AlertInfo';

interface CalenderInterface {
    readOnly: Boolean;
    setREadOnly: any,
}
const Calender: React.FC<CalenderInterface> = ({ readOnly, setREadOnly }) => {
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);

    const [availableItemsResponse, setAvailableItemsResponse] = useState<any>(null);
    const [reservationResponse, setReservationResponse] = useState<any>(null);

    const [allEvents, setAllEvents] = useState<any>([]);
    const [reservationEvents, setReservationEvents] = useState<any>([]);
    const [availableTimeEvents, setAvailableTimeEvents] = useState<any>([]);
    const [historyEvents, setHistoryEvents] = useState<any>([]);

    const [isShowQuickAvailabilityModal, setIsShowQuickAvailabilityModal] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState({})
    const [isShowReserveCard, setIsShowReserveCard] = useState(false)

    useEffect(() => {
        LoadAvailableTimes();
        LoadReservations();
        LoadHistoryEvents();

        // var events = [];
        // if(available && available.length > 0)
        // {

        // }

        // var filtered = INITIAL_EVENTS(readOnly).map((itm, key) => {
        //     if (itm.start < new Date().toISOString() && !itm.display) {
        //         itm.backgroundColor = "#CACCCF";
        //         itm.color = "#CACCCF"
        //     }
        //     return itm
        // });
        // setEvent(filtered)
    }, [])

    // const onHandleSetAvailability = (timeSlot: any) => {
    //     var newArray = [...events]
    //     timeSlot.map((itm: any) => {
    //         newArray.push({
    //             start: itm.startDate,
    //             end: itm.endDate,
    //             display: 'background',
    //             backgroundColor: '#FDD9D1'
    //         })
    //     })
    //     setEvent(newArray)
    //     setIsShowQuickAvailabilityModal(false)
    // }

    const handleClickEvent = (selectInfo: any) => {
        setSelectedEvent(selectInfo.event)
        setIsShowReserveCard(true)
    }
    const handleDateSelect = (selectInfo: any) => {
        let calendarApi = selectInfo.view.calendar
        calendarApi.unselect() // clear date selection
        if (selectInfo.startStr < new Date().toISOString() || selectInfo.endStr < new Date().toISOString()) {
            return;
        }
      
        // events.map((itm: any) => {
        //     newArray.push({
        //         start: itm.startDate,
        //         end: itm.endDate,
        //         display: 'background',
        //         backgroundColor: '#FDD9D1'
        //     })
        // })
        
        calendarApi.addEvent({
            start: selectInfo.startStr,
            end: selectInfo.endStr,
            display: 'background',
            backgroundColor: '#FDD9D1'
        })
    }

    useEffect(() => {
        if (moment(endDate).isBefore(moment())) {
            LoadHistoryEvents();
        }
    }, [startDate])


    useEffect(() => {
        if(availableItemsResponse || historyEvents || historyEvents)
        {
            LoadEvents();
        }
    }, [availableTimeEvents, historyEvents, reservationEvents])

    const LoadAvailableTimes = async () => {
        const res = await trackPromise( axios.get<any, ReturnProps>(CONVERSATION_URL + "/api/v1/available-dates?start=" + moment().unix()));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            //console.log("HATA");
        }
        //console.log(res);

        if(res.data  && res.data.length > 0)
        {
            var available= addAvaliableEventsFromServer(res.data, readOnly);
            setAvailableTimeEvents(available);
        }
    }

    const LoadHistoryEvents = async () => {
        if(!startDate || !endDate)
        {
            return;
        }
        const res = await trackPromise( axios.get<any, ReturnProps>(CONVERSATION_URL + "/api/v1/history?pageNumber=1&pageSize=60&start=" + moment(startDate).unix() +"&end=" + moment(endDate).unix()));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
        }

        if(res.data  && res.data.length > 0)
        {
            var history = addHistoryFromServer(res.data);
            setHistoryEvents(history);
        }
    }

    const LoadReservations = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(CONVERSATION_URL + "/api/v1/schedule-meetings?pageNumber=1&pageSize=100&start=" + moment().unix()));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
        }
        if(res.data  && res.data.length > 0)
        {
            var meetings = addMeetingsFromServer(res.data, readOnly)
            setReservationEvents(meetings);
            return meetings;
        }
    }

    const LoadEvents = () => {
        const newArray = [...reservationEvents, ...availableTimeEvents, ...historyEvents]
        setAllEvents(newArray);
    }

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
            
            <div className="d-flex calanderActionButton">
                {readOnly && <div>
                    <Button onClick={() => setIsShowQuickAvailabilityModal(true)}
                        variant="outline-secondary">Set Availability</Button>
                </div>}
                {/* <div>
                    <Button onClick={() => setREadOnly(!readOnly)} variant="danger">
                        {readOnly ? "Change Available Times" : "Müsaitlik Durumunu Kaydet"}
                    </Button>
                </div> */}
            </div>
            <div className={readOnly && "calender-wrapper"}>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: 'today,prev,title,next',
                        center: '',
                        right: ''
                    }}
                    initialView='timeGridWeek'
                    datesSet={(arg: any) => {
                        setStartDate(arg.view.activeStart);
                        setEndDate(arg.view.activeEnd);
                    }}
                    firstDay = {1}
                    // initialView='dayGridMonth'
                    select={(e) => readOnly ? null : handleDateSelect(e)}
                    slotDuration={readOnly ? '00:60:00' : '00:15:00'}
                    events={allEvents}
                    allDaySlot={false}
                    // eventClick = {handleClickEvent}
                    // eventClick={ }
                    // editable={true}
                    selectable={true}
                // dateClick={(e) => readOnly ? null : onClickAvailability(e.date)}
                // selectMirror={true}
                // dayMaxEvents={true}
                />
                {isShowQuickAvailabilityModal && <SetQuickAvailabilityModal
                    existingTimeSlots = {allEvents.filter((x:any) =>x.type === "available_date")}
                    show={isShowQuickAvailabilityModal}
                    hide={() => setIsShowQuickAvailabilityModal(false)}
                    update={()=>{
                        LoadAvailableTimes();
                }}
                />}

                {isShowReserveCard &&
                <ReservedDetailsModal
                        show={isShowReserveCard}
                        hide={() => setIsShowReserveCard(false)}
                        selectedEvent={selectedEvent}
                    />                   
                }
            </div>
       </React.Fragment>
    )
}

export default Calender;
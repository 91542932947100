import React,{ useState } from "react";
import { connect } from "react-redux";
import "../../layout/InstructorReservations/ReservationSummary.css";
import Input from "../../components/inputs";
import Button from "../../components/buttons";
import {calendarIcon} from "../../assets/SVG/SVG";
import { ROLES } from "../../Global/Constants/constants";
import { useHistory } from "react-router";
import { useTranslation  } from "react-i18next";

const ReservationSummary :React.FC<StateProps> = ({auth}) =>{
    var history = useHistory();
    const { t } = useTranslation();
    return (
        <div className="reservation-summary-container">
            <div className="reservation-summary-text ">
            
            </div>
            {auth.user.role === ROLES.STUDENT && <div className="reservation-summary-make-reservation">
                <Button onClick={()=>{history.push("/reservations/create") }} className="reservation-summary-make-reservation-button" type="makeSth">
                   {calendarIcon} {t("general.create_reservation")}
                </Button>
            </div>}
        </div>
    )
}
interface StateProps {
    auth: any
}
function mapStateToProps(state:any) {
return {
        auth: state.AuthReducer,
    }
}

export default connect(mapStateToProps)(ReservationSummary);

import React, { useState } from "react";
import "../../layout/RegularPage.css";
import NavBar from "../navbars";
import { History } from "history";
import { Container } from "react-bootstrap";
import Footer from "../Footer";
import "../../mobile/RegularPage.css";

interface VideoMeetingPageProps {
    history: History,
    children: React.ReactNode,
}

const VideoMeetingPage: React.FC<VideoMeetingPageProps> = ({
    history,
    children,
}) => {
    const [expandNavBar, setExpandNavBar] = useState(false);
    const handleNavBarExpand = () => setExpandNavBar(!expandNavBar)
    return (
        <div style={{ backgroundColor: "#fafafa" }}>
            <div className="regular-page-header">
                <NavBar type="video-meeting" history={history} onClickExpand={handleNavBarExpand} />
            </div>
            <Container className="regular-page-content">
                {
                    children
                }
            </Container>
            <div className="regular-page-footer-container">
                {/* <Footer /> */}
            </div>
        </div>
    )
}

export default VideoMeetingPage;

import React, {useEffect, useState} from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import "../../layout/TutorPayment/PayoutAccount.css";
import Input from "../../components/inputs";
import theme from "../../theme";
import Button from "../../components/StatusButton";
import paypalIcon from "../../assets/paypal.svg";
import dangerIcon from "../../assets/warning.svg";
import {Tabs, Tab} from 'react-bootstrap';
import {useSpring, animated} from "react-spring";
import {trackPromise} from "react-promise-tracker";
import {IDENTITY_URL, PAYMENT_URL} from "../../Global/Constants/constants";
import {ReturnProps} from "../../@types/functions";
import axios from "axios";
import moment from "moment";
import UpdatePaymentMethode from "../UpdatePaymentMethod/UpdatePaymentMethod";
import AlertInfo from "../../components/alerts/AlertInfo";
import {useTranslation} from "react-i18next";


const Upload: React.FC<any> = ({fileUploaded}) => {
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [success, setSuccess] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const { t } = useTranslation();

    const handleFileChange = async (e: any) => {
        if (e.target?.files?.length === 0) {
            return;
        }
        const file = e.target.files[0];

        var data = new FormData();
        data.append('file',file);
        const config = {
            headers: {'content-type': 'multipart/form-data'}
        }
        const res = await trackPromise(axios.post<any, ReturnProps>(IDENTITY_URL + "/api/v1/users/file", data, config));
        //const res = await trackPromise(axios.post<any, ReturnProps>(IDENTITY_URL + "/api/v1/users/video", data, config));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            return null;
        } else {
            setSuccess("Record updated");
            setShowSuccess(true);
        }

        fileUploaded(file.fileName);
    }

    return (
        <React.Fragment>

            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0}/>
            <AlertInfo show={showSuccess} setShow={setShowSuccess} isAutoHide={true} message={success} type={1}/>

            <div className="payout-header">
                <h6 className="payout">{t("general.file_upload")}</h6>
            </div>
            <div className="payout-box">
                <div className="box-header">
                    <div className="payout-b-action">
                        <input type="file" onChange={handleFileChange} className="btn btn-primary"/>
                    </div>
                </div>
            </div>


            {/*<div className="payout-danger-alert">*/}
            {/*    <img src={dangerIcon} alt=""/>*/}
            {/*    <p>Please upload your file </p>*/}
            {/*</div>*/}
        </React.Fragment>
    )
}

export default Upload;

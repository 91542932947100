
export default {
    colors: {
        main: "#52575C",
        secondary: "#929598"
    },
    fonts: {
        nunito: "font-family: 'Nunito', sans-serif",
        poppins: "font-family: 'Poppins', sans-serif"
    },
    sizes: {
        xxs: 14,
        xs: 16,
        s: 18
    },
    weight: {
        light: 400,
        meduim: 500,
        heavy: 600,
        bold: 700,
        bolder: 800
    }
}

import React, { useState } from "react";
import "../../layout/ForgetPassword/Form.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Container, Form } from "react-bootstrap";
import Input from "../../components/inputs";
import Button from "../../components/buttons";
import "../../mobile/ForgetPassword/Form.css";
import { IDENTITY_URL, ROLES } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import AlertInfo from "../../components/alerts/AlertInfo";
import { useTranslation } from "react-i18next";


const ForgetPasswordForm: React.FC<any> = ({ history }) => {
    const { t } = useTranslation();

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [mail, setMail] = useState("");

    const onForgetMail = async (e: any) => {
        e.preventDefault();
        const res = await trackPromise(axios.post<any, ReturnProps>(IDENTITY_URL + "/api/v1/users/forget/" + mail));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            return null;
        }
        history.push("/email", { type: "forgetPassword" });
    }

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />

            <div className="mt-5 forget-password-form pb-5">
                <Card className="shadow forget-password-form-container">
                    <Card.Body>
                        <Container>
                            <h5 className="forget-password-form-title">{t("forget_password.forget_password")}</h5>
                            <h6 className="forget-password-form-subtitle text-muted mt-2">{t("forget_password.enter_email_to_reset_pass")}</h6>

                            <div className="mt-4">
                                <Form className="text-left">
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label className="forget-password-label">{t("general.email")}</Form.Label>
                                        <Input
                                            name="email"
                                            type="default"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMail(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Button type="default" primary style={{ width: "100%" }} onClick={onForgetMail} className="mt-2">
                                        {t("general.send")}
                                    </Button>
                                    <div className="mt-3 form-links">
                                        <Form.Text className="not-a-member">
                                            {t("forget_password.did_remember_password")}<span style={{ color: "#F86F53", cursor: "pointer", marginLeft: 10 }} onClick={() => { history.push("/login") }}> {t("forget_password.try_to_login")}</span>
                                        </Form.Text>

                                    </div>
                                </Form>
                            </div>
                        </Container>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default ForgetPasswordForm;

import React,{ useState } from "react";
import "../../layout/InstructorProfileSettings/LessonStyle.css";
import OptionButton from "../../components/buttons";
import styled,{ css } from "styled-components";
import theme from "../../theme";
import ParamType from "../../components/others/ParamType";

const Button = styled(OptionButton)`
    ${
        ({ clicked }) =>{
            if (clicked) return css`
                background-color: ${theme.colors.secondary};
                border-color: ${theme.colors.secondary};
                color: #fff;
            `;
            if(!clicked) return css`
                &:hover {
                    color: ${theme.colors.secondary};   
                    border-color: ${theme.colors.secondary};
                }
            `;
        }
    }
    margin-right: 15px;
    margin-bottom: 15px;
`;

const interestsList = [
    "Hayvanlar", " İlişkiler", "İş Dünyası",
    "Dünyadan Haberler", " Sanat&Edebiyat", " Eğlence&Yaşam",
    "Bilim&Teknoloji", " Spor", "Sağlık ve Formda Kal",
    "Müzik", "Mizah", " Gezi","Dünya Mutfağı", "TOEFL", " Tarih",
    "Moda", "Dil&Kültür", " Sosyal Bilimler"
]

interface InterestProps {
    selectedInterest : any[];
    setSelectedInterest : any;
}

const Interests : React.FC<InterestProps> = ({
    selectedInterest = [],
    setSelectedInterest
}) =>{
    return (

        <div className="instructor-interests-container">
            <h4 className="instructor-interests-title">HOBBIES</h4>
            <div className="instructor-interests-options-container">
            <ParamType type={2} selectedParams = {selectedInterest} setSelectedParams={setSelectedInterest} />

                {/* {
                    interestsList.map((interest, index) =>(
                        <Button 
                            type="option"
                            clicked={interestsSelected.includes(index)}
                            onClick={() =>{
                                setInterestsSelected([ ...interestsSelected, index ])
                            }}
                        >
                            {interest}
                        </Button>
                    ))
                } */}
            </div>
            <hr className="instructor-section-sep" />
        </div>
    )
}

export default Interests;

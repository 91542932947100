import React, {useEffect, useState} from "react";
import ReactFlagsSelect from "react-flags-select";
import {useTranslation} from "react-i18next";

const LanguageChooser = ({ }) => {
    const { t, i18n } = useTranslation();
    const changeLanguageToFlag = () => {
        var lang = i18n.language
        console.log("asdsd",lang);
        if(!lang)
            return "GB";
        if(lang.includes('en'))
        {
            return "GB"
        }
        else if(lang.includes('tr'))
        {
            return "TR"
        }
        else if (lang.includes('pt'))
        {
            return "BR"
        }
        else
        {
            return "GB"
        }
    }

    const handleLangugeChange = (lang : string) => {
        if(lang == "GB")
        {
            i18n.changeLanguage("en-US")
        }
        else if(lang == "TR")
        {
            i18n.changeLanguage("tr")
        } else if(lang == "BR")
        {
            i18n.changeLanguage("pt")
        }
        setSelected(lang)
    }
    const [selected, setSelected] = useState("");

    useEffect(() => {
        setSelected(changeLanguageToFlag())
    }, []);

    return (
        <React.Fragment>
            <ReactFlagsSelect
                countries={["TR", "GB", "BR"]}
                customLabels={{ TR: "TR", GB: "EN", BR: "PT"}}
                placeholder="Select Language"
                onSelect={handleLangugeChange}
                optionsSize={14}
                selectedSize={14}
                selected={selected}/>
        </React.Fragment>
    )
}
export default LanguageChooser;
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Alert } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';

interface AlertInfoProp {
    show: boolean,
    setShow: any
    isAutoHide: boolean;
    message: string;
    type: number; //1 Success - 0 fail - 2 info
}
const AlertInfo: React.FC<AlertInfoProp> = ({
    show,
    setShow,
    isAutoHide = false,
    message,
    type,
}) => {

    const onDismiss = () => setShow(false);

    useEffect(() => {
        if (show && isAutoHide) {
            setTimeout(() => setShow(false), 5000);
        }
    }, [show])

    var color = "success";

    if (type === 1) color = "success";
    else if (type === 0) color = "danger"
    else if (type === 2) color = "info"

    return (
        <div className="fixed-top" style={{zIndex:9999}}>
            <Alert style={{ textAlign: "center" }} color={type === 1 ? "success" : ((type === 0) ? "danger" : "info")} isOpen={show} toggle={onDismiss}>
                {message}
            </Alert>
        </div>
    )
}

export default AlertInfo;

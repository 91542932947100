import React, { useState, useEffect, useRef } from "react";
import Page from "../../components/pages";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch } from "react-redux";
import TopBar from "./TopBar/TopBar";
import Header from "./Header";
import Content from "./Content";
import { STUDENT_DASHBOARD_URL, IDENTITY_URL } from "../../Global/Constants/constants";
import { reOrder } from "../../Global/StudentDashboard/TutorOrderManager";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import { useSetState } from "../../Global/Utilities/getsetstate";
import useInterval from "../../Global/Utilities/useInterval";

const StudentDashboardPage: React.FC<RouteComponentProps> = ({ history }) => {
    const dispatch = useDispatch();
    const [startAnimation, setStartAnimation] = useState<Boolean | null>(null);
    const [showContent, setShowContent] = useState(true);
    const [instructors, setInstructors] = useState<any[]>([]);
    const [instructorFiltered, setInstructorFiltered] = useState<any[]>([]);
    const [instructorOrginal, setInstructorOrginal] = useState<any[]>([]);
    const [favTeacherList, setFavTeacherList] = useState<any[]>();
    const [userStatusFilter, setUserStatusFilter] = useState(-1);
    const [isFavUsers, setIsFavUsers] = useState("");

    useEffect(() => {
        GetTeacherList(userStatusFilter === 3 ? true : false)
        LoadStudentPreferencesForTeacher();
    }, []);

    useInterval(() => {
        GetTeacherList(userStatusFilter === 3 ? true : false)
    }, 10000);

    useEffect(() => {
        if (userStatusFilter === -1) {
            setInstructors(instructorOrginal);
        } else if (userStatusFilter === 3) {

              setInstructors(instructorOrginal);
        } else if (userStatusFilter === 4) {
            //  GetTeacherList(false);
            // setInstructors(instructorOrginal);
            setInstructors(instructorOrginal);
        }
        else {
            var array = instructorOrginal.filter(x => x.status === userStatusFilter);
            setInstructors(array);
        }
    }, [userStatusFilter, instructorOrginal]);

    let isFav = false;

    const handleStatus = (status: "active" | "busy" | "inactive" | "favourite" | "" | "all") => {
        switch (status) {
            case "all":
                // dispatch({ type: "GET_ALL_INSTRCUTORS" })
                setUserStatusFilter(-1);
              //  GetTeacherList(userStatusFilter === 3 ? true : false);

                break;
            case "active":
                // dispatch({ type: "GET_ONLINE_INSTRUCTORS" })
                setUserStatusFilter(1);
                break;
            case "busy":
                //                dispatch({ type: "GET_busy_INSTRUCTORS" })
                setUserStatusFilter(2);
                break;
            case "favourite":
                //console.log("Fav Clicked");
                if (userStatusFilter === 4) {
                    GetTeacherList(true);
                } 
                else {
                    GetTeacherList(false);
                }

                userStatusFilter === 3 ? setUserStatusFilter(4) : setUserStatusFilter(3);

                break;
            case "inactive":
                setUserStatusFilter(0);
                break;
            default:
                break;
        }
    }

    const GetTeacherList = async (isFav: boolean) => {
        var status = -1;
        const res = await axios.get<any, ReturnProps>(STUDENT_DASHBOARD_URL + "/api/v1/teacher?status=" + status + "&isFavourited=" + isFav)
        if (!res.success) {
            //   setError(res.error as string)
            //console.log("HATA");
            return null
        }

        if (instructors && instructors.length > 0) {
            var reOrdered = reOrder(instructors, res.data);
            setInstructorOrginal(reOrdered);
            // setInstructors(reOrdered);
        }
        else {
            setInstructorOrginal(res.data);
            // setInstructors(res.data);
        }
    }

    const LoadStudentPreferencesForTeacher = async () => {
        const res = await axios.get<any, ReturnProps>(STUDENT_DASHBOARD_URL + "/api/v1/teacher?isFavourited=true")
        // Handling Error
        if (!res.success) {
            //   setError(res.error as string);
            //   setShowError(true)
            alert(res.error)
            return null;
        }
        //console.log(res.data);
        if (res.data && res.data.length > 0) {
            const onlyGuids = (res.data as [any]).filter(x => x.isFavourite === true).map(a => a.user.guid);
            setFavTeacherList(onlyGuids);
        }
    }

    return (
        <Page
            type="light-regular-page"
            history={history}
        >
            <TopBar />
            <Header
                handleStatus={handleStatus}
            />
            <Content
                startAnimation={startAnimation}
                showContent={showContent}
                instructorsData={instructors}
                favTeacherList={favTeacherList}
                onLike={() => { LoadStudentPreferencesForTeacher() }}
            />
        </Page>
    )
}



export default StudentDashboardPage;

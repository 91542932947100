import { v4 as uuidv4 } from 'uuid';

const API_KEY  = "46393212"
// const SESSION_ID  = "1_MX40NjkxOTc5NH5-MTYxNDg1NzkyNjI0Nn53SkZIZTZFVUpVMkpvc2V6RzVDUjNNT0t-fg"
// const TOKEN_STUDENT = "T1==cGFydG5lcl9pZD00NjkxOTc5NCZzaWc9NjQzNzc2OTVkNzBjNGZhZmUxOTczNDQyNzQ5YjNkYTc0ODgzMTI1NzpzZXNzaW9uX2lkPTFfTVg0ME5qa3hPVGM1Tkg1LU1UWXhORGcxTnpreU5qSTBObjUzU2taSVpUWkZWVXBWTWtwdmMyVjZSelZEVWpOTlQwdC1mZyZjcmVhdGVfdGltZT0xNjE0ODU3OTUzJm5vbmNlPTAuNjE3OTMxNjgyNjM4OTU4JnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2MTc0NDYzNTImY29ubmVjdGlvbl9kYXRhPVN0dWRlbnQlMjAxJmluaXRpYWxfbGF5b3V0X2NsYXNzX2xpc3Q9"
// const TOKEN_TEACHER = "T1==cGFydG5lcl9pZD00NjkxOTc5NCZzaWc9MDk2NGIwYWU5YjM2ODkwZjQyNTc2MzJmZmJjNDUwMmIyOWIyM2ZkOTpzZXNzaW9uX2lkPTFfTVg0ME5qa3hPVGM1Tkg1LU1UWXhNRGN5T1RNeU9UZzBOSDVhZHpWNGJVODBVMkpSWVdJNU1GWXdUVnBwVUVGRU5FSi1mZyZjcmVhdGVfdGltZT0xNjEwNzI5NDM1Jm5vbmNlPTAuNDk3NTI2NzU2NjA4Mjg5Mjcmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTYxMzMyMTQzNCZjb25uZWN0aW9uX2RhdGE9Q2xpZW50JTIwMiUyMC0lMjBUZWFjaGVyJmluaXRpYWxfbGF5b3V0X2NsYXNzX2xpc3Q9"

// const SENDER_ID = uuidv4();

export {
    API_KEY,
    // SESSION_ID,
    // TOKEN_STUDENT,
    // TOKEN_TEACHER,
    // SENDER_ID
}

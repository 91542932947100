
const initialState = {
    isConnected: false,
}

interface ActionProps {
    type: String,
    payload: any
}

export default function(state=initialState, action:ActionProps) {
    switch(action.type) {
        case "SOCKET_STATUS_CHANGED":
            return state = { ...state, isConnected: action.payload }
        default:
            return state
    }
}

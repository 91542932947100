import React,{ useState } from "react";
import "../../layout/ChoosePackage/Content.css";
import "../../mobile/ChoosePackage/Content.css";
import { Container, Button, Badge } from "react-bootstrap";
import { minutesOptions, daysOptions } from "../../Global/MinutesOptions";
import { packages } from "../../Global/SubscriptionPackages";
import "../../layout/UpgradeSubscription/Content.css";
import "../../layout/UpgradeSubscription/UpgradeSubscription.css";

interface ContentProps {
    handleWeekChange: (value:Number) => void,
    handleMinuteChange: (value:Number) => void,
    handlePackageChange: (value:Number) => void,
    currentMinutes: Number,
    currentWeek: Number,
    currentPackageValue: Number
}

const Content:React.FC<ContentProps> = ({ 
    handleMinuteChange, 
    handlePackageChange, 
    handleWeekChange,
    currentMinutes,
    currentWeek,
    currentPackageValue
}) =>{
    const [minutesChosen, setMinutesChosen] = useState<Number>(0);
    const [daysChosen, setDaysChosen] = useState<Number>(0);
    const [packChosen, setPackChosen] = useState<Number>(-1);
    const [currentPack, setCuurentPack] = useState<Number>(0);
    const minutesValues = [10, 20, 40, 60];
    const weekValue = [2, 3, 5, 7];
    const packageValue = [1, 3, 6]
    return (
        <Container className="text-left">
            <div className="current-subscription-package">
                <div className="current-subscription-details">
                    <h4 className="current-subscription-title">Mevcut Abonelik Paketiniz</h4>
                    <h4 className="current-subscription-subtitle">Günde 10 dakika, Haftada 2 gün - 3 ay süreli</h4>
                </div>
                <div className="current-subscription-price">
                    <h4 className="current-subscription-title current-subscription-price-title">Paketinizden Kalan</h4>
                    <h4 className="current-subscription-subtitle current-subscription-price-title">₺ 152.00</h4>
                </div>
            </div>
            <h6 className="packages-titles">Sana en uygun paketi seç!</h6>
            <h4 className="packages-subtitle">Ingilizceyi surekli hayatinin icinde tut.  Lorem İpsum dolar lorem ipsum dolar</h4>
            
            <div className="package-section">
                <h6 className="option-title">Günde kaç dakikalık görüşme yapmak istersiniz?</h6>
                <div className="option-odds">
                    {
                        minutesOptions.map((option, index) =>(
                            <Button 
                                variant="" 
                                className={`
                                    option-odd 
                                    ${(index === minutesChosen) && "option-odd-active"}
                                `} 
                                key={option.id} 
                                onClick={() =>{
                                    setMinutesChosen(index)
                                    handleMinuteChange(minutesValues[index])
                                }}
                            >
                                {option.number} dakika
                            </Button>
                        ))
                    }
                </div>
            </div>
            <div className="package-section">
                <h6 className="option-title" id="second-odds-container">Haftada kaç gün?</h6>
                <div className="option-odds">
                    {
                        daysOptions.map((option, index) =><Button 
                                                                variant="" 
                                                                className={`
                                                                    option-odd 
                                                                    ${(index === daysChosen) && "option-odd-active"}
                                                                `}
                                                                key={option.id} 
                                                                onClick={() =>{
                                                                    setDaysChosen(index)
                                                                    handleWeekChange(weekValue[index])
                                                                }}
                                                            >
                                                                {option.number} gün
                                                            </Button>
                        )
                    }
                </div>
            </div>

            <h6 className="option-title subscription-option-title">
                <span>Abonelik Paketleri</span> 
                <Badge className="subscription-badge">
                    Günde 10 dakika Haftada 2 gün
                </Badge>
            </h6>
            <div className="subscription-packages">
                {
                    packages.map((pack, index) =>(
                        <div 
                            className={`
                                subscription-package
                                ${(index === currentPack) && "current-package"} 
                                ${(index === packChosen) && "active"}
                            `}
                            key={pack.id} 
                            onClick={() =>{
                                if(index !== currentPack) {
                                    setPackChosen(index)
                                    handlePackageChange(packageValue[index])
                                }
                            }}
                        >
                            <h5 className="package-title">{pack.duration} Ay</h5>
                            <del className="prev-price">{pack.prevPrice}/Ay</del>
                            <h5 className="current-price">{pack.currPrice}/Ay</h5>
                            <h6 className="auto-renew mt-3 mb-2">Her {pack.autoRenew} Ayda bir yenilenir</h6>
                            <div className="discount-area">
                                <p className="discount">%{pack.discount} İndirim </p>
                            </div>
                        </div>
                   ))
                }
            </div>
        </Container>
    )
}

export default Content;

import moment from "moment";

export const addAvaliableEventsFromServer = (availableItems: any[],  isReadOnly : Boolean) =>
{
    var newArray : any = [];
    var colors = {
        displayBg: "#FAD0C6"
    }

    availableItems.map((itm: any) => {
        if(!newArray.find((x:any) =>x.id === itm.id))
        {
        newArray.push({
            start: moment(itm.startDate * 1000).toDate(),
            end: moment(itm.endDate * 1000).toDate(),
            display: 'background',
            backgroundColor: colors.displayBg,
            type:"available_date",
            id:itm.id
        })
    }
    })

    return newArray;
}

const decideReservationColor = (duration : number, startDate : number) =>
{
    if(moment(startDate * 1000).isBefore(moment()))
    {
        return "#CACCCF";
    }
    if(duration < 16)
    {
        return "#E8EFFE";
    }else  if(duration > 16 && duration < 31)
    {
        return "#DDE7FE";
    } 
    else  if(duration > 31 && duration < 61)
    {
        return "#D1E0FD";
    }
    else
    {
        return "#DDE7FE"
    } 
}

const decideReservationTextColor = (startDate : number) =>
{
    if(moment(startDate * 1000).isBefore(moment()))
    {
        return "#45494D";
    }
    else
    {
        return "#052D7F"
    } 
}



export const addMeetingsFromServer = ( meetingItems: any[],  isReadOnly : Boolean) =>
{
    var newArray : any = [];

    meetingItems.map((itm: any) => {
        if(!newArray.find((x:any)=>x.id === itm.guid))
        {
        newArray.push({
                title: (itm.studentUser.name + " " + itm.studentUser.surname) ,
                start: moment(itm.startDate * 1000).toDate(),
                end: moment(itm.endDate  * 1000).toDate(),
                backgroundColor: decideReservationColor(itm.totalMinute,itm.startDate),
                textColor: decideReservationTextColor(itm.startDate),
                color:  decideReservationColor(itm.totalMinute,itm.startDate),
                type:"reserved_meeting",
                id:itm.guid
        })
        }
    })

    return newArray;
}

export const addHistoryFromServer = ( historyItems: any[]) =>
{
    var newArray : any = [];

    historyItems.map((itm: any) => {
        if(!newArray.find((x:any)=>x.id === itm.meetingId) && itm.startDate && itm.finishDate )
        {
        newArray.push({
                title: (itm.attendee?.name + " " + itm.attendee?.surname) ,
                start: moment(itm.startDate * 1000).toDate(),
                end: moment(itm.finishDate  * 1000).toDate(),
                backgroundColor: decideHistoryBackgroundColor(itm.meetingType),
                textColor: decideHistoryTextColor(itm.meetingType),
                color:  decideHistoryBackgroundColor(itm.meetingType),
                type:"history",
                id:itm.meetingId
        })
        }
    })

    return newArray;
}

const decideHistoryBackgroundColor = (type : number) =>
{
    if(type === 0) //ADHOC
    {
        return "#C55741";
    }
    else  // 1 = RESERVED
    {
        return "#CACCCF"
    } 
}

const decideHistoryTextColor = (type : number) =>
{
    if(type === 0) //ADHOC
    {
        return "#FFFFFF";
    }
    else  // 1 = RESERVED
    {
        return "#45494D"
    } 
}

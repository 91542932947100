import React from 'react';
import { Card } from 'react-bootstrap';
import moment from 'moment';

interface UpcomingReservationItemProp
{
    startDate : any;
    endDate : any;
    totalMinute:  number;
    name : string
    surname : string;
}

const UpcomingReservationItem : React.FC<UpcomingReservationItemProp> = ({
    startDate,
    endDate,
    totalMinute,
    name = "",
    surname = "",
}) => {
    const userName = name + " " + surname?.charAt(0) + ".";

const dateTime = moment(startDate * 1000);
const date = dateTime.format("DD.MM.YYYY");
const day = dateTime.format("dddd");
const startHours = dateTime.format("HH:mm");

const endDateTime = moment(endDate * 1000);
const endHours = endDateTime.format("HH:mm");
const minute = totalMinute ?? 0;

    return (
                    <div className="app-wrapper">
                        <h4>{userName}</h4>
                        <p>{date}, {day}</p>
                        <p>{startHours} - {endHours} <span className="badge danger">{minute} min</span>  </p>
                    </div>
    )
}

export default UpcomingReservationItem;
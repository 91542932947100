import React, { useState } from "react";
import "../../layout/Signup/Signup.css";
import Form from "./Form";
import Footer from "../../components/Footer";
import NavBar from "../../components/navbars";
import { IDENTITY_URL, ROLES } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import AlertInfo from "../../components/alerts/AlertInfo";
import PolicyModal from "../../components/modals/PolicyModal/PolicyModal";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import NewFooter from "../../components/NewFooter";

const Signup: React.FC<any> = ({ history, location }) => {

  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [showPolicyModal, setShowPolicyModal] = useState(false);

  const actions = {
    setEmail: (event: React.ChangeEvent<HTMLInputElement>) =>
      setEmail(event.target.value),
    setPassword: (event: React.ChangeEvent<HTMLInputElement>) =>
      setPassword(event.target.value),
    setName: (event: React.ChangeEvent<HTMLInputElement>) =>
      setName(event.target.value),
    setSurname: (event: React.ChangeEvent<HTMLInputElement>) =>
      setSurname(event.target.value),
    setPhoneNumber: (event: any) =>
      setPhoneNumber(event),
  }

  const controllers = {
    email,
    password,
    name,
    surname,
    phoneNumber
  }

  const role = location.pathname === "/tutor/register" ? 2 : 1;

  const checkPhoneNumberIsValid = (data: any) => {  //regular expression to check phone number is valid or not
    var regex = /((\+|00)([1-4]\d\d|[1-9]\d+)\)?)[\-\.\ \\\/]?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?[\-\.\ \\\/]?(\d+))?$/i
    if (data.match(regex)) {
      if(data.startsWith("+90"))
      {
        if(data.length === 13)
        {
          return true
        }
        else  { return false }  //if phone number is not 13 digits
      }
      return true;
    }
    else {
      return false;
    }  
  }

  const checkEmailIsValid = (data: any) => {  //regular expression to check phone number is valid or not
    var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (data.match(regex)) {
      return true;
    }
    else {
      return false;
    }  
  }

  const handleSubmit = async () => {
    // Collecting The API Data That Will Be Sent

    if(!checkPhoneNumberIsValid(controllers.phoneNumber)) {
      setError(role === 1 ? "Telefon numarası geçersiz. Kabul edilen format : +905321234567" : "Invalid phone number format. Accepted format is +905321234567");
      setShowError(true);
      return;
    }

    if(!checkEmailIsValid(controllers.email))
    {
      setError(role === 1 ? "Mail adresi geçersiz." : "Invalid email address.");
      setShowError(true);
      return;
    }


    const registerInfo: any = {
      Mail: controllers.email,
      password: controllers.password,
      firstName: controllers.name,
      surname: controllers.surname,
      phoneNumber : controllers.phoneNumber,
      role: role,
    }
    //console.log("Register Info: ", registerInfo)
    // Calling The API
    const res = await trackPromise(axios.post<any, ReturnProps>(IDENTITY_URL + "/api/v1/users", registerInfo));

    // Handling Error
    if (!res.success) {
      if (Array.isArray(res.errorMessages) && res.errorMessages.length > 0) {
        // if (res.errorMessages.filter(x => x.code === "invalid-password-format").length > 0) {
        //   setError(role === 1 ? "Şifre doğru formatta değil.\nŞifreniz\n En az 8 karakterli \n En az 1 tane büyük ve küçük harf \n En az 1 tane rakam " : "Your password format is not correct.\nYour password should be\n Minumum 8 characters \n 1 Upper and lower case \n Min 1 number");
        //   setShowError(true)
        // }
        // else if (res.errorMessages.filter(x => x.code === "user-already-registered").length > 0) {
        //   setError(role === 1 ? "Kullanıcı zaten kayıtlı" : "User already registered.");
        //   setShowError(true)
        // }
        setError(res.errorMessages[0].code);
        setShowError(true)
      }
      else {
        setError(res.error as string);
        setShowError(true)
      }

      return null;
    }

    const header: String = res.headers.location
    var userId = header.split("/")[1];

    history.push("/email", { type: "activation", email: controllers.email, userId: userId, role: role });
  }

  return (
    <React.Fragment>
      <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />

      <div className="signup">
        <NavBar type="minimal" history={history} />
        <div className="mainContent">
          <Form
            handleSubmit={handleSubmit}
            controllers={controllers}
            actions={actions}
            role={role}
            setShowPolicyModal = {setShowPolicyModal}
          />
        </div>
        <Footer />
      </div>

      {
                showPolicyModal &&
                <PolicyModal
                    show={showPolicyModal}
                    hide={() => setShowPolicyModal(false)}
                    roleId = {role}
                />
      }

    </React.Fragment>
  )
}

export default Signup;

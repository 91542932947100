import React from "react";
import "../../layout/EmailVerification/EmailVerification.css";
import Footer from "../../components/Footer";
import NavBar from "../../components/navbars";
// @ts-ignore
import queryString from "query-string"
import PaymentConfirmationResult from "./PaymentConfirmationResult";
import { AuthReducerProps } from "../../@types/Auth";
import { connect } from "react-redux";
import { ROLES } from "../../Global/Constants/constants";

const PaymentConfirmationPage:React.FC<any> = ({ history, location, auth }) =>{

    const lang = (auth?.user?.role === ROLES.STUDENT)? "1" : "0" ;
    console.log("Language = ",lang);

    return (
        <div className="email-verification">    
            <NavBar type="minimal" history={history} />
                <PaymentConfirmationResult lang={lang} history={history} />
            <Footer />
        </div>
    )
}

interface StateProps {
    auth: AuthReducerProps
}

function mapStateToProps (state:any) {
    return {
        auth: state.AuthReducer
    }
}

export default connect<StateProps>(mapStateToProps)(PaymentConfirmationPage);

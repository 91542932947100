import React, { useEffect, useState } from "react";
import "../../layout/InstructorProfileSettings/Languages.css";
import Input from "../../components/inputs";
import { IDENTITY_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import { Button } from 'react-bootstrap';

interface LanguageProps {
    userLanguages?: any[];
    setUserLanguages: any;
}

const Languages: React.FC<LanguageProps> = ({
    userLanguages,
    setUserLanguages,
}) => {

    useEffect(() => {
        if (userLanguages && userLanguages.length < 1) {
            var array = [{ language: { guid: null } }];
            setUserLanguages(array);
        }
    }, [userLanguages]);

    const [accentOne, setAccentOne] = useState("");
    const [allLanguages, setAllLanguages] = useState([]);
    const [languages, setLanguages] = useState<any[]>([]);
    const [accent, setAccent] = useState<any[]>([]);
    const [selectedLanguages, setSelectedLanguages] = useState<any[]>([]);

    const LoadParams = async () => {
        const res = await axios.get<any, ReturnProps>(IDENTITY_URL + "/api/v1/parameters/type/1");//Languages
        // Handling Error
        if (!res.success || !res.data) {
            //   setError(res.error as string);
            //   setShowError(true)
            //  alert(res.error)
            return null;
        }
        // for (let index = 0; index < res.data.length; index++) {
        //     const element = res.data[index];
        //     if(langugages.find((x:any)=>x.guid === element.guid))
        //     {

        //     }
        // }

        var mainLanguages = res.data.filter((x: any) => !x.linkedTo);
        //  var array = [...languages, mainLanguages];
        var newArray = res.data.map((item: any) => {
            if (item.linkedTo) {
                return { title: item.linkedTo.name + " / " + item.name, value: item.guid }
            }
            else {
                return { title: item.name, value: item.guid }
            }
        });

        var accentArray: any[] = [];
        mainLanguages.forEach((element: any) => {
            var items = res.data.filter((x: any) => x.linkedTo?.guid === element.guid);
            var mainLang = { title: element.name, value: element.guid }
            accentArray.push({
                language: mainLang, accent: items.map((item: any) => {
                    return { title: item.name, value: item.guid }
                })
            })
        });

        var sortedLanguages = newArray.sort((a: any, b: any) => b.title.localeCompare(a.title))
        sortedLanguages.unshift({ title: "Choose", value: null });
        setAccent(accentArray);

        setLanguages(sortedLanguages)
        //  setLanguages(sortedLanguages);
        setAllLanguages(res.data);
    }

    useEffect(() => {
        LoadParams();
    }, []);

    return (
        <div className="instructor-languages-container">
            <h4 className="instructor-languages-title">LANGUAGES</h4>
            <div className="instructor-languages-settings-container">
                <div className="instructor-languages-settings-labels-container">
                    <h4 className="instructor-languages-settings-label">Choose Languages</h4>
                    {/* <h4 className="instructor-languages-settings-label">Seviyenizi Seçiniz</h4>
                    <h4 className="instructor-languages-settings-label">Aksanınızı Seçiniz</h4> */}
                </div>
                {
                    userLanguages?.map((item: any, index: number) => (
                        <div key={userLanguages[index]?.accent ? userLanguages[index]?.accent?.guid : (userLanguages[index]?.language?.guid ? userLanguages[index]?.language?.guid : index)} className="instructor-languages-setting-container">
                            <div className="instructor-languages-setting-input-container">
                                <Input
                                    onChange={(e: any) => {
                                        //console.log(e.target.value);
                                        if (!userLanguages.find(x => e.target.value === (x.accent ? x.accent?.guid : x.language?.guid))) {
                                            const array = [...userLanguages];
                                            array[index] = { language: { guid: e.target.value } };
                                            setUserLanguages(array);
                                        }
                                    }}
                                    type="select"
                                    defaultSelection={userLanguages[index]?.accent ? userLanguages[index]?.accent?.guid : userLanguages[index]?.language?.guid}
                                    options={languages}
                                />
                            </div>
                            <div className="instructor-languages-setting-action-container">
                                {
                                    (index === userLanguages.length - 1 && index < 1 && !userLanguages.find(x => !x.language.guid)) &&
                                    <Button variant="primary" onClick={() => {
                                        const array = [...userLanguages];
                                        array.push({ language: { guid: null } });
                                        setUserLanguages(array);
                                    }}> Add </Button >
                                }

                                {
                                    (userLanguages.length > 1 && index === userLanguages.length - 1) &&
                                    <Button variant="danger" onClick={() => {
                                        const array = [...userLanguages];
                                        array.splice(index, 1);
                                        setUserLanguages(array);
                                    }}> Remove </Button>
                                }

                            </div>
                        </div>
                    ))
                }


                {/* <div className="instructor-languages-setting-input-container">
                        <div className="instructor-languages-setting-input">
                            <Input
                                type="default"
                                value={accentOne}
                                onChange={(e:React.ChangeEvent<HTMLInputElement>) =>setAccentOne(e.target.value)}
                                style={{
                                    height: 40
                                }}
                                placeholder="Accent"
                            />
                        </div>
                    </div>  */}

                {/* <div className="instructor-languages-setting-input-container">
                        <Input
                            type="select"

                            options=
                            {[{title:"Level", value:0},
                            {title:"Beginner", value:1},
                            {title:"Elementary", value:2},
                            {title:"Intermediate", value:3},
                            {title:"Upper Intermediate", value:4},
                            {title:"Native", value:5} ]}
                        />
                    </div>  */}

                {/* {
                    selectedLanguages.map((item : any, index : number) => (

                    ))
                } */}


                {/* <div className="instructor-languages-setting-container">
                    <div className="instructor-languages-setting-input-container">
                        <Input
                            type="select"
                            options={["Language", "English", "Spanish", "German"]}
                        />
                    </div>
                    <div className="instructor-languages-setting-input-container">
                        <Input
                            type="select"
                            options={["Level", "Native", "Intermediate", "Beginner"]}
                        />
                    </div>
                    <div className="instructor-languages-setting-input-container">
                        <div className="instructor-languages-setting-input">
                            <Input
                                type="default"
                                value={accentTwo}
                                onChange={(e:React.ChangeEvent<HTMLInputElement>) =>setAccentTwo(e.target.value)}
                                style={{
                                    height: 40
                                }}
                                placeholder="Accent"
                            />
                        </div>
                    </div>
                </div> */}

                {/* <div className="instructor-languages-setting-container">
                    <div className="instructor-languages-setting-input-container">
                        <Input
                            type="select"
                            options={["Language", "English", "Spanish", "German"]}
                        />
                    </div>
                    <div className="instructor-languages-setting-input-container">
                        <Input
                            type="select"
                            options={["Level", "Native", "Intermediate", "Beginner"]}
                        />
                    </div>
                    <div className="instructor-languages-setting-input-container">
                        <div className="instructor-languages-setting-input">
                            <Input
                                type="default"
                                value={accentThree}
                                onChange={(e:React.ChangeEvent<HTMLInputElement>) =>setAccentThree(e.target.value)}
                                style={{
                                    height: 40
                                }}
                                placeholder="Accent"
                            />
                        </div>
                    </div>
                </div> */}

                {/* <div className="instructor-languages-setting-container">
                    <div className="instructor-languages-setting-input-container">
                        <Input
                            type="select"
                            options={["Language", "English", "Spanish", "German"]}
                        />
                    </div>
                    <div className="instructor-languages-setting-input-container">
                        <Input
                            type="select"
                            options={["Level", "Native", "Intermediate", "Beginner"]}
                        />
                    </div>
                    <div className="instructor-languages-setting-input-container">
                        <div className="instructor-languages-setting-input">
                            <Input
                                type="default"
                                value={accentFour}
                                onChange={(e:React.ChangeEvent<HTMLInputElement>) =>setAccentFour(e.target.value)}
                                style={{
                                    height: 40
                                }}
                                placeholder="Accent"
                            />
                        </div>
                    </div>
                </div> */}
            </div>
            <hr className="instructor-languages-setting-sep" />
        </div>
    )
}

export default Languages;

//{languages.find(x => x.value === (userLanguages[index]?.accent ? userLanguages[index]?.accent?.guid : userLanguages[index]?.language?.guid))}

import React, { useEffect, useState } from "react";
import Page from "../../components/pages";
import { RouteComponentProps } from "react-router-dom";
import ProfileInformation from "./ProfileInformation";
import Languages from "./Languages";
import Personality from "./Personality";
import ExpertiseOn from "./ExpertiseOn";
import Interests from "./Interests";
import Experience from "./Experience";
import Features from "./Features";
import AboutMe from "./AboutMe";
import ProfileVideo from "./ProfileVideo";
import ProfileFooter from "./ProfileFooter";
import { IDENTITY_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";

import { connect } from "react-redux";
import Summary from "./Summary";
import { trackPromise } from "react-promise-tracker";
import AlertInfo from "../../components/alerts/AlertInfo";


const InstructorProfileSettings: React.FC<any> = ({ history, auth }) => {

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [success, setSuccess] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);

    const [img, setImg] = useState();
    const [userProfile, setUserProfile] = useState<any>();
    const [name, setName] = useState<string>()
    const [surname, setSurname] = useState<string>()
    const [email, setEmail] = useState<string>()
    const [experience, setExperience] = useState<string>("")
    const [aboutMe, setAboutMe] = useState<string>("")
    const [summary, setSummary] = useState<string>("")
    const [videoUrl, setVideoUrl] = useState<string>("")

    const [userLanguages, setUserLanguages] = useState<any[]>()
    const [selectedHobbies, setSelectedHobbies] = useState<any[]>([])
    const [selectedExpertiseOns, setSelectedExpertiseOns] = useState<any[]>([])
    const [selectedPersonality, setSelectedPersonality] = useState<any[]>([])

    const LoadUserProfile = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(IDENTITY_URL + "/api/v1/teachers/" + auth.user.guid + "/profile"));
        // Handling Error
        if (!res.success || !res.data) {
            setError(res.error as string);
            setShowError(true)
            return null;
        }
        setUserProfile(res.data);
        setName(res.data.user?.name);
        setSurname(res.data.user?.surname);
        setEmail(res.data.user?.email);
        setVideoUrl(res.data.videoLink);
        setExperience(res.data.experience);
        setAboutMe(res.data.aboutMe);
        setSummary(res.data.summary);
        setSelectedHobbies(res.data?.hobbies);
        setSelectedPersonality(res.data?.personalities);
        setSelectedExpertiseOns(res.data?.expertiseOns);
        // setSelectedLessonStyle(res.data?.lessonstyles);
        setUserLanguages(res.data?.knownLanguage || []);

        setImg(res.data?.user?.photoUrl);
        //console.log("IMAGE" + res.data?.user?.photoUrl);
    }

    const LoadVideo = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(IDENTITY_URL + "/api/v1/teachers/" + auth.user.guid + "/profile"));
        // Handling Error
        if (!res.success || !res.data) {
            setError(res.error as string);
            setShowError(true)
            return null;
        }
        setVideoUrl(res.data.videoLink);
    }


    const onSaveChangesClick = async () => {
        //console.log("SaveChanges");
        var hoobieChanged = false;
        const param: any = {};
        if (JSON.stringify(selectedHobbies) !== JSON.stringify(userProfile?.hobbies)) {
            param.hobbies = selectedHobbies.map((item: any) => { return item.parameter.guid });
        }

        if (JSON.stringify(selectedPersonality) !== JSON.stringify(userProfile?.personalities)) {
            param.personalities = selectedPersonality.map((item: any) => { return item.parameter.guid });
        }

        if (JSON.stringify(selectedExpertiseOns) !== JSON.stringify(userProfile?.expertiseOns)) {
            param.expertiseOns = selectedExpertiseOns.map((item: any) => { return item.parameter.guid });
        }

        // if(JSON.stringify(selectedLessonStyle) != JSON.stringify(userProfile?.lessonstyles))
        // {
        //     param.lessonStyles = selectedLessonStyle.map((item:any) => {return item.parameter.guid});
        // }

        if (JSON.stringify(userLanguages) !== JSON.stringify(userProfile?.knownLanguage)) {
            if (JSON.stringify(userLanguages) === JSON.stringify([{ language: { guid: null } }])) {
                param.knownLanguage = [];
            }
            else {

                param.knownLanguage = userLanguages?.filter(x => x.language.guid !== "Choose")?.map((item: any) => {
                    if (item.language.guid) {
                        if (item.accent && item.accent.guid)
                            return { langParam: item.accent.guid, level: 5 }
                        else
                            return { langParam: item.language.guid, level: 5 }
                    }
                });
            }
        }

        if (summary !== userProfile?.summary)
            param.summary = summary;
        if (aboutMe !== userProfile?.aboutMe)
            param.aboutMe = aboutMe;
        if (experience !== userProfile?.experience)
            param.experience = experience;

        const res = await trackPromise(axios.put<any, ReturnProps>(IDENTITY_URL + "/api/v1/teachers/profile", param));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            // alert(res.error)
            return null;
        }
        else {
            setSuccess("Record updated");
            setShowSuccess(true);
        }
    }

    useEffect(() => {
        LoadUserProfile();
    }, []);

    return (
        <React.Fragment>

            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
            <AlertInfo show={showSuccess} setShow={setShowSuccess} isAutoHide={true} message={success} type={1} />

            <Page
                type="dashboard"
                history={history}
                headerTitle="Profile Settings"
                activePage="TutorProfileSettings"
            >
                {userProfile && (<div>
                    <ProfileInformation name={name} surname={surname} eposta={email} image={img} />
                    <Summary summary={summary} setSummary={setSummary} />
                    <Languages userLanguages={userLanguages} setUserLanguages={setUserLanguages} />
                    <ExpertiseOn selectedLessonStyle={selectedExpertiseOns} setSelectedLessonStyle={setSelectedExpertiseOns} />
                    <Personality selectedPersonality={selectedPersonality} setSelectedPersonality={setSelectedPersonality} />
                    <Interests selectedInterest={selectedHobbies} setSelectedInterest={setSelectedHobbies} />
                    <Experience experience={experience} setExperience={setExperience} />
                    {/* <Features /> */}
                    <AboutMe aboutMe={aboutMe} setAboutMe={setAboutMe} />
                    <ProfileVideo update={LoadVideo} videoUrl={videoUrl} />
                    <ProfileFooter onClick={onSaveChangesClick} />
                </div>)}

            </Page>
        </React.Fragment>
    )
}

function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer
    }
}
export default connect(mapStateToProps)(InstructorProfileSettings);

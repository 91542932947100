
export const packages = [
    {
        id: 0,
        duration: 1,
        prevPrice: 1049,
        currPrice: 524.50,
        autoRenew: 1,
        discount: 50
    },
    {
        id: 1,
        duration: 3,
        prevPrice: 435,
        currPrice: 330.70,
        autoRenew: 3,
        discount: 65
    },
    {
        id: 2,
        duration: 6,
        prevPrice: 398,
        currPrice: 285.60,
        autoRenew: 6,
        discount: 70
    },
]

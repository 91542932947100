import { css } from "styled-components";
import theme from "../../theme";

const StyledButton = css`
    background-color: ${theme.buttons.colors.google};
    border-color: ${theme.buttons.colors.google};
    color: ${theme.text.colors.main};
    border: 1px solid ${theme.colors.filter};
    &:hover {
        background-color: ${theme.buttons.colors.googleHover};
        border-color: ${theme.buttons.colors.googleHover};
        color: ${theme.text.colors.main};
        border: 1px solid ${theme.colors.filter};
    }
    @media only screen and (max-width: 720px) {
        width: 100%;
        padding: 8px 0 !important;
        font-size: 15px !important;
        margin-top: 10px;
    }
    @media only screen and (max-width: 960px) and (min-width: 720px) {
        padding: 10px 20px !important;
        font-size: 15px;
    }
    &:focus, 
    &:active {
        background-color: ${theme.buttons.colors.google};
        border-color: ${theme.buttons.colors.google};
    }
`;

export default StyledButton;

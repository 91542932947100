export const KVKKRules = [
    // {
    //     id: 0,
    //     title : "",
    //     content : ""
    // },
    {
        id: 0,
        title : "SPEAKINGLINE LTD KİŞİSEL VERİLERİN KORUNMASI HAKKINDA AYDINLATMA METNİ",
        content : "SPEAKINGLINE LTD olarak kişisel verilerinizin güvenliği bizler için önemli olup, korunması için özen göstermekteyiz. SPEAKINGLINE LTD olarak tüm şahıslara ait her türlü kişisel verilerin 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”)’na uygun olarak işlenerek, muhafaza edilmesine gayret göstermekteyiz. Veri Sorumlusu olarak KVKK kapsamında bütün sorumluluklarımızı aşağıdaki şekilde yerine getirmekteyiz."
    },
    {
        id: 1,
        title : "A. Kişisel Verileriniz Hangi Amaçlarla Toplanmakta ve İşlenmektedir?",
        content : "Toplanan kişisel verilerinizin neler olduğu, şirketimiz tarafından verilen hizmet, ürün ya da ticari faaliyete bağlı olarak değişkenlik gösterebilmekle; otomatik ya da otomatik olmayan yöntemlerle, ofisler, şubeler, çağrı merkezi, internet sitesi, sosyal medya mecraları, mobil uygulamalar ve benzeri vasıtalarla sözlü, yazılı ya da elektronik olarak toplanabilmektedir. Şirketimizin ürün ve hizmetlerinden yararlandığınız ve aksi bir talebiniz tarafımıza ulaşmadığı müddetçe oluşturularak ve güncellenerek kişisel verileriniz işlenebilecektir.\nToplanan kişisel verileriniz, şirketimiz tarafından sunulan ürün ve hizmetlerden sizleri faydalandırmak için gerekli çalışmaların iş birimlerimiz tarafından yapılması, şirketimiz tarafından sunulan ürün ve hizmetlerin sizlerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek sizlere önerilmesi, şirketimizin ve şirketimizle iş ilişkisi içerisinde olan kişilerin hukuki ve ticari güvenliğinin temini şirketimizin ticari ve iş stratejilerinin belirlenmesi ve uygulanması amaçları ile KVKK’nun 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları dahilinde işlenecektir."
    },
    {
        id: 2,
        title : "B. Şirketimiz Bünyesinde Bulundurulan Kişisel Verileriniz Kimlerle ve Hangi Amaçlarla Paylaşılmaktadır?",
        content : "Toplanan kişisel verileriniz; şirketimiz tarafından sunulan ürün ve hizmetlerden sizleri faydalandırmak için gerekli çalışmaların iş birimlerimiz tarafından yapılması, sizlerden gelen ürüne ilişkin geliştirme ve diğer taleplerin yerine getirilmesi, şirketimiz tarafından sunulan ürün ve hizmetlerin sizlerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek sizlere önerilmesi, şirketimizin ve şirketimizle iş ilişkisi içerisinde olan kişilerin hukuki ve ticari güvenliğinin temini, ticari ve iş stratejilerinin belirlenmesi ve uygulanması ile Şirketimizin insan kaynakları politikalarının yürütülmesinin temini amaçlarıyla iş ortaklarımıza, tedarikçilerimize, grup şirketlerimize, hissedarlarımıza, kanunen yetkili kamu kurumları ve özel kişilere, KVKK’nun 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde aktarılabilecektir."
    },
    {
        id: 3,
        title : "C. Kişisel Verileriniz Hangi Yöntemler ile Toplanmaktadır ve Toplanmasındaki Yasal Dayanak Nedir?",
        content : "Kişisel verileriniz, her türlü sözlü, yazılı ya da elektronik ortamda, yukarıda yer verilen amaçlar doğrultusunda şirketçe sunduğumuz ürün ve hizmetlerin belirlenen yasal çerçevede sunulabilmesi ve bu kapsamda Şirketimizin sözleşme ve yasadan doğan mesuliyetlerini eksiksiz ve doğru bir şekilde yerine getirebilmesi gayesi ile edinilir. Bu hukuki sebeple toplanan kişisel verileriniz KVKK’nun 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları kapsamında bu metnin (A) ve (B) başlıklı bölümlerinde belirtilen amaçlarla da işlenebilmekte ve aktarılabilmektedir."
    },
    {
        id: 4,
        title : "D. Şirketimiz Bünyesinde Bulundurulan Kişisel Verileriniz Kimlerle ve Hangi Amaçlarla Paylaşılmaktadır?",
        content : "Toplanan kişisel verileriniz; şirketimiz tarafından sunulan ürün ve hizmetlerden sizleri faydalandırmak için gerekli çalışmaların iş birimlerimiz tarafından yapılması, sizlerden gelen ürüne ilişkin geliştirme ve diğer taleplerin yerine getirilmesi, şirketimiz tarafından sunulan ürün ve hizmetlerin sizlerin beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek sizlere önerilmesi, şirketimizin ve şirketimizle iş ilişkisi içerisinde olan kişilerin hukuki ve ticari güvenliğinin temini, Şirketimizin ticari ve iş stratejilerinin belirlenmesi ve uygulanması ile Şirketimizin insan kaynakları politikalarının yürütülmesinin temini amaçlarıyla iş ortaklarımıza, hissedarlarımıza, kanunen yetkili kamu kurumları ve özel kişilere, KVKK’nun 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde aktarılabilecektir."
    },
    {
        id: 5,
        title : "E. Kişisel Veri Sahiplerinin KVKK kapsamında Hakları Nelerdir?",
        content : "Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi, işbu Aydınlatma Metni’nde belirtildiği şekilde SPEAKINGLINE LTD’ye iletmeniz talebinize ilişkin dönüş sağlanacaktır. Bu kapsamda kişisel veri sahibi olarak\nKişisel veri işlenip işlenmediğini öğrenme,\nKişisel verileri işlenmişse buna ilişkin bilgi talep etme,\nKişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,\nYurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,\nKişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,\nKVKK’nun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,        \nİşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,\nKişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme haklarına sahipsiniz.\nYukarıda belirtilen haklarınızı kullanmak için kimliğinizi tespit edici gerekli bilgiler ile kullanmayı talep ettiğiniz hakkınıza yönelik açıklamalarınızı içeren talebinizi; www.speakingline.com adresindeki başvuru formunu doldurarak 47 Gladbeck Way Enfield EN2 7EL London, United Kingdom adresine bizzat getirebilir veya noter kanalı ile gönderebilir ve/veya formun imzalı bir nüshasını info@speakingline.com e-posta adresine güvenli elektronik imzalı olarak kayıtlı elektronik posta adresinizden iletebilirsiniz."
    },
    {
        id: 6,
        title : "SpeakingLine London Ltd",
        content : "284 Chase Road A Block 2nd Floor Unit 139 Southgate London United Kingdom N14 6HF -  info@speakingline.com"
    },
];
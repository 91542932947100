import React, { ReactNode, useState, useRef } from "react";
import NavBar from "../navbars";
import { History } from "history";
import "../../layout/DashboardPage.css";
import { Container, Row, Col, Image } from "react-bootstrap";
import Footer from "../Footer";
import "../../mobile/DashboardPage.css";
import "../../tablet/DashboardPage.css";

import MobileDownArrow from "../../assets/Support/downArrow.png";
import MobileUpArrow from "../../assets/Support/mobile-up-arrow.png";
import TabletArrow from "../../assets/Support/tablet-arrow.png";
import TabletCloseArrow from "../../assets/Support/tabletCloseArrow.png";
import TabletNavigation from "../../assets/dashboard/tabletNav.png";
import { connect, useDispatch } from "react-redux";
import { MESSAGES_URL, ROLES } from "../../Global/Constants/constants";
import { disconnectConnection } from "../../Global/Utilities/signalr"
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import { ReturnProps } from "../../@types/functions";
import {useTranslation} from "react-i18next";

interface DashboardPageInterface {
    auth: any
    history: History,
    children: ReactNode,
    headerTitle: String,
    activePage: String
}

const DashboardPage: React.FC<DashboardPageInterface> = ({ history, auth, children, headerTitle, activePage }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigation = useRef<HTMLDivElement | null>(null);
    const [navOpened, setNavOpened] = useState(false);
    const [navMobileIcon, setNavMobileIcon] = useState(MobileDownArrow);
    const [navTabletIcon, setNavTabletIcon] = useState(TabletArrow);

    const studentDashboard = (auth?.user?.role === ROLES.STUDENT);

    const handleMobileNavigation = () => {
        if (!navOpened) {
            navigation.current?.classList.add("open-dashboard-navigation")
            setNavMobileIcon(MobileUpArrow)
            setNavOpened(true)
        } else {
            navigation.current?.classList.remove("open-dashboard-navigation")
            setNavOpened(false)
            setNavMobileIcon(MobileDownArrow)
        }
    }
    const handleTabletNavigation = () => {
        if (!navOpened) {
            navigation.current?.classList.add("open-tablet-dashboard-navigation")
            setNavTabletIcon(TabletCloseArrow)
            setNavOpened(true)
        } else {
            navigation.current?.classList.remove("open-tablet-dashboard-navigation")
            setNavOpened(false)
            setNavTabletIcon(TabletArrow)
        }
    }
    const handleNavigation = () => {
        const width = window.screen.width;
        if (width <= 720) {
            handleMobileNavigation()
        } else {
            handleTabletNavigation()
        }
    }

    const goToSupport = async () => {
        const res = await trackPromise(axios.post<any, ReturnProps>(MESSAGES_URL + "/api/v1/rooms/supportroom"))
        if (res.error || !res.headers.location) {
            return;
        }
        else {
            const header: String = res.headers.location;
            const id = header.split("/")[1];

            dispatch({
                type: "CHAT_DIALOG_IS_SHOWN",
                payload: {
                    isOpen: true,
                    roomId: id
                }
            });
        }
    }
    return (
        <div>
            <div className="dashboard-page-header">
                <div className="dashboard-page-fixed-nav-bar">
                    <NavBar
                        type="dashboard"
                        history={history}
                        studentDashboard={studentDashboard}
                    />
                </div>
                <Container>
                    <h4 className="dashboard-page-header-title">{headerTitle}</h4>
                </Container>
            </div>
            <Container className="dashboard-page-container">
                <Row className="dashboard-page-wrapper">
                    <Col sm={12} md={1} className="support-down-arrow">
                        <Image src={navMobileIcon} onClick={handleNavigation} className="dashboard-mobile-navigation" height={40} />
                        <Image src={navTabletIcon} onClick={handleNavigation} className="dashboard-tablet-navigation" height={40} />
                        <Image src={TabletNavigation} className="dashboard-tablet-nav" width={40} />
                    </Col>
                    <Col lg={3} className="support-navigation-container" ref={navigation}>
                        <div className="dashboard-page-navigation">
                            {
                                (studentDashboard === true || studentDashboard === undefined) && (
                                    <>
                                        <div className={`dashboard-page-navigation-link ${(activePage === "StudentProfileSettings") && "active"}`} onClick={() => history.push("/student/profile/settings")}>
                                            <p>{t("general.profile_settings")}</p>
                                        </div>
                                        <div className={`dashboard-page-navigation-link ${(activePage === "ChangePassword") && "active"}`} onClick={() => history.push("/change-password")}>
                                            <p>{t("change_password.change_your_password")}</p>
                                        </div>
                                        {/* <div className={`dashboard-page-navigation-link ${(activePage === "StudentPreferences") && "active"}`} onClick={() => history.push("/student/profile/preferences")}>
                                            <p>Tercihler</p>
                                        </div> */}
                                        <div className={`dashboard-page-navigation-link ${(activePage === "SubscriptionDetails") && "active"}`} onClick={() => history.push("/subscription/details")}>
                                            <p>{t("home.subscription_packages.subscription_management_title")}</p>
                                        </div>
                                        <div className={`dashboard-page-navigation-link ${(activePage === "Support") && "active"}`} onClick={goToSupport}>
                                            <p>{t("general.support")}</p>
                                        </div>
                                        <div className={`dashboard-page-navigation-link ${(activePage === "Tercihler") && "active"}`} onClick={() => {
                                            dispatch({ type: "USER_LOGGED_OUT", payload: true });
                                        }}>
                                            <p>{t("general.logout")}</p>
                                        </div>
                                    </>
                                )
                            }
                            {
                                (studentDashboard === false) && (
                                    <>
                                        <div
                                            className={`dashboard-page-navigation-link ${(activePage === "TutorProfileSettings") && "active"}`}
                                            onClick={() => history.push("/tutor/profile/settings")}>
                                            <p>{t("general.profile_settings")}</p>
                                        </div>
                                        <div
                                            className={`dashboard-page-navigation-link ${(activePage === "TutorPayment") && "active"}`}
                                            onClick={() => history.push("/tutor/payment")}>
                                            <p>{t("general.payment")}</p>
                                        </div>
                                        {/* <div className={`dashboard-page-navigation-link ${(activePage === "TutorPreferences") && "active"}`} onClick={() => history.push("/tutor/profile/preferences")}>
                                            <p>Preferences</p>
                                        </div> */}
                                        <div
                                            className={`dashboard-page-navigation-link ${(activePage === "Files") && "active"}`}
                                            onClick={() => history.push("/files")}>
                                            <p>{t("general.files")}</p>
                                        </div>
                                        <div
                                            className={`dashboard-page-navigation-link ${(activePage === "ChangePassword") && "active"}`}
                                            onClick={() => history.push("/change-password")}>
                                            <p>{t("change_password.change_your_password")}</p>
                                        </div>
                                        <div
                                            className={`dashboard-page-navigation-link ${(activePage === "Support") && "active"}`}
                                            onClick={goToSupport}>
                                            <p>{t("general.support")}</p>
                                        </div>


                                        {/* <div className={`dashboard-page-navigation-link ${(activePage === "Profil Ayarları") && "active"}`} onClick={() =>history.push("/tutor/payment/settings")}>
                                            <p>Ödemeler</p>
                                        </div> */}
                                        {/* <div className={`dashboard-page-navigation-link ${(activePage === "Şifremi Değiştir") && "active"}`} onClick={() =>history.push("/instructor/password/change")}>
                                            <p>Destek Talebi</p>
                                        </div> */}
                                        <div
                                            className={`dashboard-page-navigation-link ${(activePage === "Tercihler") && "active"}`}
                                            onClick={() => {
                                                dispatch({type: "USER_LOGGED_OUT", payload: true});
                                            }}>
                                            <p>{t("general.logout")}</p>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </Col>
                    <Col lg={9} md={11}>
                        <div className="dashboard-page-content">
                            {
                                children
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="dashboard-page-footer">
                <Footer />
            </div>
        </div>
    )
}

function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer
    }
}

export default connect(mapStateToProps)(DashboardPage);

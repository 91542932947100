import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import getMobileOperatingSystem from "../../Global/Utilities/utility";


const LoginRoute : React.FC<RouteProps> = ({component, ...rest }) => {
    
    var mobile = getMobileOperatingSystem();
    if(mobile === "Android" || mobile === "IOS")
    {
        return(<Route {...rest} render={() => {return <Redirect to='/applications' /> }} />)
    } 

    return (
        <Route {...rest} component= {component} />
      )
  }

export default LoginRoute;
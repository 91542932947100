import React from "react";
import StudentDashboardMainCard from "./StudentDashboardMainCard";
import SubscriptionSummaryCard from "./SubscriptionSummaryCard";
import NextSessionCard from "./NextSessionCard";
import "../../../layout/StudentDashboardCards/StudentDashboardCard.css";

interface StudentDashboardCardProps {
    cardName: "main-card" | "subscription-summary-card" | "next-session-card" | "";
    userName?: String;
    subscriptionDetails?: any,
    nextSession? :any,
    onPrimaryButtonClicked? : () => void
}

const StudentDashboardCard:React.FC<StudentDashboardCardProps> = ({ 
    cardName, 
    userName="", 
    subscriptionDetails={},
    nextSession = {},
    onPrimaryButtonClicked,

}) =>{
    switch(cardName) {
        case "main-card":
            return <StudentDashboardMainCard 
                       userName={userName} 
                       onPrimaryButtonClicked = {onPrimaryButtonClicked}
                    />
        case "subscription-summary-card":
            return <SubscriptionSummaryCard 
                       subscriptionDetails={subscriptionDetails}
                    />
        case "next-session-card":
            return <NextSessionCard nextSession = {nextSession}/>
        default:
            return <div></div>
    }
}

export default StudentDashboardCard;


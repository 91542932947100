import React from "react";
import "../../../layout/StudentVideoChat/ChatRoom.css";
import { Image } from "react-bootstrap";
import ChatRoomMessanger from "./ChatRoomMessanger";
import { useSpring, animated } from "react-spring";
import "../../../mobile/StudentVideoChat/ChatRoom.css";

import chatIcon from "../../../assets/chatIcon.svg";
import close from "../../../assets/student-video-chat/close.png";

interface ChatRoomProps {
    handleShowChatRoom: () => void;
    showChatRoom: Boolean;
    hideChatRoom: () => void;
    attendeeName: string;
    meetingId: string;
}

const ChatRoom: React.FC<ChatRoomProps> = ({
    handleShowChatRoom,
    showChatRoom,
    hideChatRoom,
    meetingId,
    attendeeName,
}) => {
    const getChatRoomWidth = () => {
        const width = window.screen.width;
        if (width < 720) return width * 1.09
        else return 350
    }
    const chatRoomProps = useSpring({
        width: showChatRoom ? getChatRoomWidth() : 50,
    })

    return (
        <>
            {/* {
                !showChatRoom && (
                    <div className="chatroom-icon-container">
                        <Image
                            src={ChatIcon}
                            height={48}
                            width={48}
                            onClick={handleShowChatRoom}
                        />
                    </div>
                )
            } */}
            {
                showChatRoom && (
                    <animated.div
                        className="chatroom-container"
                        style={{
                            width: chatRoomProps.width
                        }}
                    >
                        <div className="chatroom-close-icon">
                            <Image
                                src={close}
                                height={34}
                                width={34}
                                onClick={hideChatRoom}
                            />
                        </div>
                        <ChatRoomMessanger
                            meetingId={meetingId}
                            attendeeName={attendeeName}
                            showChatRoom = {showChatRoom}
                        />
                    </animated.div>
                )
            }
        </>
    )
}


export default ChatRoom;

import React from "react";
import "../../layout/EmailVerification/Form.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Container, Form } from "react-bootstrap";
import "../../mobile/EmailVerfication/Form.css";
import {useTranslation} from "react-i18next";

const EmailVerificationForm:React.FC<any> = ({ history, email, userId, type, role}) =>{
    const { t } = useTranslation();
    var content;
    if(type === "activation")
    {
        content = <div>{t("signup.activation_code_sent")} <b>{email}</b>. {t("signup.click_the_link")} </div>
    }
    else if (type === "forgetPassword")
    {
        content = <div> {t("signup.new_password_sent")} <b>{email}</b>. </div>
    }
    else 
    {
        history.replace("/");
        return null;
    }

    return (
        <div className="email-verification-form">
            <Card className="shadow email-verification-form-container">
                <Card.Body>
                    <Container>
                        <h5 className="email-verification-form-title">{t("signup.check_your_email")}</h5>
                        <h6 className="email-verification-form-subtitle text-muted mt-4">{content}</h6>
                    
                        {/* <div className="mt-4">
                            <Form className="text-left">
                                <div className="mt-3 form-links">
                                    <Form.Text className="resend-email">
                                        <span style={{color: "#F86F53", cursor: "pointer"}}>Re-send mail.</span>
                                    </Form.Text>
                                </div>
                            </Form>
                        </div> */}
                    </Container>
                </Card.Body>
            </Card>
        </div>
    )
}

export default EmailVerificationForm;

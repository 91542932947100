import React from "react";
import MinimalNavBar from "./MinimalNavBar";
import MainNavBar from "./MainNavBar";
import { NavBarProps } from "../../@types/NavBar";
import LightNavBar from "./LightNavBar";
import DashboardNavBar from "./DashboardNavBar";
import VideoMeetingNavBar from "./VideoMeetingNavBar";
import AdminDashboardNavBar from "./AdminDashboardNavBar";
import NewMainNavBar from "./NewMainNavBar";

const NavBar:React.FC<NavBarProps> = ({ 
    type, 
    links, 
    history, 
    navTheme, 
    theme, 
    onClickExpand,
    studentDashboard
}) =>{
    if(type === "main") return (
        <MainNavBar 
            links={links} 
            history={history} 
            navTheme={navTheme} 
        />
    )
    else if(type === "new_main") return (
        <NewMainNavBar 
            links={links} 
            history={history} 
            navTheme={navTheme} 
        />
    )
    else if (type === "minimal") return (
        <MinimalNavBar 
            links={links} 
            history={history} 
        />
    )
    else if(type === "light") return (
        <LightNavBar 
            links={links} 
            history={history} 
        />
    )
    else if(type === "dashboard") return (
        <DashboardNavBar 
            history={history} 
            navTheme={theme} 
            onClickExpand={onClickExpand} 
            studentDashboard={studentDashboard}
        />
    )
    else if(type === "admin-dashboard") return (
        <AdminDashboardNavBar 
            history={history} 
            navTheme={theme} 
            onClickExpand={onClickExpand} 
        />
    )
    else if(type === "video-meeting") return (
        <VideoMeetingNavBar 
            history={history} 
            navTheme={theme} 
            onClickExpand={onClickExpand}
        />
    )
    return <div></div>
}

export default NavBar;
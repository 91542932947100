import React from "react";
import "../../layout/Preferences/Prefrences.css";
import Page from "../../components/pages";
import { RouteComponentProps } from "react-router-dom";
import Input from "../../components/inputs";
import { Row, Col, Image } from "react-bootstrap";
import Button from "../../components/buttons";

import info from "../../assets/preferences/info.png";

const TutorPreferences:React.FC<RouteComponentProps> = ({ history }) =>{
    return(
        <Page type="dashboard" history={history} headerTitle="Preferences" activePage="TutorPreferences">
            <h4 className="preferances-title">Notification Settings</h4>
            <div className="preferances-settings-container">
                <Input type="checkbox" label="Seanslarımı kaydet" />
                <Input type="checkbox" label="Tarafıma mesaj gönderildiğinde mobil bildirim almak istiyorum" />
                <Input type="checkbox" label="Eu sollicitudin donec et adipiscing dolor fringilla et nulla. Mollis volutpat massa ut tristique aliquam morbi pulvinar a, suspendisse. Risus ut amet proin tristique semper sem suspendisse pharetra. Diam tempus." />
                <Input type="checkbox" label="Orci, egestas mi felis eu, vitae praesent. Et, dignissim at amet sit tristique vehicula elit. Sagittis scelerisque quisque adipiscing morbi elementum molestie. In non id molestie risus ullamcorper lectus." />
                <Input type="checkbox" label="Kampanyalar hakkında mobil bildirim almak istiyorum" />
            </div>
            {/* <hr className="preferences-sep" /> */}
            {/* <div>
                <h4 className="preferances-title">{"Language & Location"}</h4>
                <Row className="preferances-language-location">
                    <Col md={4}>
                        <Input 
                            type="select" 
                            label="Langauge" 
                            options={["Turkish"]} 
                        />
                    </Col>
                    <Col md={4}>
                        <Input 
                            type="select" 
                            label="Location" 
                            icon={info} 
                            iconHoverLabel="The Location Option Will Help Us Figure out The Time Zone That You Are Currently On ." 
                            options={["Turkey"]} 
                        />
                    </Col>
                    <Col md={4}>
                        <Input 
                            type="default" 
                            label="Time Zone" 
                            value="Istanbul (GMT+3)" 
                            style={{ backgroundColor: "#CACCCF" }} 
                            disabled 
                        />
                    </Col>
                </Row>
            </div> */}

            <hr className="preferences-sep" />
            <div className="preferences-save-button-container">
                <Button type="default" primary style={{ paddingLeft: 40, paddingRight: 40 }} className="preferences-save-button">Save  Changes</Button>
            </div>
        </Page>
    )
}

export default TutorPreferences;


import React, { useState, useEffect } from 'react'
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { NoReservationFoundIcon, vectorLeft, vectorRight } from '../../../assets/SVG/SVG';
import moment from 'moment';
import ConfirmationModal from './tutorConfirmationModal';
import { CONVERSATION_URL } from "../../../Global/Constants/constants";
import { ReturnProps } from "../../../@types/functions";
import axios from "axios";
import { trackPromise } from 'react-promise-tracker';
import "../../../layout/ReservationCalendarForTeacherModal/ReservationCalendarForTeacher.css"
import AlertInfo from '../../alerts/AlertInfo';
import {useTranslation} from "react-i18next";

let type: moment.unitOfTime.StartOf = "week";

interface ReservationCalendarForTeacherProps {
    userId: any;
    userName: string
}

const ReservationCalendarForTeacher: React.FC<ReservationCalendarForTeacherProps> = ({ userId, userName }) => {
    const { t } = useTranslation();

    const [success, setSuccess] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);


    const [selectedMeetingMinutes, setSelectedMeetingMinutes] = useState('');
    const [selectedDay, setSelectedDay] = useState(moment().format("D"));
    const [selectedDate, setSelectedDate] = useState(moment());
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(0);
    const [timeSlots, setTimeSlots] = useState<[any]>();
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [data, setData] = useState({
        meetingMinutes: [{ time: "15", label: "15 dakika" }, { time: "30", label: "30 dakika" }, { time: "60", label: "60 dakika" }],
    })

    const [weekdays, setWeekDays] = useState<any[]>([]);
    const [currentWeek, setCurrentWeek] = useState(moment().clone().startOf(type));

    useEffect(() => {
        getCurrentWeek();
    }, [currentWeek]);

    const getCurrentWeek = () => {
        var days: any[] = [];
        for (var i = 0; i <= 6; i++) {
            days.push(
                {
                    date: moment(currentWeek).add(i, 'days').format("D"),
                    month: moment(currentWeek).add(i, 'days').format("MMMM"),
                    day: moment(currentWeek).add(i, 'days').format("dddd"),
                    fullDate: moment(currentWeek).add(i, 'days')
                });
        }
        setWeekDays(days)
    }

    const prevWeek = () => {
        var date: any = "";
        date = currentWeek.subtract(1, 'week').startOf(type)
        setCurrentWeek(date);
        getCurrentWeek();
    }
    const nextWeek = () => {
        setCurrentWeek(currentWeek.add(1, 'week').startOf(type));
        getCurrentWeek();
    }
    const onHandleMeetingMinutes = (val: string) => {
        setSelectedMeetingMinutes(val)
        getAvailableTimeForTeacher(val, selectedDate);
    }

    const getAvailableTimeForTeacher = async (_selectedMinutes?: string, _selectedDate?: any) => {
        setSelectedTimeSlot(0);
        let selDt = _selectedDate ?? selectedDate;
        let selMin = _selectedMinutes ?? selectedMeetingMinutes;

        if (!selMin || !selDt) {
            return;
        }

        const startUnixTime = moment(selDt).unix();
        var endUnixTime = moment(selDt).add(1, "day").unix();

        if(moment(selDt).isSame(new Date(), "day")) //If this is today, endUnixTime should be at the end of today.
        {
          endUnixTime = moment().endOf("day").unix();
        }

        const res = await trackPromise(axios.get<any, ReturnProps>(CONVERSATION_URL + "/api/v1/schedule-meetings/" + userId + "/freetime?start=" + startUnixTime + "&end=" + endUnixTime + "&meetingInterval=" + selMin));
        // Handling Error
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            // alert(res.error)
            return null;
        }
        setTimeSlots(res.data);
    }

    const addReservationForStudent = async () => {
        setIsConfirmationModal(false);

        const reqParam = {
            teacherUserId: userId,
            startDate: selectedTimeSlot,
            minute: parseInt(selectedMeetingMinutes, 10)
        }
        const res = await trackPromise(axios.post<any, ReturnProps>(CONVERSATION_URL + "/api/v1/schedule-meetings", reqParam));
        // Handling Error
        if (!res.success || !res.headers.location) {
            setError(res.error as string);
            setShowError(true)
            //alert(res.error)
            return null;
        }
        else {
            setSuccess(t("reservation.reservation_created"));
            setShowSuccess(true);
        }
    }

    const selectedTimeSlotUnix = (selectedTimeSlot ?? 0) * 1000;

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
            <AlertInfo show={showSuccess} setShow={setShowSuccess} isAutoHide={true} message={success} type={1} />

            <div className="calendear-wrapper">
                <InputGroup className="mb-4">
                    <InputGroup.Prepend>
                        <InputGroup.Text
                            onClick={prevWeek}>{vectorLeft}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl disabled
                        value={currentWeek.format("D MMMM YYYY") + " - " +
                            moment(currentWeek).endOf('week').format("D MMMM YYYY")
                        } />
                    <InputGroup.Prepend>
                        <InputGroup.Text className="input-right"
                            onClick={nextWeek}>{vectorRight}</InputGroup.Text>
                    </InputGroup.Prepend>
                </InputGroup>

                <div className="d-flex justify-content-between mb-4">
                    {weekdays.map((dt, key) => (
                        <div key={"date" + key} className="p-2 text-center text-white" onClick={
                            () => {
                                setSelectedDay(dt.date)
                                setSelectedDate(dt.fullDate)
                                getAvailableTimeForTeacher(selectedMeetingMinutes, dt.fullDate)
                            }
                        }>
                            <div className="date-box" style={{ cursor: 'pointer', background: selectedDay === dt.date ? '#F86F53' : '#5389F8' }}>
                                <h3>{dt.date} </h3>
                                <p>{dt.month}</p>
                                <p className="day-wrapper">{dt.day}</p>
                            </div>

                        </div>
                    ))}
                </div>
                {selectedDay !== "" && <div className="mb-4">
                    <h6>{t("reservation.meeting_duration")}</h6>
                    <div className="d-flex justify-content-between time-range">
                        {
                            data.meetingMinutes.map((min, key) => (
                                <Button onClick={() => onHandleMeetingMinutes(min.time)} style={min.time === selectedMeetingMinutes ?
                                    { backgroundColor: "#F86F53", border: '1px solid #F86F53', color: 'white' }
                                    : {}} key={key} variant="outline-secondary">{min.time} {t("general.minute")}</Button>
                            ))
                        }
                    </div>
                </div>}

                {selectedMeetingMinutes !== "" && <div className="mb-4 ">
                    <h6>{t("reservation.select_start_date")}</h6>
                    <div className="row" style={{ justifyContent: "flex-start" }}>

                        {(timeSlots && timeSlots.length > 0) ? timeSlots.map((item, key) => (

                            <div key={"timeSlot" + key} className="col-3 time-slot" >
                                <Button onClick={() =>
                                    setSelectedTimeSlot(item.startTime)}
                                    variant="outline-secondary"
                                    disabled={!item.isAvailable}
                                    style={selectedTimeSlot === item.startTime ?
                                        { backgroundColor: "#F86F53", border: '1px solid #F86F53', color: 'white' }
                                        : !item.isAvailable ? { backgroundColor: "#CACCCF"} : {}
                                    } >
                                    {moment(item.startTime * 1000).format("HH:mm")}
                                </Button>
                            </div>

                        )) : (
                            <div className="reservations-calendar-container">
                                <div>{NoReservationFoundIcon}</div>
                                <p className="reservations-calendar-container-text">
                                    {t("reservation.no_available_slot")}
                                        <br />
                                    {t("reservation.change_and_try")}
                                        <br />
                                </p>
                            </div>
                        )
                        }
                    </div>

                    {selectedTimeSlotUnix !== 0 &&
                        (
                            <div className="reservation-wrapper">
                                <p> <b>{moment(selectedDate).format('DD MMMM, dddd')}, {moment(selectedTimeSlotUnix).format("HH:mm")}</b>’de eğitmenlerimizden <b>{userName} </b> ile  </p>
                                <p> <b> {selectedMeetingMinutes}</b> {t("reservation.minutes_reservation_selected")}</p>
                                <Button onClick={() => setIsConfirmationModal(true)} variant="primary">{t("reservation.do_reservation")}</Button>
                            </div>
                        )
                    }
                </div>}
            </div>
            {isConfirmationModal &&
                <ConfirmationModal
                    show={isConfirmationModal}
                    approveClicked={() => addReservationForStudent()}
                    hide={() => setIsConfirmationModal(false)}
                    selectedMeetingMinutes={selectedMeetingMinutes}
                    date={selectedDate}
                    timeSlot={selectedTimeSlotUnix}
                    userId={userId}
                    userName={userName}
                />
            }

        </React.Fragment>
    )
}

export default ReservationCalendarForTeacher
import React, { useState } from "react";
import "../../layout/StudentInstrucotrsPage/Content.css";
import Card from "../../components/cards";
import { connect } from "react-redux";
import { InstructorProps } from "../../@types/Instructors/InstructorProps";
import { animated, useSpring } from "react-spring";
import { useDispatch } from "react-redux";
import MakeReservationModal from "../../components/modals/MakeReservationModal/MakeReservationModal";

interface ContentComponentProps {
    instructorsData?: any[];
    favTeacherList?: any[];
    startAnimation: Boolean | null;
    showContent: Boolean,
    onLike: () => void
}

type ContentProps = ContentComponentProps;

const Content: React.FC<ContentProps> = ({
    instructorsData,
    favTeacherList,
    startAnimation,
    showContent,
    onLike = () => null
}) => {
    const cardAnimation = useSpring({
        opacity: startAnimation ? 1 : 0,
        transform: startAnimation ? "translateY(0px)" : "translateY(40px)",
        config: {
            duration: 700
        }
    })
    const dispatch = useDispatch();

    const [selectedUser, setSelectedUser] = useState<any>();
    const [showMakeReservationModal, setShowMakeReservationModal] = useState(false);

    return (
        <React.Fragment>
            <div className="student-instructors-page-content-container">
                {
                    showContent && instructorsData && instructorsData.length > 0 && instructorsData.map((instructor, index) => (
                        // <animated.div
                        //     key={instructor.user.guid}
                        //     style={
                        //         (startAnimation !== null) ? {
                        //             opacity: cardAnimation.opacity,
                        //             transform: cardAnimation.transform
                        //         } : {}
                        //     }
                        // >
                            <Card
                                key={instructor.user.guid}
                                type="tutor"
                                userId={instructor.user.guid}
                                name={instructor.user.name}
                                surname={instructor.user.surname}
                                languages={instructor.knownLanguages}
                                countryCode={instructor.user.countryCode}
                                availableAfter={15}
                                status={instructor.status}
                                isFav={favTeacherList && favTeacherList.length > 0 && favTeacherList?.find(x => x == instructor.user.guid) ? true : false}
                                expertise={instructor.expertiseOn}
                                description={instructor.summary}
                                thumb={instructor.user.photoUrl}
                                rating={instructor.user.rate}
                                onLike={onLike}
                                makeReservationClicked={() => {
                                    setShowMakeReservationModal(true);
                                    setSelectedUser(instructor);
                                }}
                            />
                        // </animated.div>
                    ))
                }
            </div>

            {
                showMakeReservationModal &&
                <MakeReservationModal
                    show={showMakeReservationModal}
                    hide={() => setShowMakeReservationModal(false)}
                    userId={selectedUser.user.guid}
                    name={selectedUser.user.name}
                    surname={selectedUser.user.surname}
                />
            }



        </React.Fragment>

    )
}

// interface StateProps {
//     instructorsData: InstructorProps[]
// }

// function mapStateToProps(state:any) {
//     return {
//         instructorsData: state.InstructorsData
//     }
// }

// export default connect<StateProps, {}, {}>(mapStateToProps)(Content);
export default Content;
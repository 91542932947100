import React, { useEffect, useState } from "react";
import "../../layout/InstructorProfileSettings/ProfileInformation.css";
import { Image, Row, Col, Form, InputGroup } from "react-bootstrap";
import "../../layout/ProfileSettings/ProfileSettings.css";
import Input from "../../components/inputs";
import changePicIcon from "../../assets/profile-settings/changePicIcon.png";

import img from "../../assets/instructor-profile-settings/img.png";
import uploader from "../../assets/instructor-profile-settings/uploader.png";
import checkbox from "../../assets/instructor-profile-settings/checkbox.png";
import ImageCropAndUpload from "../../components/others/ImageCropAndUpload";
import profilePic from "../../assets/profile-settings/profilePic.png";
import person_empty from "../../assets/person_empty.png";

interface ProfileInformationProps {
    name: string,
    surname: string,
    eposta: string,
    image: any
}

const ProfileInformation: React.FC<any> = ({ name, surname, eposta, image, history, auth }) => {
    const fullName = name + " " + surname;
    const [img, setImg] = useState("");
    const [prevImg, setPrevImg] = useState(image);
    const [showCropper, setShowCropper] = useState(false);

    useEffect(() => {
        setImg(image || person_empty);
    }, [image]);

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        //console.log("handleImageUploadn");
        if (e.target?.files && e.target?.files?.length > 0) {
            const url = URL.createObjectURL(e.target.files && e.target.files[0])
            setPrevImg(img);
            setImg(url);
            setShowCropper(true);
        }
    }

    return (
        <div className="instructor-profile-information-container">
            <h4 className="instructor-profile-information-title">PERSONAL INFORMATION</h4>
            <div className="instructor-profile-information-modif-container">
                <div className="profile-settings-pic">
                    <Image src={img} width={150} height={150} style={{ borderRadius: 20 }} />
                    <div className="profile-settings-pic-change-icon-container">
                        <Form.Group as={Col}>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text className="profile-settings-upload-image" style={{ height: 150, width: 150 }}>
                                        <Image src={changePicIcon} />
                                        <Form.File id="exampleFormControlFile1" label=""  onChange={handleImageUpload} className="profile-settings-upload-image-file" >
                                        </Form.File>
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </Form.Group>
                    </div>
                </div>
                <div className="instructor-profile-information-settings-container">
                    <div className="instructor-profile-information-setting-input-container">
                        <h4 className="instructor-profile-information-setting-input-label">Name and Surname</h4>
                        <div className="instructor-profile-information-setting-wrapper">
                            <Input disabled={true} type="default" placeholder={fullName} />
                        </div>
                    </div>

                    <div className="instructor-profile-information-setting-input-container">
                        <h4 className="instructor-profile-information-setting-input-label">E-Mail*</h4>
                        <div className="instructor-profile-information-setting-wrapper multiple-children">
                            <div className="instructor-profile-information-setting-input">
                                <Input disabled={true} type="default" placeholder={eposta} />
                            </div>
                            <Image
                                src={checkbox}
                                height={44}
                                width={44}
                            />
                        </div>
                    </div>
                    {/* 
                    <div className="instructor-profile-information-setting-input-container">
                        <h4 className="instructor-profile-information-setting-input-label">Telefon Numaranız*</h4>
                        <div className="instructor-profile-information-setting-wrapper multiple-children">
                            <div className="instructor-profile-information-setting-input">
                                <Input type="default" placeholder="5123456789" />
                            </div>
                            <Image 
                                src={checkbox} 
                                height={44}
                                width={44}
                            />
                        </div>
                    </div> */}
                </div>
            </div>
            {/* <hr className="instructor-profile-information-sep" /> */}
            {
                showCropper && (
                    <ImageCropAndUpload img={img} setImg={setImg} setPrevImg={setPrevImg} prevImage={prevImg} onFinish={() => {
                        setShowCropper(false)
                    }} />
                    // <div className="profile-settings-image-cropper">
                    //     <div className="profile-settings-image-cropper-container">
                    //         <ReactCrop 
                    //             onImageLoaded={onImageLoaded}
                    //             src={img} 
                    //             crop={crop} 
                    //             onChange={newCrop => setCrop(newCrop)} 
                    //         />
                    //     </div> 
                    //     <div className="profile-settings-image-cropper-btns-container">
                    //         <Button 
                    //             type="default" 
                    //             primary 
                    //             className="profile-settings-image-cropper-btn"
                    //             onClick={() => { 
                    //                 uploadCroppedImage() ;
                    //                 setShowCropper(false)
                    //             }}
                    //         >
                    //             DONE
                    //         </Button>
                    //         <Button 
                    //             type="default" 
                    //             primary 
                    //             className="profile-settings-image-cropper-btn"
                    //             onClick={() =>{
                    //              //   setImg(prevImg)
                    //                 setShowCropper(false)
                    //             }}
                    //         >
                    //             CANCEL
                    //         </Button>
                    //     </div>
                    // </div>
                )
            }
        </div>


    )
}

export default ProfileInformation;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../../layout/InstructorReservations/Content.css";
import Card from "../../components/cards";
import { CONVERSATION_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import ReservationRecords from "./HistoryRecords";
import axios from "axios";
import NoHistoryFound from "./NoHistoryFound"
import HistoryRecords from "./HistoryRecords"
import { ROLES } from "../../Global/Constants/constants";
import { AuthReducerProps } from "../../@types/Auth";
import moment from "moment";
import { trackPromise } from "react-promise-tracker";
import AlertInfo from "../../components/alerts/AlertInfo";

const Content: React.FC<StateProps> = ({ auth, daterange, searchtext }) => {
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [historyList, setHistoryList] = useState<[any]>();
    //  const [reservationFilteredList, setReservationFilteredList ] = useState<any[any]>();

    const LoadHistory = async () => {
        var filter = "";
        if (daterange != null && daterange.dateRange != null && daterange.dateRange.timeRangeValue != null && daterange.dateRange.timeRangeValue.length > 0) {
            const startDt = moment(daterange?.dateRange?.timeRangeValue[0]).unix();
            const end = moment(daterange?.dateRange?.timeRangeValue[1]).unix();
            filter = "?start=" + startDt + "&end=" + end;
        }
        if (searchtext && searchtext.text && searchtext.text.value) {
            if (!filter) {
                filter = "?"
            }
            else {
                filter = filter + "&"
            }

            filter = filter + "nameOrMail=" + searchtext.text.value;
        }

        const res = await trackPromise(axios.get<any, ReturnProps>(CONVERSATION_URL + "/api/v1/history" + filter));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true);
            return null
        }
        setHistoryList(res.data);
        //setReservationFilteredList(res.data);
    }

    useEffect(() => {
        if (!daterange || daterange.length < 1) {
            return;
        }
        LoadHistory()
    }, [daterange]);

    useEffect(() => {
        if (!searchtext.text || !searchtext.text || !searchtext.text.value) {
            // setReservationFilteredList(reservationList);
            return;
        }
        LoadHistory()
    }, [searchtext]);

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
            { (!historyList || historyList?.length < 1) && (<div><NoHistoryFound /></div>)}

            <div className="instructor-reservations-content-container">
                {historyList && historyList.map((res: any, index: any) => (
                    <Card
                        key={res.meetingId}
                        type="history"
                        userId={res.attendee.guid}
                        name={res.attendee.name}
                        surname={res.attendee.surname}
                        thumb={res.attendee.photoURL || ""}
                        startTime={res.startDate}
                        countryCode={res.attendee.countryCode}
                        endTime={res.finishDate}
                        minute={res.duration}
                        itemId={res.meetingId}
                    />
                ))}
            </div>

            {historyList && historyList.length > 0 && <HistoryRecords />}
        </React.Fragment>
    )
}

interface StateProps {
    auth: any
    daterange: any
    searchtext: any
}
function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer,
        daterange: state.DateRangeFilterReducer,
        searchtext: state.SearchTextReducer
    }
}

export default connect(mapStateToProps)(Content);


const initialState = {
    isLogged: false,
    user: null
}

interface ActionProps {
    type: String,
    payload: any
}

export default function (state = initialState, action: ActionProps) {
    switch (action.type) {
        case "USER_LOGGED_IN":
            return state = { ...state, isLogged: true, user: action.payload }
        default:
            return state
    }
}

import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Rating, RatingValue, RatingOver } from "../StyledComponents";
import { Image } from "react-bootstrap";
import star from "../../../assets/cards/star.png";
import theme from "../../../theme";
import { useTranslation } from "react-i18next";

interface RatingProps {
    ratingValue?: Number
}

export const RatingNEWValue = styled.p`
    text-align: center;
    color: #EE3F46;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    font-family: ${theme.text.fonts.nunito};
    margin: 0;
    padding: 0;
    margin-left: 8px;
`;

export const RatingNEW = styled.div`
    margin: 0;
    padding: 0;
    text-align: left;
    color: #F2C94C;
    font-size: 14px;
    font-weight: 700;
    font-family: ${theme.text.fonts.nunito};
    margin-left: 5px;
    display: flex;
    padding: 5px 10px;
    background-color: #FEF3F4;
    border-radius: 5px;
    align-items: center;
    @media only screen and (max-width: 1024px) and (min-width: 721px) {
        margin-left: 0;
        width: 95px;
    }
    @media only screen and (max-width: 720px) {
        width: 95px;
        margin-left: 0;
        margin-bottom: 10px;
        transform: translateX(-10px);
    }
`;

// export const RatingStar = styled.div`
// // align-items: center;
// // width: 16px;
// // height: 16px;
// // margin-left: 8px;
// // margin-right: 4px
// `;

const RatingComp: React.FC<RatingProps> = ({ ratingValue }) => {
    const { t } = useTranslation();

    return (
        <div>
            {
                ratingValue ? (
                    <Rating>
                        {/* <div> */}
                        <Image src={star} height={16} width={16} />
                        {/* </div> */}
                        <RatingValue>
                            {ratingValue}
                        </RatingValue>
                        <RatingOver>
                            /5
                        </RatingOver>
                    </Rating>
                ) : (
                    <RatingNEW>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.00008 1V13M1.00008 3.72727L13.0001 10.2727M13.0001 3.72727L1.00008 10.2727" stroke="#EE3F46" strokeWidth="1.33333" strokeLinecap="round" />
                        </svg>
                        <RatingNEWValue>
                            {t("general.new")}
                        </RatingNEWValue>

                    </RatingNEW>
                )
            }
        </div>
    )
}

export default RatingComp;
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../../layout/MakeReservation/Content.css";
import "../../tablet/MakeReservation/Content.css";
import "../../mobile/MakeReservation/Content.css";
import "../../layout/InstructorReservations/Content.css";
import Card from "../../components/cards";
import { CONVERSATION_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import AvailableTutorRecords from "../CreateReservation/AvailableTutorRecords";
import axios from "axios";
import NoTeacherFound from "../CreateReservation/NoTeacherFound"
import { ROLES } from "../../Global/Constants/constants";
import { AuthReducerProps } from "../../@types/Auth";
import moment from "moment";

interface ContentProps {
    availableTeacherList?: any[];
    makeReservationClicked?: (user: any) => void;
}

const Content: React.FC<ContentProps> = ({
    availableTeacherList,
    makeReservationClicked = (user: any) => null,
}) => {
    return (
        <React.Fragment>

            {(!availableTeacherList || availableTeacherList?.length < 1) && (<div><NoTeacherFound /></div>)}

            {(availableTeacherList && availableTeacherList?.length > 0) && (
                <div className="make-reservation-container">
                    {/* <div className="make-reservation-title-container">
                <h4 className="make-reservation-title">Eğitmen Listesi</h4>
                <h4 className="make-reservation-subtitle">6 eğitmen listeleniyor</h4>
            </div> */}
                    <div className="make-reservation-instructor-list-container">
                        {
                            availableTeacherList?.map(instructor => (
                                <Card
                                    key={instructor.user.guid}
                                    userId={instructor.user.guid}
                                    name={instructor.user.name}
                                    surname={instructor.user.surname}
                                    description={instructor.summary}
                                    thumb={instructor.user.photoURL}
                                    languages={instructor.knownLanguages}
                                    expertise={instructor.expertiseOn}
                                    makeReservationClicked={() => {
                                        //console.log("makeRESer Clicked " + instructor.user);
                                        makeReservationClicked(instructor.user);
                                    }}
                                    // status={instructor.status}
                                    rating={instructor.user.rate}
                                    type="tutor"
                                // availableAfter={instructor.availableAfter}
                                />
                            ))
                        }
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}

export default Content;

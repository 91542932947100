import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { useDispatch, connect } from "react-redux";

interface PrivateRouteProp extends RouteProps
{
    auth : any,
    role? : any,
}


const PrivateRoute : React.FC<PrivateRouteProp> = ({auth, role, component, ...rest }) => {
    const dispatch = useDispatch();
    
    if(!auth.user)
    {
        const user = localStorage.getItem("speakingline_user");
       
        if(!user)
        {
            return(<Route {...rest} render={() => {return <Redirect to='/login' /> }} />)
        }

        const userData = JSON.parse(user);
        dispatch({
            type: "USER_LOGGED_IN",
            payload: userData
          }) 
          return null;   
    }

    // if (!auth.user)
    // {
    //     return(<Route {...rest} render={() => {return <Redirect to='/login' /> }} />)
    // }
    
    if (role && auth.user.role !== role)
    {
        return(<Route {...rest} render={() => {return <Redirect to='/login' /> }} />)
    }

    return (
        <Route {...rest} component= {component} />
      )
  }

function mapStateToProps(state:any) {
    return {
        auth: state.AuthReducer
    }
}

export default connect(mapStateToProps)(PrivateRoute);
const initialState = {
    chatDialog : {}
}

interface ActionProps {
    type: String,
    payload: any
}

export default function(state=initialState, action:ActionProps) {
    switch(action.type) {
        case "CHAT_DIALOG_IS_SHOWN":
            return state = { ...state, chatDialog: action.payload }
        default:
            return state
    }
}

import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import {
    CardImage,
} from "./StyledComponents";
import theme from "../../theme";
import { Image, Badge, Button, Card } from "react-bootstrap";
import moment from 'moment';
import img from "../../assets/instructor-reservations/img.png";
import { Nationality } from "./StyledComponents";
import { deleteReservationIcon } from "../../assets/SVG/SVG";
import DeleteReservationModal from '../modals/studentReservationDeleteModal/DeleteReservationModal';

import { CONVERSATION_URL, ROLES, MESSAGES_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import { useHistory } from "react-router";
import { trackPromise } from "react-promise-tracker";
import AlertInfo from "../alerts/AlertInfo";
import { useTranslation } from "react-i18next";

const ReservedCardContainer = styled.div`
    width: 30.1%;
    min-width: 290px;
    height: fit-content;
    box-sizing: border-box;
    padding: 16px 16px;
    border-radius: 16px;
    box-shadow: 0px 15px 20px rgba(141, 141, 141, 0.25);
    background-color: #fff;
    display: block;
    float: left;
    margin-right: 30px;
    margin-bottom: 30px;
`;

const CardHeader = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
`;

const CardImageContainer = styled.div`
    height: 104px;
    width: 104px;
    border-radius: 24px;
    object-fit:cover;
`;

const CardInfoContainer = styled.div`
    margin-left: 16px;
`;

const CardName = styled.h4`
    margin: 0;
    padding: 0;
    text-align: left;
    color: #52575C;
    font-size: 19px;
    line-height: 18px;
    font-weight: 700;
    font-family: ${theme.text.fonts.nunito};
`;

const CardDateTime = styled.p`
    margin: 0;
    padding: 0;
    margin-top: 8px;
    text-align: left;
    color: #929598;
    font-size: 14px;
    font-weight: 600;
    font-family: ${theme.text.fonts.nunito};
    line-height: 19px;
`;

const CardDuration = styled(Badge)`
    padding: 3px 7px;
    background-color: #FDD9D1;
    border-radius: 3px;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    font-family: ${theme.text.fonts.nunito};
    margin-top: 5px;
    color: #F86F53;
`;

const CardButtonContainer = styled.div`
    padding: 7px;
    width: 100%;
    margin-top: 22px;
    display:block;
    float: left;
    justify-content: space-between;
`;


const CardBtn = styled(Button)`
    width: 40%;
    background-color: #F2F2F2;
    border-radius: 8px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    font-family: ${theme.text.fonts.nunito};
    color: #52575C;
    border: 0;
    margin-right:5px;
    &:hover {
        background-color: #E0E0E0;
        color: #52575C;
    }
`;

const CardDeleteBtn = styled(Button)`
    width: 10%;
    &:hover {
        background-color: #E0E0E0;
    }
`;

interface ReservationCardInterface {
    reservationId: string;
    isDeletable: Boolean;
    userId: String;
    name: String;
    surname: String;
    startTime: Number;
    endTime: Number;
    minute?: Number;
    countryCode?: String;
    thumb: String;
    itemId?: String;
    onDetails: () => void;
    onRefresh: () => void;
}

type ReservedStudentProps = ReservationCardInterface & StateProps;

const ReservationCard: React.FC<ReservedStudentProps> = ({
    auth,
    reservationId = "",
    userId,
    name = "Souza",
    surname = "Alex",
    thumb,
    countryCode = "uk",
    startTime = 0,
    endTime = 0,
    minute,
    itemId = "",
    isDeletable = false,
    onDetails,
    onRefresh
}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [success, setSuccess] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    let history = useHistory();

    const [isConfirmationModal, setIsConfirmationModal] = useState(false);

    const dateTime = moment((startTime as number) * 1000);
    const date = dateTime.format("DD.MM.YYYY");
    const day = dateTime.format("dddd");
    const startHours = dateTime.format("HH:mm");

    const endDateTime = moment((endTime as number) * 1000);
    const endHours = endDateTime.format("HH:mm");
    const userName = name + " " + surname?.charAt(0) + "."


    const sendDirectMessage = async () => {
        var param = { talkingWithId: userId }
        const res = await trackPromise(axios.post<any, ReturnProps>(MESSAGES_URL + "/api/v1/rooms/directmessageroom", param))
        if (res.error || !res.headers.location) {
            setError(res.error as string);
            setShowError(true);
            return;
        }
        else {
            const header: String = res.headers.location
            const id = header.split("/")[1];
            dispatch({
                type: "CHAT_DIALOG_IS_SHOWN",
                payload: {
                    isOpen: true,
                    roomId: id,
                }
            });
        }
    }

    const deleteReservation = async () => {
        const res = await trackPromise(axios.delete<any, ReturnProps>(CONVERSATION_URL + "/api/v1/schedule-meetings/" + reservationId));
        setIsConfirmationModal(true);
        // Handling Error
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            return null;
        }
        else {
            setSuccess(t("reservation.reservation_updated"));
            setShowSuccess(true);
        }
        onRefresh();
    }
    return (
        <React.Fragment>

            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
            <AlertInfo show={showSuccess} setShow={setShowSuccess} isAutoHide={true} message={success} type={1} />

            <ReservedCardContainer>
                <CardHeader>
                    <CardImageContainer>
                        <CardImage src={thumb as string} />
                    </CardImageContainer>
                    <CardInfoContainer>
                        <CardName>{userName}</CardName>
                        <CardDateTime>
                            {date}, {day} <br />
                            {startHours} - {endHours}
                        </CardDateTime>
                        <CardDuration>
                            {minute} dakika
                    </CardDuration>
                    </CardInfoContainer>
                </CardHeader>
                {/* <Button onClick={onDetails}/> */}
                <CardButtonContainer>
                    <CardBtn onClick={sendDirectMessage}>{t("general.send_message")}</CardBtn>
                    <CardBtn onClick={() => history.push("/history", { meetingWithId: itemId })}>{t("history.history")}</CardBtn>
                    {auth.user.role === ROLES.STUDENT && <button style={{ background: "none" }} onClick={() => setIsConfirmationModal(true)}>{deleteReservationIcon}</button>}
                </CardButtonContainer>

                {isConfirmationModal &&
                    <DeleteReservationModal
                        show={isConfirmationModal}
                        approveClicked={() => deleteReservation()}
                        hide={() => setIsConfirmationModal(false)}
                        selectedMeetingMinutes={minute}
                        date={dateTime}
                        timeSlot={dateTime}
                        reservationId={reservationId}
                        teacherName={userName}
                        isDeletable={isDeletable}
                    />
                }
            </ReservedCardContainer>
        </React.Fragment>
    )
}

interface StateProps {
    auth: any
}
function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer,
    }
}

export default connect(mapStateToProps)(ReservationCard);


import React,{ useState } from "react";
import styled,{ css } from "styled-components";
import theme from "../../theme";
import { Image, InputGroup, FormControl } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

const IconContainer = styled(InputGroup.Append)`
  background-color: ${theme.colors.transparent};
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.label`
  text-align: left;
  color: ${theme.text.colors.main};
  font-family: ${theme.text.fonts.poppins};
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
`;

const InputContainer = styled(InputGroup)`
  border: 1px solid ${theme.colors.line};
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 8px;
  ${
    ({ whiteFocus }) =>{
      if(whiteFocus) return css`
        background-color:#fff;
      `;
    }
  }
`;

const Input = styled(FormControl)`
  border: 0;
  margin: 0;
  padding: 0;
  text-align: left;
  color: ${theme.colors.filter};
  font-family: ${theme.text.fonts.poppins};
  font-weight: 500;
  font-size: 16px;
  &:active,
  &:focus {
    outline: 0;
    box-shadow: none;
  }
`;

interface IconInputProps {
  name?: string,
  icon: any,
  placeholder?: string,
  label?: string,
  style?: React.CSSProperties,
  value?: string,
  onChange?: (e:React.ChangeEvent<HTMLInputElement>) => void,
  iconHoverLabel?: string,
  iconHeight: number,
  iconWidth: number,
  whiteFocus?: boolean,
  onIconClick?: () => void,
  onEnterPress?: (value:string) => void,
  className?: string,
  focusColor: string
}

function handleBgColor(focused:Boolean, focusColor: String): String {
  if(!focused) return "#FFFCFC"
  else if(focused) return focusColor
  return ""
}

const IconInput:React.FC<IconInputProps> = ({
  name,
  icon,
  placeholder,
  label,
  style,
  value,
  onChange,
  iconHoverLabel,
  iconHeight,
  iconWidth,
  whiteFocus,
  onIconClick,
  onEnterPress = (str)=>null,
  className="",
  focusColor
}) =>{
  const [focused, setFocused] = useState(false);
  const handleFocus = () => setFocused(true)
  const handleUnfocus = () =>setFocused(false);
    return(
      <>
      <Label>{label}</Label>
      <InputContainer
        style={{
          borderColor: focused && "#F86F53",
          ...style,
          backgroundColor: handleBgColor(focused, focusColor)
        }}
        whiteFocus={whiteFocus ? whiteFocus : false}
        className={className}
      >
        <Input
          name = {name}
          placeholder={placeholder}
          onBlur={handleUnfocus}
          onFocus={handleFocus}
          style={{ backgroundColor: !focused && "#FFFCFC" }}
          value={value}
          onChange={onChange}
          onKeyDown={(e:any)=>{
            if (e.key === 'Enter') {
              //console.log('do validate ' + e.target.value)
              onEnterPress(e.target.value);
            }
          }}
        />
        <IconContainer>
          <Image
            src={icon}
            height={iconHeight as number}
            width={iconWidth as number}
            onClick={onIconClick}
          />
        </IconContainer>
      </InputContainer>
      </>
    )
}

export default IconInput;


export const minutesOptions = [
    {
        id: 0,
        number: 10,
        active: true
    },
    {
        id: 1,
        number: 20,
        active: false
    },
    {
        id: 2,
        number: 40,
        active: false
    },
    {
        id: 3,
        number: 60,
        active: false
    },
]

export const daysOptions = [
    {
        id: 0,
        number: 2,
        active: true
    },
    {
        id: 1,
        number: 3,
        active: false
    },
    {
        id: 2,
        number: 5,
        active: false
    },
    {
        id: 3,
        number: 7,
        active: false
    },
]

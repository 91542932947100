
import React, { useState } from 'react';
import { Modal, Image, Tabs, Tab } from 'react-bootstrap';
import cross from "../assets/upgrade-package/cross.png";
import chatIcon from "../assets/chatIcon.svg";
import "../layout/HistoryModal/HistoryModal.css";
import StyledButton from '../components/buttons';
import MessageReceivedInfo from '../components/alerts/MessageReceivedInfo';
import CorparateFormModal from './CorparateForm/CorparateFormModal';

const Preview1 = () => {
    const [showError, setShowError] = useState(true);

    return (
        // <CorparateFormModal show={true} hide={() => { }} />
        <React.Fragment>
            <MessageReceivedInfo show={showError} setShow={setShowError} isAutoHide={true} title="Ali" message="This is a message and another message and this is another also and another one is her!" />
            <MessageReceivedInfo show={showError} setShow={setShowError} isAutoHide={true} title="uyuyuyu" message="This is a message and another message and this is another also and another one is her!" />
            <MessageReceivedInfo show={showError} setShow={setShowError} isAutoHide={true} title="asd" message="This is a message and another message and this is another also and another one is her!" />
        </React.Fragment>
    )
}

export default Preview1;

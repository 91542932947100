import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown'
import "../../layout/InstructorsPage/TopBar.css";
import Input from "../../components/inputs";
import theme from "../../theme";
import Button from "../../components/StatusButton";
import PayoutAccount from "./PayoutAccount";
import EarningHistory from "./EarningHistory";
import "../../layout/TutorPayment/PayoutAccount.css";

const Content: React.FC<any> = ({ handleStatus }) => {
    const [selectedStatus, setSelectedStatus] = useState({ label: "All Tutors", status: "all" });

    return (
        <React.Fragment>
            {/* <div className="container">
                <div className="row">
                    <div className="col-lg-9">
                        <PayoutAccount />
                        <EarningHistory />
                    </div>
                </div>
            </div> */}
            <div className="payout-card">
                <div className="payout-body">
                    <PayoutAccount />
                    <EarningHistory />
                </div>
            </div>
        </React.Fragment >
    )
}

export default Content;

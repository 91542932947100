import React from "react";
import "../../layout/InstructorReservations/ReservationRecords.css";
import { Image } from "react-bootstrap";
import { NoReservationFoundIcon } from "../../assets/SVG/SVG";
import Button from "../../components/buttons";

import dots from "../../assets/instructor-reservations/dots.png";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ROLES } from "../../Global/Constants/constants";

interface StateProps {
    auth: any
}

const NoReservationFound: React.FC<StateProps> = ({ auth }) => {
    var history = useHistory();
    const { t } = useTranslation();

    return (
        <div className="instructor-reservations-records-container">
            <div>{NoReservationFoundIcon}</div>
            <Image src={dots} />
            <p className="instructor-reservations-records-text">
                {t("dashboard.no_reservation_found")}
                <br />
                {auth.user.role === ROLES.STUDENT  ? <div> {t("reservation.reserve_now_to_speak")} <br />
                    <Button onClick={() => history.push("/reservations/create")}>{t("reservation.do_reservation")}</Button>  </div> : null}
            </p>
        </div>
    )
}


function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer,
    }
}

export default connect(mapStateToProps)(NoReservationFound);

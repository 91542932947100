import React from "react";
import "../../layout/InstructorNotificationsSettings/SettingsFooter.css";
import StyledButton from "../../components/buttons";
import styled,{ css } from "styled-components";
import theme from "../../theme";

const Button = styled(StyledButton)`
    padding: 10px 83px;
    ${
        ({ transparent }) =>{
            if(transparent) return css`
                padding: 10px 41px;
                &:hover {
                    background-color: #fff;
                    color: ${theme.colors.primary};
                }
            `;
        }
    }
`;

const SettingsFooter = () =>{
    return (
        <div className="instructor-notifications-settings-footer">
            <div className="instructor-notifications-settings-footer-container">
                <Button type="third" transparent>İptal</Button>
                <Button type="default" primary>Kaydet</Button>
            </div>
        </div>
    )
}

export default SettingsFooter;



export const PRODUCT_TYPES =  {
    ONETIME : 0,
    PERIODIC : 1,
};

export const PERIOD_TYPES =  {
        MINUTE_IN_A_DAY : 0,
        DAY_IN_A_WEEK : 1,
        MONTH_IN_A_YEAR : 2,
        ANYTIME : 3
};

export const  getPeriodicValueList = function  getPeriodicValueList(products:[any], periodType:any) {
    var list = products.filter(item=> item.type === periodType).map(a => a.values).flat();
    return list;
}


export const getMonthsList = function  getPeriodicValueList(products:[any], periodType:any, minInDay:any, dayInWeek : any) {
    var list = products.filter(item=> minInDay === item.dailyProduct.value 
        && dayInWeek === item.weeklyProduct.value).sort((a, b) => (a.monthlyProduct.value > b.monthlyProduct.value) ? 1 : -1).flat();
    return list;
}
import React, { useEffect, useRef, useState } from "react";
import "../../layout/StudentVideoChat/ChatRoomMessanger.css";
import Message from "./Message";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSpring, animated } from "react-spring";
import Input from "../../components/inputs";
import { connect, useDispatch } from "react-redux";
import "../../mobile/StudentVideoChat/ChatRoomMessanger.css";

import send from "../../assets/student-video-chat/send.png";
import moment from "moment";
import { connection } from "../../Global/Utilities/signalr";
import useSound from "use-sound";
import MessageReceivedInfo from "../../components/alerts/MessageReceivedInfo";

interface ChatRoomMessangerProps {
    openChatRoom: Boolean,
}

const ChatRoomMessanger: React.FC<any> = ({
    auth,
    session,
    meetingId,
    attendeeName,
    attendeeGuid,
    senderId,
    showChatRoom,
    isMeetingFinished
}) => {

    const messagesEndRef = useRef<any>();

    const [chatMessages, setChatMessages] = useState<any[]>([]);
    const activeChatMessages = useRef(chatMessages);
    const [startAnim, setStartAnim] = useState(false);
    const [sessionIsCreated, setSessionIsCreated] = useState(false);

    const [showMessageReceivedDialog, setShowMessageReceivedDialog] = useState(false);
    const [messageReceivedDialogTitle, setMessageReceivedDialogTitle] = useState(attendeeName);
    const [messageReceivedDialogMessage, setMessageReceivedDialogMessage] = useState("");
    const [messageReceivedDialogGuid, setMessageReceivedDialogGuid] = useState("");

    const activeShowChatRoom = useRef(showChatRoom);


    const [play, { stop }] = useSound("/sounds/message_sound.mp3");

    const dispatch = useDispatch();

    useEffect(() => {
        //console.log("useEeffectenkladf");
        //console.log("useEffect - chatMessages  ", chatMessages);
        activeChatMessages.current = chatMessages;
        //console.log("useEffect - activeChatMessages", activeChatMessages.current);
        scrollToBottom();
    }, [chatMessages]);

    useEffect(() => {
        //console.log("useEeffectenkladf");
        scrollToBottom();
    }, [activeChatMessages?.current]);

    const chatroomContentProps = useSpring({
        opacity: startAnim ? 1 : 0,
        config: {
            duration: 500
        }
    })
    const handleOpacity = () => {
        setTimeout(() => {
            setStartAnim(true)
        }, 500)
    }
    useEffect(() => {
        handleOpacity()
        scrollToBottom();
        activeShowChatRoom.current = showChatRoom;

        if(showChatRoom)
        {
            setShowMessageReceivedDialog(false);
        }

    }, [showChatRoom])

    useEffect(() => {
        if (isMeetingFinished) {
            // sendMessagesToServer();
        }
    }, [isMeetingFinished])

    // const sendMessagesToServer = async () => {
    //     if (messages && messages?.length > 0) {
    //         //console.log("sendMessagesToServer called");
    //         var resultMessages = messages.map((item: any) => ({
    //             messageText: item.msg,
    //             date: item.messageCreatedDate,
    //             userId: item.senderUserGuid
    //         }));
    //         var params = {
    //             studentId: ((auth.user.role === 1) ? auth.user.guid : attendeeGuid),
    //             teacherId: ((auth.user.role === 2) ? auth.user.guid : attendeeGuid),
    //             messages: resultMessages
    //         };

    //         const res = await axios.post<any, ReturnProps>(MESSAGES_URL + "/api/v1/rooms/" + meetingId + "/conversationmessages", params);

    //         if (!res.success || !res.headers.location) {
    //             // alert(res.error)
    //             return null;
    //         }
    //     }
    //     // setStartMeeting(false);
    // };

    const [message, setMessage] = useState("");

    const sendMessage = () => {
        if (!message.length) return null

        const messageData = {
            senderUserGuid: auth?.user?.guid,
            messageCreatedDate: moment().unix(),
            text: message,
            owner: senderId,
        }
        var list = [...activeChatMessages.current];
        list.push(messageData);
        // var sorted = list.sort((a, b) => b.messageCreatedDate - a.messageCreatedDate)
        activeChatMessages.current = list;
        setChatMessages(list);

        session.signal({
            type: 'msg',
            data: JSON.stringify(messageData),
        }, function (error: any) {
            if (error) {
                //console.log('Error sending signal:', error.name, error.code);
            } else {
                setMessage("");
                sendMessageToServer();
            }
        });
    }

    const sendMessageToServer = async () => {
        if (!message) {
            return;
        }
        var param = {
            message_text: message,
            room_id: meetingId,
            room_type: 2
        };
        //console.log("Message Data - while sending to server", param);
        try {
            var result = await connection.invoke("SendMessageFromClient", "chat", param);
            //console.log("MEssage send to Server");
        } catch (error) {
            //console.log(error);
            return;
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        sendMessage()
    }
    const handleNewMessage = () => {
        if (!sessionIsCreated && session) {
            session?.on('signal:msg', function (event: any) {
                const message = JSON.parse(event.data);
                receiveMessage(message)
            });
            setSessionIsCreated(true);
        }
    }

    const receiveMessage = (msg: any) => {
        //console.log("receiveMessge - activeChatMessages ", activeChatMessages.current);
        //console.log("receiveMessge - chatMessages ", chatMessages);
        //console.log("receiveMessge - msg ", msg);
        //console.log("receiveMessge - date", moment(msg.messageCreatedDate * 1000).toDate())



        console.log("ActiveShowMessage", activeShowChatRoom);
        console.log("ShowMessage", showChatRoom);

        if ( msg.senderUserGuid !== auth?.user?.guid) {
            play();
            setShowMessageReceivedDialog(true);
            setMessageReceivedDialogMessage(msg.text);

            // dispatch({
            //     type: "VIDEO_CHAT_MESSAGE_IS_RECEIVED",
            //     payload: {
            //         senderName: msg.senderUserGuid,
            //         message: msg.text
            //     }
            // });
        }


        if (msg.senderUserGuid !== auth?.user?.guid) {
            var list = [...activeChatMessages.current];
            list.push(msg);
            // var sorted = list.sort((a, b) => b.messageCreatedDate - a.messageCreatedDate)
            activeChatMessages.current = list;
            setChatMessages(list);
        }
    }

    useEffect(() => {
        handleNewMessage()
    }, [session])

    const scrollToBottom = () => {
        //console.log("scrollToBottom", messagesEndRef?.current);
        messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth', block: "nearest" })
    }

    if (!showChatRoom) {
        return (<React.Fragment>
            {
                !activeShowChatRoom.current && <MessageReceivedInfo show={showMessageReceivedDialog} setShow={setShowMessageReceivedDialog} title={messageReceivedDialogTitle} message={messageReceivedDialogMessage} isAutoHide={true} />
            }
        </React.Fragment>)
    }
    return (
        <React.Fragment>


            <div className="chatroom-wrapper">
                <animated.div
                    className="chatroom-div-wrapper"
                    style={{
                        opacity: chatroomContentProps.opacity
                    }}
                >
                    <div className="chatroom-mobile-header">
                        <h4 className="chatroom-mobile-header-name">{attendeeName}</h4>
                        {/* <Image
                        src={uk}
                        height={14}
                        width={23}
                        className="chatroom-mobile-header-nationality"
                    /> */}
                    </div>
                    <animated.div
                        className="chatroom-messages-container"
                        style={{
                            opacity: chatroomContentProps.opacity
                        }}
                    >
                        <Scrollbars>
                            {
                                // eslint-disable-next-line array-callback-return
                                chatMessages.map((msg: any) => (
                                    <Message
                                        key={senderId + " " + msg.messageCreatedDate}
                                        type={(msg.owner === senderId) ? "owner" : "opponent"}
                                        message={msg.text}
                                        time={msg.messageCreatedDate}
                                    />
                                ))
                            }
                            <div ref={messagesEndRef} />
                        </Scrollbars>
                    </animated.div>
                    <form
                        className="chatroom-input-container"
                        onSubmit={handleSubmit}
                    >
                        <Input
                            type="icon"
                            icon={send}
                            iconWidth={28}
                            iconHeight={28}
                            whiteFocus={true}
                            value={message}
                            onChange={(e: any) => setMessage(e.target.value)}
                            onIconClick={sendMessage}
                        />
                    </form>
                </animated.div>
            </div>

        </React.Fragment>
    )
}

function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer,
    }
}

// function mapDispatchToProps(dispatch: any) {
//     return {
//         storeMessage: (data: String) => dispatch({ type: "ADD_NEW_MSG", payload: data }),
//         storeNewOwnerMsg: (data: String) => dispatch({ type: "STORE_NEW_OWNER_MSG", payload: data })
//     }
// }

export default connect(mapStateToProps)(ChatRoomMessanger);

import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "../../layout/Payment/Checkout.css";
import TotalBill from "../../components/TotalBill";
import Form from "../../components/others/Form";
import { History } from "history";

import Arrow from "../../assets/payment/arrow.png";
import whiteArrow from "../../assets/payment/arrow-white.png";
import { PAYMENT_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import {useSetState} from "../../Global/Utilities/getsetstate"
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { promise } from "../../Global/Utilities/stripe";
import AlertInfo from "../../components/alerts/AlertInfo";
import { trackPromise } from "react-promise-tracker";
import {useTranslation} from "react-i18next";

interface ContentProps {
    history: History
    transactionId : any
}

const Content:React.FC<ContentProps> = ({ history, transactionId }) =>{
    const { t } = useTranslation();

    const [buttonArrow, setButtonArrow] = useState(Arrow);
    const handleHoverIn = () => setButtonArrow(whiteArrow);
    const handleHoverOut = () => setButtonArrow(Arrow)

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

   // const [paymentSecret, setPaymentSecret, getPaymentSecret] = useSetState("");
   const paymentSecret = useRef();

    const [product, setProduct, getProduct] = useSetState<any>({});

    const handleClickPayment = async (couponCode? : string) => {
    }

    const LoadPaymentIntent = async() =>{
        const res = await trackPromise( axios.get<any, ReturnProps>(PAYMENT_URL + "/api/v1/payments/payment-orders/" + transactionId));

        // Handling Error
        if(!res.success) {
          setError(res.error as string);
          setShowError(true)
          return null;
        } else if (!res.data || !res.data.paymentIntent)
        {
            setError("Bir Hata olustu");
            setShowError(true)
            return null;
        }

        //console.log(res.data);
        
        paymentSecret.current = res.data.paymentIntent.paymentSecretKey;
        setProduct(res.data);
    }

    useEffect(() => {
        LoadPaymentIntent()
    }, []);

    //const data = paymentSecret;
  //  const pt = product;

    const pr = promise;

    // //console.log(pt);

    return(
        <React.Fragment>
        
        <AlertInfo show={showError} setShow={setShowError} isAutoHide= {true} message = {error} type = {0} />
    
        <Container className="payment-container">
            <Row>
                <Col lg={8}>
                <div className="App">
                     <Elements stripe={pr}>
                        <CheckoutForm onPaymentError={LoadPaymentIntent} clientSecret = {paymentSecret.current}/>
                    </Elements>
                </div>
                </Col>
                <Col lg={4} className="payment-total-bill-container">
                    <TotalBill productId={product?.guid} onClickPayment={handleClickPayment} comp="payment" totalSum={product?.price}  title = {product?.productName}/>
                </Col>
            </Row>
            <div 
                className="go-back-payment" 
                onMouseOver={handleHoverIn} 
                onMouseLeave={handleHoverOut} 
                onClick={() =>history.goBack()}
            >
                <Image 
                    src={buttonArrow} 
                    height={24} 
                    width={24} 
                    style={{
                        marginRight: 10 
                    }} 
                />
                <p>{t("general.back")}</p>
            </div>
        </Container>
        </React.Fragment>
    )
}

export default Content;


import React from "react";
import "../../layout/InstructorReservations/ReservationRecords.css";
import { Image } from "react-bootstrap";

import dots from "../../assets/instructor-reservations/dots.png";

const HistoryRecords = () =>{
    return (
        <div className="instructor-reservations-records-container">
            <Image src={dots} />
            {/* <p className="instructor-reservations-records-text">
                Gecmis gorusmeleriniz listelenmiştir. 
            </p> */}
        </div>
    )
}

export default HistoryRecords;


export const questions = [
    {
        id: 0,
        question: "home.faq.first.title",
        answer: "home.faq.first.content"
    },
    {
        id: 1,
        question: "home.faq.second.title",
        answer: "home.faq.second.content"
    },
    {
        id: 2,
        question: "home.faq.third.title",
        answer: "home.faq.third.content"
    },
    {
        id: 3,
        question: "home.faq.fourth.title",
        answer: "home.faq.fourth.content"
    },
    {
        id: 4,
        question: "home.faq.fifth.title",
        answer: "home.faq.fifth.content"
    },
    {
        id: 5,
        question: "home.faq.sixth.title",
        answer: "home.faq.sixth.content"
    },
];


export const questionsForKids = [
    {
        id: 0,
        question: "kids.faq.first.title",
        answer: "kids.faq.first.content"
    },
    {
        id: 1,
        question: "kids.faq.second.title",
        answer: "kids.faq.second.content"
    },
    {
        id: 2,
        question: "kids.faq.third.title",
        answer: "kids.faq.third.content"
    },
    {
        id: 3,
        question: "kids.faq.fourth.title",
        answer: "kids.faq.fourth.content"
    },
];

export const questionsForCorprate = [
    {
        id: 0,
        question: "career.faq.first.title",
        answer: "career.faq.first.content"
    },
    {
        id: 1,
        question: "career.faq.second.title",
        answer: "career.faq.second.content"
    },
    {
        id: 2,
        question: "career.faq.third.title",
        answer: "career.faq.third.content"
    },
    {
        id: 3,
        question: "career.faq.fourth.title",
        answer: "career.faq.fourth.content"
    },
    {
        id: 4,
        question: "career.faq.fifth.title",
        answer:"career.faq.fifth.content"
    }
];


import React from "react";
import "../../layout/InstructorReservations/ReservationRecords.css";
import { Image } from "react-bootstrap";
import {NoReservationFoundIcon} from "../../assets/SVG/SVG";
import Button from "../../components/buttons";

import dots from "../../assets/instructor-reservations/dots.png";
import {useTranslation} from "react-i18next";

const NoTeacherFound = () =>{
    const { t } = useTranslation();

    return (
        <div className="instructor-reservations-records-container">
            <div>{NoReservationFoundIcon}</div>
            <Image src={dots} />
            <p className="instructor-reservations-records-text">
                {t("reservation.no_tutors_found")}
            <br/>
                {t("reservation.try_search_later")}
            </p>
        </div>
    )
}

export default NoTeacherFound;

import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown'
import "../../layout/InstructorsPage/TopBar.css";
import Input from "../../components/inputs";
import theme from "../../theme";
import Button from "../../components/StatusButton";
import Upload from "./Upload";
import FileLists from "./FileLists";
import "../../layout/TutorPayment/PayoutAccount.css";

const Content: React.FC<any> = ({ handleStatus }) => {
    const [fileName, setFileName] = useState("");
    const statusChanged = (newFileName:string) =>{
        setFileName(newFileName);
    }
    return (
        <React.Fragment>
            <div className="payout-card">
                <div className="payout-body">
                    <Upload fileUploaded={statusChanged}/>
                    <FileLists uploadedFileName={fileName} />
                </div>
            </div>
        </React.Fragment >
    )
}

export default Content;

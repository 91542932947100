import { css } from "styled-components";
import theme from "../../theme";

const TutorsCardBtn = css`
    background-color: ${theme.buttons.colors.secondary};
    border-color: ${theme.buttons.colors.secondary};
    &:hover {
        background-color: ${theme.buttons.colors.secondaryHover};
        border-color: ${theme.buttons.colors.secondaryHover};
    }
    &:focus, 
    &:active {
        background-color: ${theme.buttons.colors.secondary};
        border-color: ${theme.buttons.colors.secondary};
    }
`;

export default TutorsCardBtn;

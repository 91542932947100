import React, { useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import "../../layout/TutorPayment/PayoutAccount.css";
import Input from "../../components/inputs";
import theme from "../../theme";
import Button from "../../components/StatusButton";
import paypalIcon from "../../assets/paypal.svg";
import dangerIcon from "../../assets/warning.svg";
import { Tabs, Tab } from 'react-bootstrap';
import { useSpring, animated } from "react-spring";
import { trackPromise } from "react-promise-tracker";
import { PAYMENT_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import moment from "moment";
import UpdatePaymentMethode from "../UpdatePaymentMethod/UpdatePaymentMethod";
import AlertInfo from "../../components/alerts/AlertInfo";


const PayoutAccount: React.FC<any> = ({ handleStatus }) => {
    const [payoutAccount, setPayoutAccount] = useState<any>(null);

    const [showMoreDetails, setShowMoreDetails] = useState(false);
    const [showMoreDetailsContent, setShowMoreDetailsContent] = useState(false)
    const moreInfoState = useSpring({
        height: showMoreDetails ? 600 : 160,
        opacity: showMoreDetails ? 1 : 0,
        transform: showMoreDetails ? "rotate(180deg)" : "rotate(0deg)"
    });

    const [monthlyRevenueList, setMonthlyRevenueList] = useState([]);
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [success, setSuccess] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);

    const [mail, setMail] = useState("");
    const [confirmMail, setConfirmMail] = useState("");
    const [iban, setIban] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [paymentType, setPaymentType] = useState(-1);

    const LoadTeacherPayoutAccount = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(PAYMENT_URL + "/api/v1/payments/user/payment-address"));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            return null
        }
        if (res.data) {
            setPayoutAccount(res.data);
            // setName(res.data.name);
            // setSurname(res.data.surname);
            // setMail(res.data.email);
            // setPaymentType(res.data.)
        }

        //  dispatch({ type: "TEACHER_DASHBOARD_CHANGED" , payload : res.data });
    }

    const updateTeacherPayoutAccount = async (type: number) => {
        var param = {};

        if (type === 0) //Paypal
        {
            param = {
                paymentType: type,
                email: mail,
            };
        } else if (type === 1) //IBAN
        {
            param = {
                paymentType: type,
                name: name,
                surname: surname,
                IBAN: iban
            };
        }

        const res = await trackPromise(axios.put<any, ReturnProps>(PAYMENT_URL + "/api/v1/payments/user/payment-address", param));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            return null
        }
        setShowMoreDetails(false);
        setSuccess("Record updated");
        setShowSuccess(true);
        LoadTeacherPayoutAccount();
        //  dispatch({ type: "TEACHER_DASHBOARD_CHANGED" , payload : res.data });
    }

    useEffect(() => {
        LoadTeacherPayoutAccount()
    }, []);

    return (
        <React.Fragment>

            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
            <AlertInfo show={showSuccess} setShow={setShowSuccess} isAutoHide={true} message={success} type={1} />

            <animated.div
                // className="student-instructors-page-card-container subscription-summary"
                style={{
                    top: 0,
                    height: moreInfoState.height,
                }}
            >
                <div className="payout-header">
                    <h6 className="payout">Payout Account</h6>
                </div>
                <div className="payout-box">
                    <div className="box-header">
                        {(payoutAccount && payoutAccount.paymentType === 0) && (
                            <div className="payimg">
                                {/* <img src={paypalIcon} alt="" /> */}
                                <p> <b>Payoneer Adress:</b> {payoutAccount.email}</p>
                            </div>)
                        }

                        {(payoutAccount && payoutAccount.paymentType === 1) && (
                            <div className="payimg">
                                {/* <img src={paypalIcon} alt="" /> */}
                                <p> <b>IBAN Number:</b> {payoutAccount.iban}</p>
                            </div>)
                        }
                        {(!payoutAccount) && (
                            <div className="payimg">
                                {/* <img src={paypalIcon} alt="" /> */}
                                <p> <b>Not assigned</b></p>
                            </div>)
                        }

                        <div className="payout-b-action">
                            <button className="btn btn-primary" onClick={(e: any) => { setShowMoreDetails(true) }}>Set Account</button>
                        </div>
                    </div>

                    {
                        showMoreDetails &&
                        <div className="payout-tab">
                            <Tabs id="uncontrolled-tab-example" defaultActiveKey="PayPal">
                                <Tab eventKey="PayPal" title="Payoneer">
                                    <div className="payout-tab-content">
                                        <p>
                                            Payments are completed in every 5th day of month to your account. Please make sure that your account details are correct.
                                        </p>
                                        <div className="payout-form-wrapper">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Email address</label>
                                                        <Input value={mail} onChange={(e: any) => { setMail(e.target.value) }} type="default" />
                                                        {/* <input type="text" className="form-control" /> */}
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Confirm Email address</label>
                                                        <Input type="default" value={confirmMail} onChange={(e: any) => { setConfirmMail(e.target.value) }} />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="payout-action">
                                                        <button onClick={(e: any) => { updateTeacherPayoutAccount(0) }} disabled={mail !== confirmMail} className="btn btn-primary">Set Payout Account</button>
                                                        <button onClick={(e: any) => { setShowMoreDetails(false) }} className="btn btn-link">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="IBAN" title="IBAN">
                                    <div className="payout-tab-content">
                                        <p>
                                            Payments are completed in every 5th day of month to your account. Please make sure that your account details are correct.
                                        </p>
                                        <div className="payout-form-wrapper">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Name</label>
                                                        <Input type="default" value={name} onChange={(e: any) => { setName(e.target.value) }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Surname</label>
                                                        <Input type="default" value={surname} onChange={(e: any) => { setSurname(e.target.value) }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>IBAN</label>
                                                        <Input type="default" value={iban} onChange={(e: any) => { setIban(e.target.value) }} />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="payout-action">
                                                        <button onClick={(e: any) => { updateTeacherPayoutAccount(1) }} className="btn btn-primary">Set Payout Account</button>
                                                        <button onClick={(e: any) => { setShowMoreDetails(true) }} className="btn btn-link">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    }
                </div>


                <div className="payout-danger-alert">
                    <img src={dangerIcon} alt="" />
                    <p>Please write your account details, you are going to be paid accordingly </p>
                </div>
            </animated.div>
        </React.Fragment>
    )
}

export default PayoutAccount;

import React from "react";
import { useSpring, animated } from "react-spring";
import moment from 'moment';
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import {useTranslation} from "react-i18next";

const SubscriptionSummaryMoreDetails:React.FC<{showMoreInfo: Boolean, info : any}> = ({ showMoreInfo, info }) =>{
    const ContentProps = useSpring({
        opacity: showMoreInfo ? 1 : 0,
    })
    const { t } = useTranslation();
    const subscription = info;
    const dailyResetDateTime = moment(subscription.dailyResetTime * 1000);
    const dailyResetHour = dailyResetDateTime.format("HH:mm");

    const weeklyResetDateTime = moment(subscription.weeklyResetTime * 1000);
    const weeklyResetDate = weeklyResetDateTime.format("DD.MM.YYYY - HH:mm");

    const periodicResetDateTime = moment(subscription.subscriptionWillExpireAt * 1000);
    const periodicResetDate = periodicResetDateTime.format("DD.MM.YYYY");

    const history = useHistory();
    return (
        <animated.div 
            className="card-more-details-container"
            style={{
                opacity: ContentProps.opacity
            }}
        >
            <div className="card-more-details-plan-container">
                <h4 className="card-more-details-plan-container-title">{t("dashboard.subscription_title")}</h4>
                <h4 className="card-more-details-plan-desc">
                    {t("dashboard.daily")} {subscription.product.dailyProduct.value} {t("general.minute")} <br />
                    {t("dashboard.daily")} { subscription.product.weeklyProduct.value }  {t("dashboard.day")} <br />
                </h4>
                {/* <p className="card-more-details-plan-change"><Button onClick={()=>history.push("/packages")}>Change</Button></p> */}
            </div>
            <div className="card-more-details-info">
                <div className="card-more-details-info-wrapper">
                    <h4 className="card-more-details-info-wrapper-title">{t("dashboard.daily_reset")}</h4>
                    <h4 className="card-more-details-info-wrapper-desc">{ dailyResetHour }</h4>
                </div>
                <div className="card-more-details-info-wrapper">
                    <h4 className="card-more-details-info-wrapper-title">{t("dashboard.weekly_reset")}</h4>
                    <h4 className="card-more-details-info-wrapper-desc">{ weeklyResetDate }</h4>
                </div>
                <div className="card-more-details-info-wrapper">
                    <h4 className="card-more-details-info-wrapper-title">{t("dashboard.subsc_finish_date")}</h4>
                    <h4 className="card-more-details-info-wrapper-desc">{ periodicResetDate }</h4>
                </div>
            </div>
        </animated.div>
    )
}

export default SubscriptionSummaryMoreDetails;

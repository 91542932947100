import React, {useState,useEffect} from 'react';
import { Modal, Image, Button } from 'react-bootstrap';
import ReservationCalendarForTeacher from '../tutorDetailsModal/ReservationCalendarForTeacher'
import "../../../layout/InstructerDetailModal/InstructurDetail.css"
import {useTranslation} from "react-i18next";

interface MakeReservationModalInterface {
    show: Boolean;
    hide: () => void,
    userId: String;
    name : String;
    surname : String;
}
const MakeReservationModal: React.FC<MakeReservationModalInterface> = ({
    show,
    hide,
    userId,
    name,
    surname,
}) => {
    
    var userName = name + " " + surname?.charAt(0) + ".";
    const { t } = useTranslation();

    return (
        <div>
            <Modal show={show} onHide={hide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{t("reservation.modal_title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReservationCalendarForTeacher userName = {userName} userId = {userId} />
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default MakeReservationModal;
import { css } from "styled-components";
import theme from "../../theme";

const StyledButton = css`
    background-color: ${theme.buttons.colors.secondary};
    border-color: ${theme.buttons.colors.secondary};
    &:hover {
        background-color: ${theme.buttons.colors.secondaryHover};
        border-color: ${theme.buttons.colors.secondaryHover};
    }
    &:active,
    &:focus { bb
        background-color: ${theme.buttons.colors.secondary};
        border-color: ${theme.buttons.colors.secondary};
    }
`;

export default StyledButton;
import React from "react";
import Page from "../../components/pages";
import "../../layout/Privacy/Privacy.css";
// import { privacyPolicy } from "../../Global/Privacy";
import { KVKKRules } from "../../Global/Support/KVKK";
import { privacyRules } from "../../Global/Support/PrivacyRules";
import TagManager from "react-gtm-module";
import {useTranslation} from "react-i18next";

const Privacy: React.FC<any> = ({ history, location }) => {
    const { t } = useTranslation();

    const tagManagerArgs = {
        dataLayer: {
            userId: '001',
            userProject: 'project',
            page: 'privacy'
        },
        dataLayerName: 'PageDataLayer'
    }

   // TagManager.dataLayer(tagManagerArgs)

    var data: any[] = [];
    var title = "";
    if (location.pathname === "/policy/privacy") {
        data = [...privacyRules];
        title = t("general.privacy_agreement_title");
    } else if (location.pathname === "/policy/usage") {
        data = [...KVKKRules];
        title = t("general.kvkk_agreement_title");
    } else if (location.pathname === "/policy/cookies") {
        data = [...KVKKRules];
        title = t("general.cookie_agreement_title");
    }
    
    return (
        <div>
            <Page type="privacy" headerTitle ={title} history={history}>
                {/* <h4 className="privacy-policy-title">{title}</h4> */}
                {/* <p className="privacy-policy-subtitle">Last Updated May 7, 2018 - Version 2018.5.7</p> */}
                {
                    data.map(item => (
                        <p className="privacy-policy-paragraph">
                            <p className = "privacy-policy-title"> {item.title} </p>
                            <p className = "privacy-policy-content"> {item.content} </p>
                        </p>
                    ))
                }
            </Page>
        </div>
    )
}

export default Privacy;

import React, { useState, useEffect } from "react";
import { Image, Row, Col } from "react-bootstrap";
import "../../../layout/HomeV2/New_HowDoesItWork.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../../mobile/Home/HowDoesItWork.css";
import "../../../tablet/Home/HowDoesItWork.css";
import Container from "../../../components/Container";



import oneMobile from "../../../assets/Home/oneMobile.png";
import twoMobile from "../../../assets/Home/twoMobile.png";
import threeMobile from "../../../assets/Home/threeMobile.png";
import { useTranslation } from "react-i18next";

interface ContentComponentProps {
    items: any[];
}

type ContentProps = ContentComponentProps;

const HowDoesItWork: React.FC<ContentProps> = ({ items }) => {

    const { t } = useTranslation();

    const handleResponsiveness = () => {
        const width = window.screen.width;
        if (width < 720) {
            // setOneImg(oneMobile) 
            // setTwoImg(twoMobile)
            // setThreeImg(threeMobile)

        }
    }
    useEffect(() => {
        handleResponsiveness()
    }, [])
    return (
        <div className="new-how-does-it-work">
            <Container>
                <Container className="mt-1 col-10">
                    {items.map(item => (
                        item.index % 2 === 0 ? <Row className="mt-4 new-how-does-it-work-section1">
                            <Col md={6} sm={12} className="order-1" >
                                <Image src={item.image} className="new-how-does-it-work-img-1 new-how-does-it-work-img" />
                            </Col>
                            <Col md={6} sm={12} className="order-2">
                                <div className="new-how-does-it-work-section1-right-side">
                                    <div style={{}}>
                                        <h4 className="new-how-does-it-work-section1-title"> {t(item.title)} </h4>
                                        <p  style={{ whiteSpace: "pre-line" }} className="new-how-does-it-work-section1-description">
                                            {t(item.description).split("<br/>").join("\n")}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row> : <Row className="new-how-does-it-work-section1">
                            <Col md={6} sm={12} className="order-2 order-md-1">
                                <div className="new-how-does-it-work-section1-right-side new-how-does-it-work-section1-right-side-start" id="howDoesItWorkRightSide3">
                                    <div style={{}}>
                                        <h4 className="new-how-does-it-work-section1-title"> {t(item.title)}</h4>
                                        <p  style={{ whiteSpace: "pre-line" }} className="new-how-does-it-work-section1-description">
                                            {t(item.description).split("<br/>").join("\n")}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} sm={12} className="new-how-does-it-work-section1-left-side-start order-1 order-md-2" id="howDoesItWorkLeftSide3">
                                <Image src={item.image} className="new-how-does-it-work-img3  new-how-does-it-work-img" />
                            </Col>
                        </Row>
                    ))
                    }

                </Container>

                {/* <Container className="col-10">
                    <Row className="mt-4 new-how-does-it-work-section2">
                        <Col md={7} lg={7}>
                            <div className="new-how-does-it-work-section2-left-side new-how-does-it-work-section2-left-side-start " id="howDoesItWorkLeftSide2">
                                <div style={{}}>
                                    <h4 className="new-how-does-it-work-section1-title"> {t("home.how_it_works.second.title")}</h4>
                                    <p className="new-how-does-it-work-section2-description">
                                        {t("home.how_it_works.second.content")}
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} lg={3} className="new-how-does-it-work-section2-right-side-start" id="howDoesItWorkRightSide2">
                            <Image src={img2} className="new-how-does-it-work-img-2 new-how-does-it-work-img" />
                        </Col>
                    </Row>
                </Container> */}

                {/* <Container className="col-9 mt-4">

                </Container>


                <Container className="col-9">
                    <Row className="mt-4 new-how-does-it-work-section1">
                        <Col md={4} lg={5} className="new-how-does-it-work-section1-left-side-start" id="howDoesItWorkLeftSide3">
                            <Image src={img3} className="new-how-does-it-work-img3  new-how-does-it-work-img" />
                        </Col>
                        <Col md={7} lg={5}>
                            <div className="new-how-does-it-work-section1-right-side new-how-does-it-work-section1-right-side-start" id="howDoesItWorkRightSide3">
                                <div style={{ marginLeft: -70 }}>
                                    <h4 className="new-how-does-it-work-section1-title"> {t("home.how_it_works.third.title")}</h4>
                                    <p className="new-how-does-it-work-section1-description">
                                        {t("home.how_it_works.third.content")}
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className="col-9 mt-4">
                    <Row className="new-how-does-it-work-section1">
                        <Col md={7} lg={6}>
                            <div className="new-how-does-it-work-section1-right-side new-how-does-it-work-section1-right-side-start" id="howDoesItWorkRightSide3">
                                <div style={{}}>
                                    <h4 className="new-how-does-it-work-section1-title"> {t("home.how_it_works.fourth.title")}</h4>
                                    <p className="new-how-does-it-work-section1-description">
                                        {t("home.how_it_works.fourth.content")}
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} lg={5} className="new-how-does-it-work-section1-left-side-start" id="howDoesItWorkLeftSide3">
                            <Image src={img4} className="new-how-does-it-work-img3  new-how-does-it-work-img" />
                        </Col>
                    </Row>
                </Container> */}

            </Container>
        </div>
    )
}

export default HowDoesItWork;

import React, { useState } from "react";
import Page from "../../components/pages";
import { RouteComponentProps } from "react-router-dom";
import TopBar from "./TopBar";
import Content from "./Content";
import axios from "axios";
import moment from "moment";
import { trackPromise } from "react-promise-tracker";
import { CONVERSATION_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import AlertInfo from "../../components/alerts/AlertInfo";
import TutorConfirmationModal from "../../components/modals/tutorDetailsModal/tutorConfirmationModal";
import {useTranslation} from "react-i18next";

const MakeReservation: React.FC<RouteComponentProps> = ({ history }) => {
    const { t } = useTranslation();

    const [selectedTeacherId, setSelectedTeacherId] = useState<any>(null);
    const [selectedTeacherName, setSelectedTeacherName] = useState<any>(null);
    const [selectedDate, setSelectedDate] = useState<any>();
    const [selectedMinute, setSelectedMinute] = useState(15);

    const [success, setSuccess] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [isConfirmationModal, setIsConfirmationModal] = useState(false);

    const [availableTeachers, setAvailableTeachers] = useState<any>();

    const onSelectionChanged = async (param: any) => {
        // param.date;
        // param.minute;    
        const startDt = moment(param.date);
        const filter = "?start=" + startDt.unix() + "&meetingMinute=" + param.minute;

        setSelectedDate(startDt);
        setSelectedMinute(param.minute);

        const res = await trackPromise(axios.get<any, ReturnProps>(CONVERSATION_URL + "/api/v1/schedule-meetings/freetime" + filter));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            //console.log("HATA");
            return null
        }

        setAvailableTeachers(res.data);
    }


    const addReservationForStudent = async () => {
        const startDt = moment(selectedDate).unix();
        const reqParam = {
            teacherUserId: selectedTeacherId,
            startDate: startDt,
            minute: selectedMinute
        }
        const res = await trackPromise(axios.post<any, ReturnProps>(CONVERSATION_URL + "/api/v1/schedule-meetings", reqParam));
        setIsConfirmationModal(false);
        // Handling Error
        if (!res.success || !res.headers.location) {
            setError(res.error as string);
            setShowError(true)
            return null;
        }
        else {
            setSuccess("Rezervasyonunuz olusturuldu");
            setShowSuccess(true);
        }
    }

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
            <AlertInfo show={showSuccess} setShow={setShowSuccess} isAutoHide={true} message={success} type={1} />

            <Page
                type="regular"
                history={history}
                headerTitle={t("reservation.do_reservation")}
            >
                <div>
                    <TopBar onSelectionChanged={onSelectionChanged}
                        setSelectedMinute={setSelectedMinute}
                        setSelectedDate={setSelectedDate} />
                    <Content availableTeacherList={availableTeachers} makeReservationClicked={(e: any) => {
                        if (selectedDate) {
                            //console.log("Date " + selectedDate + " --- " + " $$ " + moment(selectedDate) + "   %%" + moment(selectedDate).format('DD MMMM YYYY, dddd'));
                            setIsConfirmationModal(true);
                            setSelectedTeacherId(e.guid);
                            setSelectedTeacherName(e.name + " " + e.surname.charAt(0) + ".")
                        }

                    }
                    } />
                </div>
            </Page>

            { isConfirmationModal &&
                <TutorConfirmationModal
                    show={isConfirmationModal}
                    approveClicked={() => addReservationForStudent()}
                    hide={() => setIsConfirmationModal(false)}
                    selectedMeetingMinutes={selectedMinute}
                    date={selectedDate}
                    timeSlot={selectedDate}
                    userId={selectedTeacherId}
                    userName={selectedTeacherName}
                />
            }

        </React.Fragment>
    )
}

export default MakeReservation


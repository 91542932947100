import quote1 from "../assets/quote1.png";
import quote2 from "../assets/quote2.png";
import quote3 from "../assets/quote3.png";

export const reviews = [
    {
        id: 0,
        description: "home.reviews.first.description",
        image: quote1,
        name: "Ali",
        country: "home.reviews.first.country"
    },
    {
        id: 1,
        description: "home.reviews.second.description",
        image: quote2,
        name: "Fatih",
        country: "home.reviews.second.country"
    },
    {
        id: 2,
        description: "home.reviews.third.description",
        image: quote3,
        name: "Nilüfer",
        country: "home.reviews.third.country"
    },
]

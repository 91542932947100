import React, { useState, useEffect } from "react";
import "../../layout/ChangePassword/ChangePassword.css";
import { RouteComponentProps } from "react-router-dom";
import Page from "../../components/pages";
import Input from "../../components/inputs";
import Button from "../../components/buttons";
import "../../mobile/ChangePassword/ChangePassword.css";
import "../../tablet/ChangePassword/ChangePassword.css";
import { IDENTITY_URL, ROLES } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import { connect } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import AlertInfo from "../../components/alerts/AlertInfo";
import { useTranslation } from "react-i18next";

interface ChangePasswordProps extends RouteComponentProps { }


const ChangePassword: React.FC<any> = ({ history, auth }) => {
    const { t } = useTranslation();

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [success, setSuccess] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);

    const [originalPassword, setOriginalPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [disabled, setDisabled] = useState(false)
    const handleSubmitPermission = () => {
        if (originalPassword && newPassword && confirmNewPassword) {
            if (newPassword === confirmNewPassword) {
                setDisabled(true)
            }
        }
    }

    const onChangePasswordHandle = async (e: any) => {
        e.preventDefault();
        if (originalPassword && newPassword && confirmNewPassword) {
            const changePasswordParams: any = {
                newPassword: newPassword,
                currentPassword: originalPassword,
            }
            //console.log("Register Info: ", changePasswordParams)
            // Calling The API
            const res = await trackPromise(axios.put<any, ReturnProps>(IDENTITY_URL + "/api/v1/users/password", changePasswordParams));

            // Handling Error
            if (!res.success) {
                setError(res.error as string);
                setShowError(true)
                //alert(res.error)
                return null;
            }
            else {
                setSuccess("Record updated");
                setShowSuccess(true);
            }
        }
    }

    useEffect(() => {
        handleSubmitPermission()
    }, [originalPassword, newPassword, confirmNewPassword])

    // @ts-ignore
    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
            <AlertInfo show={showSuccess} setShow={setShowSuccess} isAutoHide={true} message={success} type={1} />

            <div>
                <Page type="dashboard" history={history} headerTitle={t("change_password.change_password")} activePage="ChangePassword">
                    <h4 className="change-password-title">{t("change_password.change_your_password")}</h4>
                    <div className="change-password-inputs-container">
                        <Input type="password" label={t("change_password.current_password")} style={{ marginBottom: 10 }} value={originalPassword} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOriginalPassword(e.target.value)} />
                        <Input type="password" label={t("change_password.new_password")} style={{ marginBottom: 30 }} value={newPassword} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)} />
                        <Input type="password" label={t("change_password.confirm_new_password")} style={{ marginBottom: 30 }} value={confirmNewPassword} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmNewPassword(e.target.value)} />
                        <div className="change-password-btn-container">
                            <Button type="default" onClick={onChangePasswordHandle} primary={true} style={{ width: "100%", backgroundColor: disabled ? undefined : "#CACCCF", opacity: 1 }} disabled={!disabled}>{t("change_password.change_password")}</Button>
                        </div>
                    </div>
                </Page>
            </div>
        </React.Fragment>
    )
}

interface StateProps {
    auth: any
}
function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer,
    }
}

export default connect(mapStateToProps)(ChangePassword);

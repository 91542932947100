import React, { createRef, useEffect, useState } from 'react';
import { Modal, Image } from 'react-bootstrap';
import { calender } from '../../../assets/SVG/SVG';
import moment from 'moment';

import Button from "../../../components/buttons";
import "../../../layout/HistoryModal/HistoryModal.css";
import cross from "../../../assets/upgrade-package/cross.png";
import theme from "../../../theme";
import StyledButton from '../../buttons';
import { CONVERSATION_URL, IDENTITY_URL, ROLES } from "../../../Global/Constants/constants";
import { ReturnProps } from "../../../@types/functions";
import axios from "axios";
import { trackPromise } from 'react-promise-tracker';

import "../../../layout/VideoModal/VideoModal.css";
import ReactPlayer from 'react-player';
// @ts-ignore
import VideoRecorder from 'react-video-recorder'
import Actions from './defaults/render-actions';
import AlertInfo from '../../alerts/AlertInfo';
import { deleteIcon } from '../../../assets/SVG/SVG';
import { injectMetadata } from '../../../Global/Utilities/videoConverter';

interface VideoModalInterface {
    videoDate?: string;
    videoID?: string;
    videoUrl?: string;
    show: Boolean;
    hide: () => void;
    update?: () => void;
}
const VideoModal: React.FC<VideoModalInterface> = ({
    show,
    videoDate,
    videoID,
    videoUrl,
    hide,
    update = () => { }
}) => {
    const videoRecorderRef = createRef<any>();
    const [success, setSuccess] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [videoBlob, setVideoBlob] = useState<any>(null);
    const [teacherRate, setTeacherRate] = useState<any>();
    const [systemRate, setSystemRate] = useState<any>();

    const [isVideoRecord, setIsVideoRecord] = useState(!videoUrl ? true : false);

    const UploadVideo = async () => {
        var data = new FormData();
        data.append('file', (videoBlob as Blob));
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        const res = await trackPromise(axios.post<any, ReturnProps>(IDENTITY_URL + "/api/v1/users/video", data, config));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            return null;
        }
        else {
            setSuccess("Record updated");
            setShowSuccess(true);
            update();
            hide();
        }
    }

    const DeleteVideo = async () => {
        const res = await trackPromise(axios.delete<any, ReturnProps>(IDENTITY_URL + "/api/v1/users/video"));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            return null;
        }
        else {
            setSuccess("Record updated");
            setShowSuccess(true);
            update();
            hide();
        }
    }

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
            <AlertInfo show={showSuccess} setShow={setShowSuccess} isAutoHide={true} message={success} type={1} />

            <div className="greybgHeader">
                <Modal className="historyModal" show={show} onHide={hide} backdrop="static">
                    <Modal.Header className="historyHeader">
                        <Modal.Title>
                            <h4 className="h4">Profile Video</h4>
                        </Modal.Title>
                        <Image
                            onClick={hide}
                            src={cross}
                            height={40}
                            width={40}
                            style={{ cursor: "pointer" }}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <div className="content">
                            {!isVideoRecord ?
                                <div>
                                    <div className="video-container">
                                        <ReactPlayer onError={() => {
                                            setIsVideoRecord(true);
                                        }} width="560" height="315" controls={true} url={videoUrl} />
                                    </div>
                                    <div className="button-container">
                                        <Button type="tutorsCardBtn" className="button-danger" onClick={DeleteVideo}>Delete Video</Button>
                                        <Button type="tutorsCardBtn" secondary onClick={() => setIsVideoRecord(true)}>New Video</Button>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="video-recorder-container">
                                        <VideoRecorder
                                            ref={videoRecorderRef}
                                            chunkSize={250}
                                            constraints={{
                                                audio: true,
                                                video: true
                                            }}
                                            timeLimit={50000}
                                            countdownTime={3000}
                                            dataAvailableTimeout={500}
                                            isFlipped
                                            // isOnInitially
                                            replayVideoAutoplayAndLoopOff
                                            showReplayControls
                                            renderActions={Actions}
                                            onRecordingComplete={(videoBlob: any) => {
                                                // Do something with the video...
                                                //console.log("onRecordingCompleted", videoBlob);
                                                injectMetadata(videoBlob).then((blob: any) => {
                                                    setVideoBlob(blob);
                                                    //console.log('injectedData', blob);
                                                });
                                                // UploadVideo(videoBlob);
                                            }}
                                        />
                                    </div>
                                    {videoBlob &&
                                        <div className="button-container">
                                            <Button type="tutorsCardBtn" secondary onClick={() => {
                                                setVideoBlob(null);
                                                videoRecorderRef?.current?.handleStopReplaying();
                                                videoRecorderRef?.current?.turnOnCamera();
                                            }}>New Video</Button>

                                            <Button type="tutorsCardBtn" primary onClick={UploadVideo}>Save Video</Button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    )
}
export default VideoModal;
import Loader from 'react-loader-spinner';

function Spinner() {
    return (
        <div style={{
            color: "#FF0000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            left: "0px",
            top: "0px",
            width: "100%",
            height: "100%",
            zIndex: 99999
        }}><Loader type="MutatingDots" width={100} height={100} color="#F86F53" /></div>
    );
}

export default Spinner;
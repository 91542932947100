import React, { useState } from "react";
import "../../layout/MakeReservation/TopBar.css";
import { Modal, Form } from 'react-bootstrap';
import Datetime from "react-datetime";
import OptionButton from "../../components/buttons";
import styled, { css } from "styled-components";
import theme from "../../theme";
import { Image } from "react-bootstrap";
import SelectInput from "../../components/inputs";
import SecondaryBtn from "../../components/buttons";
import "../../tablet/MakeReservation/TopBar.css";
import "../../mobile/MakeReservation/TopBar.css";
import calendar from "../../assets/make-reservations/calendar.png";
import { makeTimeSlot, todayStr } from '../../utils/helper';
import moment from "moment";
import {useTranslation} from "react-i18next";

// @ts-ignore
const Button = styled(OptionButton)`
    ${({ selected }) => {
        if (selected) return css`
                background-color: ${theme.colors.primary};
                color: #fff;
                border-color: ${theme.colors.primary};
            `;
        if (!selected) return css`
                &:hover {
                    border-color: ${theme.colors.primary};
                    color: ${theme.colors.primary};
                }
            `;
    }
    }
    @media only screen and (max-width: 960px) {
        width: 80px;
        padding: 0 5px;
        font-size: 14px;
    }
    @media only screen and (max-width: 720px) {
        padding: 0;
        font-size: 12px;
    }
    @media only screen and (max-width: 340px) {
        margin-right: 7px;
        width: 75px;
    }
`;

const ViewBtn = styled(SecondaryBtn)`
    border-radius: 8px;
    @media only screen and (max-width: 960px) {
        padding-right: 5px;
        padding-left: 5px;
    }
    @media only screen and (max-width: 720px) {
        width: 82px;
        font-size: 14px;
        margin-right: 0;
    }
    @media only screen and (max-width: 340px) {
        margin-left: 0px;
    }
`;

const Input = styled(SelectInput)`
    height: 100% !important;
    width: 100%;
`;

const durations = [
    {value: "15", title: "15 dakika"},
    {value: "30", title: "30 dakika"},
    {value: "60", title: "60 dakika"},
];

interface TopBarProp
{
    onSelectionChanged : any;
    setSelectedMinute : any,
    setSelectedDate : any,
}

const TopBar :React.FC<TopBarProp> = ({onSelectionChanged,setSelectedDate, setSelectedMinute}) => {
    const { t } = useTranslation();

    const [selectedDuration, setSelectedDuration] = useState(0);
    const [date, setDate] = useState(moment());
    const [fromSlot, setFromSlot] = useState('00:00');

    var yesterday = moment().subtract(1, 'day');
    var valid = function (current: any) {
        return current.isAfter(yesterday);
    };

    const onListClicked = () =>{
        const startDate =  moment(todayStr(moment(date)) + `T${fromSlot}:00`);

        onSelectionChanged ({date:startDate, minute : durations[selectedDuration].value});
    }

    return (
        <div className="reservation-top-bar-container">
            <div className="reservation-call-duration">
                <h4 className="reservation-call-duration-title">
                    {t("reservation.choose_res_minute")}
                </h4>
                <div className="reservation-options-container">
                    {
                        durations.map((duration, index) => (
                            <div>
                                <Button
                                    type="option"
                                    selected={(index === selectedDuration)}
                                    onClick={() => setSelectedDuration(index)}
                                >
                                    {duration.value} {t("general.minute")}
                                </Button>
                            </div>

                        ))
                    }
                </div>
            </div>
            <div className="reservation-schedule">
                <h4 className="reservation-schedule-title">
                    {t("reservation.choose_res_date_and_hour")}
                </h4>
                <div className="reservation-schedule-selectors">
                    <div className="reservation-schedule-calendar">

                        {/* <p>23.08.2020</p>
                        <Image
                            src={calendar}
                            className="calendar-image"
                        /> */}
                        <Datetime
                            className="date-picker"
                            timeFormat={false}
                            dateFormat="DD.MM.YYYY, dddd"
                            inputProps={{ placeholder: "Select date" }}
                            isValidDate={valid}
                            locale="tr"
                            onChange={(d) =>
                            {
                                setDate(moment(d));
                                setSelectedDate(d);
                            }}
                            value={date}
                            closeOnSelect={true}
                        />
                    </div>
                    <hr className="reservation-schedule-seperator" />
                    <div className="reservation-schedule-time">
                        {/* <Input
                            type="select"
                            options={["12:00"]}
                        /> */}

                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Control as="select" onChange={e => {
                                setFromSlot(e.target.value);
                                setSelectedDate(e.target.value);
                                }}>
                                {
                                    makeTimeSlot({
                                        slotInterval: 15,
                                        openTime: 0,
                                        closeTime: '00:00'
                                    }).map((itm, key) => (
                                        <option key={"dropdownFromTimeSlot" + key}>{itm.split('-')[0]}</option>
                                    ))
                                }

                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div className="reservation-view-btn-container">
                        <ViewBtn type="default" onClick={onListClicked}  secondary> {t("reservation.list")}</ViewBtn>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopBar;



export default {
    primary: "#F86F53",
    primaryShade: "#551103",
    primaryTiny: "#FDD9D1",
    secondary: "#5389F8",
    secondaryShade: "#031E55",
    secondaryTiny: "#D1E0FD",
    white: "#fff",
    line: "#CACCCF",
    transparent: "transparent",
    darkPrimary: "#551103",
    filter: "#929598",
    dark: "#45494D",
}

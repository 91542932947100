import React from "react";
import "../../layout/ReservationPage.css";
import SecondNavBar from "../SecondNavBar";
import { Container } from "react-bootstrap";
import Footer from "../Footer";
import {useTranslation} from "react-i18next";

interface ReservationProps {
    children: React.ReactNode
}

const Reservation: React.FC<ReservationProps> = ({ children }) => {
    const { t } = useTranslation();
    return (
        <div className="reservation">
            <div className="reservation-nav-bar-container">
                <SecondNavBar />
            </div>
            <div className="reservation-banner">
                <Container>
                    <h4 className="pt-5 reservation-banner-title">{t("reservation.reservation")}</h4>
                </Container>
            </div>
            <Container className="reservation-sections">
                <div className="reservation-section reservation-section-active">
                    <h6 className="reservation-section-title">{t("reservation.do_reservation")}</h6>
                </div>
                <div className="reservation-section">
                    <h6 className="reservation-section-title">{t("reservation.existing_reservations")}</h6>
                </div>
            </Container>    
            <Container className="mt-4 reservation-container">
                {
                    children
                }
            </Container>
            <Footer />
        </div>
    )
}

export default Reservation;


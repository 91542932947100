import React,{ useState } from "react";
import "../../layout/RegularPage.css";
import NavBar from "../navbars";
import { History } from "history";
import { Container } from "react-bootstrap";
import Footer from "../Footer";
import "../../mobile/RegularPage.css";

interface RegularPageProps {
    history: History,
    children: React.ReactNode,
    pageTitle: String,
    subTitle: String
}

const RegularPage:React.FC<RegularPageProps> = ({ 
    history, 
    children, 
    pageTitle,
    subTitle
}) =>{
    const [expandNavBar, setExpandNavBar] = useState(false);
    const handleNavBarExpand = () =>setExpandNavBar(!expandNavBar)
    return(
        <div style={{ backgroundColor: "#fafafa" }}>
            <div className="regular-page-header">
                <NavBar type="dashboard" history={history} onClickExpand={handleNavBarExpand} />
                <Container className="regular-paga-header-container">
                    <h4 className="regular-page-header-title" style={{ color: expandNavBar ? "#fafafa" : "" }}>{pageTitle}</h4>
                    <h4 className="regular-page-header-subtitle">
                        { subTitle }
                    </h4>
                </Container>
            </div>
            <Container className="regular-page-content">
                {
                    children
                }
            </Container>
            <div className="regular-page-footer-container">
                <Footer />
            </div>
        </div>
    )
}

export default RegularPage;

import React,{ useState } from "react";
import { useSpring, animated } from "react-spring";
import { Image } from "react-bootstrap";
import SubscriptionSummaryMoreDetails from "./SubscriptionSummaryMoreDetails";

import downArrow from "../../../assets/student-instructors-page/down-arrow.png";
import cardTwoImg from "../../../assets/student-instructors-page/card-two-img.png";
import { useTranslation } from "react-i18next";

interface SubscriptionAvailableProps {
    subscriptionDetails: any
}

const SubscriptionSummaryAvailableOnlyOneTime:React.FC<SubscriptionAvailableProps> = ({ subscriptionDetails }) =>{
    // var subscription = {usedDayCounts : 3,leftDayCounts:2, todayAvailableMinutes:2};
    const { t } = useTranslation();
    let subscriptionOneTime: any =  Array.isArray(subscriptionDetails) && subscriptionDetails.filter((t: any) => t?.subscriptionType === 0)[0]

    const [showMoreDetails, setShowMoreDetails] = useState(false);
    const moreInfoState = useSpring({
        height: showMoreDetails ? 300 : 130,
        opacity: showMoreDetails ? 1 : 0,
        transform: showMoreDetails ? "rotate(180deg)" : "rotate(0deg)"
    })


    return (
        <animated.div 
            className="student-instructors-page-card-container subscription-summary"
            style={{
                top : 0,
                height: moreInfoState.height,
            }}
        >
            <div className="student-instructors-page-card-wrapper subscription-summary">
                <Image 
                    src={cardTwoImg}  
                    height={88} 
                    style={{
                        marginTop: 15
                    }}
                />
                <div className="student-instructors-page-card-content">
                    <div className="student-instructors-page-other-content-container">
                        <h4 className="student-instructors-page-card-title">
                            {t("dashboard.today_free_minutes")}
                        </h4>
                        <h4 className="student-instructors-page-card-call-duration">
                            { subscriptionOneTime?.todayAvailableMinutes }  {t("dashboard.minutes_left")}
                        </h4>
                    </div>
                </div>

            </div>
            
        </animated.div>
    )
}

export default SubscriptionSummaryAvailableOnlyOneTime;

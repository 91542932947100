import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Card } from 'react-bootstrap';
import Calender from '../../components/tutorDashboard/calender';
import "../../layout/Tutor/TutorDashboard.css"

import { STUDENT_DASHBOARD_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";


const ScheduleCalendarContent = () => {

    const [readOnly, setREadOnly] = useState(true)

    return (
        <div >
            <h2 className="dash-title">Schedule</h2>
            <Card className="calender-main-wrapper">

                <Card.Body>
                    <Calender
                        readOnly={true}
                        setREadOnly={setREadOnly}
                    />

                    {readOnly ? <div className="timeWrapper">
                        <div className="time-inner">
                            <div className="box availableTime"></div>
                            <p>Available time</p>
                        </div>
                        <div className="time-inner">
                            <div className="box fifMin"></div>
                            <p>15 min reservation</p>
                        </div>

                        <div className="time-inner">
                            <div className="box thirthyMin"></div>
                            <p>30 min reservation</p>
                        </div>

                        <div className="time-inner">
                            <div className="box sixtyMon"></div>
                            <p>60 min reservation</p>
                        </div>

                        <div className="time-inner">
                            <div className="box moremin"></div>
                            <p>Completed reservation</p>
                        </div>
                    </div>
                        : <div className="timeWrapper">
                            <div className="time-inner">
                                <div className="box moremin"></div>
                                <p>Kesinleşen Rezervasyon</p>
                            </div>
                            <div className="time-inner">
                                <div className="box moremin"></div>
                                <p>Müsait olarak ayarlanmış</p>
                            </div>
                            <div className="time-inner">
                                <div className="box moremin"></div>
                                <p>Herhangi bir ayarlama olmayan</p>
                            </div>
                        </div>
                    }
                </Card.Body>
            </Card>
        </div>
    )
}

export default ScheduleCalendarContent;
import React from "react";
import NextSessionAvailable from "./NextSessionAvailable";
import NextSessionUnavailable from "./NextSessionUnavailable";

import turk from "../../../assets/student-instructors-page/turk.png";
import cardThreeImg from "../../../assets/student-instructors-page/card-three-img.png";
import moment from 'moment';

interface NextSessionCardProps {
    nextSession: any
}

const NextSessionCard:React.FC<NextSessionCardProps> = ({ nextSession }) =>{

    let notAvailable = false
    if(!nextSession || !nextSession.teacherUser || !nextSession.startDate)
        notAvailable = true
    
    return (
        <>
        {
            !notAvailable ? 
            <NextSessionAvailable nextSession = {nextSession} /> :
            <NextSessionUnavailable />
        }
        </>
    )
}

export default NextSessionCard; 

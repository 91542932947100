import showPassword from "../assets/icons/showPassword.png";
import hidePassword from "../assets/icons/hidePassword.png";
import calendar from "../assets/icons/calendar.png";
import search from "../assets/icons/search.png";
import dropdown from "../assets/icons/dropdown.png";
import favStroke from "../assets/icons/fav-stroke.png";
import favFill from "../assets/icons/fav-fill.png";
import calendarWhite from "../assets/icons/calendar-white.png";
import msgWhite from "../assets/icons/msg-white.png";
import notificationWhite from "../assets/icons/notification-white.png";
import profileWhite from "../assets/icons/profile-white.png";
import calendarDark from "../assets/icons/calendar-dark.png";
import msgDark from "../assets/icons/msg-dark.png";
import notificationDark from "../assets/icons/notification-dark.png";
import profileDark from "../assets/icons/profile-dark.png";
import faq from "../assets/icons/faq.png";

export default {
    password: {
        show: showPassword,
        hide: hidePassword
    },
    calendar: {
        white: calendarWhite,
        default: calendar,
        dark: calendarDark
    },
    search,
    dropdown,
    fav: {
        stroke: favStroke,
        fill: favFill
    },
    msg: {
        white: msgWhite,
        dark: msgDark,
    },
    notification: {
        white: notificationWhite,
        dark: notificationDark
    },
    profile: {
        white: profileWhite,
        dark: profileDark
    },
    faq: {
        white: faq
    }
}

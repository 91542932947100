import React from "react";
import { Container, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../layout/PrivacyPage.css";
import NavBar from "../navbars";
import Footer from "../Footer";
import "../../mobile/Privacy/Privacy.css";
import "../../tablet/Privacy/Privacy.css";
import NewFooter from "../NewFooter";

const NoLoginPage:React.FC<any> = ({ children, history, headerTitle }) =>{
    return (
        <div className="privacy-page">
            <div className="privacy-page-header">
                <NavBar type="light" history={history} />
                <div className="privacy-page-header-title-container">
                    <Container>
                        <h4>{headerTitle}</h4>
                    </Container>
                </div>
            </div>
            <Container className="privacy-policy-container">
                {
                    children
                }
            </Container>
            <div className="privacy-policy-footer">
                <Footer />
            </div>
        </div>
    )
}

export default NoLoginPage;

import React from "react";
import Page from "../../../components/pages";
import "../../../layout/Support/Instructors/Support.css";
import "../../../mobile/Support/Instructor/Support.css";
import "../../../tablet/Support/Instructor/Support.css"
import { Link } from "react-router-dom";
import { links, paragraphs } from "../../../Global/Support/Instrcutors/Support";

const Support:React.FC<any> = ({ history }) => {
  return (
    <Page type="support" bannerType="instructor" history={history}>
      <h6 className="support-instructor">Öğrenciler İçin Yardım</h6>
      <div className="support-questions">
        {
          links.map(link =>(
            <div key={link.id}>
              <Link to={link.to} className="instructors-support-link">
                {link.label}
              </Link>
            </div>
          ))
        }
      </div>
      <div className="instructors-support-text mt-4">
        {
          paragraphs.map(para =>(
            <p className="mb-3" key={para.id}>
              {
                para.content.map((p,index) =>(
                  <>
                    {p}
                    { (para.content.length > 0 &&  index < (para.content.length - 1)) && <br />}
                  </>
                ))
              }
            </p>
          ))
        }
      </div>
    </Page>
  );
};

export default Support;

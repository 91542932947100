
const initialState = {
    text : ""
}

interface ActionProps {
    type: String,
    payload: string
}

export default function(state=initialState, action:ActionProps) {
    switch(action.type) {
        case "SEARCH_TEXT_CHANGED":
            return state = { ...state, text: action.payload}
        default:
            return state
    }
}

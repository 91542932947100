
export const levels = [
    "Başlangıç",
    "Temel",
    "Orta",
    "İleri Orta",
    "İleri",
]

export const intrests = [
    "Hayvanlar",
    "İlişkiler",
    "İş Dünyası",
    "Dünyadan Haberler",
    "Dil & Kültür",
    "Sanat & Edebiyat",
    "Eğlence & Yaşam",
    "Bilim & Teknoloji",
    "Sosyal Bilimler",
    "Spor",
    "Sağlık ve Formda Kal",
    "Müzik",
    "Mizah",
    "Gezi",
    "Moda",
    "Dünya Mutfağı",
    "TOEFL",
    "Tarih",
]

export const goals = [
    "İşyerinde uzmanlaşmak",
    "Yurtdışında okumak",
    "İş görüşmesi yapmak",
    "Gramer",
    "Planlı Dersler",
    "Konuşma",
    "Bir testi geçmek",
    "Kişisel gelişim",
    "Seyahat",
]

import React,{ useState } from "react";
import "../../layout/UpdatePaymentMethode/Informations.css";
import Input from "../../components/inputs";
import Button from "../../components/buttons";
import theme from "../../theme";
import "../../mobile/UpdatePayment/UpdatePayment.css";
import "../../tablet/UpdatePayment/UpdatePayment.css";

const Informations = () =>{
    const [condOneChecked, setCondOneChecked] = useState(false);
    const [condTwoChecked, setCondTwoChecked] = useState(false);
    return(
        <div>
            <div className="update-payment-informations">
                <h4 className="update-payment-informations-title">Bilgilendirme</h4>
                <div className="update-payment-informations-conditions">
                    <Input
                        type="checkbox"
                        label={`
                            Nisl gravida a natoque accumsan sed proin semper.
                            Sed sed tellus lacus sagittis etiam congue eget id. 
                            Risus vitae felis porta id.
                        `}
                        labelColor="#929598"
                        onChange={() =>setCondOneChecked(true)}
                    />
                    <div style={{ marginTop: 30 }}>
                        <Input
                            type="checkbox"
                            label={`
                                Kredi kartı bilgileri saklama ve satış sözleşmeleri 
                                ile ilgili kurallar buraya yazılabilir. 
                                Kurallar bir ya da iki cümle olabilir.
                            `}
                            labelColor="#929598"
                            onChange={() =>setCondTwoChecked(true)}
                        />
                    </div>
                </div>
            </div>
            <Button
                type="default"
                secondary={true}
                style={{
                    width: "100%",
                    marginTop: 30,
                    backgroundColor: (condOneChecked && condTwoChecked) ? theme.colors.secondary : theme.colors.filter,
                    borderColor: (condOneChecked && condTwoChecked) ? theme.colors.secondary : theme.colors.filter
                }}
                className="update-payment-btn"
                disabled={!(condOneChecked && condTwoChecked)}
            >Kartı Kaydet</Button>
        </div>
    )
}

export default Informations;

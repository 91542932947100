import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../../layout/InstructorReservations/Content.css";
import Card from "../../components/cards";
import { CONVERSATION_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import ReservationRecords from "./ReservationRecords";
import axios from "axios";
import NoReservationFound from "./NoReservationFound"
import { ROLES } from "../../Global/Constants/constants";
import { AuthReducerProps } from "../../@types/Auth";
import moment from "moment";
import { trackPromise } from "react-promise-tracker";


const Content: React.FC<StateProps> = ({ auth, daterange, searchtext }) => {
    const [reservationList, setReservationList] = useState<[any]>();
    const [reservationFilteredList, setReservationFilteredList] = useState<any[any]>();

    const LoadReservations = async () => {
        //console.log("Load Res  " + daterange);

        var filter = "";
        if (daterange != null && daterange.dateRange?.timeRangeValue != null) {
            const startDt = moment(daterange.dateRange.timeRangeValue[0]).unix();
            const end = moment(daterange.dateRange.timeRangeValue[1]).unix();
            filter = "?start=" + startDt + "&end=" + end;

            const res = await trackPromise(axios.get<any, ReturnProps>(CONVERSATION_URL + "/api/v1/schedule-meetings" + filter));
            if (!res.success) {
                //   setError(res.error as string)
                //console.log("HATA");
                return null
            }
            setReservationList(res.data);
            setReservationFilteredList(res.data);
        }

    }

    const LoadReservationsOffline = async () => {
        if (reservationList && reservationList.length > 0) {
            if (auth.user.role === ROLES.STUDENT) {
                setReservationFilteredList(reservationList.filter((x: any) => x.teacherUser.name.includes(searchtext.text.value)))
            }
            else {
                setReservationFilteredList(reservationList.filter((x: any) => x.studentUser.name.includes(searchtext.text.value)))
            }
        }
    }

    useEffect(() => {
        if (!daterange || daterange.length < 1) {
            return;
        }
        LoadReservations()
    }, [daterange]);

    useEffect(() => {
        if (!searchtext.text || !searchtext.text || !searchtext.text.value) {
            setReservationFilteredList(reservationList);
            return;
        }
        LoadReservationsOffline()
    }, [searchtext]);


    return (
        <div>
            { !reservationList || reservationList?.length < 1 && (<div><NoReservationFound /></div>)}

            <div className="instructor-reservations-content-container">
                {reservationFilteredList && reservationFilteredList.map((res: any, index: any) => (
                    <Card
                        type="reserved-student"
                        userId = {auth.user.role === ROLES.STUDENT ? res.teacherUser.guid : res.studentUser.guid}
                        name={auth.user.role === ROLES.STUDENT ? res.teacherUser.name : res.studentUser.name}
                        surname={auth.user.role === ROLES.STUDENT ? res.teacherUser.surname : res.studentUser.surname}
                        thumb={(auth.user.role === ROLES.STUDENT ? res.teacherUser.photoURL : res.studentUser.photoURL) || ""}
                        startTime={res.startDate}
                        countryCode={auth.user.role === ROLES.STUDENT ? res.teacherUser.countryCode : res.studentUser.countryCode}
                        endTime={res.endDate}
                        minute={res.totalMinute}
                        itemId={res.guid}
                        reservationIsDeletable={res.isDeletable}
                        onRefresh={() => LoadReservations()}
                    />
                ))}
            </div>

            {reservationFilteredList && reservationFilteredList.length > 0 && <ReservationRecords />}
        </div>
    )
}

interface StateProps {
    auth: any
    daterange: any
    searchtext: any
}
function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer,
        daterange: state.DateRangeFilterReducer,
        searchtext: state.SearchTextReducer
    }
}

export default connect(mapStateToProps)(Content);

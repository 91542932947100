import React, { useState } from "react";
import Page from "../../components/pages";
import { RouteComponentProps } from "react-router-dom";
import PayoutAccount from "./PayoutAccount";
import EarningHistory from "./EarningHistory";
import Content from "./Content";
//  import { instructors, getInstructorsByStatus } from "../../Global/InstructorsPage/Instructors";

const TutorPaymentPage: React.FC<RouteComponentProps> = ({ history }) => {
    const [instructorsList, setInstructorsList] = useState([]);
    const handleStatus = (status: "active" | "inactive" | "bussy" | "" | "favourite") => { };
    return (
        <Page type="dashboard" history={history} headerTitle="Payment" activePage="TutorPayment">
            <Content />
        </Page>
    )
}

export default TutorPaymentPage;

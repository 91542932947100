import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2'
import {Lang} from "../../App"
// import { useTranslation } from "react-i18next";

// export const axios = Axios.create({
//     timeout : 10000,
//    // headers : [{'Access-Control-Allow-Origin' : 'http://localhost:3000'} , {'Access-Control-Allow-Credentials':'true'}]
// });


axios.defaults.timeout = 50000;
const accessToken = localStorage.getItem("speakingline_access_token")
axios.defaults.headers.common['Authorization'] =
  "Bearer " + accessToken;

axios.interceptors.request.use(function (config) {
  config.headers.common = { ...config.headers.common, "mobon-trace-id": uuidv4() }
  //console.log("REQUST : " + config.url, config);
  return config;
}, function (error) {
  // Do something with request error
  console.error(error);
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  console.log("RESPONSE : " + response.config.url, response);
  return {
    success: true,
    data: response.data.data,
    headers: response.headers
  }
}, function (error) {
  console.log("RESPONSE : " + error?.response?.config?.url, error?.response);
  if (401 === error.response?.status) {
    window.location = '/login';
    // Swal.fire({
    //   title: t("general.session_expired"),
    //   text: t("general.session_redirect_login"),
    //   showDenyButton: true,
    //   showCancelButton: false,
    //   confirmButtonText: "a",//t("general.yes"),
    //   denyButtonText: "d",//t("general.yes"),
    //   closeOnConfirm: true
    // }).then((result) => {
    //   /* Read more about isConfirmed, isDenied below */
    //   if (result.isConfirmed) {
    //     //console.log("Button Clicked")
    //     window.location = '/login';
    //   } else if (result.isDenied) {
    //   }
    // })

    // Swal.fire({
    //     title: "Session Expired",
    //     text: "Your session has expired. Would you like to be redirected to the login page?",
    //     showCancelButton: true,
    //     confirmButtonColor: "#DD6B55",
    //     confirmButtonText: "Yes",
    //     closeOnConfirm: false
    // }, function(e){
    //   if (e.isConfirmed) {
    //     //console.log("Button Clicked")
    //     window.location = '/login';
    //   }
    // });
    return Promise.reject(error);
  }
  else if (403 === error.response?.status) {
    Swal.fire({
      title: "UnAuthorized",
      text: "You are not authorized to do that. Would you like to be redirected to the login page?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      closeOnConfirm: false
    }, function () {
      window.location = '/login';
    });

    return Promise.reject(error);
  }
  else {
    return {
      success: false,
      error: (error.response?.data?.errors && error.response?.data?.errors.length > 0 && error.response?.data?.errors[0]?.code)
        ? Lang("server_messages."+error.response?.data?.errors[0].code) :
        Lang("server_messages.generic_message"),
      errorMessages: (error.response?.data?.errors && error.response?.data?.errors.length > 0) ? error.response?.data?.errors :
        []
    }
    return Promise.reject(error);
  }
});


// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

// export axios;

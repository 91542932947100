import React from "react";
import styled,{ css } from "styled-components";
import theme from "../theme";
import { Image } from "react-bootstrap";

import fav from "../assets/instructors-page/favourite.png";

interface ButtonContainerProps {
    statusBtn: String;
    selected: Boolean
}

const ButtonContainer = styled("button")<ButtonContainerProps>`
    background-color: transparent;
    border: 0;
    text-align: center;
    color: ${theme.colors.dark};
    font-size: 14px;
    font-weight: 600;
    font-family: ${theme.text.fonts.nunito};
    margin: 0;
    padding: 9px 60px;
    width: 150px;
    height: 48px !important;
    background-color: #F2F2F2;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
    }
    &:hover {
        transition: background-color 0.3 ease-in-out;
        background-color: #e4e4e4;
    }
    ${
        ({ selected }) =>{
            if(selected) return css`
                background-color: #E9E9E9;
                padding: 9px 60px;
            `;
            if(!selected) return css`
            background-color: #F2F2F2;
            padding: 9px 60px;
        `;
        } 
    }
    @media only screen and (max-width: 960px) {
        font-size: 12px;
        margin-right: 15px;
        width: 98px;
        padding: 0;
        ${
            ({ selected }) =>{
                if(selected) return css`
                    padding: 0;
                `;
            }
        }
    }
    @media only screen and (max-width: 720px) {
        font-size: 11px;
        width: 98px;
    }
`;

const ButtonWrapper = styled("div")`
    width: 150px !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

interface StatusIndicatorProps {
    bgColor: String
}

const StatusIndicator = styled("div")<StatusIndicatorProps>`
    height: 13px;
    width: 13px;
    margin-right: 0px;
    margin-left: 0px;
    border-radius: 50%;
    ${
        ({ bgColor }) =>{
            return css`
                background-color: ${bgColor as string};
            `;
        }
    }
    @media only screen and (max-width: 960px) {
        margin-right: 5px;
    }
`;

const ButtonText = styled("p")`
    width: 85px !important;
    text-align: center; 
    transform: translateY(38%);
`;

interface StatusButtonProps {
    children: React.ReactNode;
    status: string;
    className?: String;
    onClick?: () => any,
    selected?: Boolean
}

function getStatusColor(status: String):String {
    switch(status) {
        case "online":
            return "#00D21D"
        case "busy":
            return "#EE3F46"
        case "inactive":
            return "#AAAAAA"
        case "all":
            return "#45494D"
        case "offline":
            return "#AAAAAA"
        default:
            return "#AAAAAA"
    }
}

const StatusButton:React.FC<StatusButtonProps> = ({ 
    children, 
    status, 
    className="", 
    onClick,
    selected=false
}) =>{
    return(
        <ButtonContainer
            statusBtn={status}
            className={className as string}
            onClick={onClick}
            selected = {selected}
        >
            <ButtonWrapper>
                {
                    ( 
                        status !== "favourite" && 
                        status !== "none"
                    ) && (
                        <StatusIndicator
                            bgColor={getStatusColor(status)}
                        />
                    )
                }
                {
                    (status === "favourite") && (
                        <Image 
                            src={fav}
                            style={{
                                marginRight: 2,
                            }}
                        />
                    )
                }
                <ButtonText>
                    {
                        children
                    } 
                </ButtonText>
            </ButtonWrapper>
        </ButtonContainer>
    )
}

export default StatusButton;

import React from "react";
import { Image } from "react-bootstrap";
import { useHistory } from "react-router";

import cardThreeUnavailableImg from "../../../assets/student-instructors-page/card-three-unavailable.png";
import { useTranslation } from "react-i18next";

const NextSessionUnavailable = () => {
    var history = useHistory();
    const { t } = useTranslation();
    return (
        <div className="student-instructors-page-card-container">
            <Image
                src={cardThreeUnavailableImg}
            />
            <div className="student-instructors-page-card-content">
                <h4 className="student-instructors-page-card-next-session-unavailable">
                    {t("dashboard.no_reservation_found")}
                    <span className="student-instructors-page-card-next-session-unavailable-reservation" onClick={() => {
                        history.push("/reservations/create")
                    }}>
                        {t("dashboard.no_reservation_found_create")}
                    </span>

                </h4>
            </div>
        </div>
    )
}

export default NextSessionUnavailable;

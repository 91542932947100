import horoscope from "../../assets/trainings/horoscopes.png";
import joinInterviews1 from "../../assets/trainings/joinInterviews1.png";
import joinInterviews2 from "../../assets/trainings/joinInterviews2.png";
import joinInterviews3 from "../../assets/trainings/joinInterviews3.png";

export const trainingCards = [
    {
        title: "HOROSCOPES",
        img: horoscope
    },
    {
        title: "JOIN INTERVIEWS",
        img: joinInterviews1
    },
    {
        title: "JOIN INTERVIEWS",
        img: joinInterviews2
    },
    {
        title: "JOIN INTERVIEWS",
        img: joinInterviews3
    },
]

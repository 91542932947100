import React, { useState } from "react";
import Page from "../../components/pages";
import { RouteComponentProps } from "react-router-dom";
import Upload from "./Upload";
import FileLists from "./FileLists";
import Content from "./Content";
import {useTranslation} from "react-i18next";
//  import { instructors, getInstructorsByStatus } from "../../Global/InstructorsPage/Instructors";

const FileUploadPage: React.FC<RouteComponentProps> = ({ history }) => {
    const [instructorsList, setInstructorsList] = useState([]);
    const { t } = useTranslation();
    return (
        <Page type="dashboard" history={history} headerTitle={t("general.files")} activePage="Files">
            <Content />
        </Page>
    )
}

export default FileUploadPage;

import React from 'react';
import { Card } from 'react-bootstrap';
import cardThreeUnavailableImg from "../../assets/student-instructors-page/card-three-unavailable.png";
import UpcomingReservationItem from './UpcomingReservationItem';
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface UpcomingReservationsProp {
    response: any
}

const UpcomingReservations: React.FC<UpcomingReservationsProp> = ({
    response,
}) => {
    const { t } = useTranslation();

    let flag = true

    if (!response?.scheduledMeeting || response?.scheduledMeeting.length < 1) {
        flag = false;
    }

    return (
        <Card className="small-card">
            <Card.Body>
                <h5>{t("dashboard.upcoming_reservations")}</h5>
                <div className="yak-card">
                    {flag && response.scheduledMeeting.map((res: any, index: any) => (
                        <UpcomingReservationItem
                            name={res.studentUser.name}
                            surname={res.studentUser.surname}
                            startDate={res.startDate}
                            endDate={res.endDate}
                            totalMinute={res.totalMinute} />
                    ))
                    }
                    {!flag && <div>
                        <Image
                            src={cardThreeUnavailableImg}
                        />
                        <div className="student-instructors-page-card-content">
                            <h4 className="student-instructors-page-card-next-session-unavailable">
                                {t("dashboard.no_reservation_found")}
                            </h4>
                        </div>
                    </div>
                    }
                </div>
            </Card.Body>
        </Card>
    )
}

export default UpcomingReservations;
import React, { useState } from "react";
import "../../../layout/HomeV2/New_FrequentlyAskedQuestions.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion, Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Button from "../../../components/buttons";
import styled from "styled-components";
import theme from "../../../theme";
import "../../../mobile/Home/FrequentlyAskedQuestions.css";
import "../../../tablet/Home/FrequentlyAskedQuestions.css";
import Container from "../../../components/Container";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const StyledButton = styled(Button)`
    padding: 12px 80px;
    display: block;
    margin: 30px auto 0 !important;
    &:hover {
        background-color: ${theme.colors.darkPrimary};
        border-color: ${theme.colors.darkPrimary};
    }
`;

interface ContentComponentProps {
    questions: any[];
}

type ContentProps = ContentComponentProps;

const FrequentlyAskedQuestions: React.FC<ContentProps> = ({ questions }) => {
    var history = useHistory();
    const { t } = useTranslation();
    const [openOne, setOpenOne] = useState(false)
    const [openTwo, setOpenTwo] = useState(false)
    const [openThree, setOpenThree] = useState(false)
    const [openFour, setOpenFour] = useState(false)
    const [openFive, setOpenFive] = useState(false)
    const [openSix, setOpenSix] = useState(false)
    const controllers = [openOne, openTwo, openThree, openFour, openFive, openSix];
    const actions = [setOpenOne, setOpenTwo, setOpenThree, setOpenFour, setOpenFive, setOpenSix];
    const handleOpen = (open: any, setOpen: any) => {
        if (open) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    }
    return (
        <div className="new_frequently-asked-questions">
            <Container className=" frequently-asked-questions-container">
                <h2 className="new_frequently-asked-questions-title">{t("home.faq.title")}</h2>
                <div>
                    {
                        questions.map((question, index) => (
                            <Accordion defaultActiveKey="0" className="new_frequently-asked-questions-accordion mb-3" key={question.id}>
                                <Card style={{ border: 0 }}>
                                    <Card.Header className="new_frequently-asked-questions-accordion-header" style={{ backgroundColor: "#F2F2F2" }}>
                                        <h6 className="new_frequently-asked-questions-accordion-title" style={{ color: `${controllers[index] ? "#FF725E" : "#52575C"}` }}>{t(question.question)}</h6>
                                        <Accordion.Toggle as={Button} variant="link" className="new_frequently-ask-question-accordion-toggler" eventKey="1" onClick={() => handleOpen(controllers[index], actions[index])}>
                                            {
                                                controllers[index] ? <FontAwesomeIcon icon={faMinus} size="lg" color="#FF725E" /> : <FontAwesomeIcon icon={faPlus} size="lg" color="#52575C" />
                                            }
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body className="new_frequently-asked-questions-collapse">
                                            <p className="new_frequently-asked-questions-answer">
                                                {t(question.answer)}
                                            </p>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        ))
                    }
                    {/* <StyledButton type="default" onClick={(e: any) => { history.push("/questions/student") }} primary className="new_frequently-asked-questions-btn">Tüm SSS</StyledButton> */}
                </div>
            </Container>
        </div>
    )
}

export default FrequentlyAskedQuestions;

import React, { useEffect, useState } from "react";
import "../layout/NewFooter.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
  faLinkedinIn,
  faWhatsapp,
  faWhatsappSquare
} from "@fortawesome/free-brands-svg-icons";
import "../mobile/Footer.css";
import "../tablet/Footer.css";
import Container from "./Container";
import ChatDialog from "./chat/ChatDialog";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import whatsappIcon from "../assets/Home/icon-whatsapp.png";
import { Col, Image, Row } from "react-bootstrap";

import googlePlay from "../assets/google-play.png";
import appStore from "../assets/appstore.png";

import googlePlayMobile from "../assets/Home/google-play-mobile.png";
import logo from "../assets/Home/sllogo.png";
import appStoreMobile from "../assets/Home/app-store-mobile.png";
import getMobileOperatingSystem from "../Global/Utilities/utility";
import ReactFlagsSelect from "react-flags-select";
import languages from "../pages/TutorProfileSettings/Languages";
import LanguageChooser from "./LanguageChooser";

// The Footer Component

const NewFooter = ({ }) => {

  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [googlePlayIcon, setGooglePlayIcon] = useState(googlePlay);
  const [appStoreIcon, setAppStoreIcon] = useState(appStore);
  const handleResponsiveness = () => {
    const width = window.screen.width;
    if (width < 960) {
      setGooglePlayIcon(googlePlayMobile)
      setAppStoreIcon(appStoreMobile)
    }
  }

  const handleApplicationDownloadClicked = (title: String) => {
    var mobile = getMobileOperatingSystem();
    if (mobile === "Android") {
        window.location.href = "https://play.google.com/store/apps/details?id=com.SpeakingLine.android";
    }
    else if (mobile === "IOS") {
        window.location.href = "itms-apps://apps.apple.com/tr/app/speakingline/id1484624249";
    }
    else {
        if (title === "android") {
            window.location.href = "https://play.google.com/store/apps/details?id=com.SpeakingLine.android";
        }
        else if (title === "ios") {
            window.location.href = "itms-apps://apps.apple.com/tr/app/speakingline/id1484624249";
        }
    }
}

  useEffect(() => {
    handleResponsiveness()
  }, [])

  // useEffect(() => {
  //   handleResponsiveness()
  // }, [selected])
  return (
    <React.Fragment>
      <Container>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ChatDialog />
        </div>
      </Container>

      <Container>
        <div >
          <a id="chatButton" href="http://api.whatsapp.com/send?phone=902162180218" className="whatsapp-button-container" rel="noreferrer" target="_blank">
            {/* <i className="fab fa-whatsapp"></i>
          <span className="chatText">WhatsApp Hattı</span> */}
            <Image
              src={whatsappIcon}
              className="whatsapp-icon"
              onClick={() => { window.location.href = "http://api.whatsapp.com/send?phone=902162180218" }}
            />
            {/* <a target="_blank" href="http://api.whatsapp.com/send?phone=902162180218"  className="whatsapp-link">
                  Whatsapp
                </a> */}
            <span className="chatText">WhatsApp</span>
          </a>
        </div>
      </Container>

      <footer className="new-footer">
        <Container className="new-footer-container">
          <Row className="new-footer-first">
            <Col className="new-footer-first-sl col-md-4 col-12">
              <Container>
                <Row className="new-footer-app-logo"><Image src={logo} /></Row>
                <Row><p className="new-footer-first-description"> {t("home.footer.new-footer-first-description")}</p></Row>
                <Row>
                  <Col className="new-app-icon col-6"><Image src={googlePlayIcon} onClick={() => handleApplicationDownloadClicked('android')} className="new-app-icon" /></Col>
                  <Col className="new-app-icon col-6"><Image src={appStoreIcon} onClick={() => handleApplicationDownloadClicked('ios')} className="new-app-icon" /></Col>
                </Row>
              </Container>
            </Col>
            <Col className="new-footer-first-katil m-10 col-md-2 col-12">
              <Container>
                <Row> <p className="new-footer-title">{t("home.footer.new-footer-title")}</p></Row>
                <Row> <a onClick={() => { history.push("/") }} className="new-footer-link">{t("home.footer.new-footer-link")}</a></Row>
                <Row> <a onClick={() => { history.push("/kids") }} className="new-footer-link">{t("home.footer.new-footer-link_for_kids")}</a></Row>
                <Row> <a onClick={() => { history.push("/corporate") }} className="new-footer-link">{t("home.footer.new-footer-link_for_comp")}</a></Row>
                <Row> <a onClick={() => { history.push("/tutor") }} className="new-footer-link">{t("home.footer.new-footer-link_for_be_tutor")}</a></Row>
              </Container>
            </Col>
            <Col className="new-footer-first-katil col-md-2 col-12">
              <Container>
                <Row> <p className="new-footer-title">Speakingline</p></Row>
                <Row> <a onClick={() => { history.push("/policy/privacy") }} className="new-footer-link">{t("home.footer.new-footer-link_for_about")}</a></Row>
                <Row> <a onClick={() => { history.push("/career") }} className="new-footer-link">{t("home.footer.new-footer-link_for_career")}</a></Row>
                {/* <Row> <a onClick={() => { history.push("/policy/usage") }} className="new-footer-link">Şirketler için Speakingline</a></Row>
                <Row> <a onClick={() => { history.push("/policy/usage") }} className="new-footer-link">Be a Tutor</a></Row> */}
              </Container>
            </Col>
            <Col className="new-footer-first-katil col-md-2 col-12">
              <Container>
                <Row> <p className="new-footer-title">{t("home.footer.new-footer-link_for_social")}</p></Row>
                <Row>
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    size={"lg"}
                    className="footer-icon"
                    onClick={() => { window.location.href = "https://www.facebook.com/speakinglineapp" }} />

                  <FontAwesomeIcon
                    icon={faYoutube}
                    size={"lg"}
                    className="footer-icon"
                    onClick={() => { window.location.href = "https://www.youtube.com/channel/UC3xaQCQoQxZTb0JhzpohX4g" }} />

                  <FontAwesomeIcon
                    icon={faLinkedinIn}
                    size={"lg"}
                    className="footer-icon"
                    onClick={() => { window.location.href = "https://www.linkedin.com/company/speakingline/" }} />
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size={"lg"}
                    className="footer-icon"
                    onClick={() => { window.location.href = "https://www.instagram.com/speakinglineapp" }} />
                </Row>
              </Container>
            </Col>
            <Col className="new-footer-first-katil col-md-2 col-12">
              <Row> <p className="new-footer-title">Language</p></Row>
              <Row>
                <LanguageChooser/>
              </Row>
            </Col>
          </Row>
          <div className="new-footer-horizantal-line" />
          <Row className="new-footer-second">
            <Col className=" new-footer-second-title col-md-7 col-12">© 2024 Speakingline</Col>
            <Col> <a onClick={() => { history.push("/policy/privacy") }} className="new-footer-second-link">{t("home.footer.new-footer-link_for_privacy")}</a></Col>
            <Col> <a onClick={() => { history.push("/policy/usage") }} className="new-footer-second-link">{t("home.footer.new-footer-link_for_usage")}</a></Col>
            <Col> </Col>
          </Row>
        </Container>
      </footer>

    </React.Fragment>
  );
};

export default NewFooter;

import React, { useState } from "react";
import "../../layout/InstructorProfileSettings/ProfileVideo.css";
import StyledButton from "../../components/buttons";
import styled from "styled-components";
import VideoModal from "../../components/modals/VideoModal/VideoModal";

const Button = styled(StyledButton)`
    padding: 10px 125px;
    margin-top: 15px;
`;

interface ProfileVideoProps {
    videoUrl : string;
    update?: () => void;
}

const ProfileVideo : React.FC<ProfileVideoProps> = ({
    videoUrl,
    update,
}) => {

    const [isShowVideoModal, setIsShowVideoModal] = useState(false);
    return (
        <React.Fragment>
            <div className="instructor-profile-video-container">
                <h4 className="instructor-profile-video-title">PROFILE VIDEO</h4>
                <Button type="default" secondary onClick={()=>{setIsShowVideoModal(true)}}>Edit/Save</Button>
                <hr className="instructor-section-sep" />
            </div>

            {
                isShowVideoModal &&
                <VideoModal
                    update = {update}
                    videoUrl = {videoUrl}
                    show={isShowVideoModal}
                    hide={() => { setIsShowVideoModal(false) }}
                />
            }
        </React.Fragment>
    )
}

export default ProfileVideo;

import React, { useState, useEffect } from "react";
import { History } from "history";
import { Navbar, Nav, Image, Button } from "react-bootstrap";
import theme from "../../theme";
import {
    DashboardNavBarContainer,
    DashboardNavBarWrapper,
    DashboardNavBarLink,
    DashboardNavBarIcon,
    NavBarCollpase
} from "./StyledComponents";
import "../../layout/navbar/NavBarBurgerMenu.css";
import styled, { css } from "styled-components";

import logo from "../../assets/navbar/white-logo.png";
import logoDark from "../../assets/navbar/logo.png";
import burgerMenu from "../../assets/navbar/burger-menu.png";
import burgerMenuWhite from "../../assets/navbar/burger-menu-white.png";
import { ROLES } from "../../Global/Constants/constants";
import { connect, useDispatch } from "react-redux";
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import ChatRoom from "../chat/ChatRoom";
import { message_white, message_black, message_white_new, message_black_new, calendar_white_new, calendar_black_new, profile_black_new, profile_white_new } from "../../assets/SVG/SVG"
import { MESSAGES_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import useSound from "use-sound";




const AdminDashboardNavBar: React.FC<any> = ({
    history,
    chatProp,
    navTheme,
    onClickExpand
}) => {
    const [play, { stop }] = useSound("/sounds/message_sound.mp3");

    const [messageIcon, setMessageIcon] = useState<any>({ white: message_white, dark: message_black });
    const [popoverOpen, setPopoverOpen] = useState(false);
    const toggle = () => {
        setMessageIcon({ white: message_white, dark: message_black })
        setPopoverOpen(!popoverOpen)
    };

    const [logoImg, setLogoImg] = useState(logo)
    const handleLogoImg = () => {
        if (navTheme === "dark") return setLogoImg(logoDark)
        return logoImg
    }
    useEffect(() => {
        if (chatProp?.chatMessage?.roomId && !popoverOpen) {
            console.log("AdminDashboard -- popOver not open ");
            play();
            setMessageIcon({ white: message_white_new, dark: message_black_new })
        }
    }, [chatProp])

    useEffect(() => {
        handleLogoImg();
        LoadRooms();
    }, [])

    const LoadRooms = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(MESSAGES_URL + "/api/v1/rooms/user"));
        // Handling Error
        if (!res.success) {
            return null;
        }
        if (res.data && res.data.length > 0) {

            const unReadMessage = res.data.filter((x: any) => x.unReadMessageCount > 0);
            if (unReadMessage && unReadMessage.length > 0) {
                setMessageIcon({ white: message_white_new, dark: message_black_new })
                // dispatch({
                //     type: "CHAT_MESSAGE_IS_RECEIVED",
                //     payload: { 
                //         roomId : unReadMessage[0].id
                //     }
                // })
            }
            else {
                setMessageIcon({ white: message_white, dark: message_black })
            }

        }
    }

    return (
        <React.Fragment>
            <DashboardNavBarContainer collapseOnSelect expand="lg" dark={(navTheme === "dark")}>
                <DashboardNavBarWrapper>
                    <Navbar.Brand style={{ cursor: "pointer" }} onClick={() =>  history.push("/student/dashboard") }>
                        <Image src={logoImg} />
                    </Navbar.Brand>
                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                        className="main-nav-bar-burger-menu"
                        style={{ marginTop: -10 }}
                    >
                        {
                            (navTheme === "dark") ? (
                                <Image
                                    src={burgerMenu}
                                    onClick={onClickExpand}
                                />
                            ) : (
                                <Image
                                    src={burgerMenuWhite}
                                    onClick={onClickExpand}
                                />
                            )
                        }
                    </Navbar.Toggle>
                    <NavBarCollpase navTheme={navTheme || "normal"} id="responsive-navbar-nav">
                        <Nav className="ml-auto">
                            {/* {
                                (studentDashboard) ? studentDashboardPageLink.map((link, index) => (
                                    <DashboardNavBarLink
                                        dark={(navTheme === "dark")}
                                        key={index}
                                        onClick={() => history.push(link.link)}
                                    >
                                        {link.title}
                                    </DashboardNavBarLink>
                                )) : InstructorDashboardPageLink.map((link, index) => (
                                    <DashboardNavBarLink
                                        dark={(navTheme === "dark")}
                                        key={index}
                                        onClick={() => history.push(link.link)}
                                    >
                                        {link.title}
                                    </DashboardNavBarLink>
                                ))
                            } */}

                            <DashboardNavBarIcon>
                                <div id="message" >
                                    {
                                        (navTheme === "dark") ?
                                            messageIcon.dark :
                                            messageIcon.white
                                    }
                                </div>

                            </DashboardNavBarIcon>
                            {/* <DashboardNavBarIcon>
                                <button style={{ background: "none" }} onClick={() => studentDashboard ? history.push("/student/profile/settings") : history.push("/tutor/profile/settings")}>
                                    {
                                        (navTheme === "dark") ?
                                            profile_black_new :
                                            profile_white_new
                                    }
                                </button>
                            </DashboardNavBarIcon> */}
                        </Nav>
                    </NavBarCollpase>
                </DashboardNavBarWrapper>
            </DashboardNavBarContainer>

            <Popover placement="bottom" isOpen={popoverOpen} target="message" toggle={toggle}>
                <ChatRoom isOpen={popoverOpen} hide={toggle} />
                {/* <PopoverHeader>Popover Title</PopoverHeader>
                <PopoverBody>Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</PopoverBody> */}
            </Popover>

        </React.Fragment>
    )
}

function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer,
        chatProp: state.ChatReducer,
    }
}

export default connect(mapStateToProps)(AdminDashboardNavBar);
import React, { useEffect, useState } from "react";
import "../../../layout/StudentVideoChat/ChatRoomMessanger.css";
import Message from "../../../pages/StudentVideoChat/Message";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSpring, animated } from "react-spring";
import { connect } from "react-redux";

import { MESSAGES_URL } from "../../../Global/Constants/constants";
import { ReturnProps } from "../../../@types/functions";
import axios from "axios";
import "../../../mobile/StudentVideoChat/ChatRoomMessanger.css";
import { Image } from "react-bootstrap";

import moment from "moment";
import AlertInfo from "../../alerts/AlertInfo";

interface ChatRoomMessangerProps {
    openChatRoom: Boolean,
}

const ChatRoomMessanger: React.FC<any> = ({
    auth,
    meetingId,
    attendeeName,
    showChatRoom,
}) => {
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [startAnim, setStartAnim] = useState(false);
    const [messages, setMessages] = useState([]);

    const chatroomContentProps = useSpring({
        opacity: startAnim ? 1 : 0,
        config: {
            duration: 500
        }
    })
    const handleOpacity = () => {
        setTimeout(() => {
            setStartAnim(true)
        }, 500)
    }

    useEffect(() => {
        handleOpacity()
    }, [showChatRoom])


    useEffect(() => {
            getMessagesFromServer()
    }, [showChatRoom])


    const getMessagesFromServer = async () => {

        const res = await axios.get<any, ReturnProps>(MESSAGES_URL + "/api/v1/rooms/" + meetingId + "/messages?pageSize=100&pageNumber=1");
        if (!res.success || !res.data) {
            setError(res.error as string);
            setShowError(true)
            // alert(res.error)
            return null;
        }
        if(res.data.messages && res.data.messages.length > 0)
        {
            var array = res.data.messages;
            var sorted:any = array.sort((a:any, b:any) => a.createdAt - b.createdAt)
            setMessages(sorted);
        }
    };

    const [message, setMessage] = useState("");

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />

            <div className="chatroom-wrapper">
                <animated.div
                    className="chatroom-div-wrapper"
                    style={{
                        justifyContent : "flex-start",
                        opacity: chatroomContentProps.opacity
                    }}
                >
                    {/* <div className="chatroom-mobile-header">
                        <h4 className="chatroom-mobile-header-name">{attendeeName}</h4>
                    </div> */}
                    <animated.div
                        className="chatroom-messages-container"
                        style={{
                            opacity: chatroomContentProps.opacity
                        }}
                    >
                        <Scrollbars>
                            {
                                // eslint-disable-next-line array-callback-return
                                messages.map((msg: any) => (
                                    <Message
                                        type={(msg.senderUser?.guid === auth.user.guid) ? "owner" : "opponent"}
                                        message={msg.messageText}
                                        time={msg.createdAt}
                                    />
                                ))
                            }
                        </Scrollbars>
                    </animated.div>
                </animated.div>
            </div>
        </React.Fragment>

    )
}

function mapStateToProps(state: any) {
    return {
        auth: state.AuthReducer,
        showChatRoom: state.chatRoomReducer,
    }
}

export default connect(mapStateToProps)(ChatRoomMessanger)


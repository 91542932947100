import React, { useState, useEffect } from "react";
import "../../layout/ChoosePackage/Content.css";
import "../../mobile/ChoosePackage/Content.css";
import { Container, Button, Badge } from "react-bootstrap";
import { minutesOptions, daysOptions } from "../../Global/MinutesOptions";
import { getPeriodicValueList, PRODUCT_TYPES, PERIOD_TYPES, getMonthsList } from "../../Global/ChoosePackage/Packages";
//import {PRODUCT_TYPES, PERIOD_TYPES} from "../../Global/Constants/constants";
import { SUBSCRIPTION_URL } from "../../Global/Constants/constants";
import { ReturnProps } from "../../@types/functions";
import axios from "axios";
import { useSetState } from "../../Global/Utilities/getsetstate"
import { trackPromise } from "react-promise-tracker";
import AlertInfo from "../../components/alerts/AlertInfo";
import {useTranslation} from "react-i18next";

interface ContentProps {
    existingProduct: any;
    handlePackageValueChosen: (product: any) => void;
    discountForExistingPackages?: number;
}

const Content: React.FC<ContentProps> = ({
    handlePackageValueChosen,
    existingProduct = null,
    discountForExistingPackages = 0,
}) => {
    const { t } = useTranslation();

    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [minutesChosen, setMinutesChosen, getMinutesChosen] = useSetState<number>(0);
    const [daysChosen, setDaysChosen, getDaysChosen] = useSetState<number>(0);
    const [monthsChosen, setMonthsChosen, getMonthsChosen] = useSetState<number>(0);

    const [minutes, setMinutes] = useState<any>([]);
    const [days, setDays] = useState<any>([]);
    const [months, setMonths, getMonths] = useSetState<any>([]);

    const [packages, setPackages] = useState<[any]>([{}]);
    // let minutesValues = [10, 20, 40, 60];
    // let weekValues = [2, 3, 5, 7];
    //let packagesChosenValue = [1, 3, 6];

    const LoadPackageData = async () => {
        const res = await trackPromise(axios.get<any, ReturnProps>(SUBSCRIPTION_URL + "/api/v1/product"));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            return null
        }
        //console.log(res);

        const d = getPeriodicValueList(res.data.productPeriodTypes, PERIOD_TYPES.DAY_IN_A_WEEK);
        const m = getPeriodicValueList(res.data.productPeriodTypes, PERIOD_TYPES.MINUTE_IN_A_DAY)
        const dValue: number = d[daysChosen];
        const mValue: number = m[minutesChosen]
        const mo = getMonthsList(res.data.products, PERIOD_TYPES.MONTH_IN_A_YEAR, mValue, dValue)
        setDays(d);
        setMinutes(m);
        setMonths(mo);

        // //console.log(days)
        // //console.log(minutesChosen)

        setPackages(res.data.products);

        //  dispatch({ type: "STUDENT_DASHBOARD_CHANGED" , payload : res.data });
    }

    const selectPackage = async () => {
        // const min = await getMinutesChosen()
        // const da = await getDaysChosen()
        const mo = await getMonthsChosen()
        const temp_months = await getMonths()
        //   const month = getMonthsList(packages, PERIOD_TYPES.MONTH_IN_A_YEAR,minutes[min],days[da])
        //  setMonths(month);

        const selected_package = temp_months[mo]
        //  const selected_package = months[index]
        // let selectedPackage = packages.filter(x=>x.dailyProduct.value === minutes[minutesChosen] && x.weeklyProduct.value === days[daysChosen] && x.monthlyProduct.value === months[monthsChosen])
        handlePackageValueChosen(selected_package);
    }

    const selectMinuteAndDay = async () => {
        const min = await getMinutesChosen()
        const da = await getDaysChosen()
        const month = getMonthsList(packages, PERIOD_TYPES.MONTH_IN_A_YEAR, minutes[min], days[da])
        setMonths(month);
        setMonthsChosen(0);
        selectPackage();
    }

    // const selectMinute = async(index:number) => {
    //     //useEffect(() => { setMinutesChosen(index) }, [])
    // }

    useEffect(() => {
        LoadPackageData()
    }, []);

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />

            <Container className="text-left">

                {existingProduct && discountForExistingPackages > 0 && <div className="current-subscription-package">
                    <div className="current-subscription-details">
                        <h4 className="current-subscription-title">{t("home.subscription_packages.active_package")}</h4>
                        <h4 className="current-subscription-subtitle">{t("dashboard.daily")} {existingProduct.dailyProduct.value} {t("dashboard.minutes")}, {t("dashboard.weekly")} {existingProduct.weeklyProduct.value} {t("dashboard.day")} - {existingProduct.monthlyProduct.value} {t("home.subscription_packages.monthly_exist")}</h4>
                    </div>
                    <div className="current-subscription-price">
                        <h4 className="current-subscription-title current-subscription-price-title">{t("home.subscription_packages.remaining_package")}</h4>
                        <h4 className="current-subscription-subtitle current-subscription-price-title">₺ {discountForExistingPackages}</h4>
                    </div>
                </div>
                }


                <h6 className="packages-titles">{t("home.subscription_packages.package_title")}</h6>
                <h4 className="packages-subtitle">{t("home.subscription_packages.package_subtitle")}</h4>

                <div className="package-section">
                    <h6 className="option-title">{t("home.subscription_packages.package_option")}</h6>
                    <div className="option-odds">
                        {
                            minutes.map((option: number, index: number) => (
                                <Button
                                    variant=""
                                    className={`
                                    option-odd 
                                    ${(index === minutesChosen) && "option-odd-active"}
                                `}
                                    key={option}
                                    onClick={() => {
                                        setMinutesChosen(index)
                                        selectMinuteAndDay()
                                        //    useEffect(() => { setMinutesChosen(index) }, [])
                                        //   selectMinute(index);
                                        //      selectPackage();
                                        //  handleMinuteChosen(minutes[index])
                                    }}
                                >
                                    {option} {t("dashboard.minutes")}
                                </Button>
                            ))
                        }
                    </div>
                </div>
                <div className="package-section">
                    <h6 className="option-title" id="second-odds-container">{t("home.subscription_packages.package_option_minute_title")}</h6>
                    <div className="option-odds">
                        {
                            days.map((option: any, index: number) => <Button
                                variant=""
                                className={`
                                                                    option-odd
                                                                    ${(index === daysChosen) && "option-odd-active"}
                                                                `}
                                key={index}
                                onClick={() => {
                                    // set
                                    // useEffect(() => {setDaysChosen(index)}, [])
                                    setDaysChosen(index)
                                    selectMinuteAndDay()
                                    //  selectPackage()
                                    //   handleWeekChosen(days[index])
                                }}
                            >
                                {option} {t("general.day")}
                                                            </Button>
                            )
                        }
                    </div>
                </div>

                <h6 className="option-title subscription-option-title">
                    <span>{t("home.subscription_packages.title")}</span>
                    <Badge className="subscription-badge">
                        {t("dashboard.daily")} {minutes[minutesChosen]} {t("dashboard.minutes")} {t("dashboard.weekly")} {days[daysChosen]} {t("dashboard.day")}
                </Badge>
                </h6>
                <div className="subscription-packages">
                    {
                        months.map((pack: any, index: number) => (
                            <div
                                className={`
                                subscription-package 
                                ${(pack.guid === existingProduct?.guid) && "current-package"} 
                                ${(index === monthsChosen) && "active"}
                            `}
                                key={pack.guid}
                                onClick={() => {
                                    if (pack.guid !== existingProduct?.guid) {
                                        setMonthsChosen(index)
                                        selectPackage()
                                    }
                                }}
                            >
                                <h5 className="package-title">{pack.monthlyProduct.value} {t("general.month")}</h5>
                                <h5 className="current-price">{Math.round(((pack.price - pack.price * pack.discountRate / 100) / pack.monthlyProduct.value) * 100) / 100} /{t("general.month")}</h5>
                                <h6 className="auto-renew mt-3 mb-2">{pack.monthlyProduct.value} {t("home.subscription_packages.renew_monthly")}</h6>
                                <div className="discount-area">
                                    {pack.discountRate && pack.discountRate > 0 ? <p className="discount">%{pack.discountRate} {t("dashboard.discount")}</p> : <p className="discount">&nbsp;</p>}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Container>
        </React.Fragment>
    )
}


export default Content;
